import { BpWeeksDto } from 'types/PrpTable/dto/ProjectTableParamsDto';

const isCellAloneInBillingPeriod = (
  billingPeriodWeeks: BpWeeksDto[],
  billingPeriodIndex: number
) => {
  return billingPeriodWeeks[billingPeriodIndex]?.numberOfWeeks === 1;
};

export default isCellAloneInBillingPeriod;
