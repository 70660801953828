import { createAsyncThunk } from '@reduxjs/toolkit';
import authService from 'api/authApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/employee/employeeSlice';
import { AuthorizationDto } from 'types/authorization/AuthorizationsDto';
import AuthorizationAction from 'types/authorization/enum/Authorization';

const getUser = createAsyncThunk<
  AuthorizationDto,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(AuthorizationAction.FETCH_USER, async () => {
  const response = await authService.fetchUser();
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default getUser;
