import { AllocationsDictionariesState } from 'store/slicesState/allocationsDictionaries';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';

const selectEmployeeWeeklyCapacitiesById = (
  state: AllocationsDictionariesState,
  id: number,
  property: DictionariesSelector.Name | DictionariesSelector.DisplayName
): number | null => {
  const employeeWeeklyCapacitiesDictionary = state.data?.employeeWeeklyCapacities;
  if (!employeeWeeklyCapacitiesDictionary) {
    return null;
  }
  const employeeWeeklyCapacities = employeeWeeklyCapacitiesDictionary.find(
    (cl) => cl.id === Number(id)
  );

  return employeeWeeklyCapacities?.[property] ? Number(employeeWeeklyCapacities?.[property]) : null;
};

export default selectEmployeeWeeklyCapacitiesById;
