import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AddAdditionalCostFormDto } from 'types/project/dto/AddAdditionalCostFormDto';
import { AdditionalCostDto } from 'types/invoice/AdditionalCostDto';
import mapToAdditionalCostDto from 'store/mappers/project/additionalCostMapper';

const additionalCostsApiService: IApiService = {
  editAdditionalCost: async (data: AddAdditionalCostFormDto) => {
    try {
      const addAdditionalCostDto = mapToAdditionalCostDto(data);
      const response = await apiRequest<AdditionalCostDto>({
        method: 'PUT',
        endpoint: `${API_URL}/invoices/${data.invoiceId}/additional-cost`,
        requestBody: addAdditionalCostDto
      });
      return response;
    } catch (error) {
      logError('Error in editAdditionalCost');
      throw new Error('Error in editAdditionalCost');
    }
  },
  deleteAdditionalCost: async (invoiceId: number) => {
    try {
      await apiRequest<void>({
        method: 'DELETE',
        endpoint: `${API_URL}/invoices/${invoiceId}/additional-cost`
      });
    } catch (error) {
      logError('Error in deleteAdditionalCost');
      throw new Error('Error in deleteAdditionalCost');
    }
  }
};

interface IApiService {
  editAdditionalCost: (data: AddAdditionalCostFormDto) => Promise<AdditionalCostDto>;
  deleteAdditionalCost: (invoiceId: number) => Promise<void>;
}
export default additionalCostsApiService;
