import {
  borderRadiusCommon,
  marginTopCommon
} from 'components/sharedComponents/StyleConsts/styleConsts';
import { ALERT_COLOR, DARK_GREY, LOW_EMPHASISE_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledFormTextField = styled.div({
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    padding: '0'
  },
  '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  },
  '&  .MuiInputBase-root': {
    padding: 0
  },
  textarea: {
    border: '1px solid',
    borderColor: DARK_GREY,
    borderRadius: borderRadiusCommon,
    padding: '6px',
    width: '580px',
    marginTop: marginTopCommon,
    minHeight: '28px'
  },
  '& .error': {
    color: ALERT_COLOR
  },
  '.label': {
    color: LOW_EMPHASISE_COLOR
  }
});

export default StyledFormTextField;
