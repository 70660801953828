import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { SURFACE_COLOR, TEXT_COLOR, BORDERS_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledModalWindow = styled.div({
  height: '100%',
  width: '100%',
  position: 'fixed',
  top: '0',
  left: '0',
  zIndex: '1300',
  overflow: 'auto',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  '.btn-close': {
    position: 'absolute',
    right: '20px',
    top: '20px',
    cursor: 'pointer',
    border: 'none',
    background: 'none'
  },
  '.btn-close-with-dots': {
    position: 'absolute',
    right: '10px',
    top: '12px',
    cursor: 'pointer',
    border: 'none',
    background: 'none'
  },
  '.btn-more': {
    position: 'absolute',
    right: '56px',
    top: '28px',
    cursor: 'pointer',
    border: 'none',
    background: 'none'
  },
  '.btn-more-with-close': {
    position: 'absolute',
    right: '50px',
    top: '20px',
    cursor: 'pointer',
    border: 'none',
    background: 'none'
  },
  '.modal-body': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '60px'
  },
  '.modal-content, .small-modal-content': {
    backgroundColor: SURFACE_COLOR,
    width: '654px',
    minHeight: '530px',
    maxHeight: '600px',
    padding: '24px 24px',
    borderRadius: borderRadiusCommon,
    position: 'relative',
    overflow: 'visible',
    '.modal-container': {
      paddingBottom: '10px'
    }
  },
  '.small-modal-content': {
    minHeight: '200px',
    '.modal-container': {
      paddingBottom: '20px'
    },
    '.modal-scroll-content': {
      maxHeight: '450px',
      minHeight: '250px'
    }
  },
  '.modal-row': {
    marginBottom: '16px',
    display: 'flex',
    width: '100%'
  },
  '.title': {
    color: TEXT_COLOR,
    margin: '0 0 0 15px'
  },
  '.modal-scroll-content': {
    maxHeight: '440px',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  '.title-line': {
    color: BORDERS_COLOR,
    borderColor: BORDERS_COLOR,
    borderTop: BORDERS_COLOR
  },
  '.invoice-menu-button': {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    right: '60px',
    top: '28px'
  }
});

export default StyledModalWindow;
