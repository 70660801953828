import { YELLOW_ONE_HUNDRED } from 'constants/groupColors';
import styled from 'styled-components';

const StyledInitials = styled.div({
  width: '34px',
  height: '32px',
  background: YELLOW_ONE_HUNDRED,
  borderRadius: '50%',
  marginRight: '8px',
  display: 'flex',
  justifyContent: 'center',
  '.name': {
    alignSelf: 'center'
  }
});

export default StyledInitials;
