import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { BORDERS_COLOR, ON_PRIMARY_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledGenericSearchComponent = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  border: '1px solid',
  borderRadius: borderRadiusCommon,
  backgroundColor: ON_PRIMARY_COLOR,
  borderColor: BORDERS_COLOR,
  padding: '2px 8px 2px 8px',
  marginBottom: '20px',
  width: '294px',
  cursor: 'pointer',
  '.delete-icon': {
    cursor: 'pointer'
  }
});
export default StyledGenericSearchComponent;
