import { createAsyncThunk } from '@reduxjs/toolkit';
import allocationsTimeOffApiService from 'api/allocationTimeOffApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store/index';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import { AllocationTimeOffDto } from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffDto';
import AllocationTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffFormDto';
import AllocationsAction from 'types/allocation/enums/Allocations';

const updateAllocationTimeOff = createAsyncThunk<
  AllocationTimeOffDto,
  AllocationTimeOffFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(AllocationsAction.UPDATE_TIME_OFF, async (data: AllocationTimeOffFormDto) => {
  const response = await allocationsTimeOffApiService.updateTimeOff(data);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});
export default updateAllocationTimeOff;
