import { UpdateDiscountFormDto } from 'types/project/dto/UpdateDiscountFormDto';
import DiscountTypeEnum from 'types/PrpTable/enums/discountTypeEnum';

const UpdateDiscountFormDefalutValue: UpdateDiscountFormDto = {
  id: null,
  weekId: null,
  employeeId: null,
  projectId: null,
  duration: null,
  reason: '',
  repeatForWeeksInBillingPeriod: false,
  discountType: DiscountTypeEnum.DEFAULT
};

export default UpdateDiscountFormDefalutValue;
