import { ChangeEvent } from 'react';
import EmployeeStatusFilter from 'types/employee/enums/EmployeeStatusFilter';
import PageType from 'types/generalEnums/PageType';
import SearchItem from './SearchItem/SearchItem';
import SelectItem from '../../employee/SelectComponent/SelectItem';
import StyledGenericSearchComponent from './StyledGenericSearchComponent';

type GenericSearchComponentProps = {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  type: PageType.Employees | PageType.Clients | PageType.Project;
  statusFilter?: EmployeeStatusFilter;
  setStatusFilter?: (statusFilter: string) => void;
};
const GenericSearchComponent = ({
  value,
  onChange,
  type,
  statusFilter,
  setStatusFilter,
  onClear
}: GenericSearchComponentProps) => {
  return (
    <StyledGenericSearchComponent>
      <SearchItem value={value} onChange={onChange} className="search-item" onClear={onClear} />
      {type === PageType.Employees ? (
        <SelectItem
          className="select-item"
          statusFilter={statusFilter ?? EmployeeStatusFilter.All}
          setStatusFilter={setStatusFilter}
        />
      ) : null}
    </StyledGenericSearchComponent>
  );
};

export default GenericSearchComponent;
