import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import EmployeePage from 'pages/EmployeesPage/EmployeesPage';
import ClientsPage from 'pages/ClientsPage/ClientsPage';
import ProjectsPage from 'pages/ProjectsPage';
import AllocationsPage from 'pages/AllocationsPage/AllocationsPage';
import RedirectPage from 'pages/RedirectPage/RedirectPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';
import { ACCESS_TOKEN_LOCAL_STORAGE_KEY } from 'environment/environment';
import { ReactNode, useEffect } from 'react';
import DownTimeErrorPage from 'pages/DownTimeErrorPage/DownTimeErrorPage';
import ProjectDetails from 'components/project/ProjectDetails/ProjectDetails';
import RoutingPath from './routing';

const RootRouter = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY) !== null;
    if (!isAuthenticated && location.pathname !== RoutingPath.LogInPage) {
      navigate(RoutingPath.LogInPage);
    }
  }, [location, navigate]);

  const renderProtectedRoute = (element: ReactNode, isProtected: boolean) => {
    const isAuthenticated = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY) !== null;
    if (isAuthenticated) {
      return element;
    }
    if (!isProtected) {
      return element;
    }
    return <Navigate to={RoutingPath.LogInPage} replace />;
  };

  return (
    <Routes>
      <Route path="/" element={<Navigate to={RoutingPath.EmployeesPage} />} />
      <Route
        path={RoutingPath.EmployeesPage}
        element={renderProtectedRoute(<EmployeePage />, true)}
      />
      <Route
        path={RoutingPath.EmployeesPageDetails}
        element={renderProtectedRoute(<EmployeePage />, true)}
      />
      <Route path={RoutingPath.ClientsPage} element={renderProtectedRoute(<ClientsPage />, true)} />
      <Route
        path={RoutingPath.ClientsPageDetails}
        element={renderProtectedRoute(<ClientsPage />, true)}
      />
      <Route
        path={RoutingPath.ProjectsPage}
        element={renderProtectedRoute(<ProjectsPage />, true)}
      />
      <Route
        path={RoutingPath.AllocationPage}
        element={renderProtectedRoute(<AllocationsPage />, true)}
      />
      <Route
        path={RoutingPath.ProjectDetailsPage}
        element={renderProtectedRoute(<ProjectDetails />, true)}
      />
      <Route path={RoutingPath.LogInPage} element={<LoginPage />} />
      <Route path={RoutingPath.NotFoundPage} element={<NotFoundPage />} />
      <Route path={RoutingPath.RedirectPage} element={<RedirectPage />} />
      <Route path={RoutingPath.DownTimeErrorPage} element={<DownTimeErrorPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default RootRouter;
