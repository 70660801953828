import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import generateInvoiceApiService from 'api/generateInvoiceApiService';
import GenerateInvoice from 'types/generateInvoice/GenerateInvoice';
import { GenerateInvoiceDto } from 'types/invoice/GenerateInvoiceDto';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { GenerateInvoiceResponseDto } from 'types/invoice/GenerateInvoiceResponseDto';

const generateInvoice = createAsyncThunk<
  GenerateInvoiceResponseDto,
  GenerateInvoiceDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(GenerateInvoice.GENERATE_INVOICE, async (data: GenerateInvoiceDto) => {
  const response = await generateInvoiceApiService.generateInvoice(data);

  if (!response) notifyUser();
  return addKeyToReactItem(response);
});
export default generateInvoice;
