import { FormControl, InputBase } from '@mui/material';
import StyledFormInput from 'components/sharedComponents/FormComponents/FormInput/StyledFormInput';
import {
  FieldValues,
  Path,
  UseFormRegister,
  UseFormGetValues,
  useFormContext
} from 'react-hook-form';
import { useState } from 'react';

type FormInputNumberProps<T extends FieldValues> = {
  label?: string;
  name: string;
  required: boolean;
  register: UseFormRegister<T>;
  getValues: UseFormGetValues<T>;
  className?: string;
  error?: string;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
  placeholder?: string;
  isDecimal?: boolean;
  numberOfDigitsAfterDecimal?: number;
  numberOfDigitsBeforeDecimal?: number;
  icon?: JSX.Element | null;
};

const FormInputNumber = <T extends FieldValues>({
  label,
  name,
  required,
  register,
  getValues,
  error,
  className,
  disabled,
  placeholder,
  minValue,
  maxValue,
  isDecimal,
  numberOfDigitsAfterDecimal = 1,
  icon,
  numberOfDigitsBeforeDecimal
}: FormInputNumberProps<T>) => {
  const [value, setInputValue] = useState<number | string | null>(
    getValues(name as Path<T>) || null
  );
  const { setValue } = useFormContext();

  const getNumberRegex = () => {
    if (isDecimal && numberOfDigitsBeforeDecimal) {
      return new RegExp(
        `^[0-9]{0,${numberOfDigitsBeforeDecimal}}(\\.[0-9]{0,${numberOfDigitsAfterDecimal}})?$`
      );
    }
    if (isDecimal) {
      return new RegExp(`^[0-9]*(\\.?[0-9]{0,${numberOfDigitsAfterDecimal}})?$`);
    }
    return /^[0-9\b]+$/;
  };

  const handleNumberInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (e.target.value === '' || getNumberRegex().test(e.target.value)) {
      setInputValue(e.target.value);
      setValue(name, e.target.value);
    }
    if (maxValue && Number(e.target.value) > maxValue) {
      setInputValue(maxValue);
      setValue(name, maxValue);
    }
    if (e.target.value !== '' && minValue !== undefined && Number(e.target.value) < minValue) {
      setInputValue(minValue);
      setValue(name, minValue);
    }
  };
  return (
    <StyledFormInput>
      <FormControl>
        <div className="title-with-icon">
          <p className="inter-caption_medium label">{label}</p>
          {icon && icon}
        </div>
        <InputBase
          id={name as Path<T>}
          {...register(name as Path<T>, { required })}
          type="number"
          className={className}
          disabled={disabled || false}
          placeholder={placeholder || ''}
          onChange={(e) => {
            handleNumberInputChange(e);
          }}
          value={value}
        />
        {error && <p className="error inter-caption_medium">{error}</p>}
      </FormControl>
    </StyledFormInput>
  );
};

export default FormInputNumber;
