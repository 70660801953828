import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { Grid } from '@mui/material';
import FormInputNumber from 'components/sharedComponents/FormComponents/FormInput/FormInputNumber';
import InputType from 'types/generalEnums/InputType';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import EditEmployeeRateFormNames from 'types/project/enums/EditEmployeeRateFormNames';
import FormDatePicker from 'components/sharedComponents/FormComponents/FormDatePicker/FormDatePicker';
import StyledItemCard, {
  editRateCardStyle
} from 'components/sharedComponents/SharedItemCardStyle/StyledItemCard';
import Initials from 'components/sharedComponents/Initials/Initials';
import { minRate, maxRate } from 'constants/validationConstants';
import { ProjectDto } from 'types/project/dto/ProjectDto';
import selectPositionPropertyById from 'store/dictionarySelectors/selectorPosition';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import EditEmployeeRateDefaultValue, {
  EmployeeRateDefault
} from 'components/project/ProjectDetails/EditEmployeeRate/EditEmployeeRateDefaultValue';
import EditEmployeeRateValidation from 'components/project/ProjectDetails/EditEmployeeRate/EditEmployeeRateValidation';
import EditEmployeeRateFormDto from 'types/project/dto/EditEmployeeRateFormDto';
import { yupResolver } from '@hookform/resolvers/yup';
import FormTextField from 'components/sharedComponents/FormComponents/FormTextField/FormTextField';
import { useEffect, useState } from 'react';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { arrowDown, arrowUp } from 'shared/PrpIcon/icons.constants';
import { unwrapResult } from '@reduxjs/toolkit';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import getEmployeeRates from 'store/action/projectActions/getEmployeeRates';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import { memoize } from 'proxy-memoize';
import getEmployees from 'store/action/employeeActions/getEmployee';
import MainSpinner from 'components/sharedComponents/Spinner/MainSpinner';
import getWeekId from 'store/mappers/dictionaries/calendarMapper';
import addEmployeeRate from 'store/action/projectActions/addEmployeeRate';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import ButtonsText from 'types/generalEnums/ButtonsText';
import OnlyDateFilter from 'types/generalEnums/DatePickerFilterType';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import selectSeniorityPropertyById from 'store/dictionarySelectors/selectorSeniority';

type EditEmployeeRateFormProps = {
  onClose: () => void;
  item: ProjectDto | null;
  employeeId: number;
};

const EditEmployeeRateForm = ({ onClose, item, employeeId }: EditEmployeeRateFormProps) => {
  const employeeListState = useSelector((state: RootState) => state.dictionaries);
  const [isRateUpdated, setIsRateUpdated] = useState(true);
  const [hasRateHistory, setHasRateHistory] = useState(true);
  const [isRateHistoryVisible, setIsRateHistoryVisible] = useState(false);
  const [employeeRates, setEmployeeRates] = useState(EmployeeRateDefault);
  const dispatch = useThunkAppDispatch();
  const [defaultRate, setDefaultRate] = useState<number | null>(0);
  const [startDate, setStartDate] = useState(new Date());
  const [rate, setRate] = useState<number | null>(0);
  const [reason, setReason] = useState<string | null>('');

  useEffect(() => {
    if (item) {
      dispatch(getEmployeeRates({ projectId: item.id, employeeId }))
        .then(unwrapResult)
        .then()
        .catch(() => {
          logError('Error fetching employee rates');
        });
    }
  }, [item]);

  useEffect(() => {
    dispatch(getEmployees())
      .then(unwrapResult)
      .then()
      .catch(() => {
        logError('Error fetching employees');
      });
  }, [item]);

  const loadingEmployeeRates = useSelector((state: RootState) => state.employeeRatesSlice.loading);
  useEscapeKey(onClose);
  const loadingEmployees = useSelector((state: RootState) => state.employees.readLoading);

  const employees = useSelector((state: RootState) => state.employees.data);
  const stateEmployee = useAppSelector((state: RootState) => state.dictionaries);

  const selectedEmployee = employees.find((employee) => employee.id === employeeId);
  const seniorityName = selectSeniorityPropertyById(
    stateEmployee,
    selectedEmployee?.seniorityId || 0,
    DictionariesSelector.DisplayName
  );
  const fetchedEmployeeRates = useAppSelector(
    memoize((state: RootState) => state.employeeRatesSlice.data)
  );
  const calendarState = useAppSelector((state: RootState) => state.calendarDictionaries.data);

  const methods = useForm<EditEmployeeRateFormDto>({
    defaultValues: EditEmployeeRateDefaultValue(),
    resolver: yupResolver(
      EditEmployeeRateValidation(minRate, maxRate)
    ) as unknown as Resolver<EditEmployeeRateFormDto>
  });
  const {
    control,
    handleSubmit,
    register,
    getValues,
    watch,
    setValue,
    formState: { errors }
  } = methods;

  useEffect(() => {
    if (fetchedEmployeeRates) {
      setEmployeeRates(fetchedEmployeeRates);
    }
  }, [fetchedEmployeeRates]);

  useEffect(() => {
    if (employeeRates.length > 0) {
      setDefaultRate(employeeRates[0].defaultRate);
      setStartDate(new Date(employeeRates[0].startDate));
      setRate(employeeRates[0].rate);
      setReason(employeeRates[0].reason);
      setIsRateUpdated(true);
      setHasRateHistory(true);
    }
  }, [employeeRates]);

  const startDateWatch = watch('startDate');

  useEffect(() => {
    if (startDateWatch === '') return;
    setValue('startWeekId', getWeekId(startDateWatch, calendarState));
  }, [startDateWatch]);
  useEffect(() => {
    if (item) {
      setValue('projectId', item.id);
    }
    setValue('employeeId', employeeId);
  }, [item]);

  const onSubmit: SubmitHandler<EditEmployeeRateFormDto> = async (data) => {
    try {
      await dispatch(addEmployeeRate(data)).unwrap();
      onClose();
    } catch (error) {
      logError('Error adding project');
    } finally {
      if (item) {
        await dispatch(getEmployeeRates({ projectId: item.id, employeeId }))
          .then(unwrapResult)
          .catch(() => {
            logError('Error fetching employee rates');
          });
      }
    }
  };

  const rateHistoryItems = employeeRates.slice(1, employeeRates.length).map((employeeRate) => {
    return (
      <StyledItemCard className="card-rate-history" key={employeeRate.id}>
        <div className="history-rates-cards">
          <p className="inter-caption_medium history_rate_text position">
            {new Date(employeeRate.startDate).toDateString()}
          </p>
          <p className="inter-caption_medium history_rate_text position">{`${employeeRate.reason}`}</p>
          <p className="inter-caption_m_bold history_rate position">{`$${employeeRate.rate}`}</p>
        </div>
      </StyledItemCard>
    );
  });
  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <MainSpinner loading={loadingEmployeeRates || loadingEmployees} />
        <Grid container spacing={2} columns={20} className="modal-container">
          <StyledItemCard style={editRateCardStyle} className="card">
            <Initials name={selectedEmployee?.name ?? ''} className="img" />
            <div className="info-box">
              <h3 className="inter-p3 info-box_text">{selectedEmployee?.name}</h3>
              <p className="inter-caption_medium info-box_text position">
                {selectedEmployee?.positionId
                  ? `${selectPositionPropertyById(
                      employeeListState,
                      selectedEmployee.positionId,
                      DictionariesSelector.DisplayName
                    )}, ${seniorityName}`
                  : null}
              </p>
            </div>
            <div className="rate">
              <p className="inter-caption_medium info-box_text position">
                {fetchedEmployeeRates && `$${defaultRate ?? 0}`}
              </p>
              <p className="inter-caption_medium info-box_text position">Default Rate</p>
            </div>
          </StyledItemCard>
          {isRateUpdated ? (
            <>
              <Grid item xs={20} md={20}>
                <p className="inter-h4">Project Rate</p>
              </Grid>
              <StyledItemCard style={editRateCardStyle} className="card">
                <div className="history-rates-cards">
                  <p className="inter-caption_medium history_rate_date position">
                    {startDate.toDateString()}
                  </p>
                  <p className="inter-caption_medium history_rate_text position">{`${reason}`}</p>
                  <p className="inter-caption_m_bold history_rate position">{`$${rate}`}</p>
                </div>
              </StyledItemCard>
            </>
          ) : null}
          {hasRateHistory ? (
            <div className="rate-history">
              <Grid item xs={20} md={20}>
                <div className="header-with-icon">
                  <p className="inter-h4">Project Rate History</p>
                  <button
                    type="button"
                    className="button-with-icon"
                    onClick={() => setIsRateHistoryVisible(!isRateHistoryVisible)}
                  >
                    {isRateHistoryVisible ? (
                      <PrpIcon src={arrowUp} height="22px" width="22px" />
                    ) : (
                      <PrpIcon src={arrowDown} height="22px" width="22px" />
                    )}
                  </button>
                </div>
              </Grid>
              {isRateHistoryVisible ? rateHistoryItems : null}
            </div>
          ) : null}
          <Grid item xs={20} md={20}>
            <p className="inter-h4">Update Rate</p>
          </Grid>
          <Grid item xs={11} md={11}>
            <FormInputNumber
              label={EditEmployeeRateFormNames.NewRateTitle}
              name={EditEmployeeRateFormNames.NewRate}
              required
              register={register}
              getValues={getValues}
              error={errors.rate?.message}
              className="input-form"
              minValue={minRate}
            />
          </Grid>
          <Grid item xs={10} md={9} className="edit-rate-date">
            <FormDatePicker
              name={EditEmployeeRateFormNames.StartDate}
              control={control}
              label={EditEmployeeRateFormNames.StartDateTitle}
              className="form-date"
              error={errors.startDate?.message}
              infoIcon
              filter={OnlyDateFilter.Mondays}
            />
          </Grid>
          <Grid item xs={20} md={20}>
            <FormTextField
              label={EditEmployeeRateFormNames.ReasonTitle}
              name={EditEmployeeRateFormNames.Reason}
              type={InputType.Text}
              required
              register={register}
              error={errors.reason?.message}
              className="form-project-rate-details"
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.UpdateRate}
          isCancelButtonVisible
          submitButtonClassName="add-button button-text"
          cancelButtonClassName="cancel-button button-text"
          onClick={onClose}
          className={`${!isRateUpdated && !hasRateHistory ? 'edit-project-range-buttons' : ''}`}
        />
      </StyledForm>
    </FormProvider>
  );
};

export default EditEmployeeRateForm;
