const allocationPopupItems = [
  {
    id: 0,
    label: 'Add booking',
    icon: ''
  },
  {
    id: 1,
    label: 'Add time off',
    icon: ''
  }
];
export default allocationPopupItems;
