import { ClientNoteDto } from 'types/clients/dto/ClientNoteDto';
import { ClientNoteFormDto } from 'types/clients/dto/ClientNoteFormDto';

const mapToClientNoteDto = (data: ClientNoteFormDto): ClientNoteDto => {
  return {
    content: data.content ?? '',
    clientId: data.clientId,
    authorId: data.authorId ?? null,
    authorName: data.authorName ?? '',
    timestamp: data.timestamp ?? '',
    id: data.id
  } as ClientNoteDto;
};

export default mapToClientNoteDto;
