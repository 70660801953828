import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import styled from 'styled-components';

const StyledSelectItem = styled.div({
  alignSelf: 'center',
  cursor: 'pointer',
  em: {
    fontStyle: 'normal'
  },
  '& .MuiPaper-root': {
    width: '94px',
    borderRadius: borderRadiusCommon,
    display: 'flex',
    justifyContent: 'center',
    left: '440px'
  }
});

export default StyledSelectItem;
