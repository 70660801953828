import { minCellWidth } from 'constants/styleConstants';
import {
  dataToDisplayDto,
  subTotalsDevelopersDto,
  subTotalsDiscountsDto
} from 'types/PrpTable/dto/ProjectTableDto';
import TotalsTableBorderType from 'types/PrpTable/enums/TotalsTableBorderType';

type CalculateCellWidthParams = {
  billingPeriodWeeks: { billingPeriodId: number; numberOfWeeks: number }[];
  borderType: TotalsTableBorderType;
  cell: dataToDisplayDto | subTotalsDevelopersDto | subTotalsDiscountsDto;
};

const calculateCellWidth = ({
  billingPeriodWeeks,
  borderType,
  cell
}: CalculateCellWidthParams): number => {
  const cellWidthMultiplier =
    borderType === TotalsTableBorderType.BillingPeriod
      ? billingPeriodWeeks?.find((week) => week.billingPeriodId === cell.id)?.numberOfWeeks || 1
      : 1;

  return minCellWidth * cellWidthMultiplier;
};

export default calculateCellWidth;
