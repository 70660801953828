import { TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledClientsDetailsNotes = styled.div({
  p: {
    color: TEXT_COLOR,
    margin: '16px 0 16px 0'
  },
  '.clients-name-row': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'baseline'
  },

  '.notes-author-icon': {
    alignSelf: 'baseline'
  },
  '.notes-author-name': {
    alignSelf: 'baseline'
  },
  '.note-text': {
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    maxWidth: '90%',
    wordWrap: 'break-word'
  }
});

export default StyledClientsDetailsNotes;
