import { FormControl, Select, Chip, MenuItem } from '@mui/material';
import StyledFormSelectWithChips from 'components/employee/EmployeeFormItems/EmployeeFormSelectPermissions/StyledFormSelectPermissions';
import { Control, Controller } from 'react-hook-form';
import { EmployeeDictionariesDto } from 'types/employee/dto/EmployeeDictionariesDto';
import { EmployeeFormDto } from 'types/employee/dto/EmployeeFormDto';

type FormSelectPermissionsProps = {
  control: Control<EmployeeFormDto>;
  dictionaries: EmployeeDictionariesDto;
  name: keyof EmployeeFormDto;
  error?: string;
  className?: string;
};

const FormSelectPermissions = ({
  control,
  dictionaries,
  name,
  error,
  className
}: FormSelectPermissionsProps) => {
  const selectedPermissionNames = (selected: number[]) => {
    return dictionaries.permissions.filter((permission) => selected.includes(permission.id));
  };

  return (
    <StyledFormSelectWithChips>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl>
            <p className="inter-caption_medium label">Permissions</p>
            <Select
              className={className}
              {...field}
              multiple
              value={field.value}
              renderValue={() => {
                const selectedPermissions = selectedPermissionNames(field.value as number[]);
                return (
                  <div className="permission-chip-row">
                    {selectedPermissions?.map((p) => (
                      <Chip key={p.key} label={p.displayName} className="permission-chip" />
                    ))}
                  </div>
                );
              }}
            >
              {dictionaries.permissions?.map((p) => (
                <MenuItem key={p.key} value={p.id}>
                  {p.displayName}
                </MenuItem>
              ))}
            </Select>
            {error && <p className="error inter-caption_medium">{error}</p>}
          </FormControl>
        )}
      />
    </StyledFormSelectWithChips>
  );
};
export default FormSelectPermissions;
