import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import ProjectFormValidation from 'components/project/AddProjectForm/ProjectFormValidation';
import ProjectChangeFormDefaultValues from 'components/project/ProjectChangeForm/ProjectChangeFormDefaultValues';
import FormStatusSelector from 'components/project/ProjectFormComponents/FormStatusSelector';
import FormRadioButtons from 'components/project/ProjectFormComponents/FormRadioButtons';
import FormSelectDictionaries from 'components/sharedComponents/FormComponents/FormSelectDictionaries/FormSelectDictionaries';
import closeStatusProjectModalConfig from 'components/project/const/formModalConfig';
import {
  maxCustomBillingPeriodWeeks,
  minCustomBillingPeriodWeeks
} from 'constants/validationConstants';
import DependentColorPicker from 'components/sharedComponents/ColorPicker/DependentColorPicker';
import { useSelector } from 'react-redux';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import selectClientColorsById from 'store/dictionarySelectors/selectorClientColors';
import getShadeColors from 'helpers/getShadeColors';
import { Control, FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import FormInput from 'components/sharedComponents/FormComponents/FormInput/FormInput';
import FormSwitch from 'components/sharedComponents/FormComponents/FormSwitch/FormSwitch';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { useEffect, useState } from 'react';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import updateProjectDetails from 'store/action/projectActions/updateProjectDetails';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import InputType from 'types/generalEnums/InputType';
import { ProjectDto } from 'types/project/dto/ProjectDto';
import ProjectFormDto from 'types/project/dto/ProjectFormDto';
import ProjectFormComponentNames from 'types/project/enums/ProjectFormComponentNames';
import FormDatePicker from 'components/sharedComponents/FormComponents/FormDatePicker/FormDatePicker';
import { ProjectStatusName } from 'types/project/enums/ProjectStatusName';
import getProjectDetails from 'store/action/projectActions/getOneProject';
import { useBeforeUnload } from 'react-router-dom';
import { getToday } from 'helpers/dateUtils';
import ButtonsText from 'types/generalEnums/ButtonsText';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import getAllProjects from 'store/action/projectActions/getAllProjects';
import ProjectFormFieldMaxLengths from '../../../types/project/enums/ProjectFormFieldMaxLengths';

type ProjectChangeFormProps = {
  onClose: () => void;
  item: ProjectDto | null;
};

const ProjectChangeForm = ({ onClose, item }: ProjectChangeFormProps) => {
  const methods = useForm({
    // TODO: fix unknown
    defaultValues: ProjectChangeFormDefaultValues(item as ProjectDto),
    // TODO delete unlnown in the future
    resolver: yupResolver(
      ProjectFormValidation(
        minCustomBillingPeriodWeeks,
        maxCustomBillingPeriodWeeks,
        !!item?.customBillingPeriodWeeks,
        true
      )
    ) as unknown as Resolver<ProjectFormDto>
  });
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors, isDirty }
  } = methods;

  const projectDictionaries = useAppSelector((state: RootState) => state.projectDictionaries.data);
  const loadingProject = useAppSelector((state: RootState) => state.project.saveLoading);
  const dispatch = useThunkAppDispatch();
  const [, setCustomBillingPeriodEnabled] = useState(false);
  const selectedStatus = watch(ProjectFormComponentNames.StatusName);
  const closedStatus = projectDictionaries.statuses.find(
    (s: { displayName: string }) => s.displayName === ProjectStatusName.Closed
  );
  const [showEndDate, setShowEndDate] = useState(false);
  useEscapeKey(onClose);
  const selectedClientId = watch(ProjectFormComponentNames.ClientName) ?? 0;
  const selectedClientColorDisplayName = useSelector((state: RootState) =>
    selectClientColorsById(
      state.projectDictionaries,
      selectedClientId,
      DictionariesSelector.DisplayName
    )
  );

  const shadesArray = selectedClientColorDisplayName
    ? [1, 2, 3, 4].map((i) => getShadeColors(selectedClientColorDisplayName, i))
    : [];

  useEffect(() => {
    if (closedStatus && selectedStatus === closedStatus.id) {
      setValue(ProjectFormComponentNames.StartDateName, item?.startDate ?? '');
      methods.trigger(ProjectFormComponentNames.StartDateName);
      setValue(
        ProjectFormComponentNames.EndDateName,
        item?.endDate ? item?.endDate : getToday().slice(0, -1)
      );
      setShowEndDate(true);
    }
  }, [selectedStatus, setValue, methods, projectDictionaries.statuses]);

  const onSubmit: SubmitHandler<ProjectFormDto> = async (data: ProjectFormDto) => {
    dispatch(updateProjectDetails(data))
      .then(() => {
        dispatch(getProjectDetails(data.id as number));
      })
      .catch(() => {
        logError('Error submitting project details');
      });
    dispatch(getAllProjects())
      .then(onClose)
      .catch(() => logError('Error fetching projects'));
  };
  const handleConfirmLeave = (event: BeforeUnloadEvent) => {
    if (isDirty) {
      event.preventDefault();
      event.returnValue = '';
    }
  };
  useBeforeUnload(handleConfirmLeave);
  useEffect(() => {
    setValue(ProjectFormComponentNames.BillingTypeName, item?.billingType || null);
  }, [item]);

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className="modal-container">
          <Grid item xs={12} md={12}>
            <FormSelectDictionaries
              title={ProjectFormComponentNames.ClientName}
              label={ProjectFormComponentNames.ClientNameLabel}
              control={control}
              options={projectDictionaries.clientNames || []}
              className="project-form-select"
              disabled
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormInput
              label={ProjectFormComponentNames.ProjectNameLabel}
              name={ProjectFormComponentNames.ProjectName}
              maxLength={ProjectFormFieldMaxLengths.ProjectName}
              type={InputType.Text}
              required
              register={register}
              className="input-project-name"
              error={errors.name?.message}
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormInput
              label={ProjectFormComponentNames.AzdoAliasLabel}
              name={ProjectFormComponentNames.AzdoAliasName}
              maxLength={ProjectFormFieldMaxLengths.AzdoAlias}
              type={InputType.Text}
              required
              register={register}
              className="input-project-name"
              error={errors.azdoAlias?.message}
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormInput
              label={ProjectFormComponentNames.ProjectCodeLabel}
              name={ProjectFormComponentNames.ProjectCodeName}
              maxLength={ProjectFormFieldMaxLengths.ProjectCode}
              type={InputType.Text}
              required
              register={register}
              className="input-project-name"
              error={errors.code?.message}
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <DependentColorPicker<ProjectFormDto>
              label={ProjectFormComponentNames.ColorLabel}
              name={ProjectFormComponentNames.ColorName}
              control={control as Control<ProjectFormDto>}
              error={errors.colorHex?.message}
              colors={shadesArray}
              dependentComponentName={ProjectFormComponentNames.ClientName}
              editForm
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormSelectDictionaries
              title={ProjectFormComponentNames.ProjectManagerName}
              label={ProjectFormComponentNames.ProjectManagerLabel}
              control={control}
              options={projectDictionaries.projectManagers || []}
              className="project-form-select"
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <FormStatusSelector
              title={ProjectFormComponentNames.StatusName}
              label={ProjectFormComponentNames.StatusLabel}
              control={control}
              options={projectDictionaries.statuses}
              className="project-form-select project-form-status"
              modalConfig={closeStatusProjectModalConfig}
              disabled={selectedStatus === closedStatus?.id}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <FormDatePicker
              name={ProjectFormComponentNames.StartDateName}
              control={control}
              label={ProjectFormComponentNames.StartDateLabel}
              className="form-date"
              disabled
            />
          </Grid>
          {showEndDate && (
            <Grid item xs={12} md={12} className="end-date-row">
              <FormDatePicker
                name={ProjectFormComponentNames.EndDateName}
                control={control}
                label={ProjectFormComponentNames.EndDateLabel}
                className="form-date"
                disabled
              />
            </Grid>
          )}
          <Grid item xs={11} md={11} className="billiable-row">
            <p className="inter-caption_bold ">Billing information</p>
            <FormSwitch
              label={ProjectFormComponentNames.BillableLabel}
              name={ProjectFormComponentNames.BillableName}
              disabled
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormSelectDictionaries
              title={ProjectFormComponentNames.BillingTypeName}
              label={ProjectFormComponentNames.BillingTypeLabel}
              control={control}
              options={projectDictionaries.billingTypes || []}
              className="project-form-select"
              disabled
              valueType="string"
            />
          </Grid>
          <Grid item xs={7} md={7}>
            <FormRadioButtons
              name={ProjectFormComponentNames.BillingPeriodName}
              label={ProjectFormComponentNames.BillingPeriodLabel}
              control={control}
              dictionaries={projectDictionaries}
              className="project-radio-buttons"
              disabled
              setDependentTextInput={setCustomBillingPeriodEnabled}
              radioButtonIdForDependentInput={2}
            />
          </Grid>
          <Grid item xs={5} md={5}>
            <FormInput
              label={ProjectFormComponentNames.CustomBillingPeriodLabel}
              name={ProjectFormComponentNames.CustomBillingPeriodName}
              type={InputType.Text}
              required
              register={register}
              className="input-customer-period"
              error={errors.customBillingPeriodWeeks?.message}
              disabled
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.Update}
          isSpinnerButtonAdded
          loading={loadingProject}
          isCancelButtonVisible
          submitButtonClassName="add-button"
          onClick={onClose}
          className="add-buttons-row"
        />
      </StyledForm>
    </FormProvider>
  );
};

export default ProjectChangeForm;
