import styled from 'styled-components';
import { DARK_GREY, ON_PRIMARY_COLOR } from 'constants/colors';

const StyledAutocompleteEmployeesSearch = styled.div({
  '.autocomplete .MuiOutlinedInput-root': {
    padding: '2px 5px',
    marginBottom: '12px',
    backgroundColor: ON_PRIMARY_COLOR,
    borderRadius: '8px',
    borderColor: DARK_GREY
  }
});
export default StyledAutocompleteEmployeesSearch;
