import { FormControl, Select, MenuItem } from '@mui/material';
import StyledFormSelectController from 'components/sharedComponents/FormComponents/FormSelectDictionaries/StyledFormSelectController';
import { Control, Controller } from 'react-hook-form';
import { EmployeeDictionariesDto } from 'types/employee/dto/EmployeeDictionariesDto';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import { EmployeeFormDto } from 'types/employee/dto/EmployeeFormDto';

type FormSelectPositionProps = {
  control: Control<EmployeeFormDto>;
  dictionaries: EmployeeDictionariesDto;
  label: string;
  title: keyof EmployeeDto;
  error?: string;
  className?: string;
};
const FormSelectPosition = ({
  title,
  control,
  dictionaries,
  label,
  error,
  className
}: FormSelectPositionProps) => {
  const positionOptions = dictionaries.positions || [];

  return (
    <StyledFormSelectController>
      <Controller
        name={title}
        control={control}
        render={({ field }) => {
          const value = field.value as number;
          const isValidValue = positionOptions.some((pos) => pos.id === value);

          return (
            <FormControl>
              <p className="inter-caption_medium label">{label}</p>
              <Select {...field} value={isValidValue ? value : ''} className={className}>
                {positionOptions.map((pos) => (
                  <MenuItem key={pos.key} value={pos.id}>
                    {pos.displayName}
                  </MenuItem>
                ))}
              </Select>
              {error && <p className="error inter-caption_medium">{error}</p>}
            </FormControl>
          );
        }}
      />
    </StyledFormSelectController>
  );
};

export default FormSelectPosition;
