import StyledMenuItem from './StyledMenuItem';

type OptionalMenuItemProps = {
  shouldRender: boolean;
  label: string;
  onClick: () => void;
};

const OptionalMenuItem = ({ shouldRender, label, onClick }: OptionalMenuItemProps) => {
  return shouldRender && <StyledMenuItem onClick={onClick}>{label}</StyledMenuItem>;
};

export default OptionalMenuItem;
