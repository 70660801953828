import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import { detailsPattern } from 'constants/validationConstants';
import * as yup from 'yup';
import DiscountTypeEnum from 'types/PrpTable/enums/discountTypeEnum';

const UpdateDiscountFormValidation = (
  maxValue: number,
  minValue: number,
  discountType?: DiscountTypeEnum
) => {
  return yup.object().shape({
    duration: yup
      .number()
      .min(minValue, FormValidationMessage.DiscountActualsAmountError)
      .max(
        maxValue,
        discountType !== DiscountTypeEnum.PRE_DISCOUNT
          ? FormValidationMessage.DiscountActualsAmountError
          : FormValidationMessage.DiscountForecastAmountError
      )
      .required(FormValidationMessage.DiscountError)
      .typeError(FormValidationMessage.DiscountActualsAmountError),
    reason: yup
      .string()
      .required(FormValidationMessage.ReasonError)
      .matches(detailsPattern, FormValidationMessage.MaxLengthError)
  });
};

export default UpdateDiscountFormValidation;
