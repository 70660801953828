import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { ON_PRIMARY_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledPublicHoliday = styled.div({
  borderRadius: borderRadiusCommon,
  marginBottom: '3px',
  p: {
    margin: '0'
  },
  padding: '5px 0 5px 0',
  '.public-holiday-row': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    '.public-holiday-img': {
      marginBottom: '3px',
      alignSelf: 'center'
    },
    '.holiday-title': {
      alignSelf: 'center',
      color: ON_PRIMARY_COLOR
    }
  }
});

export default StyledPublicHoliday;
