import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { InvoiceDictionariesState } from 'store/slicesState/invoiceDictionaries';
import getInvoiceDictionaries from 'store/action/invoiceAction/getInvoiceDictionaries';
import { InvoiceDictionariesDto } from 'types/invoice/InvoiceDictionariesDto';

const initialState: InvoiceDictionariesState = {
  data: {
    permittedStatuses: []
  },
  loading: false,
  error: null
};
export type DispatchError = {
  errorMessage: string;
};

const invoiceDictionariesSlice = createSlice({
  name: 'invoiceDictionaries',
  initialState,
  reducers: {
    getInvoiceDictionary: (state, action: PayloadAction<InvoiceDictionariesDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoiceDictionaries.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getInvoiceDictionaries.fulfilled, (state, action) => {
      state.error = null;
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getInvoiceDictionaries.rejected, (state) => {
      state.error = 'getInvoiceDictionaries error in slice';
      state.loading = false;
    });
  }
});

export const { getInvoiceDictionary } = invoiceDictionariesSlice.actions;
export const SELECT_INVOICE_DICTIONARIES = (root: RootState) => root.invoiceDictionaries;
export default invoiceDictionariesSlice.reducer;
