import ClientChangeForm from 'components/clients/ClientChangeForm/ClientChangeForm';
import ClientNotesList from 'components/clients/ClientsNotesList/ClientNotesList';
import { ClientsDto } from 'types/clients/dto/ClientsDto';

type ClientsDescriptionsProps = {
  item: ClientsDto;
  onCancel: () => void;
};

const ClientDescriptions = ({ item, onCancel }: ClientsDescriptionsProps) => {
  return (
    <div>
      <ClientChangeForm item={item as ClientsDto} onCancel={onCancel} />
      <ClientNotesList item={item as ClientsDto} />
    </div>
  );
};

export default ClientDescriptions;
