import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PreinvoiceSummary } from 'store/slicesState/preinvoiceSummary';
import getPreinvoiceDetails from 'store/action/projectActions/getPreinvoiceSummary';
import { PreInvoiceSummaryDto } from 'types/project/dto/PreinvoiceSummaryDto';

const initialState: PreinvoiceSummary = {
  data: {
    billingPeriodStartDate: '',
    billingPeriodEndDate: '',
    forecastForNextBillingPeriod: {
      startDate: '',
      endDate: '',
      duration: 0,
      cost: 0
    },
    differenceForPreviousBillingPeriod: {
      startDate: '',
      endDate: '',
      forecastCost: 0,
      forecastDuration: 0,
      actualsDuration: 0,
      actualsCost: 0,
      differenceDuration: 0,
      differenceCost: 0
    },
    discountsForPreviousBillingPeriod: {
      startDate: '',
      endDate: '',
      discountDuration: 0,
      discountCost: 0
    },
    invoiceTotal: {
      id: 0,
      startDate: '',
      endDate: '',
      invoiceStatus: '',
      invoiceCost: 0,
      notes: '',
      additionalCostNotes: '',
      additionalCostAmount: 0,
      preDiscountCost: 0,
      preDiscountDuration: 0
    }
  },
  readLoading: false,
  saveLoading: false,
  error: null
};

export type DispatchError = {
  errorMessage: string;
};

const preinvoiceSummarySlice = createSlice({
  name: 'preinvoice',
  initialState,
  reducers: {
    getPreinvoiceSummary: (state, action: PayloadAction<PreInvoiceSummaryDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPreinvoiceDetails.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getPreinvoiceDetails.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.readLoading = false;
    });
    builder.addCase(getPreinvoiceDetails.rejected, (state) => {
      state.error = 'getPreinvoice error in slice';
      state.readLoading = false;
    });
  }
});

export const { getPreinvoiceSummary } = preinvoiceSummarySlice.actions;
export default preinvoiceSummarySlice.reducer;
