enum FormValidationMessage {
  EmailError = 'Please enter a correct email address',
  RequiredError = 'This field is required',
  PermissionError = 'Please select at least one tag',
  CapacityError = 'Please enter a value between 1-60',
  NameError = 'Please enter the name',
  ProjectNameLengthError = 'Please enter between 3 and 100 characters, use only letters and numbers',
  ClientNameLengthError = "Please enter between 3 and 100 characters and use only letters, numbers, spaces and the sign '-'",
  ContactNameLengthError = "Please enter between 3 and 1000 characters and use only letters, spaces and the sign '-'",
  NameLengthError = "Please enter between 3 and 100 characters and use only letters, spaces and the sign '-'",
  PositionError = 'Please select the position',
  SeniorityError = 'Please select a seniority',
  ClientColorError = 'Please select the color',
  ClientPositionError = 'Please enter the position',
  ClientPositionLengthError = 'Please enter max 1000 characters',
  ClientPhoneError = 'Please enter the phone',
  ClientPhoneLengthError = 'Please enter max 1000 characters',
  ClientNameError = 'Please select the client name',
  ProjectCodeError = 'Please enter the code',
  ProjectCodeMinMaxLengthError = "Please enter between 3 and 5 characters and don't use whitespaces",
  ProjectNameError = 'Please enter the project name',
  ProjectProjectManagerError = 'Please select the project person',
  CustomBillingPeriodError = 'Please enter amount of weeks',
  AzdoAliasError = 'Please enter the AZDO alias',
  NotesLengthError = 'Please enter max 1000 characters',
  ProjectChoiceError = 'Please select the project',
  EmploeeChoiceError = 'Please select people / resources',
  DateError = 'Please select the date',
  TimeError = 'Please enter the correct time',
  DetailsError = 'Please enter the details',
  MaxLengthError = 'Please enter max 1000 characters',
  TypeChoiceError = 'Please select type',
  NoteError = 'Please enter the note',
  BillingPeriodError = 'Billing Period can have between 1 and 12 weeks',
  SprintLengthError = 'Sprint can have between 1 and 12 weeks',
  ReasonError = 'Please enter the reason',
  RateErrir = 'Please enter a New Rate',
  RateRangeError = 'Please enter numbers between 0 and 350',
  DiscountError = 'Please enter the discount',
  DiscountActualsAmountError = "Please enter a value between 0 and number of Actuals' hours",
  DiscountForecastAmountError = 'Please enter a value between 0 and number of Forecast hours',
  ForecastError = 'Please enter a numerical value between 0.5 and 60',
  DateRangeError = 'Please select a date range from 1 to 30 days',
  DateBeforeProjectStartDateError = 'Start date should not be earlier than project start date',
  ReasonMaxLengthError = 'Please enter max 350 characters',
  DiscountTimeError = 'Discounted hours cannot exceed the allocated hours',
  CustomBillingPeriodWeeksMaxLengthError = 'Please enter a value between 1 and 10',
  AutoDiscountMinValueError = 'Please enter a value greater than 0',
  EndDateBeforeStartDateError = 'Start date should be earlier than the end date',
  StartSprintNumberError = 'Please enter a value between 1 and 9999',
  SprintNoteLengthError = 'Please enter max 1000 characters and do not use white spaces at the beginning',
  BillingTypeError = 'Please select the billing type',
  ProjectManagerError = 'Please select the project manager'
}
export default FormValidationMessage;
