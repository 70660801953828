import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { BORDERS_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledAllocationFilterMultiSelect = styled.div({
  '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
    padding: '8px',
    borderRadius: borderRadiusCommon
  },
  '.chip-row': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  '.chip': {
    marginRight: '5px',
    marginBottom: '5px',
    borderRadius: borderRadiusCommon,
    backgroundColor: BORDERS_COLOR
  }
});

export default StyledAllocationFilterMultiSelect;
