type DataCategoriesProps = {
  isFinancialInfoVisible: boolean;
  filterDevelopersTableRows: () => { name: string; id: number }[];
};

const DataCategories = ({
  isFinancialInfoVisible,
  filterDevelopersTableRows
}: DataCategoriesProps) => {
  return (
    <div className={`table-content-types ${!isFinancialInfoVisible && 'without-financial-info'}`}>
      {filterDevelopersTableRows().map((item) => (
        <p className="inter-caption_medium" key={item.id}>
          {item.name}
        </p>
      ))}
    </div>
  );
};

export default DataCategories;
