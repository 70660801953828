import { createAsyncThunk } from '@reduxjs/toolkit';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import { InvoiceSummaryDto } from 'types/project/dto/InvoiceSummaryDto';
import InvoiceAction from 'types/project/enums/Invoice';
import invoiceSummaryApiService from 'api/invoiceSummaryApiService';

const getInvoiceDetails = createAsyncThunk<
  InvoiceSummaryDto,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(InvoiceAction.GET_INVOICE_SUMMARY, async (billingPeriodId) => {
  const response = await invoiceSummaryApiService.getInvoiceSummary(billingPeriodId);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default getInvoiceDetails;
