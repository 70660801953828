import { InvoiceFixedPriceFormDto } from 'types/project/dto/InvoiceFixedPriceFormDto';

const CreateInvoiceFixedPriceFormDefaultValue = (
  billingPeriodId: number,
  invoiceId: number,
  savedAmount?: number,
  savedDescription?: string
): InvoiceFixedPriceFormDto => {
  return {
    amount: savedAmount || 0,
    description: savedDescription || '',
    billingPeriodId,
    invoiceId
  };
};

export default CreateInvoiceFixedPriceFormDefaultValue;
