import { DictionariesState } from 'store/slicesState/dictionaries';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';

const selectSeniorityPropertyById = (
  state: DictionariesState,
  id: number,
  property: DictionariesSelector.Name | DictionariesSelector.DisplayName
): string | null => {
  const seniorityDictionary = state.data?.seniorities;
  if (!seniorityDictionary) {
    return null;
  }
  const seniority = seniorityDictionary.find((s) => s.id === id);
  return seniority?.[property] ?? null;
};

export default selectSeniorityPropertyById;
