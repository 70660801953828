import * as yup from 'yup';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';

const UpdateForecastFormValidation = (maxForecast: number, minForecast: number) => {
  return yup.object().shape({
    forecastLength: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .min(minForecast)
      .max(maxForecast)
      .required(FormValidationMessage.ForecastError)
  });
};

export default UpdateForecastFormValidation;
