import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { EmployeeDictionariesDto } from 'types/employee/dto/EmployeeDictionariesDto';

const getDictionariesWithKeys = (
  dictionaries: EmployeeDictionariesDto
): EmployeeDictionariesDto => {
  return {
    ...dictionaries,
    key: crypto.randomUUID(),
    permissions: dictionaries.permissions?.map((permissions) => addKeyToReactItem(permissions)),
    seniorities: dictionaries.seniorities?.map((seniority) => addKeyToReactItem(seniority)),
    positions: dictionaries.positions?.map((position) => addKeyToReactItem(position))
  };
};
export default getDictionariesWithKeys;
