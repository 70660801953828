import { createAsyncThunk } from '@reduxjs/toolkit';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import UpdateDiscountDto from 'types/project/dto/UpdateDiscountDto';
import DiscountsAction from 'types/project/enums/Discounts';
import discountApiService from 'api/discountApiService';
import { UpdateDiscountFormDto } from 'types/project/dto/UpdateDiscountFormDto';
import mapToDiscountDto from 'store/mappers/project/discountMapper';

const updateDiscount = createAsyncThunk<
  UpdateDiscountDto,
  UpdateDiscountFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(DiscountsAction.UPDATE_DISCOUNT, async (data: UpdateDiscountFormDto) => {
  const discountDto = mapToDiscountDto(data);
  const response = await discountApiService.updateDiscount(discountDto);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});
export default updateDiscount;
