import styled from 'styled-components';

const StyledTitleContainer = styled.div({
  width: '100%',
  '.initials-container': {
    alignItems: 'center'
  }
});

export default StyledTitleContainer;
