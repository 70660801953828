import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import InvoiceAction from 'types/project/enums/Invoice';
import invoiceApiService from 'api/invoiceApiServices';

const deleteInvoice = createAsyncThunk<
  void,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(InvoiceAction.DELETE_INVOICE, async (invoiceId: number) => {
  await invoiceApiService.deleteInvoice(invoiceId);
});
export default deleteInvoice;
