import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import addAllocationTimeOff from 'store/action/allocationsAction/addAllocationTimeOff';
import deleteAllocationTimeOff from 'store/action/allocationsAction/deleteAllocationTimeOff';
import getOneTimeOff from 'store/action/allocationsAction/getTimeOff';
import updateTimeOff from 'store/action/allocationsAction/updateTimeOff';
import { AllocationTimeOffState } from 'store/slicesState/allocationTimeOff';
import { AllocationTimeOffDto } from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffDto';

const initialState: AllocationTimeOffState = {
  data: {
    employeeId: 0,
    startDate: '',
    endDate: '',
    type: '',
    details: '',
    id: 0
  },
  readLoading: false,
  saveLoading: false,
  error: null
};

export type DispatchError = {
  errorMessage: string;
};

const allocationTimeOffSlice = createSlice({
  name: 'allocationTimeOff',
  initialState,
  reducers: {
    getTimeOff: (state, action: PayloadAction<AllocationTimeOffDto>) => {
      state.data = action.payload;
    },
    updateTimeOffs: (state, action: PayloadAction<AllocationTimeOffDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addAllocationTimeOff.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(addAllocationTimeOff.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(addAllocationTimeOff.rejected, (state) => {
      state.error = 'addAllocationTimeOff error in slice';
      state.saveLoading = false;
    });
    builder.addCase(getOneTimeOff.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getOneTimeOff.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.readLoading = false;
    });
    builder.addCase(getOneTimeOff.rejected, (state) => {
      state.error = 'getTimeOff error in slice';
      state.readLoading = false;
    });
    builder.addCase(deleteAllocationTimeOff.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(deleteAllocationTimeOff.fulfilled, (state) => {
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(deleteAllocationTimeOff.rejected, (state) => {
      state.error = 'deleteAllocationTimeOff error in slice';
      state.saveLoading = false;
    });
    builder.addCase(updateTimeOff.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(updateTimeOff.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(updateTimeOff.rejected, (state) => {
      state.error = 'updateTimeOff error in slice';
      state.saveLoading = false;
    });
  }
});
export const { getTimeOff, updateTimeOffs } = allocationTimeOffSlice.actions;
export default allocationTimeOffSlice.reducer;
