import { BORDERS_COLOR, TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledAllocationEventcalendarHeader = styled.div({
  '.button': {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    padding: '4px',
    width: '100%'
  },
  '.text-row': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '.day': {
      marginLeft: '3px'
    }
  },
  p: {
    margin: '0',
    color: TEXT_COLOR
  },
  '.clicked': {
    backgroundColor: BORDERS_COLOR
  }
});

export default StyledAllocationEventcalendarHeader;
