import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { InvoiceSummaryDto } from 'types/project/dto/InvoiceSummaryDto';
import { InvoiceFixedPriceDto } from 'types/project/dto/FixedInvoiceDto';

const invoiceSummaryApiService: IApiService = {
  getInvoiceSummary: async (billingPeriodId: number) => {
    try {
      const response = await apiRequest<InvoiceSummaryDto>({
        method: 'GET',
        endpoint: `${API_URL}/billing-periods/${billingPeriodId}/invoice/summary`
      });
      return response;
    } catch (error) {
      logError('Error in getInvoiceSummary');
      throw new Error('Error in getInvoiceSummary');
    }
  },
  getFixedInvoiceSummary: async (billingPeriodId: number) => {
    try {
      const response = await apiRequest<InvoiceFixedPriceDto>({
        method: 'GET',
        endpoint: `${API_URL}/billing-periods/${billingPeriodId}/fixed-price-invoice/summary`
      });
      return response;
    } catch (error) {
      logError('Error in getFixedInvoiceSummary');
      throw new Error('Error in getFixedInvoiceSummary');
    }
  }
};

interface IApiService {
  getInvoiceSummary: (billingPeriodId: number) => Promise<InvoiceSummaryDto>;
  getFixedInvoiceSummary: (billingPeriodId: number) => Promise<InvoiceFixedPriceDto>;
}

export default invoiceSummaryApiService;
