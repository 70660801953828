import GenerateForecastButton from 'components/project/ProjectTable/GenerateForecastButton/GenerateForecastButton';
import formatDate from 'helpers/formatDate';
import { BillingPeridWeeksDto, BillingPeriodsDto } from 'types/PrpTable/dto/ProjectTableDto';
import PageType from 'types/generalEnums/PageType';
import { minCellWidth } from 'constants/styleConstants';
import { BpWeeksDto } from 'types/PrpTable/dto/ProjectTableParamsDto';
import { iconComment } from 'shared/PrpIcon/icons.constants';
import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import { useState } from 'react';

type BillingPeriodColumnHeaderProps = {
  matchingSprints: {
    sprintNumber: number;
    weeks: BillingPeridWeeksDto[];
    sprintId: number;
    note: string;
  }[];
  handleEditSprintLengthPopUp: (date: string, sprintNumber: number) => void;
  handleEditBillingPeriodPopUp: (date: string, id: number, isForecastFrozen: boolean) => void;
  billingPeriodWeeks: BpWeeksDto[];
  billing: BillingPeriodsDto;
  refreshProjectData: () => void;
};

const BillingPeriodColumnHeader = ({
  matchingSprints,
  billingPeriodWeeks,
  handleEditSprintLengthPopUp,
  handleEditBillingPeriodPopUp,
  billing,
  refreshProjectData
}: BillingPeriodColumnHeaderProps) => {
  const [isSprintTooltipOpen, setIsSprintTooltipOpen] = useState(false);
  const [selectedSprint, setSelectedSprint] = useState(0);

  const closeSprintTooltip = () => {
    setIsSprintTooltipOpen(false);
    setSelectedSprint(0);
  };

  const openSprintTooltip = (sprintId: number) => {
    setSelectedSprint(sprintId);
    setIsSprintTooltipOpen(true);
  };

  const checkIfBillingContainsSprintHeader = (
    specificBillingPeriodWeeks: BillingPeridWeeksDto[],
    sprint: { sprintNumber: number; weeks: BillingPeridWeeksDto[]; sprintId: number; note: string }
  ) => {
    if (specificBillingPeriodWeeks.some((week) => sprint?.weeks.includes(week)))
      if (specificBillingPeriodWeeks.length !== 1) {
        specificBillingPeriodWeeks.flatMap((billingWeeks) => {
          return sprint.weeks
            .filter((week) => billingWeeks.weekId === week.weekId)
            .map((week) => (
              <div className="billing-date-row" key={week.weekId}>
                <p className="inter-caption_m_bold billing-weeks">
                  {formatDate(week.weekStartDate, PageType.ProjectTable, false)}
                </p>
              </div>
            ));
        });
        const numberOfWeeksInBp =
          specificBillingPeriodWeeks.filter((week) => sprint.weeks.includes(week)).length ?? 1;

        return (
          <div
            className="sprint-row"
            style={{
              width: numberOfWeeksInBp === 1 ? '100%' : `${minCellWidth * numberOfWeeksInBp}px`
            }}
            key={sprint.sprintNumber}
          >
            <div className="billing-sprint">
              <button
                type="button"
                className="sprint-length-button"
                onClick={() =>
                  handleEditSprintLengthPopUp(
                    formatDate(sprint.weeks[0].weekStartDate),
                    sprint.sprintId
                  )
                }
              >
                <p className="inter-caption_m_bold">Sprint {sprint.sprintNumber}</p>
                {sprint.note !== '' && !!sprint.note && (
                  <CustomTooltip
                    className="sprint-tooltip"
                    onClose={closeSprintTooltip}
                    onOpen={() => openSprintTooltip(sprint.sprintId)}
                    isTooltipOpen={isSprintTooltipOpen && selectedSprint === sprint.sprintId}
                    title={<div>{sprint.note}</div>}
                    placement="bottom"
                    icon={iconComment}
                  />
                )}
              </button>
            </div>
            <div className="sprint-weeks-wrapper">
              {specificBillingPeriodWeeks.flatMap((billingWeeks) => {
                return sprint.weeks
                  .filter((week) => billingWeeks.weekId === week.weekId)
                  .map((week) => (
                    <div className="billing-date-row" key={week.weekId}>
                      <p className="inter-caption_m_bold billing-weeks">
                        {formatDate(week.weekStartDate, PageType.ProjectTable, false)}
                      </p>
                    </div>
                  ));
              })}
            </div>
          </div>
        );
      } else {
        return (
          <div className="sprint-row-single" key={sprint.sprintNumber}>
            <div className="billing-sprint">
              <button
                type="button"
                className="sprint-length-button"
                onClick={() =>
                  handleEditSprintLengthPopUp(
                    formatDate(sprint.weeks[0].weekStartDate),
                    sprint.sprintId
                  )
                }
              >
                <p className="inter-caption_m_bold ">Sprint {sprint.sprintNumber}</p>
              </button>
            </div>
            <div className="sprint-weeks-wrapper">
              {specificBillingPeriodWeeks.map((billingWeeks) =>
                sprint.weeks.map((week) => {
                  if (billingWeeks.weekId === week.weekId) {
                    return (
                      <div className="billing-date-row" key={week.weekId}>
                        <p className="inter-caption_m_bold billing-weeks">
                          {formatDate(week.weekStartDate, PageType.ProjectTable, false)}
                        </p>
                      </div>
                    );
                  }
                  return '';
                })
              )}
            </div>
          </div>
        );
      }
    return '';
  };

  return (
    <div className="table-billing-wrapper" key={billing.billingPeriodStartDate}>
      <div className="billing-title-row">
        <p className="billing-title inter-caption_m_bold">Billing Period</p>
        <GenerateForecastButton
          billing={billing}
          refreshProjectData={refreshProjectData}
          bpLength={
            billingPeriodWeeks.find((bp) => bp.billingPeriodId === billing.billingPeriodId)
              ?.numberOfWeeks ?? 0
          }
        />
      </div>
      <div className="billing-title-date">
        <button
          type="button"
          className="billing-period-button"
          onClick={() =>
            handleEditBillingPeriodPopUp(
              formatDate(billing.billingPeriodStartDate),
              billing.billingPeriodId,
              billing.billingPeriodForecastStatus === 'FROZEN'
            )
          }
        >
          <p className="inter-caption_medium">
            {formatDate(billing.billingPeriodStartDate)} <span> - </span>
            {formatDate(billing.billingPeriodEndDate, '', true)}
          </p>
        </button>
      </div>
      <div className="sprint-wrapper">
        {matchingSprints.map((sprint) =>
          checkIfBillingContainsSprintHeader(billing.billingPeriodWeeks, sprint)
        )}
      </div>
    </div>
  );
};

export default BillingPeriodColumnHeader;
