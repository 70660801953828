import { useEffect, useRef } from 'react';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { vectorLeft, vectorRight } from 'shared/PrpIcon/icons.constants';
import StyledChangeMonthSwitch from './StyledChangeMonthSwitch';

type ChangeMonthSwitchProps = {
  currentMonth: number;
  currentYear: number;
  month: number;
  year: number;
  minYear: number;
  maxYear: number;
  setMonth: React.Dispatch<React.SetStateAction<number>>;
  setYear: React.Dispatch<React.SetStateAction<number>>;
  projectStartDate: Date;
};

const ChangeMonthSwitch = ({
  currentMonth,
  currentYear,
  month,
  year,
  minYear,
  maxYear,
  setMonth,
  setYear,
  projectStartDate
}: ChangeMonthSwitchProps) => {
  const isMountedRef = useRef(false);
  const handleMonthIncrement = () => {
    if (month < 12) {
      setMonth(month + 1);
    } else if (year + 1 < maxYear) {
      setMonth(1);
      setYear(year + 1);
    }
  };
  const handleMonthDecrement = () => {
    if (month === projectStartDate.getMonth() + 1 && projectStartDate.getFullYear() === year)
      return;
    if (month > 1) {
      setMonth(month - 1);
    } else if (year - 1 >= minYear) {
      setMonth(12);
      setYear(year - 1);
    }
  };
  const handleCurrentMonth = () => {
    setMonth(currentMonth);
    setYear(currentYear);
  };

  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
    }
  }, [month]);

  return (
    <StyledChangeMonthSwitch>
      <div className="month-switch">
        <button onClick={handleCurrentMonth} type="button">
          <p className="inter-caption">Current Month</p>
        </button>
        <button onClick={handleMonthDecrement} type="button">
          <PrpIcon src={vectorLeft} />
        </button>
        <button onClick={handleMonthIncrement} type="button">
          <PrpIcon src={vectorRight} />
        </button>
      </div>
    </StyledChangeMonthSwitch>
  );
};

export default ChangeMonthSwitch;
