import { useState } from 'react';
import Popover from '@mui/material/Popover';
import { MenuItem } from '@mui/material';
import PrpIcon from 'shared/PrpIcon/PrpIcon';

import {
  genericPopUpWithButtonsDeleteDisplay,
  genericPopUpWithButtonsDeleteDrection,
  genericPopUpWithButtonsDeleteMargin
} from 'constants/styleConstants';

type GenericPopupWithButtonsProps = {
  buttonLabel?: string;
  buttonIcon?: string;
  buttonClassName?: string;
  items: { id: number; label: string; icon: string }[];
  onClickFunctions: (() => void)[];
};
const GenericPopupWithButtons = ({
  buttonLabel,
  buttonIcon,
  buttonClassName,
  items,
  onClickFunctions
}: GenericPopupWithButtonsProps): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorElement(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElement(null);
  };
  const isOpen = anchorElement !== null && anchorElement !== undefined;
  const id = isOpen ? 'simple-popover' : '';

  const closeOnClickFunctions = (functionId: number) => {
    handleClose();
    onClickFunctions[functionId]();
  };

  return (
    <div className="top-div">
      <button className={buttonClassName} type="button" onClick={handleClick}>
        {buttonIcon ? <PrpIcon src={buttonIcon} /> : null}
        {buttonLabel}
      </button>
      <Popover
        id={id}
        open={isOpen}
        anchorEl={anchorElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
      >
        {items.map((item) => (
          <MenuItem key={item.id} onClick={() => closeOnClickFunctions(item.id)}>
            {item.icon !== '' ? (
              <div
                style={{
                  display: genericPopUpWithButtonsDeleteDisplay,
                  flexDirection: genericPopUpWithButtonsDeleteDrection
                }}
              >
                <PrpIcon src={item.icon} height="23px" width="23px" />
                <p style={{ margin: genericPopUpWithButtonsDeleteMargin }}> {item.label}</p>
              </div>
            ) : (
              item.label
            )}
          </MenuItem>
        ))}
      </Popover>
    </div>
  );
};

export default GenericPopupWithButtons;
