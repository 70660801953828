import { addFormInputBoderColor } from 'constants/styleConstants';
import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { ALERT_COLOR, BORDERS_COLOR, LOW_EMPHASISE_COLOR, TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledClientChangeForm = styled.form({
  p: {
    color: LOW_EMPHASISE_COLOR,
    margin: '0px'
  },
  '.input-form': {
    width: '380px',
    '& .MuiInputBase-input': {
      width: '360px'
    }
  },
  '.input-form-position': {
    width: '230px'
  },
  '.input-form-phone': { width: '240px' },
  '.MuiFormControlLabel-label': {
    lineHeight: '18px',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Inter',
    color: TEXT_COLOR
  },
  '.MuiGrid-item': {
    paddingBottom: '10px'
  },
  '.error': {
    marginTop: '5px',
    color: ALERT_COLOR
  },
  '.MuiInputBase-input': {
    borderColor: addFormInputBoderColor
  },
  '.clients-projects-wrapper': {
    border: '1px solid ',
    borderColor: BORDERS_COLOR,
    marginBottom: '20px',
    borderRadius: borderRadiusCommon,
    ':last-child': {
      marginBottom: '0'
    },
    p: {
      color: TEXT_COLOR,
      margin: '16px 0 16px 0'
    }
  },
  '.clients-projects-row': {
    display: 'flex',
    flexDirection: 'column',
    margin: '32px 0 0 15px'
  },

  '.clients-name-row': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  '.client-code-row': {
    display: 'flex'
  },
  '.client-color': {
    width: '6px',
    height: '32px',
    borderRadius: borderRadiusCommon,
    marginRight: '12px',
    alignSelf: 'center'
  }
});

export default StyledClientChangeForm;
