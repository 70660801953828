import Box from '@mui/material/Box';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import AllocationsModalWindowTitle from 'types/allocation/dto/enums/AllocationsModalWindowTitle';
import AllocationBookingAddForm from 'components/allocations/AllocationBooking/AllocationAddBookingForm/AllocationBookingAddForm';
import AllocationTimeOffAddForm from 'components/allocations/AllocationTimeOff/AllocationTimeOffAddForm/AllocationTimeOffAddForm';
import StyledAllocationModalWindowWithTabs from 'components/allocations/AllocationModalWindowWithTabs/StyledAllocationModalWindowWithTabs';
import formatDateToCustomString from 'helpers/formatDateToCustomString';

type AllocationModalWindowWithTabsProps = {
  onClose: () => void;
  clickCellName: number;
  clickCellDate: Date;
  setIsTimeOffModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsBookingModalOpen: Dispatch<SetStateAction<boolean>>;
};

const AllocationModalWindowWithTabs = ({
  onClose,
  clickCellDate,
  clickCellName,
  setIsTimeOffModalOpen,
  setIsBookingModalOpen
}: AllocationModalWindowWithTabsProps) => {
  const defaultBillingType = '1';
  const [defaultTab, setDefaultTab] = useState<string>(defaultBillingType);
  useEffect(() => {
    setIsBookingModalOpen(true);
    setIsTimeOffModalOpen(false);
  }, []);
  const handleChange = (_event: React.SyntheticEvent, newTab: string) => {
    setDefaultTab(newTab);
    setIsBookingModalOpen(newTab === defaultBillingType);
    setIsTimeOffModalOpen(newTab !== defaultBillingType);
  };

  return (
    <StyledAllocationModalWindowWithTabs>
      <TabContext value={defaultTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '620px' }}>
          <TabList onChange={handleChange}>
            <Tab
              label={AllocationsModalWindowTitle.BookingFormTitle}
              value="1"
              className="inter-caption tab"
            />
            <Tab
              label={AllocationsModalWindowTitle.TimeOffFormTitle}
              value="2"
              className="inter-caption tab"
            />
          </TabList>
        </Box>
        <TabPanel value="1" className="tabel-panel">
          <AllocationBookingAddForm
            onClose={onClose}
            clickCellDate={formatDateToCustomString(clickCellDate)}
            clickCellName={clickCellName}
          />
        </TabPanel>
        <TabPanel value="2" className="tabel-panel">
          <AllocationTimeOffAddForm
            onClose={onClose}
            clickCellDate={formatDateToCustomString(clickCellDate)}
            clickCellName={clickCellName}
          />
        </TabPanel>
      </TabContext>
    </StyledAllocationModalWindowWithTabs>
  );
};

export default AllocationModalWindowWithTabs;
