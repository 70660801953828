import { ReactNode } from 'react';
import StyledEmptyView from './StyledEmptyItem';

type EmptyViewProps = {
  title: string;
  description: string;
  children: ReactNode;
};

const EmptyView = ({ title, description, children }: EmptyViewProps) => {
  return (
    <StyledEmptyView>
      {children}
      <h3 className="inter-caption_bold">{title}</h3>
      <p className="inter-caption_medium">{description}</p>
    </StyledEmptyView>
  );
};

export default EmptyView;
