import { InvoiceStatusFormDto } from 'types/project/dto/InvoiceStatusFormDto';
import { UpdateInvoiceStatusDto } from 'types/invoice/updateInvoiceStatusDto';

const mapToUpdateInvoiceStatusDto = (data: InvoiceStatusFormDto): UpdateInvoiceStatusDto => {
  return {
    invoiceStatus: data.invoiceStatus,
    notes: data.notes
  } as UpdateInvoiceStatusDto;
};

export default mapToUpdateInvoiceStatusDto;
