enum InvoiceDetailsFormNames {
  InvoiceDetailsTitle = 'Invoice Details',
  PreinvoiceDetailsTitle = 'Preinvoice Details',
  CorrectionInvoiceDetailsTitle = 'Correction Invoice Details',
  FixedPriceInvoiceDetailsTitle = 'Fixed Price Invoice Details',
  FixedPricePreInvoiceDetailsTitle = 'Fixed Price Preinvoice Details',
  InvoiceDetailsTotalTitle = 'Invoice Total',
  PreinvoiceDetailsTotalTitle = 'Preinvoice Total',
  CorrectionInvoiceDetailsTotalTitle = 'Correction Invoice Total',
  FixedPriceInvoiceDetailsTotalTitle = 'Fixed Price Invoice Total',
  FixedPricePreInvoiceDetailsTotalTitle = 'Fixed Price Preinvoice Total'
}

export default InvoiceDetailsFormNames;
