import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import * as yup from 'yup';
import { notePattern } from 'constants/validationConstants';

const InvoiceStatusFormValidation = () => {
  return yup.object().shape({
    invoiceStatus: yup.string().trim().required(FormValidationMessage.RequiredError),
    notes: yup
      .string()
      .trim()
      .matches(notePattern, FormValidationMessage.ReasonMaxLengthError)
      .nullable()
  });
};

export default InvoiceStatusFormValidation;
