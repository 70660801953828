import React, { ChangeEvent, useState } from 'react';
import { Autocomplete, TextField, Chip, FormControl } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import AllocationsFilterDashboardDto from 'types/allocation/dto/AllocationsFilterDashboardDto';
import AllocationsDictionariesDto from 'types/allocation/dto/AllocationsDictionariesDto';
import { DictionaryItemDto } from 'types/employee/enums/DictionaryItemDto';
import StyledAllocationFilterMultiSelect from 'components/allocations/AllocationFilterDashboardItems/AllocationFilterMultiSelect/StyledAllocationFilterMultiSelect';
import { BORDERS_COLOR } from 'constants/colors';
import {
  allocationMultiSelectChipsMargin,
  allocationMultiSelectListItemRedious,
  allocationUnselectedListItemColor
} from 'components/allocations/consts/styleConst';

type AllocationFilterMultiSelectProps = {
  control: Control<AllocationsFilterDashboardDto>;
  dictionaries: AllocationsDictionariesDto;
  name: keyof AllocationsFilterDashboardDto;
  className: string;
  label: string;
  selectedValue: DictionaryItemDto[];
  setSelectedValue: React.Dispatch<React.SetStateAction<DictionaryItemDto[]>>;
};

const AllocationFilterMultiSelect = ({
  control,
  dictionaries,
  name,
  className,
  label,
  selectedValue,
  setSelectedValue
}: AllocationFilterMultiSelectProps) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleAutocompleteClose = () => {
    setSearchValue('');
  };

  const handleAutocompleteChange = (_: unknown, newValue: DictionaryItemDto[]) => {
    const updatedValue = newValue || [];
    setSelectedValue(updatedValue);
  };

  return (
    <StyledAllocationFilterMultiSelect>
      <Controller
        name={name}
        control={control}
        render={() => {
          const dictionary = dictionaries[name] as DictionaryItemDto[];

          const filteredItem = dictionary
            .filter((item) => {
              const isOptionSelected = selectedValue.some((option) => option.id === item.id);
              const matchesSearch = item.name.toLowerCase().includes(searchValue.toLowerCase());
              return matchesSearch || isOptionSelected;
            })
            .sort((a, b) => a.displayName.localeCompare(b.displayName));
          return (
            <FormControl>
              <p className="inter-caption_medium">{label}</p>
              <Autocomplete
                className={className}
                multiple
                value={selectedValue}
                onChange={handleAutocompleteChange}
                onClose={handleAutocompleteClose}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Start typing"
                    value={searchValue}
                    onChange={handleSearchChange}
                  />
                )}
                renderOption={(props, option, state) => (
                  <li
                    {...props}
                    style={{
                      backgroundColor: state.selected
                        ? BORDERS_COLOR
                        : allocationUnselectedListItemColor,
                      margin: allocationMultiSelectChipsMargin,
                      borderRadius: allocationMultiSelectListItemRedious
                    }}
                  >
                    {option.displayName}
                  </li>
                )}
                renderTags={(value, getTagProps) => {
                  if (value.length === 0) {
                    return null;
                  }
                  return (
                    <div className="chip-row">
                      {value.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          label={option.displayName}
                          key={option.key}
                          className="chip"
                        />
                      ))}
                    </div>
                  );
                }}
                options={filteredItem}
                getOptionLabel={(option) => option.displayName}
                isOptionEqualToValue={(option, value) => option.id === value?.id}
              />
            </FormControl>
          );
        }}
      />
    </StyledAllocationFilterMultiSelect>
  );
};
export default AllocationFilterMultiSelect;
