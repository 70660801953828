import { LOW_EMPHASISE_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledAllocationEmployeesDetails = styled.div({
  display: 'flex',
  flexDirection: 'row',
  '.button-with-icon': {
    width: '100%',
    backgroundColor: 'transparent',
    border: 'none',
    textAlign: 'left',
    padding: '8px 0',
    '.img': {
      alignSelf: 'center'
    },
    '.employee-shifts-info': {
      display: 'flex',
      flexDirection: 'column',
      '.employee-shifts-name': {
        fontSize: '13px',
        fontWeight: '200',
        lineHeight: '20px'
      }
    },
    '.employee-shifts-title': {
      color: LOW_EMPHASISE_COLOR
    },
    cursor: 'pointer'
  }
});

export default StyledAllocationEmployeesDetails;
