import { createAsyncThunk } from '@reduxjs/toolkit';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import EmployeeRate from 'types/project/enums/EmployeeRate';
import EditEmployeeRateFormDto from 'types/project/dto/EditEmployeeRateFormDto';
import employeeRateApiService from 'api/employeeRateApiService';
import AddEmployeeRateDto from 'types/project/dto/AddEmployeeRateDto';

const addEmployeeRate = createAsyncThunk<
  AddEmployeeRateDto,
  EditEmployeeRateFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(EmployeeRate.ADD_EMPLOYEE_RATE, async (data: EditEmployeeRateFormDto) => {
  const response = await employeeRateApiService.addEmployeeRate(data);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});
export default addEmployeeRate;
