import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import AllocationBookingAddFormDefaultValue from 'components/allocations/AllocationBooking/AllocationAddBookingForm/AllocationBookingAddFormDefaultValue';
import AllocationBookingFormValidation from 'components/allocations/AllocationBooking/AllocationAddBookingForm/AllocationBookingFormValidation';
import AllocationEditBookingFormDefaultValue from 'components/allocations/AllocationBooking/AllocationEditBookingForm/AllocationEditBookingFormDefaultValue';
import calendarSelectorByWeekId from 'components/allocations/consts/calendarSelectorByWeekId';
import FormAutocomplete from 'components/sharedComponents/FormComponents/FormAutocomplete/FormAutocomplete';
import FormSwitch from 'components/sharedComponents/FormComponents/FormSwitch/FormSwitch';
import FormTextField from 'components/sharedComponents/FormComponents/FormTextField/FormTextField';
import FormTimePicker from 'components/sharedComponents/FormComponents/FormTimePicker/FormTimePicker';
import SpinnerButton from 'components/sharedComponents/Spinner/SpinnerButton';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import {
  calculateFridayWeeksFromNow,
  calculateTotalTime,
  calculateTotalWorkingDays,
  makeDecemberLastMonthInMaxYear
} from 'helpers/dateUtils';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { ApiError, logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import getAllocationsDictionaries from 'store/action/allocationsAction/getAllocationsDictionaries';
import updateBooking from 'store/action/allocationsAction/updateBooking';
import { useThunkAppDispatch, useAppSelector } from 'store/redux-hooks/hooks';
import AllocationsAddBookingFormDto from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingFormDto';
import AllocationsFormComponentNames from 'types/allocation/dto/enums/AllocationsFormComponentNames';
import InputType from 'types/generalEnums/InputType';
import getProjectDictionaries from 'store/action/projectActions/getProjectDictionaries';
import getAllProjects from 'store/action/projectActions/getAllProjects';
import filterProjectsByName from 'components/allocations/consts/filterProjectsByName';
import ButtonsText from 'types/generalEnums/ButtonsText';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import { dateBeforeProjectStartDate, endDateBeforeStartDate } from 'helpers/invalidDateTest';
import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import { infoGrey } from 'shared/PrpIcon/icons.constants';
import TootlipName from 'components/sharedComponents/Tooltip/TooltipNames';
import getAllocationFormTimePickerNames from 'types/allocation/enums/AllocationFormTimePickerNames';
import { ProjectStatusNumber } from 'types/project/enums/ProjectStatusName';
import FormInputNumber from 'components/sharedComponents/FormComponents/FormInput/FormInputNumber';
import { ErrorType } from 'types/ApiError/ErrorEnums';
import { convertDecimalValueToHoursAndMinutes, getMinutes } from 'helpers/calculateTime';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import ErrorConfirmModal from 'components/sharedComponents/ErrorConfirmModal/ErrorConfirmModal';
import MAX_BOOKING_TIMESPAN_IN_WEEKS from 'components/allocations/consts/dateConstants';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import FormDatePicker from 'components/sharedComponents/FormComponents/FormDatePicker/FormDatePicker';
import OnlyDateFilter from 'types/generalEnums/DatePickerFilterType';

type AllocationEditBookingFormProps = {
  onClose: () => void;
  showDeleteModal: boolean;
};

const AllocationEditBookingForm = ({
  onClose,
  showDeleteModal
}: AllocationEditBookingFormProps) => {
  const [isBookingClashModalOpen, setIsBookingClashModalOpen] = useState(false);
  const [bookingError, setBookingError] = useState<ApiError | null>(null);
  useEscapeKey(() => {
    if (!showDeleteModal) {
      onClose();
    }
  });
  const dispatch = useThunkAppDispatch();
  const bookingState = useAppSelector((state: RootState) => state.allocationBooking.data);
  const loadingBooking = useAppSelector((state: RootState) => state.allocationBooking.readLoading);
  const calendarState = useAppSelector((state: RootState) => state.calendarDictionaries.data);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isAutoDiscountSelected, setIsAutoDiscountSelected] = useState(false);
  const { startDate, endDate } = calendarSelectorByWeekId(calendarState, bookingState.weekId);
  const methods = useForm<AllocationsAddBookingFormDto>({
    defaultValues: bookingState.id
      ? AllocationEditBookingFormDefaultValue(bookingState, startDate, endDate)
      : AllocationBookingAddFormDefaultValue,
    // TODO fix unknown
    resolver: yupResolver(
      AllocationBookingFormValidation(isAutoDiscountSelected)
    ) as unknown as Resolver<AllocationsAddBookingFormDto>
  });
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors }
  } = methods;

  const allocationsDictionaries = useAppSelector(
    (state: RootState) => state.allocationsDictionaries.data
  );
  const projectState = useAppSelector((state: RootState) => state.project.data);

  useEffect(() => {
    dispatch(getProjectDictionaries())
      .then(unwrapResult)
      .then(() => {
        dispatch(getAllProjects())
          .then(unwrapResult)
          .catch(() => logError('Error fetching projects'));
      })
      .catch(() => {
        logError('Error fetching project dictionaries');
      });

    dispatch(getAllocationsDictionaries())
      .then(unwrapResult)
      .catch(() => logError('Error fetching allocationDictionaries'));
  }, []);
  const closedProjects = projectState
    .filter((project) => project.status === ProjectStatusNumber.Closed)
    .map((project) => project.name);

  const filteredProjects = filterProjectsByName(
    allocationsDictionaries.projectNames,
    closedProjects
  );

  useEffect(() => {
    if (bookingState) {
      methods.reset(bookingState);
    }
  }, [bookingState, methods]);

  const projectData = projectState.find(
    (project) => project.id === watch(AllocationsFormComponentNames.Project)
  );
  const startDateFromProject = projectData ? new Date(projectData.startDate) : new Date();
  const startDateWatch = watch(AllocationsFormComponentNames.StartDate);
  const startSelectedDate = new Date(watch(AllocationsFormComponentNames.StartDate)).getTime();
  const endSelectedDate = new Date(watch(AllocationsFormComponentNames.EndDate)).getTime();
  const projectWatch = watch(AllocationsFormComponentNames.Project);
  const weeklyHour = watch(AllocationsFormComponentNames.HoursWeekly) || 0;
  const weeklyMins = watch(AllocationsFormComponentNames.MinutesWeekly) || 0;
  const autoDiscountDuration = watch(AllocationsFormComponentNames.AutoDiscountDuration);
  const calendarMinDate = calendarState?.minDate ? new Date(calendarState.minDate) : new Date();

  const minDate = projectWatch ? startDateFromProject : calendarMinDate;
  const maxDate = startSelectedDate
    ? calculateFridayWeeksFromNow(MAX_BOOKING_TIMESPAN_IN_WEEKS, new Date(startSelectedDate))
    : makeDecemberLastMonthInMaxYear(new Date(calendarState?.maxDate ?? new Date()));

  const numOfDaysSelected = calculateTotalWorkingDays(
    new Date(startSelectedDate),
    new Date(endSelectedDate)
  );
  const numOfDaysDefault = calculateTotalWorkingDays(
    new Date(startDate as string),
    new Date(endDate as string)
  );
  const totalTime = calculateTotalTime(
    numOfDaysSelected ?? numOfDaysDefault,
    Number(weeklyHour) ?? bookingState.hours,
    Number(weeklyMins) ?? bookingState.minutes
  );

  const totalDiscountTime = calculateTotalTime(
    numOfDaysSelected,
    convertDecimalValueToHoursAndMinutes(Number(autoDiscountDuration)).hours,
    convertDecimalValueToHoursAndMinutes(Number(autoDiscountDuration)).minutes
  );

  const isDiscountErrorVisible =
    getMinutes(Number(weeklyHour)) + weeklyMins < getMinutes(Number(autoDiscountDuration));

  const onSubmit: SubmitHandler<AllocationsAddBookingFormDto> = async (
    data: AllocationsAddBookingFormDto
  ) => {
    if (dateBeforeProjectStartDate(startDateWatch, minDate, projectWatch) || isDiscountErrorVisible)
      return;
    try {
      await dispatch(updateBooking({ data, force: isBookingClashModalOpen })).unwrap();
      onClose();
    } catch (error) {
      if (error instanceof ApiError && error.type === ErrorType.Confirmation) {
        setIsBookingClashModalOpen(true);
        setBookingError(error);
      }

      logError('Error updating booking');
    }
  };

  const closeModal = () => {
    setIsBookingClashModalOpen(false);
  };

  const handleClashSubmit = useCallback(async () => {
    const formData: AllocationsAddBookingFormDto = watch();
    await onSubmit(formData);
    closeModal();
  }, [watch, onSubmit, closeModal]);

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };
  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };
  useEffect(() => {
    setValue(AllocationsFormComponentNames.HoursWeekly, bookingState.hours || 0);
    setValue(AllocationsFormComponentNames.MinutesWeekly, bookingState.minutes || 0);
    if (bookingState) {
      setValue(AllocationsFormComponentNames.StartDate, startDate || '');
      setValue(AllocationsFormComponentNames.EndDate, endDate || '');
    }
  }, [bookingState]);
  const autoDiscountWatch = watch(AllocationsFormComponentNames.AutoDiscount);
  useEffect(() => {
    setIsAutoDiscountSelected(autoDiscountWatch);
  }, [autoDiscountWatch]);

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        {loadingBooking ? (
          <SpinnerButton loading={loadingBooking} className="allocation-form-mainspinner" />
        ) : (
          <div>
            <Grid container spacing={2} columns={20} className="modal-container">
              <Grid item xs={20} md={20}>
                <FormAutocomplete
                  title={AllocationsFormComponentNames.PeopleRecourses}
                  label={AllocationsFormComponentNames.PeopleRecoursesLabel}
                  control={control}
                  options={allocationsDictionaries.bookableEmployeeNames || []}
                  className="allocation-form-select"
                  error={errors.employeeId?.message}
                  disabled
                />
              </Grid>
              <Grid item xs={11} md={11}>
                <div className="time-input-row">
                  <FormTimePicker
                    dataToDisplay={getAllocationFormTimePickerNames()}
                    required
                    className="input-form-time"
                    error={errors.hours?.message}
                    hoursValue={weeklyHour}
                    minsValue={weeklyMins}
                    caption="per week"
                    register={register}
                  />
                </div>
              </Grid>
              <Grid item xs={10} md={9}>
                <div className="booking-dates">
                  <FormDatePicker
                    name={AllocationsFormComponentNames.StartDate}
                    control={control}
                    label={AllocationsFormComponentNames.FromDateLabel}
                    className="allocation-form-date"
                    error={errors.startDate?.message}
                    minDate={minDate}
                    maxDate={maxDate}
                    filter={OnlyDateFilter.Mondays}
                    isEndDatePicker
                  />
                  <FormDatePicker
                    name={AllocationsFormComponentNames.EndDate}
                    control={control}
                    label={AllocationsFormComponentNames.ToDateLabel}
                    className="allocation-form-date"
                    error={errors.endDate?.message}
                    minDate={minDate}
                    maxDate={maxDate}
                    filter={OnlyDateFilter.Fridays}
                    isEndDatePicker
                  />
                </div>
                {endDateBeforeStartDate(new Date(startSelectedDate), new Date(endSelectedDate)) && (
                  <span className="error-message error inter-caption_medium">
                    {FormValidationMessage.EndDateBeforeStartDateError}
                  </span>
                )}
                {dateBeforeProjectStartDate(startDateWatch, minDate, projectWatch) && (
                  <span className="error-message error inter-caption_medium">
                    {FormValidationMessage.DateBeforeProjectStartDateError}
                  </span>
                )}
              </Grid>
              <Grid item xs={16} md={10}>
                <p className="inter-caption-grey total-time-caption">
                  Total: {totalTime[0]}h {totalTime[1]}m ({numOfDaysSelected || numOfDaysDefault}{' '}
                  days)
                </p>
              </Grid>
              <Grid item xs={16} md={20}>
                <div className="switch-row inter-caption_medium">
                  <p className="inter-caption_medium booking-confirmed-label">
                    {AllocationsFormComponentNames.TentativeLabel}
                  </p>
                  <FormSwitch name={AllocationsFormComponentNames.Confirmed} control={control} />
                  <p className="inter-caption_medium booking-confirmed-label">
                    {AllocationsFormComponentNames.ConfirmedLabel}
                  </p>
                </div>
              </Grid>
              <Grid item xs={16} md={20}>
                <div className="switch-row inter-caption_medium">
                  <FormSwitch name={AllocationsFormComponentNames.AutoDiscount} control={control} />
                  <p className="inter-caption_medium booking-confirmed-label">
                    {AllocationsFormComponentNames.AutoDiscountLabel}
                  </p>
                  <CustomTooltip
                    icon={infoGrey}
                    onClose={handleCloseTooltip}
                    onOpen={handleOpenTooltip}
                    isTooltipOpen={openTooltip}
                    title={TootlipName.ApplyAutoDiscountTitle}
                    placement="bottom"
                  />
                </div>
              </Grid>
              {autoDiscountWatch && (
                <div className="auto-discount-container">
                  <Grid item xs={12} md={7}>
                    <div className="discount-time-input-row">
                      <FormInputNumber
                        label={AllocationsFormComponentNames.AutoDiscountDurationLabel}
                        name={AllocationsFormComponentNames.AutoDiscountDuration}
                        required
                        register={register}
                        getValues={getValues}
                        error={errors.autoDiscountDuration?.message}
                        minValue={0}
                        className="discount-input-form"
                        isDecimal
                        numberOfDigitsAfterDecimal={2}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={16} md={16}>
                    <p className="inter-caption-grey total-time-caption">
                      Total discounted: {totalDiscountTime[0]}h {totalDiscountTime[1]}m (out of{' '}
                      {totalTime[0]}h {totalTime[1]}m)
                    </p>
                  </Grid>
                  {isDiscountErrorVisible && (
                    <Grid item xs={16} md={16}>
                      <p className="discount-error">{FormValidationMessage.DiscountTimeError}</p>
                    </Grid>
                  )}
                </div>
              )}
              <Grid item xs={20} md={20}>
                <FormAutocomplete
                  title={AllocationsFormComponentNames.Project}
                  label={AllocationsFormComponentNames.ProjectLabel}
                  control={control}
                  options={filteredProjects || []}
                  className="allocation-form-select"
                  error={errors.employeeId?.message}
                  disabled
                />
              </Grid>
              <Grid item xs={16} md={20}>
                <FormTextField
                  label={AllocationsFormComponentNames.BookingDetailsLabel}
                  name={AllocationsFormComponentNames.BookingDetails}
                  type={InputType.Text}
                  required
                  register={register}
                  className="input-form-allocation-details"
                  error={errors.details?.message}
                />
              </Grid>
            </Grid>
            <CustomModalButtons
              submitButtonText={ButtonsText.Update}
              isSpinnerButtonAdded
              loading={loadingBooking}
              isCancelButtonVisible
              submitButtonClassName="add-button"
              onClick={onClose}
            />
          </div>
        )}
      </StyledForm>
      {isBookingClashModalOpen && bookingError && (
        <ErrorConfirmModal
          onClose={closeModal}
          onSubmit={handleClashSubmit}
          header={bookingError.header}
          body={bookingError.message}
        />
      )}
    </FormProvider>
  );
};

export default AllocationEditBookingForm;
