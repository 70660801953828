import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import * as yup from 'yup';

const EditBillingPeriodFormValidation = () => {
  return yup.object().shape({
    amount: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(FormValidationMessage.BillingPeriodError)
  });
};

export default EditBillingPeriodFormValidation;
