import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import AllocationPublicHolidayFormValidation from 'components/allocations/AllocationPublicHoliday/AllocationPublicHolidayAddForm/AllocationPublicHolidayFormValidation';
import AllocationPublicHolidayEditFormDefaultValues from 'components/allocations/AllocationPublicHoliday/AllocationPublicHolidayEditForm/AllocationPublicHolidayEditFormDefaultValues';
import { textFildInputPropsHeight } from 'components/allocations/consts/styleConst';
import FormDatePicker from 'components/sharedComponents/FormComponents/FormDatePicker/FormDatePicker';
import FormTextField from 'components/sharedComponents/FormComponents/FormTextField/FormTextField';
import SpinnerButton from 'components/sharedComponents/Spinner/SpinnerButton';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import ButtonsText from 'types/generalEnums/ButtonsText';
import { useEffect } from 'react';
import { useForm, Resolver, SubmitHandler, FormProvider } from 'react-hook-form';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import updatePublicHoliday from 'store/action/allocationsAction/updatePublicHoliday';
import { useThunkAppDispatch, useAppSelector } from 'store/redux-hooks/hooks';
import AllocationPublicHolidayFormDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayFormDto';
import AllocationPublicHolidayFormNames from 'types/allocation/enums/AllocationPublicHolidayFormNames';
import InputType from 'types/generalEnums/InputType';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import AllocationPublicHolidayDefaultValue from '../AllocationPublicHolidayAddForm/AllocationPublicHolidayAddFormDefaultValue';

type AllocationPublicHolidayEditFormProps = {
  onClose: () => void;
  showDeleteModal: boolean;
};

const AllocationPublicHolidayEditForm = ({
  onClose,
  showDeleteModal
}: AllocationPublicHolidayEditFormProps) => {
  const holidayState = useAppSelector((state: RootState) => state.allocationPublicHoliday.data);
  const readLoadingPublicHoliday = useAppSelector(
    (state: RootState) => state.allocationPublicHoliday.readLoading
  );
  useEscapeKey(() => {
    if (!showDeleteModal) {
      onClose();
    }
  });
  const methods = useForm<AllocationPublicHolidayFormDto>({
    defaultValues: holidayState
      ? AllocationPublicHolidayEditFormDefaultValues(holidayState)
      : AllocationPublicHolidayDefaultValue,
    // TODO fix unknown
    resolver: yupResolver(
      AllocationPublicHolidayFormValidation()
    ) as unknown as Resolver<AllocationPublicHolidayFormDto>
  });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = methods;

  const dispatch = useThunkAppDispatch();

  useEffect(() => {
    if (holidayState) {
      methods.reset(holidayState);
    }
  }, [holidayState, methods]);

  const onSubmit: SubmitHandler<AllocationPublicHolidayFormDto> = async (
    data: AllocationPublicHolidayFormDto
  ) => {
    try {
      await dispatch(updatePublicHoliday(data)).unwrap();
      onClose();
    } catch (error) {
      logError('Error submitting public holiday');
    }
  };

  const textFildInputProps = { style: { height: textFildInputPropsHeight } };
  const loadingPublicHoliday = useAppSelector(
    (state: RootState) => state.allocationPublicHoliday.saveLoading
  );

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        {readLoadingPublicHoliday ? (
          <SpinnerButton
            loading={readLoadingPublicHoliday}
            className="allocation-form-mainspinner"
          />
        ) : (
          <div>
            <Grid container spacing={2} className="modal-container">
              <Grid item xs={12} md={12} className="allocation-public-holiday-date">
                <FormDatePicker
                  name={AllocationPublicHolidayFormNames.DataName}
                  control={control}
                  label={AllocationPublicHolidayFormNames.DateLabel}
                  className="form-date allocation-public-holiday-datepicker"
                  error={errors.date?.message}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <FormTextField
                  label={AllocationPublicHolidayFormNames.DetailsLabel}
                  name={AllocationPublicHolidayFormNames.DetailsName}
                  type={InputType.Text}
                  required={false}
                  register={register}
                  className="allocation-public-holidat-text-field"
                  inputProps={textFildInputProps}
                  error={errors.details?.message}
                />
              </Grid>
            </Grid>
            <CustomModalButtons
              submitButtonText={ButtonsText.Update}
              isSpinnerButtonAdded
              loading={loadingPublicHoliday}
              isCancelButtonVisible
              submitButtonClassName="add-button"
              onClick={onClose}
              className="add-buttons-holiday-row"
            />
          </div>
        )}
      </StyledForm>
    </FormProvider>
  );
};

export default AllocationPublicHolidayEditForm;
