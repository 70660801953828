import UpdateDiscountDto from 'types/project/dto/UpdateDiscountDto';
import { UpdateDiscountFormDto } from 'types/project/dto/UpdateDiscountFormDto';
import DiscountTypeEnum from 'types/PrpTable/enums/discountTypeEnum';

const mapToDiscountDto = (data: UpdateDiscountFormDto): UpdateDiscountDto => {
  return {
    id: data.id ?? null,
    weekId: data.weekId ?? null,
    projectId: data.projectId ?? null,
    employeeId: data.employeeId ?? null,
    duration: data.duration ?? '',
    reason: data.reason ?? '',
    repeatForWeeksInBillingPeriod: data.repeatForWeeksInBillingPeriod ?? false,
    discountType: data.discountType ?? DiscountTypeEnum.DEFAULT
  } as UpdateDiscountDto;
};
export default mapToDiscountDto;
