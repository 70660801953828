import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
import getReactItemsWithKey from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import invoiceApiService from 'api/invoiceApiServices';
import { ProjectTableDto } from 'types/PrpTable/dto/ProjectTableDto';
import { InvoiceDto } from 'types/project/dto/InvoiceDto';
import InvoiceAction from 'types/project/enums/Invoice';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';

const getInvoice = createAsyncThunk<
  InvoiceDto[],
  ProjectTableDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(InvoiceAction.GET_INVOICE, async (data: ProjectTableDto) => {
  const response = await invoiceApiService.getInvoice(data);
  if (!response) notifyUser();
  return getReactItemsWithKey(response);
});

export default getInvoice;
