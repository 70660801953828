import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MainSpinner from 'components/sharedComponents/Spinner/MainSpinner';
import RoutingPath from 'routes/routing';
import { ACCESS_TOKEN_LOCAL_STORAGE_KEY, ACCESS_TOKEN_QUERY_PARAM } from 'environment/environment';

const RedirectPage = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const tokenParam = params.get(ACCESS_TOKEN_QUERY_PARAM);
    if (tokenParam) {
      localStorage.setItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY, tokenParam);
    }
    setIsLoading(false);
    navigate(tokenParam ? RoutingPath.EmployeesPage : RoutingPath.LogInPage);
  }, [params, navigate]);

  return (
    <div>
      <MainSpinner loading={isLoading} />
    </div>
  );
};
export default RedirectPage;
