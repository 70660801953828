import { Grid } from '@mui/material';
import StyledClientChangeForm from 'components/clients/ClientChangeForm/StyledClientChangeForm';
import FormChangeHeader from 'components/sharedComponents/FormComponents/FormChangeHeader/FormChangeHeader';
import FormInput from 'components/sharedComponents/FormComponents/FormInput/FormInput';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import ClientFormComponentNames from 'types/clients/enums/ClientFormComponentNames';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import InputType from 'types/generalEnums/InputType';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import ClientDetailsProject from 'components/clients/ClientDetails/ClientDetailsProject';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import updateClients from 'store/action/clientActions/updateClients';
import { unwrapResult } from '@reduxjs/toolkit';
import { ClientFormDto } from 'types/clients/dto/ClientFormDto';
import ClientFormsValidation from 'components/clients/ClientChangeForm/ClientChangeFormValidation';
import ClientFormDefaultValues from 'components/clients/ClientChangeForm/ClientFormDefaulValues';
import { RootState } from 'store';
import ClientAddFormDefaultValue from 'components/clients/ClientAddForm/ClientAddFormDefaultValue';
import PageType from 'types/generalEnums/PageType';
import FormTypeName from 'types/generalEnums/FormTypeName';
import { memoize } from 'proxy-memoize';
import ConfirmationModal from 'components/sharedComponents/ConfirmationWindow/ConfirmationModal';
import ModalWindow from 'modals/ModalWindow';
import { useState } from 'react';
import { useBeforeUnload } from 'react-router-dom';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import getClients from 'store/action/clientActions/getClients';
import { employeeConfirmationModalHeight } from 'components/sharedComponents/StyleConsts/styleConsts';

type ClientChangeFormProps = {
  item: ClientsDto;
  onCancel: () => void;
};

const ClientChangeForm = ({ item, onCancel }: ClientChangeFormProps) => {
  const client = useAppSelector(
    memoize((state: RootState) => state.clients.data.find((c: ClientsDto) => c.id === item.id))
  );
  const methods = useForm<ClientFormDto>({
    // TODO: fix unknown
    defaultValues: client ? ClientFormDefaultValues(item) : ClientAddFormDefaultValue,
    resolver: yupResolver(
      ClientFormsValidation(FormTypeName.EditForm)
    ) as unknown as Resolver<ClientFormDto>
  });
  const {
    handleSubmit,
    register,
    formState: { errors, isDirty }
  } = methods;

  const dispatch = useThunkAppDispatch();
  const onSubmit: SubmitHandler<ClientFormDto> = async (data: ClientFormDto) => {
    dispatch(updateClients(data))
      .then(unwrapResult)
      .then(() => {
        dispatch(getClients())
          .then(() => onCancel())
          .catch(() => {
            logError('Error fetching clients');
          });
      })
      .catch(() => {
        logError('Error submitting clients');
      });
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleConfirmLeave = (event: BeforeUnloadEvent) => {
    if (isDirty) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  useBeforeUnload(handleConfirmLeave);
  useEscapeKey(onCancel);
  const handleLeaveForm = () => {
    if (isDirty) {
      setShowConfirmationModal(false);
      onCancel();
    }
  };

  const handleStayInForm = () => {
    setShowConfirmationModal(false);
  };

  const handleCancel = () => {
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      onCancel();
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledClientChangeForm onSubmit={handleSubmit(onSubmit)}>
        {showConfirmationModal ? (
          <ModalWindow
            onClose={() => setShowConfirmationModal(false)}
            style={{ minHeight: employeeConfirmationModalHeight }}
          >
            <ConfirmationModal
              handleLeaveForm={handleLeaveForm}
              handleStayInForm={handleStayInForm}
            />
          </ModalWindow>
        ) : null}
        <FormChangeHeader
          onCancel={handleCancel}
          onSave={handleSubmit(onSubmit)}
          register={register}
          errors={errors.name?.message}
          type={PageType.Clients}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} className="contact-information">
            <p className="inter-caption_medium">Contact Information</p>
          </Grid>
          <Grid item xs={6} md={4}>
            <FormInput
              label={ClientFormComponentNames.ContactNameLabel}
              name={ClientFormComponentNames.ContactName}
              type={InputType.Text}
              required
              register={register}
              className="input-form-position"
              error={errors.contactName?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormInput
              label={ClientFormComponentNames.ContactPositionLabel}
              name={ClientFormComponentNames.ContactPosition}
              type={InputType.Text}
              required
              register={register}
              className="input-form-position"
              error={errors.contactPosition?.message}
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormInput
              label={ClientFormComponentNames.ContactPhoneLabel}
              name={ClientFormComponentNames.ContactPhone}
              type={InputType.Text}
              required
              register={register}
              error={errors.contactPhone?.message}
              className="input-form-phone"
            />
          </Grid>
          <Grid item xs={6} md={8}>
            <FormInput
              label={ClientFormComponentNames.ContactEmailLabel}
              name={ClientFormComponentNames.ContactEmail}
              type={InputType.Text}
              required
              register={register}
              className="input-form"
              error={errors.contactEmail?.message}
            />
          </Grid>
          {item?.projects.length > 0 ? (
            <Grid item xs={12} md={12}>
              <ClientDetailsProject item={item} />
            </Grid>
          ) : null}
        </Grid>
      </StyledClientChangeForm>
    </FormProvider>
  );
};

export default ClientChangeForm;
