import { createAsyncThunk } from '@reduxjs/toolkit';
import allocationsApiService from 'api/allocationsApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import AllocationDto from 'types/allocation/dto/AllocationDto';
import AllocationParamsDto from 'types/allocation/dto/AllocationParamsDto';
import AllocationsAction from 'types/allocation/enums/Allocations';

const getAllAllocations = createAsyncThunk<
  AllocationDto,
  AllocationParamsDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(AllocationsAction.FETCH_ALL, async ({ startDate, endDate, filter, ids }) => {
  const response = await allocationsApiService.fetchAllocations(startDate, endDate, filter, ids);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default getAllAllocations;
