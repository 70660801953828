import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { UpdateDiscountFormDto } from 'types/project/dto/UpdateDiscountFormDto';
import UpdateDiscountDto from 'types/project/dto/UpdateDiscountDto';
import mapToDiscountDto from 'store/mappers/project/discountMapper';
import apiRequest from './apiRequest';

const discountApiService: IApiService = {
  createDiscount: async (data: UpdateDiscountFormDto) => {
    const createDiscountDto = mapToDiscountDto(data);
    try {
      const response = await apiRequest<UpdateDiscountDto>({
        method: 'POST',
        endpoint: `${API_URL}/discounts`,
        requestBody: createDiscountDto
      });
      return response;
    } catch (error) {
      logError('Error in createDiscount');
      throw new Error('Error in createDiscount');
    }
  },
  updateDiscount: async (data: UpdateDiscountFormDto) => {
    const updateDiscountDto = mapToDiscountDto(data);
    try {
      const response = await apiRequest<UpdateDiscountDto>({
        method: 'PUT',
        endpoint: `${API_URL}/discounts/${data.id}`,
        requestBody: updateDiscountDto
      });
      return response;
    } catch (error) {
      logError('Error in updateDiscount');
      throw new Error('Error in updateDiscount');
    }
  }
};
interface IApiService {
  createDiscount: (data: UpdateDiscountFormDto) => Promise<UpdateDiscountDto>;
  updateDiscount: (data: UpdateDiscountFormDto) => Promise<UpdateDiscountDto>;
}
export default discountApiService;
