import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid } from '@mui/material';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { RootState } from 'store';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import formatDate from 'helpers/formatDate';
import { DiscountDto, UpdateDiscountFormDto } from 'types/project/dto/UpdateDiscountFormDto';
import PrpTableComponentNames from 'types/PrpTable/enums/projectTableComponentNames';
import Initials from 'components/sharedComponents/Initials/Initials';
import { ALERT_COLOR } from 'constants/colors';
import InputType from 'types/generalEnums/InputType';
import ButtonsText from 'types/generalEnums/ButtonsText';
import FormTextField from 'components/sharedComponents/FormComponents/FormTextField/FormTextField';
import FormInputNumber from 'components/sharedComponents/FormComponents/FormInput/FormInputNumber';
import FormCheckbox from 'components/sharedComponents/FormComponents/FormCheckbox/FormCheckbox';
import createDiscount from 'store/action/projectActions/addDiscount';
import updateDiscount from 'store/action/projectActions/updateDiscount';
import { getHoursIfValueExistOrPlaceholder } from 'helpers/formatCurrencyAndHours';
import { useEffect, useState } from 'react';
import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import { infoGrey } from 'shared/PrpIcon/icons.constants';
import TootlipName from 'components/sharedComponents/Tooltip/TooltipNames';
import DiscountTypeEnum from 'types/PrpTable/enums/discountTypeEnum';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import selectSeniorityPropertyById from 'store/dictionarySelectors/selectorSeniority';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import UpdateDiscountFormValidation from './UpdateDiscountFormValidation';
import UpdateDiscountFormDefalutValue from './UpdateDiscountFormDefaultValue';
import BillingType from '../../../../types/project/enums/BillingType';

type UpdateDiscountFormProps = {
  onClose: () => void;
  discountItem: DiscountDto;
  refreshProjectData: () => void;
  projectBillingType?: BillingType;
};

const UpdateDiscountForm = ({
  onClose,
  discountItem,
  refreshProjectData,
  projectBillingType
}: UpdateDiscountFormProps) => {
  const minValue = 0;
  const [discountType, setDiscountType] = useState(DiscountTypeEnum.DEFAULT);
  const stateEmployee = useAppSelector((state: RootState) => state.dictionaries);
  const employeeState = useAppSelector((state: RootState) => state.employees);
  const methods = useForm<UpdateDiscountFormDto>({
    defaultValues: {
      ...UpdateDiscountFormDefalutValue,
      duration: discountItem.discountDuration,
      reason: discountItem.discountReason,
      discountType: discountItem.discountType
    },
    // TODO 29679 fix unknown
    resolver: yupResolver(
      UpdateDiscountFormValidation(
        discountType !== DiscountTypeEnum.PRE_DISCOUNT
          ? discountItem.actuals || 0
          : discountItem.forecast || 0,
        0,
        discountType
      )
    ) as unknown as Resolver<UpdateDiscountFormDto>
  });
  const {
    handleSubmit,
    register,
    getValues,
    watch,
    setValue,
    formState: { errors }
  } = methods;
  const dispatch = useThunkAppDispatch();
  const [openTooltip, setOpenTooltip] = useState(false);
  const isDiscountSwitchShown = projectBillingType === BillingType.FORECAST;
  useEscapeKey(onClose);
  useEffect(() => {
    setDiscountType(discountItem.discountType);
  }, [discountItem]);
  const isAutoDiscountAdded = discountItem.discountType === DiscountTypeEnum.AUTO_DISCOUNT;
  const onDiscountButtonClick = () => {
    setDiscountType(
      isAutoDiscountAdded ? DiscountTypeEnum.AUTO_DISCOUNT : DiscountTypeEnum.DEFAULT
    );
  };
  const onPreDiscountButtonClick = () => {
    setDiscountType(DiscountTypeEnum.PRE_DISCOUNT);
  };
  useEffect(() => {
    setValue(PrpTableComponentNames.DiscountTypeName, discountType);
  }, [discountType]);

  const onSubmit: SubmitHandler<UpdateDiscountFormDto> = async (data: UpdateDiscountFormDto) => {
    try {
      data.employeeId = discountItem.employeeId;
      data.projectId = discountItem.projectId;
      data.weekId = discountItem.weekId;
      data.id = discountItem.id;
      if (data.id) {
        await dispatch(updateDiscount(data)).unwrap();
      } else {
        await dispatch(createDiscount(data)).unwrap();
      }
      refreshProjectData();
      onClose();
    } catch (error) {
      logError('Error adding booking');
    }
  };
  const loadingPublicHoliday = useAppSelector(
    (state: RootState) => state.allocationPublicHoliday.saveLoading
  );
  const discountHours = watch(PrpTableComponentNames.DiscountName);
  const forecastAmount = discountItem.forecast * discountItem.rate;
  const actualsAmount = discountItem.actuals * discountItem.rate;
  const difference = discountItem.actuals - discountItem.forecast;
  const differenceAmount = difference * discountItem.rate;
  let costs: number;
  if (discountItem.discountCost) {
    costs = discountItem.discountCost;
  } else {
    costs = discountHours ? discountItem.rate * discountHours : 0;
  }
  const formatCurrency = (amount: number, currency: string, region: string) => {
    return new Intl.NumberFormat(region, { style: 'currency', currency }).format(amount);
  };
  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };
  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };
  const matchedSeniorityId = employeeState?.data?.find(
    (employee) => employee?.id === discountItem.employeeId
  )?.seniorityId;
  const seniorityDisplayName = selectSeniorityPropertyById(
    stateEmployee,
    matchedSeniorityId || 0,
    DictionariesSelector.DisplayName
  );

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justifyContent="space-between" className="modal-container">
          <Grid item xs={12} md={12} className="update-discount-row update-discount-row-margin">
            <p className="inter-caption-grey_extrasmall">
              Sprint:
              <span className="inter-caption"> {discountItem.sprintNo}</span>
            </p>
            <p className="inter-caption-grey_extrasmall">
              Week: <span className="inter-caption"> {formatDate(discountItem.date)}</span>
            </p>
          </Grid>
          <Grid item xs={12} md={12} className="edit-billing-row start-date-row">
            <div className="update-discount-row">
              <Initials name={discountItem.name} className="img" />
              <div>
                <p className="inter-p3">{discountItem.name}</p>
                <p className="inter-caption-grey">
                  {`${discountItem.position}, ${seniorityDisplayName}`}
                </p>
              </div>
            </div>
            <div>
              <p className="inter-p3" style={{ textAlign: 'right' }}>
                ${Number(discountItem.rate)}
              </p>
              <p className="inter-caption-grey"> Project Rate</p>
            </div>
          </Grid>
          <Grid item xs={12} md={3.7} className="update-discount-actuals-row">
            <div className="top-row">
              <p className="inter-caption_bold">Forecast</p>
              <p className="inter-caption_bold hours">
                {getHoursIfValueExistOrPlaceholder(discountItem.forecast)}
              </p>
            </div>
            <div>
              <p className="inter-caption-grey costs">
                {formatCurrency(forecastAmount, 'USD', 'en-US')}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={3.7} className="update-discount-actuals-row">
            <div className="top-row">
              <p className="inter-caption_bold">Actual</p>

              <p className="inter-caption_bold hours">
                {getHoursIfValueExistOrPlaceholder(discountItem.actuals)}
              </p>
            </div>
            <div>
              <p className="inter-caption-grey costs">
                {formatCurrency(actualsAmount, 'USD', 'en-US')}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={3.7} className="update-discount-actuals-row">
            <div className="top-row">
              <p className="inter-caption_bold" style={{ color: ALERT_COLOR }}>
                Difference
              </p>
              <p className="inter-caption_bold hours" style={{ color: ALERT_COLOR }}>
                {getHoursIfValueExistOrPlaceholder(difference)}
              </p>
            </div>
            <div>
              <p className="inter-caption-grey costs" style={{ color: ALERT_COLOR }}>
                {formatCurrency(differenceAmount, 'USD', 'en-US')}
              </p>
            </div>
          </Grid>
          {isDiscountSwitchShown && (
            <Grid item xs={12} md={12}>
              <div className="discount-title-container">
                <p className="inter-caption_medium discount-label">Discount Type</p>
                <CustomTooltip
                  icon={infoGrey}
                  onClose={handleCloseTooltip}
                  onOpen={handleOpenTooltip}
                  isTooltipOpen={openTooltip}
                  title={TootlipName.DiscountTypeTitle}
                  placement="bottom"
                />
              </div>
              <div>
                <Button
                  className={`${
                    discountType !== DiscountTypeEnum.PRE_DISCOUNT && 'button-selected'
                  } discount-select`}
                  onClick={onDiscountButtonClick}
                >
                  Discount
                </Button>
                <Button
                  className={`${
                    discountType === DiscountTypeEnum.PRE_DISCOUNT && 'button-selected'
                  } pre-discount-select`}
                  onClick={onPreDiscountButtonClick}
                >
                  Pre-Discount
                </Button>
              </div>
            </Grid>
          )}
          <Grid item xs={6} md={6}>
            <FormInputNumber
              label={PrpTableComponentNames.DiscountLabel}
              name={PrpTableComponentNames.DiscountName}
              required
              register={register}
              getValues={getValues}
              error={errors.duration?.message}
              maxValue={
                discountType !== DiscountTypeEnum.PRE_DISCOUNT
                  ? discountItem.actuals
                  : discountItem.forecast
              }
              minValue={minValue}
              className="input-form"
              isDecimal
              numberOfDigitsAfterDecimal={2}
              icon={
                isAutoDiscountAdded && discountType !== DiscountTypeEnum.PRE_DISCOUNT ? (
                  <p className="discount-type-icon">auto</p>
                ) : null
              }
            />
          </Grid>
          <Grid item xs={4} md={4}>
            <p className="inter-p3 costs-caption">
              {`Costs: ${formatCurrency(costs, 'USD', 'en-US')}`}
            </p>
          </Grid>
          <Grid item xs={12} md={12} className="repeat-label">
            <FormCheckbox
              label={PrpTableComponentNames.DiscountRepeatLabel}
              name={PrpTableComponentNames.DiscountRepeatName}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormTextField
              label={PrpTableComponentNames.DiscountReasonLabel}
              name={PrpTableComponentNames.DiscountReasonName}
              type={InputType.Text}
              required
              register={register}
              className="input-form-allocation-details"
              error={errors.reason?.message}
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.Update}
          isSpinnerButtonAdded
          loading={loadingPublicHoliday}
          isCancelButtonVisible
          submitButtonClassName="add-button"
          onClick={onClose}
          className="add-buttons-row"
        />
      </StyledForm>
    </FormProvider>
  );
};

export default UpdateDiscountForm;
