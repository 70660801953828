import { DictionariesState } from 'store/slicesState/dictionaries';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';

const selectPositionPropertyById = (
  state: DictionariesState,
  id: number,
  property: DictionariesSelector.Name | DictionariesSelector.DisplayName
): string | null => {
  const positionDictionary = state.data?.positions;
  if (!positionDictionary) {
    return null;
  }
  const position = positionDictionary.find((pos) => pos.id === id);
  return position?.[property] ?? null;
};

export default selectPositionPropertyById;
