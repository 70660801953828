import styled from 'styled-components';
import {
  borderRadiusCommon,
  marginTopCommon
} from 'components/sharedComponents/StyleConsts/styleConsts';
import { DARK_GREY, LOW_EMPHASISE_COLOR, TEXT_COLOR } from 'constants/colors';

const StyledFormTimePicker = styled.div({
  input: {
    border: '1px solid',
    borderColor: DARK_GREY,
    borderRadius: borderRadiusCommon,
    padding: '6px',
    width: '250px',
    marginTop: marginTopCommon
  },
  '.time-input-row': {
    display: 'flex',
    flexDirection: 'row'
  },
  '.label': {
    color: LOW_EMPHASISE_COLOR
  },
  '.per-week-caption': {
    color: TEXT_COLOR
  }
});

export default StyledFormTimePicker;
