enum PageType {
  Employees = 'employees',
  Clients = 'clients',
  Allocation = 'allocations',
  Project = 'project',
  ProjectDetails = 'projectDetails',
  ProjectTable = 'projectTable'
}

export default PageType;
