import { Checkbox } from '@mui/material';
import {
  getCurrencyIfValueExistOrPlaceholder,
  getHourOrPlaceholder,
  getHoursIfValueExistOrPlaceholder,
  getNegativeValueOrNull
} from 'helpers/formatCurrencyAndHours';
import { useState } from 'react';
import { checkBox, checkBoxEmpty, frame } from 'shared/PrpIcon/icons.constants';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { BpWeeksDto } from 'types/PrpTable/dto/ProjectTableParamsDto';
import TotalsTableBorderType from 'types/PrpTable/enums/TotalsTableBorderType';
import {
  dataToDisplayDto,
  subTotalsDevelopersDto,
  totalsDevelopersOnlyDto,
  totalsDto
} from 'types/PrpTable/dto/ProjectTableDto';
import isBorderVisible from 'helpers/isCellBorderVisible';
import calculateCellWidth from 'helpers/calculateTotalsCellWidth';
import isCellAloneInBillingPeriod from 'helpers/isCellAloneInBillingPerio';
import SubTotalType from 'types/PrpTable/enums/subTotalTypeEnum';
import ProjectFilterName from 'types/project/enums/ProjectFilterNames';
import SubTotals from './SubTotals/SubTotals';
import ValuesCell from '../ValuesCell/ValuesCell';
import getPrpTableCellClass from '../../../../helpers/getPrpTableCellClass';

export function transformToTotalsData<T extends { totals: totalsDto }>(
  inputArray: T[],
  getId: (item: T) => number
): dataToDisplayDto[] {
  return inputArray.map((item) => ({
    id: getId(item),
    totals: item.totals
  }));
}

export function transformToSubTotalsDevelopersData<T extends { totals: totalsDevelopersOnlyDto }>(
  inputArray: T[],
  getId: (item: T) => number
): subTotalsDevelopersDto[] {
  return inputArray.map((item) => ({
    id: getId(item),
    totals: item.totals
  }));
}

type TotalsTableProps = {
  filterDevelopersTableRows: () => { name: string; id: number }[];
  filterDiscountsTableRows: () => { name: string; id: number }[];
  dataToDisplay: dataToDisplayDto[];
  subTotalsDevelopers: subTotalsDevelopersDto[];
  filters: string[];
  title: string;
  borderType: TotalsTableBorderType;
  billingPeriodWeeks: BpWeeksDto[];
  shouldRenderBorders?: boolean;
  subTotalsDevelopersTitle?: string;
  subTotalsDiscountsTitle?: string;
  developersCheckMarkTitle?: string;
  discountCheckMarkTitle?: string;
  disableFinancialInfo: boolean;
};

const TotalsTable = ({
  filterDevelopersTableRows,
  filterDiscountsTableRows,
  dataToDisplay,
  subTotalsDevelopers,
  filters,
  title,
  borderType = TotalsTableBorderType.BillingPeriod,
  shouldRenderBorders = true,
  billingPeriodWeeks,
  subTotalsDevelopersTitle,
  subTotalsDiscountsTitle,
  developersCheckMarkTitle = 'Separate developers',
  discountCheckMarkTitle = 'Show Discount Details',
  disableFinancialInfo
}: TotalsTableProps) => {
  const [isDeveloperSectionVisible, setIsDeveloperSectionVisible] = useState(false);
  const [isDiscountDetailsSectionVisible, setIsDiscountDetailsSectionVisible] = useState(false);

  let billingPeriodIndex = 0;
  let summedNumberOfWeeks = billingPeriodWeeks[0]?.numberOfWeeks ?? 0;

  const onDevelopersCheckBoxChange = () => {
    setIsDeveloperSectionVisible((prev) => !prev);
  };

  const onDiscountDetailsCheckBoxChange = () => {
    setIsDiscountDetailsSectionVisible((prev) => !prev);
  };

  const actualsButtonClicked = filters.includes(ProjectFilterName.Actuals);

  const discountsButtonClicked = filters.includes(ProjectFilterName.Discounts);

  const differenceButtonClicked = filters.includes(ProjectFilterName.Difference);

  const forecastButtonClicked = filters.includes(ProjectFilterName.Forecast);
  return (
    <tr className="table-content-employee-row" key={99999}>
      <td className="table-content-wrapper">
        <div className="week-totals-border">
          <div className="week-totals">
            <div className="main-totals-wrapper">
              <div
                className={`${
                  filters.length === 1
                    ? 'table-right-content-with-background-and-filters'
                    : 'table-right-content-with-background'
                }`}
              >
                <div className="table-content-container">
                  <div className="table-content-name">
                    <PrpIcon src={frame} className="frame-img" />
                    <p className="inter-caption_bold totals-title">{title}</p>
                  </div>
                  {subTotalsDevelopers && subTotalsDevelopers?.length > 0 && (
                    <div className="table-content-name separate-developers-container">
                      <Checkbox
                        className="developers-checkbox"
                        checked={isDeveloperSectionVisible}
                        onChange={onDevelopersCheckBoxChange}
                        icon={<PrpIcon src={checkBoxEmpty} />}
                        checkedIcon={<PrpIcon src={checkBox} />}
                      />
                      <p className="inter-p3 developers-title">{developersCheckMarkTitle}</p>
                    </div>
                  )}
                  {dataToDisplay && dataToDisplay?.length > 0 && (
                    <div className="table-content-name separate-developers-container">
                      <Checkbox
                        className="developers-checkbox"
                        checked={isDiscountDetailsSectionVisible}
                        onChange={onDiscountDetailsCheckBoxChange}
                        icon={<PrpIcon src={checkBoxEmpty} />}
                        checkedIcon={<PrpIcon src={checkBox} />}
                        disabled={!discountsButtonClicked}
                      />
                      <p className="inter-p3 developers-title">{discountCheckMarkTitle}</p>
                    </div>
                  )}
                </div>
                <div
                  className={`table-content-types ${
                    disableFinancialInfo && 'totals-without-financial-info'
                  }`}
                >
                  {filterDevelopersTableRows().map((item: { name: string; id: number }) => (
                    <p className="inter-caption_medium" key={item.id}>
                      {item.name}
                    </p>
                  ))}
                </div>
              </div>

              <div className="table-content-actuals">
                <div className="sprint-wrapper-weeks-total">
                  <div className="sprint-row-actuals">
                    {dataToDisplay.map((cell: dataToDisplayDto, index) => {
                      const { totals } = cell;

                      const hasBorder = isBorderVisible(
                        index,
                        shouldRenderBorders,
                        borderType,
                        summedNumberOfWeeks
                      );

                      const cellWidth = calculateCellWidth({
                        billingPeriodWeeks,
                        borderType,
                        cell
                      });

                      if (hasBorder) {
                        billingPeriodIndex += 1;
                        summedNumberOfWeeks +=
                          billingPeriodWeeks[billingPeriodIndex]?.numberOfWeeks ?? 0;
                      }

                      const isAloneInBillingPeriod = isCellAloneInBillingPeriod(
                        billingPeriodWeeks,
                        billingPeriodIndex
                      );
                      return (
                        <div
                          className={`table-content-type-details ${
                            hasBorder ? 'totals-border' : ''
                          }`}
                          key={cell.id}
                          style={{
                            width: cellWidth,
                            paddingLeft: isAloneInBillingPeriod ? '51px' : '0px'
                          }}
                        >
                          {forecastButtonClicked && (
                            <ValuesCell
                              duration={getHourOrPlaceholder(
                                totals.forecastDuration ?? (totals?.actualDuration ? 0 : null)
                              )}
                              cost={getCurrencyIfValueExistOrPlaceholder(
                                totals.forecastCost ?? (totals?.actualCost ? 0 : null)
                              )}
                              disableFinancialInfo={disableFinancialInfo}
                            />
                          )}

                          {actualsButtonClicked && (
                            <ValuesCell
                              duration={getHoursIfValueExistOrPlaceholder(
                                totals?.actualDuration ?? (totals?.forecastDuration ? 0 : null)
                              )}
                              cost={getCurrencyIfValueExistOrPlaceholder(totals.actualCost)}
                              disableFinancialInfo={disableFinancialInfo}
                            />
                          )}

                          {differenceButtonClicked && (
                            <ValuesCell
                              duration={getHoursIfValueExistOrPlaceholder(
                                totals.differenceDuration
                              )}
                              cost={getCurrencyIfValueExistOrPlaceholder(totals.differenceCost)}
                              className={getPrpTableCellClass({
                                intensity: totals.differenceIntensity,
                                differenceDuration: totals.differenceDuration
                              })}
                              disableFinancialInfo={disableFinancialInfo}
                            />
                          )}

                          {discountsButtonClicked && (
                            <ValuesCell
                              duration={getHourOrPlaceholder(
                                getNegativeValueOrNull(totals.totalDiscountDuration)
                              )}
                              cost={getCurrencyIfValueExistOrPlaceholder(
                                getNegativeValueOrNull(totals.totalDiscountCost)
                              )}
                              className={getPrpTableCellClass({
                                discountDuration: totals.totalDiscountDuration
                              })}
                              disableFinancialInfo={disableFinancialInfo}
                            />
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            {isDiscountDetailsSectionVisible && dataToDisplay && (
              <SubTotals
                subTotalsDevelopers={subTotalsDevelopers}
                subTotalsDiscounts={dataToDisplay}
                filterTableRows={filterDiscountsTableRows}
                shouldRenderBorders={shouldRenderBorders || false}
                isActualsButtonClicked={actualsButtonClicked}
                title={subTotalsDiscountsTitle ?? ''}
                billingPeriodWeeks={billingPeriodWeeks}
                borderType={borderType}
                subTotalType={SubTotalType.DISCOUNTS}
                disableFinancialInfo={disableFinancialInfo}
              />
            )}
            {isDeveloperSectionVisible && subTotalsDevelopers && (
              <SubTotals
                subTotalsDevelopers={subTotalsDevelopers}
                subTotalsDiscounts={dataToDisplay}
                filterTableRows={filterDevelopersTableRows}
                shouldRenderBorders={shouldRenderBorders || false}
                isActualsButtonClicked={actualsButtonClicked}
                title={subTotalsDevelopersTitle ?? ''}
                billingPeriodWeeks={billingPeriodWeeks}
                borderType={borderType}
                subTotalType={SubTotalType.DEVELOPERS}
                disableFinancialInfo={disableFinancialInfo}
              />
            )}
          </div>
        </div>
      </td>
    </tr>
  );
};

export default TotalsTable;
