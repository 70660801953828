import { Grid } from '@mui/material';
import ClientNotesAddForm from 'components/clients/ClientNoteAddForm/ClientNotesAddForm';
import ClientDetailsNotes from 'components/clients/ClientDetails/ClientDetailsNotes';
import { RootState } from 'store';
import { useAppSelector } from 'store/redux-hooks/hooks';
import { ClientNoteDto } from 'types/clients/dto/ClientNoteDto';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import { memoize } from 'proxy-memoize';
import MainSpinner from '../../sharedComponents/Spinner/MainSpinner';

type ClientNotesListProps = {
  item: ClientsDto;
};

const ClientNotesList = ({ item }: ClientNotesListProps) => {
  const notesLoading = useAppSelector((state: RootState) => state.notes.readLoading);
  const notes = useAppSelector(
    memoize((state: RootState) =>
      state.notes.data.filter((note: ClientNoteDto) => note.clientId === item?.id)
    )
  );

  return notesLoading ? (
    <MainSpinner loading />
  ) : (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <ClientNotesAddForm client={item} />
      </Grid>
      {notes.length > 0 && (
        <Grid item xs={12} md={12}>
          <ClientDetailsNotes item={item as ClientsDto} />
        </Grid>
      )}
    </Grid>
  );
};

export default ClientNotesList;
