import { ClientFormDto } from 'types/clients/dto/ClientFormDto';
import { ClientsDto } from 'types/clients/dto/ClientsDto';

const ClientFormDefaultValues = (item: ClientsDto): ClientFormDto => {
  return {
    id: item.id,
    name: item.name ?? '',
    contactName: item.contactName ?? '',
    contactPosition: item.contactPosition ?? '',
    contactPhone: item.contactPhone ?? '',
    contactEmail: item.contactEmail ?? '',
    colorHex: item.colorHex,
    projects: item.projects ?? []
  };
};

export default ClientFormDefaultValues;
