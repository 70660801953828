import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import EditBillingPeriodDto from 'types/PrpTable/dto/EditBillingPeriodDto';
import EditBillingPeriodFormDto from 'types/PrpTable/dto/EditBillingPeriodFormDto';
import mapToBillingDto from 'store/mappers/project/billingMapper';
import apiRequest from './apiRequest';
import BillingPeriodDto from '../types/PrpTable/dto/BillingPeriodDto';

const billingApiService: IApiService = {
  updateBillingPeriod: async (data: EditBillingPeriodFormDto) => {
    const updateBillingDto = mapToBillingDto(data);
    try {
      return await apiRequest<EditBillingPeriodDto>({
        method: 'PUT',
        endpoint: `${API_URL}/billing-periods/${data.id}`,
        requestBody: updateBillingDto
      });
    } catch (error) {
      logError('Error in updateBillingPeriod');
      throw new Error('Error in updateBillingPeriod');
    }
  },
  getBillingPeriod: async (id: number) => {
    try {
      return await apiRequest<BillingPeriodDto>({
        method: 'GET',
        endpoint: `${API_URL}/billing-periods/${id}`
      });
    } catch (error) {
      logError('Error in getBillingPeriod');
      throw new Error('Error in getBillingPeriod');
    }
  }
};
interface IApiService {
  updateBillingPeriod: (data: EditBillingPeriodFormDto) => Promise<EditBillingPeriodDto>;
  getBillingPeriod: (id: number) => Promise<BillingPeriodDto>;
}
export default billingApiService;
