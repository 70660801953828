import { AllocationsAddBookingDto } from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingDto';
import AllocationsAddBookingFormDto from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingFormDto';

const mapToBookingDto = (data: AllocationsAddBookingFormDto): AllocationsAddBookingDto => {
  return {
    id: data.id ?? null,
    hours: data.hours ?? null,
    minutes: data.minutes ?? null,
    projectId: data.projectId ?? null,
    employeeId: data.employeeId ?? null,
    startDate: data.startDate ?? '',
    endDate: data.endDate ?? '',
    details: data.details ?? '',
    confirmed: data.confirmed,
    weekId: data.weekId ?? null,
    isAutoDiscountApplied: data.isAutoDiscountApplied ?? null,
    autoDiscountDuration: data.isAutoDiscountApplied ? data.autoDiscountDuration ?? 0 : 0
  } as AllocationsAddBookingDto;
};
export default mapToBookingDto;
