import AllocationPublicHolidayDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayDto';
import AllocationPublicHolidayFormDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayFormDto';

const mapToAllocationPublicHolidayDto = (
  data: AllocationPublicHolidayFormDto
): AllocationPublicHolidayDto => {
  return {
    id: data.id ?? null,
    date: data.date ?? '',
    details: data.details ?? '',
    dbid: data.dbid ?? null
  } as AllocationPublicHolidayDto;
};

export default mapToAllocationPublicHolidayDto;
