import { createAsyncThunk } from '@reduxjs/toolkit';
import employeeApiService from 'api/employeeApiService';
import { AppDispatch, RootState } from 'store';
import { addKeyToEmployee } from 'store/mappers/employee/employeeMapper';
import { DispatchError } from 'store/slices/employee/employeeSlice';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import { EmployeeFormDto } from 'types/employee/dto/EmployeeFormDto';
import EmployeeAction from 'types/employee/enums/Employee';
import { ApiError, notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';

const updateEmployee = createAsyncThunk<
  EmployeeDto,
  EmployeeFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: ApiError | DispatchError;
  }
>(EmployeeAction.UPDATE_EMPLOYEE, async (updatedEmployee: EmployeeFormDto, { rejectWithValue }) => {
  try {
    const response = await employeeApiService.updateEmployee(updatedEmployee);
    if (!response) notifyUser();
    return addKeyToEmployee(response);
  } catch (err) {
    if (err instanceof ApiError) return rejectWithValue(err);

    throw err;
  }
});

export default updateEmployee;
