import { FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProjectFilterName from 'types/project/enums/ProjectFilterNames';
import StyledProjectTableMultiSelect from 'components/sharedComponents/SharedMultiSelect/StyledProjectTableMultiSelect';

type ProjectTableMultiSelectProps = {
  items: ProjectFilterName[];
  searchValue: string[];
  setSearchValue: React.Dispatch<React.SetStateAction<string[]>>;
};

const ProjectTableMultiSelect = ({
  items,
  searchValue,
  setSearchValue
}: ProjectTableMultiSelectProps) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (searchValue.length === 4) setSearchValue(items);
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof searchValue>) => {
    const {
      target: { value }
    } = event;
    setSearchValue(typeof value === 'string' ? value.split(',') : value);
  };

  const checkIfIsDisabled = (item: ProjectFilterName) => {
    return searchValue.length === 1 && item === searchValue[0];
  };

  return (
    <StyledProjectTableMultiSelect>
      <p className="inter-p3 project-details-label">Project Details</p>
      <FormControl sx={{ m: 1, width: 300 }} id="multiselect">
        <Select
          multiple
          displayEmpty
          open={open}
          onOpen={handleOpen}
          onClose={handleClose}
          value={searchValue}
          onChange={handleChange}
          input={<OutlinedInput label="" />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>Select Filter</em>;
            }
            return selected.join(', ');
          }}
          className="multi-select"
        >
          <MenuItem disabled value="" className="placeholder">
            <em>Select Filter</em>
          </MenuItem>
          {items.map((item) => (
            <MenuItem key={item} value={item} disabled={checkIfIsDisabled(item)}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StyledProjectTableMultiSelect>
  );
};

export default ProjectTableMultiSelect;
