export const YELLOW_BLACK = '#EBD9A6';
export const YELLOW_ONE_HUNDRED = '#F7E4AF';
export const YELLOW_SIXTY = '#F7EBCB';
export const YELLOW_THIRTY_FIVE = '#F8F1E3';
export const GREY_BLACK = '#C8CFDB';
export const GREY_ONE_HUNDRED = '#D3DAE7';
export const GREY_SIXTY = '#E0E5EF';
export const GREY_THIRTY_FIVE = '#EBEEF7';
export const SKYBLUE_BLACK = '#A6D6E5';
export const SKYBLUE_ONE_HUNDRED = '#AFE1F1';
export const SKYBLUE_SIXTY = '#C9E9F6';
export const SKYBLUE_THIRTY_FIVE = '#DEF0FA';
export const BLUE_BLACK = '#B1D3EE';
export const BLUE_ONE_HUNDRED = '#BADEFB';
export const BLUE_SIXTY = '#D0E7FC';
export const BLUE_THIRTY_FIVE = '#E2EFFE';
export const VIOLET_BLACK = '#D9C6F2';
export const VIOLET_ONE_HUNDRED = '#E4D0FF';
export const VIOLET_SIXTY = '#EBDEFF';
export const VIOLET_THIRTY_FIVE = '#F1EAFF';
export const ROSE_BLACK = '#EEC0E3';
export const ROSE_ONE_HUNDRED = '#FACAEF';
export const ROSE_SIXTY = '#F9DAF5';
export const ROSE_THIRTY_FIVE = '#F9E8F9';
export const GREY_TWO_BLACK = '#D1C8DB';
export const GREY_TWO_HUNDRED = '#DCD3E7';
export const GREY_TWO_SIXTY = '#E6E0EF';
export const GREY_TWO_FIVE = '#EEEBF7';
export const ORANGE_TWO_BLACK = '#EFC6AE';
export const ORANGE_TWO_HUNDRED = '#EFC6AE';
export const ORANGE_TWO_SIXTY = '#FBDED0';
export const ORANGE_TWO_FIVE = '#F9EAE6';
export const YELLOW_TWO_BLACK = '#EAEBA6';
export const YELLOW_TWO_HUNDRED = '#F6F7AF';
export const YELLOW_TWO_SIXTY = '#F6F7CB';
export const YELLOW_TWO_FIVE = '#F7F8E3';
export const GREEN_TWO_BLACK = '#C7DAAF';
export const GREEN_TWO_HUNDRED = '#D1E5B8';
export const GREEN_TWO_SIXTY = '#DFECD1';
export const GREEN_TWO_FIVE = '#EAF1E6';
export const SKYBLUE_TWO_BLACK = '#A6E5C7';
export const SKYBLUE_TWO_HUNDRED = '#AFF1D1';
export const SKYBLUE_TWO_SIXTY = '#C9F3E1';
export const SKYBLUE_TWO_FIVE = '#DEF6EF';
export const BLUE_TWO_BLACK = '#B1BEEE';
export const BLUE_TWO_HUNDRED = '#BAC8FB';
export const BLUE_TWO_SIXTY = '#D0D9FC';
export const BLUE_TWO_FIVE = '#E2E7FE';
export const VIOLET_TWO_BLACK = '#C9C6F2';
export const VIOLET_TWO_HUNDRED = '#D4D0FF';
export const VIOLET_TWO_SIXTY = '#E0DEFF';
export const VIOLET_TWO_FIVE = '#EBEAFF';
export const ROSE_TWO_BLACK = '#EEC0C8';
export const ROSE_TWO_HUNDRED = '#FACAD3';
export const ROSE_TWO_SIXTY = '#F9DAE2';
export const ROSE_TWO_FIVE = '#F9E8EF';
export const LIGHT_VIOLET = '#F8F8FF';
