import {
  borderRadiusCommon,
  projectTitleHeight,
  stickyUtilsContainerHeight
} from 'components/sharedComponents/StyleConsts/styleConsts';
import {
  ALERT_COLOR,
  BACKGROUND_COLOR,
  BORDERS_COLOR,
  CAUTION_BACKGROUND_COLOR,
  CAUTION_HOVER,
  DARK_GREY,
  GREEN_BACKGROUND_COLOR,
  GREEN_TEXT_COLOR,
  LOW_EMPHASISE_COLOR,
  ON_PRIMARY_COLOR,
  PRIMARY_COLOR,
  SURFACE_COLOR,
  TEXT_COLOR
} from 'constants/colors';
import { minCellWidth } from 'constants/styleConstants';
import styled from 'styled-components';

export const StyledHeader = styled.thead({
  borderBottom: '1px solid',
  borderColor: BORDERS_COLOR
});

const StyledProjectTable = styled.table({
  borderColor: BORDERS_COLOR,
  backgroundColor: 'rgb(247, 247, 250)',
  borderCollapse: 'collapse',
  minHeight: '440px',
  display: 'block',
  th: {
    padding: 0,
    margin: 0,
    backgroundColor: 'rgb(247, 247, 250)'
  },
  thead: { position: 'sticky', top: stickyUtilsContainerHeight + projectTitleHeight, zIndex: 10 },
  td: {
    padding: 0,
    margin: 0
  },
  '.empty': { borderRight: '1px solid', borderColor: BORDERS_COLOR },
  '.visually-hidden': {
    position: 'absolute',
    width: '1px',
    height: '1px',
    margin: '-1px',
    padding: '0',
    overflow: 'hidden',
    clipPath: 'polygon(0 0, 0 0, 0 0)',
    whiteSpace: 'nowrap',
    border: '0'
  },
  '.month': {
    borderLeft: '1px solid',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderColor: BORDERS_COLOR,
    padding: '15px'
  },
  '.table-header': {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'
  },

  '.table-right-header': {
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderTop: '1px solid',
    borderColor: BORDERS_COLOR,
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 16px 15px 15px',
    height: '153px'
  },
  '.table-header-buttons': {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    marginTop: '15px',
    p: {
      margin: 0
    }
  },
  '.show-employees-row': {
    width: '100%',
    display: 'flex',
    marginLeft: '-5px',
    '.MuiFormControlLabel-labelPlacementStart': {
      width: '100%',
      marginLeft: '0'
    },
    '.MuiFormControlLabel-label': {
      color: TEXT_COLOR,
      fontSize: '13px'
    }
  },
  '.selected-button': {
    border: 'none',
    backgroundColor: ON_PRIMARY_COLOR,
    boxShadow: '0px 1px 4px 0px rgba(198, 198, 198, 1)'
  },
  '.unselected-button': {
    border: 'solid 1px',
    borderColor: DARK_GREY,
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  '.table-header-button': {
    display: 'flex',
    borderRadius: '8px',
    padding: '8px',
    width: '98px',
    marginRight: '5px',
    cursor: 'pointer',
    p: {
      color: LOW_EMPHASISE_COLOR,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '1px'
    },
    img: {
      marginLeft: '-6px'
    },
    '.plus-icon': {
      marginTop: '-1px',
      width: '20px',
      height: '20px'
    }
  },
  '.switch-row': {
    display: 'flex',
    flexDirection: 'row',
    p: {
      margin: '0 10px 0 0',
      alignSelf: 'center',
      color: TEXT_COLOR
    }
  },
  '.table-header-left': {
    display: 'flex',
    flexDirection: 'row'
  },
  '.table-header-month': {
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid',
    borderTop: '1px solid',
    borderColor: BORDERS_COLOR
  },
  '.table-header-month-wrapper': {
    borderBottom: '1px solid',
    padding: '20px',
    borderRight: '1px solid',
    borderColor: BORDERS_COLOR,
    p: {
      margin: 0
    }
  },
  '.table-billing-row': {
    display: 'flex',
    flexDirection: 'row'
  },
  '.table-billing-wrapper': {
    paddingTop: '15px',
    borderRight: '1px solid',
    minWidth: '240px',
    borderColor: BORDERS_COLOR
  },
  '.billing-title-row': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: '18px',
    margin: '0 0 3px 0',
    p: {
      margin: 0
    }
  },
  '.billing-title': {
    color: TEXT_COLOR,
    margin: 0,
    whiteSpace: 'nowrap',
    paddingLeft: '20px'
  },
  '.billing-weeks': {
    color: TEXT_COLOR,
    margin: '0 0 0 20px',
    whiteSpace: 'nowrap',
    paddingLeft: '20px',
    paddingRight: '20px',
    width: '130px',
    textAlign: 'end'
  },
  '.billing-forecast': {
    color: LOW_EMPHASISE_COLOR,
    span: {
      textTransform: 'capitalize'
    },
    paddingRight: '20px',
    whiteSpace: 'nowrap'
  },
  '.billing-title-date': {
    whiteSpace: 'nowrap',
    paddingLeft: '20px',
    display: 'flex'
  },
  '.billing-sprint': {
    padding: '5px 5px 5px 10px',
    margin: '8px 10px 0 10px',
    borderRadius: borderRadiusCommon,
    backgroundColor: SURFACE_COLOR,
    color: TEXT_COLOR,
    textAlign: 'start'
  },
  '.billing-date-row': {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '5px',
    justifyContent: 'space-around'
  },
  '.table-content-employee-row, .preinvoice-table': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  '.preinvoice-table': {
    backgroundColor: SURFACE_COLOR,
    borderRadius: '20px',
    margin: '7px 0'
  },
  '.week-totals': {
    backgroundColor: SURFACE_COLOR,
    borderRadius: '20px',
    margin: '7px 0',
    display: 'flex',
    flexDirection: 'column'
  },
  '.main-totals-wrapper': {
    display: 'flex',
    flexDirection: 'row'
  },
  '.subtotals-wrapper': {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: '8px'
  },

  '.week-totals-border': {
    borderRight: '1px solid',
    borderColor: BORDERS_COLOR,
    width: '100%'
  },
  '.table-content-wrapper': {
    display: 'flex',
    flexDirection: 'row'
  },
  '.table-right-content': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderColor: BORDERS_COLOR,
    padding: '15px 20px 0 14px',
    width: '317px',
    '.preinvoice-content': {
      width: '55%'
    },
    '.preinvoice-text': {
      color: LOW_EMPHASISE_COLOR
    },
    '.preinvoice-title': {
      color: TEXT_COLOR,
      marginBottom: '3px'
    }
  },
  '.table-right-content-with-background, .table-right-content-with-background-and-filters': {
    width: '317px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRight: '1px solid',
    borderColor: BORDERS_COLOR,
    padding: '15px 20px 0 14px',
    '.preinvoice-content': {
      width: '55%'
    },
    '.preinvoice-text': {
      color: LOW_EMPHASISE_COLOR
    },
    '.preinvoice-title': {
      color: TEXT_COLOR,
      marginBottom: '3px'
    }
  },
  '.table-right-content-with-background-and-filters': {
    padding: '15px 20px 10px 14px'
  },
  '.spacer-left': {
    backgroundColor: SURFACE_COLOR,
    width: '12px'
  },
  '.subtotals-padding': {
    width: 'calc(317px - 12px)',
    borderRadius: '8px 0px 0px 8px',
    backgroundColor: BACKGROUND_COLOR
  },
  '.subtotals-cell': {
    backgroundColor: BACKGROUND_COLOR
  },
  '.table-content-container': {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
  },
  '.table-content-name': {
    display: 'flex',
    flexDirection: 'row',

    '.inter-caption_medium': {
      marginTop: '3px'
    },
    '.frame-img': {
      marginRight: '8px'
    }
  },
  '.developers-checkbox': {
    width: '20px',
    height: '20px',
    marginRight: '8px'
  },
  '.developers-title': {
    color: TEXT_COLOR
  },
  '.table-content-cost': {
    display: 'flex',
    flexDirection: 'column',
    p: {
      margin: 0
    },
    '.rates-button': {
      padding: '0 10px',
      border: 'none',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: BORDERS_COLOR,
        borderRadius: '5px'
      }
    }
  },
  '.table-content-types': {
    display: 'flex',
    flexDirection: 'column',
    '.inter-caption_medium': {
      marginBottom: '39px',
      color: LOW_EMPHASISE_COLOR,
      marginTop: 0
    },
    textAlign: 'right'
  },
  '.without-financial-info': {
    '.inter-caption_medium': {
      marginBottom: '30px'
    }
  },

  '.totals-without-financial-info': {
    '.inter-caption_medium': {
      marginBottom: '32px'
    }
  },

  '.table-content-left': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '70%'
  },
  '.table-content-type-details': {
    display: 'flex',
    flexDirection: 'column',
    minWidth: minCellWidth,
    width: minCellWidth,
    '.details': {
      color: LOW_EMPHASISE_COLOR,
      whiteSpace: 'nowrap',
      cursor: 'pointer',
      backgroundColor: 'transparent',
      border: 'none',
      position: 'absolute',
      right: '12px'
    },
    '.generate-disabled': {
      backgroundColor: BORDERS_COLOR,
      pointerEvents: 'none'
    },
    '.forecast-hours, .forecast-hours-for-one-week': {
      color: TEXT_COLOR,
      margin: '16px 0 0 20px',
      whiteSpace: 'nowrap',
      paddingLeft: '20px',
      textAlign: 'end',
      paddingRight: '19px'
    },
    '.forecast-hours-for-one-week': {
      paddingRight: '0',
      marginRight: '-32px'
    },
    '.invoice-total': {
      marginTop: '-10px',
      fontWeight: 'bold',
      whiteSpace: 'nowrap',
      textAlign: 'end',
      paddingRight: '18px'
    },
    '.invoice-total-with-note': {
      fontWeight: 'bold',
      margin: '-10px auto 0 20px',
      whiteSpace: 'nowrap',
      textAlign: 'end',
      paddingRight: '18px'
    }
  },
  '.details-container, .details-container-for-one-week': {
    marginRight: '0',
    marginLeft: 'auto',
    width: '150px',
    position: 'relative'
  },
  '.details-container-for-one-week': {
    marginRight: '-42px'
  },
  '.table-content-type-details-discounts': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    '.discount-cost': {
      color: LOW_EMPHASISE_COLOR
    }
  },
  '.table-right-content-row': {
    display: 'flex',
    flexDirection: 'column',
    ':last-child': {
      borderBottom: 'none'
    }
  },
  '.employee-name': {
    marginRight: '10px',
    color: TEXT_COLOR,
    width: '120px'
  },
  '.employee-position': {
    margin: '3px 0 0 0',
    color: LOW_EMPHASISE_COLOR
  },
  '.employee-img': {
    p: {
      margin: 0,
      alignSelf: 'center',
      color: TEXT_COLOR
    },
    marginRight: '10px'
  },
  '.employee-current-rate': {
    width: 'auto',
    color: TEXT_COLOR,
    textAlign: 'end'
  },
  '.employee-default-rate': {
    width: '50px',
    textAlign: 'end',
    color: LOW_EMPHASISE_COLOR,
    textDecoration: 'line-through'
  },
  '.table-content-details-row': {
    width: '70%',
    padding: '15px',
    borderBottom: '1px solid',
    borderRight: '1px solid',
    borderColor: BORDERS_COLOR
  },
  '.table-content-actuals': {
    '.cost': {
      marginRight: '0'
    }
  },
  '.table-content-actuals, .table-content-preinvoice': {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  '.table-content-preinvoice': {
    '.actuals-border': {
      borderBottom: 'none'
    }
  },

  '.employee-name-row': {
    display: 'flex',
    flexDirection: 'column',
    textOverflow: 'truncate'
  },
  '.sprint-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    minWidth: '240px'
  },
  '.sprint-wrapper-weeks-total': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    minWidth: '240px',
    flex: 1
  },
  '.actuals-border': {
    borderRight: '1px solid',
    borderBottom: '1px solid',
    borderColor: BORDERS_COLOR
  },
  '.totals-border': {
    borderLeft: '1px solid',
    borderColor: BORDERS_COLOR
  },
  '.sprint-row': {
    display: 'flex',
    flexDirection: 'column'
  },
  '.sprint-row-single': {
    maxWidth: '100%',
    minWidth: '25%',
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%'
  },
  '.sprint-row-actuals': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    flex: 1
  },
  '.sprint-row-preinvoices': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end'
  },
  '.sprint-weeks-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    p: {
      paddingRight: '19px'
    }
  },
  '.button-with-icon': {
    padding: '0',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer'
  },
  '.locker-icon': {
    marginTop: '-3px'
  },
  '.forecast-button-container': {
    display: 'flex',
    button: {
      marginTop: '-3px'
    }
  },
  '.forecast-button': {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    ':hover': {
      textDecoration: 'underline'
    }
  },
  '.forecast-button-disabled': {
    backgroundColor: 'transparent',
    border: 'none',
    color: LOW_EMPHASISE_COLOR
  },
  '.freeze-button-container': {
    display: 'flex',
    button: {
      marginTop: '-10px'
    }
  },

  '.sprint-row-cell-outer': {
    display: 'flex',
    justifyContent: 'end',
    height: '56px'
  },

  '.duration': {
    color: TEXT_COLOR,
    fontWeight: 500,
    fontSize: '14px'
  },

  '.cost, .cost-for-one-week': {
    color: LOW_EMPHASISE_COLOR,
    fontWeight: 400,
    fontSize: '12px',
    marginRight: '20px',
    marginLeft: 'auto'
  },

  '.cost-for-one-week': {
    marginRight: '-32px'
  },

  '.sprint-row-cell-inner': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',

    width: '151px',

    marginRight: '11px',

    p: {
      textAlign: 'end',
      paddingRight: '8px'
    }
  },

  '.small-cell': {
    height: '50px'
  },

  '.small-button': {
    height: '42px'
  },

  '.difference-orange': {
    p: {
      color: CAUTION_HOVER
    }
  },

  '.difference-red': {
    p: {
      color: ALERT_COLOR
    }
  },

  '.difference-red-background': {
    p: {
      color: ALERT_COLOR
    },
    '.sprint-row-cell-inner': {
      borderRadius: '5px',
      backgroundColor: '#FF466720'
    }
  },

  '.difference-green': {
    p: {
      color: GREEN_TEXT_COLOR
    }
  },

  '.difference-green-background': {
    p: {
      color: GREEN_TEXT_COLOR
    },
    '.sprint-row-cell-inner': {
      borderRadius: '5px',
      backgroundColor: GREEN_BACKGROUND_COLOR
    }
  },

  '.generate-invoice-btn, .generate-invoice-btn-for-one-week': {
    backgroundColor: PRIMARY_COLOR,
    color: SURFACE_COLOR,
    width: '150px',
    marginRight: '18px',
    marginLeft: 'auto',
    marginTop: '20px',
    height: '35px',
    borderRadius: borderRadiusCommon,
    border: 'none',
    cursor: 'pointer'
  },
  '.generate-invoice-btn-for-one-week': {
    marginRight: '-32px'
  },
  '.invoice-status-details, .invoice-status-details-for-one-week': {
    display: 'flex',
    gap: '2px',
    alignItems: 'center',
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  '.invoice-status-details-for-one-week': {
    marginLeft: '42px'
  },
  '.invoice-status': {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '35px',
    paddingLeft: '20px',
    div: {
      minWidth: '8px',
      marginRight: '10px',
      marginLeft: '5px'
    }
  },
  '.invoice-status-small': {
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '-30px',
    div: {
      minWidth: '8px',
      marginRight: '10px',
      marginLeft: '5px'
    }
  },
  '.greenCircle': {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#00B341'
  },
  '.redCircle': {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#FF4667'
  },
  '.orangeCircle': {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#FFA540'
  },
  '.greyCircle': {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#DADAEA'
  },
  '.blackCircle': {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: 'black'
  },
  '.optional-button-icon': {
    display: 'flex'
  },
  '.auto-discount-type-icon, .pre-discount-type-icon': {
    backgroundColor: BORDERS_COLOR,
    fontSize: '12px',
    padding: '4px 6px',
    borderRadius: '5px',
    marginRight: '20px'
  },
  '.pre-discount-type-icon': {
    backgroundColor: CAUTION_BACKGROUND_COLOR,
    color: CAUTION_HOVER
  },
  '.table-header-filters': {
    display: 'flex',
    flexDirection: 'row',
    width: '320px'
  },
  '.project-details-label': {
    width: '200px'
  }
});

export default StyledProjectTable;
