import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { FormProvider, Resolver, useForm, Controller, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import ProjectFormComponentNames from 'types/project/enums/ProjectFormComponentNames';
import AllocationTimeOffDeleteDefaultValue from 'components/allocations/AllocationTimeOff/AllocationTimeOffDeleteForm/AllocationTimeOffDeleteDefaultValue';
import TimeOffFormDeleteValidation from 'components/allocations/AllocationTimeOff/AllocationTimeOffDeleteForm/AllocationTimeOffDeleteValidation';
import AllocationDeleteTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/allocationDeleteTimeOffDto/AllocationDeleteTimeOffFormDto';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import { RootState } from 'store';
import deleteAllocationTimeOff from 'store/action/allocationsAction/deleteAllocationTimeOff';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import ButtonsText from 'types/generalEnums/ButtonsText';
import AllocationDeleteFormNames from 'types/allocation/enums/AllocationDeleteFormComponentsName';
import useEscapeKey from 'store/customHooks/useEscapeKey';

type AllocationTimeOffDeleteFormProps = {
  onClose: (isSubmitted: boolean) => void;
};

const AllocationTimeOffDeleteForm = ({ onClose }: AllocationTimeOffDeleteFormProps) => {
  const timeOffState = useAppSelector((state: RootState) => state.allocationTimeOff.data);
  const dispatch = useThunkAppDispatch();

  const methods = useForm({
    defaultValues: AllocationTimeOffDeleteDefaultValue(timeOffState.id),
    // TODO fix unknown
    resolver: yupResolver(
      TimeOffFormDeleteValidation()
    ) as unknown as Resolver<AllocationDeleteTimeOffFormDto>
  });
  const { handleSubmit, control } = methods;
  useEscapeKey(() => onClose(false));
  const onSubmit: SubmitHandler<AllocationDeleteTimeOffFormDto> = async (
    data: AllocationDeleteTimeOffFormDto
  ) => {
    try {
      await dispatch(deleteAllocationTimeOff(data)).unwrap();
      onClose(true);
    } catch (error) {
      logError('Error deleting time off');
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} columns={20}>
          <Grid item xs={16} md={16}>
            <p className="inter-p3">{AllocationDeleteFormNames.DeleteTimeOffTitle}</p>
          </Grid>
          <Grid item xs={20} md={20}>
            <Controller
              name={ProjectFormComponentNames.DeleteTimeOffTypeName}
              control={control}
              render={({ field }) => (
                <FormControl>
                  <RadioGroup
                    name={ProjectFormComponentNames.DeleteTimeOffTypeName}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={AllocationDeleteFormNames.DeleteTimeOffFirstLabel}
                      value={false}
                      className="delete-radio-button delete-radio-button-first"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label={AllocationDeleteFormNames.DeleteTimeOffSecondLabel}
                      value
                      className="delete-radio-button "
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <div className="add-buttons-row">
          <button type="submit" className="add-button" onSubmit={handleSubmit(onSubmit)}>
            <p className="inter-caption"> {ButtonsText.Delete} </p>
          </button>
          <button type="button" onClick={() => onClose(false)} className="cancel-button">
            <p className="inter-caption">{ButtonsText.Cancel} </p>
          </button>
        </div>
      </StyledForm>
    </FormProvider>
  );
};

export default AllocationTimeOffDeleteForm;
