import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import { EmployeeFormDto } from 'types/employee/dto/EmployeeFormDto';
import EmployeeStatusFilter from 'types/employee/enums/EmployeeStatusFilter';

export const addKeyToEmployee = (employee: EmployeeDto): EmployeeDto => {
  return addKeyToReactItem(employee);
};

export const selectFilteredEmployees = (
  filter: EmployeeStatusFilter,
  listOfEmployees: EmployeeDto[]
): EmployeeDto[] => {
  switch (filter) {
    case EmployeeStatusFilter.Active:
      return listOfEmployees.filter((employee) => employee.active);
    case EmployeeStatusFilter.InActive:
      return listOfEmployees.filter((employee) => !employee.active);
    default:
      return listOfEmployees;
  }
};

export const mapToEmployeeDto = (data: EmployeeFormDto): EmployeeDto => {
  return {
    id: data.id ?? null,
    name: data.name.trim() ?? '',
    positionId: data.positionId ?? null,
    seniorityId: data.seniorityId ?? null,
    email: data.email ?? '',
    weeklyCapacity: data.weeklyCapacity ?? null,
    active: data.active,
    hasSystemAccess: data.hasSystemAccess,
    permissionIds: data.permissionIds ?? [],
    force: data.force ?? false
  } as EmployeeDto;
};
