import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  startDate: '',
  endDate: ''
};

export type DispatchError = {
  errorMessage: string;
};

const pageDateSlice = createSlice({
  name: 'pageDateSlice ',
  initialState,
  reducers: {
    getStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    getEndDate: (state, action) => {
      state.endDate = action.payload;
    }
  }
});

export const { getStartDate, getEndDate } = pageDateSlice.actions;
export default pageDateSlice.reducer;
