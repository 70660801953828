import { Grid } from '@mui/material';
import FormInput from 'components/sharedComponents/FormComponents/FormInput/FormInput';
import FormInputNumber from 'components/sharedComponents/FormComponents/FormInput/FormInputNumber';
import { maxCapacity, minCapacity } from 'constants/validationConstants';
import FormSelectPermissions from 'components/employee/EmployeeFormItems/EmployeeFormSelectPermissions/FormSelectPermissions';
import FormSwitch from 'components/sharedComponents/FormComponents/FormSwitch/FormSwitch';
import StyledEmployeeChangeForm from 'components/employee/EmployeeChangeForm/StyledEmployeeChangeForm';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import { EmployeeFormDto } from 'types/employee/dto/EmployeeFormDto';
import { yupResolver } from '@hookform/resolvers/yup';
import updateEmployee from 'store/action/employeeActions/updateEmployee';
import InputType from 'types/generalEnums/InputType';
import EmployeeFormComponentNames from 'types/employee/enums/EmployeeFormComponentNames';
import EmployeeFormDefaultValues from 'components/employee/EmployeeChangeForm/EmployeeFormValue';
import FormSelectPosition from 'components/employee/EmployeeFormItems/EmployeeFormSelectController/FormSelectPosition';
import FormSelectSeniority from 'components/employee/EmployeeFormItems/EmployeeFormSelectController/FormSelectSeniority';
import makeEmployeeInactiveModalConfig from 'components/employee/const/employeeConst';
import { RootState } from 'store';
import EmployeeFormsValidation from 'components/employee/EmployeeChangeForm/EmployeeFormsValidation';
import FormChangeHeader from 'components/sharedComponents/FormComponents/FormChangeHeader/FormChangeHeader';
import EmployeeAddFormDefaultValue from 'components/employee/EmployeeAddForm/EmployeeAddFormDefaultValue';
import { ApiError, logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import PageType from 'types/generalEnums/PageType';
import FormTypeName from 'types/generalEnums/FormTypeName';
import { memoize } from 'proxy-memoize';
import { useBeforeUnload } from 'react-router-dom';
import ModalWindow from 'modals/ModalWindow';
import { useCallback, useState } from 'react';
import ConfirmationModal from 'components/sharedComponents/ConfirmationWindow/ConfirmationModal';
import ErrorConfirmModal from 'components/sharedComponents/ErrorConfirmModal/ErrorConfirmModal';
import { ErrorType } from 'types/ApiError/ErrorEnums';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import { employeeConfirmationModalHeight } from 'components/sharedComponents/StyleConsts/styleConsts';

type EmployeeChangeFormProps = {
  item: EmployeeDto;
  onCancel: () => void;
};

const EmployeeChangeForm = ({ item, onCancel }: EmployeeChangeFormProps): JSX.Element => {
  const employee = useAppSelector(
    memoize((state: RootState) => state.employees.data.find((e: EmployeeDto) => e.id === item.id))
  );
  const methods = useForm<EmployeeFormDto>({
    defaultValues: employee ? EmployeeFormDefaultValues(employee) : EmployeeAddFormDefaultValue,
    // TODO fix unknown
    resolver: yupResolver(
      EmployeeFormsValidation(FormTypeName.EditForm, minCapacity, maxCapacity)
    ) as unknown as Resolver<EmployeeFormDto>
  });
  const {
    control,
    watch,
    handleSubmit,
    register,
    getValues,
    formState: { errors, isDirty }
  } = methods;
  const dispatch = useThunkAppDispatch();
  const dictionaries = useAppSelector((state: RootState) => state.dictionaries.data);
  const isActive = employee?.active ?? true;
  const hasSystemAccess = employee?.hasSystemAccess ?? true;
  const [isAllocationErrorModalOpen, setIsAllocationErrorModalOpen] = useState(false);
  const [allocationError, setAllocationError] = useState<ApiError | null>(null);
  useEscapeKey(onCancel);
  const onSubmit: SubmitHandler<EmployeeFormDto> = async (data: EmployeeFormDto) => {
    try {
      await dispatch(updateEmployee({ ...data, force: isAllocationErrorModalOpen })).unwrap();
      onCancel();
    } catch (error) {
      if (error instanceof ApiError && error.type === ErrorType.Confirmation) {
        setIsAllocationErrorModalOpen(true);
        setAllocationError(error);
      }

      logError('Error editing employee');
    }
  };

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleConfirmLeave = (event: BeforeUnloadEvent) => {
    if (isDirty) {
      event.preventDefault();
      event.returnValue = '';
    }
  };

  useBeforeUnload(handleConfirmLeave);

  const handleLeaveForm = () => {
    if (isDirty) {
      setShowConfirmationModal(false);
      onCancel();
    }
  };

  const handleStayInForm = () => {
    setShowConfirmationModal(false);
  };

  const handleCancel = () => {
    if (isDirty) {
      setShowConfirmationModal(true);
    } else {
      onCancel();
    }
  };

  const closeModal = () => {
    setIsAllocationErrorModalOpen(false);
  };
  const handleErrorSubmit = useCallback(async () => {
    const formData = watch();
    await onSubmit(formData);
    closeModal();
  }, [watch, onSubmit, closeModal]);

  return (
    <FormProvider {...methods}>
      <StyledEmployeeChangeForm onSubmit={handleSubmit(onSubmit)}>
        {showConfirmationModal ? (
          <ModalWindow
            onClose={() => setShowConfirmationModal(false)}
            style={{ minHeight: employeeConfirmationModalHeight }}
          >
            <ConfirmationModal
              handleLeaveForm={handleLeaveForm}
              handleStayInForm={handleStayInForm}
            />
          </ModalWindow>
        ) : null}
        <FormChangeHeader
          onCancel={handleCancel}
          onSave={handleSubmit(onSubmit)}
          register={register}
          errors={errors.name?.message}
          type={PageType.Employees}
        />
        <Grid container spacing={2}>
          <Grid item xs={6} md={4}>
            <FormSelectPosition
              title={EmployeeFormComponentNames.Position}
              label={EmployeeFormComponentNames.PositionLabel}
              control={control}
              dictionaries={dictionaries}
              className="position-select"
            />
          </Grid>
          <Grid item xs={6} md={3.5}>
            <FormSelectSeniority
              title={EmployeeFormComponentNames.Seniority}
              label={EmployeeFormComponentNames.SeniorityLabel}
              control={control}
              dictionaries={dictionaries}
              className="seniority-select"
            />
          </Grid>
          <Grid item xs={6} md={4.5}>
            <FormInput
              label={EmployeeFormComponentNames.EmailLabel}
              name={EmployeeFormComponentNames.Email}
              type={InputType.Text}
              required
              register={register}
              error={errors.email?.message}
              className="input-form"
            />
          </Grid>
          <Grid item xs={6} md={4}>
            <FormInputNumber
              label={EmployeeFormComponentNames.CapacityLabel}
              name={EmployeeFormComponentNames.Capacity}
              required
              register={register}
              getValues={getValues}
              error={errors.weeklyCapacity?.message}
              className="input-capacity"
              minValue={minCapacity}
              maxValue={maxCapacity}
            />
          </Grid>
          <Grid item xs={6} md={3.5}>
            <FormSwitch
              labelOn={EmployeeFormComponentNames.ActivityLabel}
              labelOff={EmployeeFormComponentNames.InactivityLabel}
              name={EmployeeFormComponentNames.Activity}
              title={EmployeeFormComponentNames.ActivityTitle}
              modalConfig={makeEmployeeInactiveModalConfig}
              control={control}
              initialValue={isActive}
            />
          </Grid>
          <Grid item xs={6} md={4.5}>
            <FormSwitch
              labelOn={EmployeeFormComponentNames.AccessLabel}
              labelOff={EmployeeFormComponentNames.NoAccessLabel}
              name={EmployeeFormComponentNames.Access}
              title={EmployeeFormComponentNames.AccessTitle}
              control={control}
              initialValue={hasSystemAccess}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormSelectPermissions
              control={control}
              dictionaries={dictionaries}
              name={EmployeeFormComponentNames.Permissions}
              error={errors.permissionIds?.message}
              className="permission-select"
            />
          </Grid>
        </Grid>
      </StyledEmployeeChangeForm>
      {isAllocationErrorModalOpen && allocationError && (
        <ErrorConfirmModal
          onClose={closeModal}
          onSubmit={handleErrorSubmit}
          header={allocationError.header}
          body={allocationError.message}
        />
      )}
    </FormProvider>
  );
};

export default EmployeeChangeForm;
