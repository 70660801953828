import StyledProjectTable from 'components/project/ProjectTable/StyledProjectTable';
import { unwrapResult } from '@reduxjs/toolkit';
import formatDate from 'helpers/formatDate';
import { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { logError, notifyUserWith } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import getProjectTable from 'store/action/projectActions/getProjectTable';
import getProjectTotals from 'store/action/projectActions/getProjectTotals';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import {
  BillingPeridWeeksDto,
  EmployeeInfoDto,
  EmployeesActualDto,
  weekTotalsDevelopersOnly,
  weekTotalsDto
} from 'types/PrpTable/dto/ProjectTableDto';
import projectTableRows from 'types/PrpTable/enums/projectTableRows';
import AutocompleteEmployeesSearch from 'components/sharedComponents/SearchComponents/AutocompleteEmployeesSearch';
import { memoize } from 'proxy-memoize';
import InvoiceTable from 'components/project/ProjectTable/PreInvoice/InvoiceTable';
import ProjectRateTooltip from 'components/project/ProjectTable/ProjectRateTooltip/ProjectRateTooltip';
import { DiscountDto } from 'types/project/dto/UpdateDiscountFormDto';
import ModalWindow from 'modals/ModalWindow';
import UpdateForecastForm from 'components/project/ProjectDetails/UpdateForecast/UpdateForecastForm';
import {
  allocationPublicHolidayModalWidth,
  editRateModalWindowHeight,
  editRateModalWindowWidth,
  topPanelModalWindowWidth,
  updateForecastModalWindowHeight,
  updateForecastModalWindowWidth,
  updateSprintModalHeight,
  uploadActualsModalHeight
} from 'components/sharedComponents/StyleConsts/styleConsts';
import UpdateForecastFormNames from 'types/project/enums/UpdateForecastFormNames';
import { BpWeeksDto } from 'types/PrpTable/dto/ProjectTableParamsDto';
import MatchingSprintDto from 'types/project/dto/MatchingSprintDto';
import TotalsTableBorderType from 'types/PrpTable/enums/TotalsTableBorderType';
import getInvoice from 'store/action/projectActions/getInvoice';
import GenericErrorMessage from 'types/generalEnums/GenericErrorMessage';
import ProjectFilterName from 'types/project/enums/ProjectFilterNames';
import PrpTableFilters from 'components/project/ProjectTable/PrpTableFilters/PrpTableFilters';
import BillingPeriodColumnHeader from 'components/project/ProjectTable/BillingPeriodColumnHeader/BillingPeriodColumnHeader';
import ProjectModalWindowTitle from 'types/project/enums/ProjectModalWindowTitle';
import EditBillingPeriodForm from 'components/project/ProjectDetails/EditBillingPeriodForm/EditBillingPeriodForm';
import EditSprintLengthForm from 'components/project/ProjectTable/EditSprintLengthForm/EditSprintLengthForm';
import UpdateDiscountForm from 'components/project/ProjectDetails/UpdateDiscountForm/UpdateDiscountForm';
import EditEmployeeRateFormNames from 'types/project/enums/EditEmployeeRateFormNames';
import EditEmployeeRateForm from 'components/project/ProjectDetails/EditEmployeeRate/EditEmployeeRateForm';
import defaultUpdateDiscountItem from 'components/project/const/defaultUpdateDiscountItem';
import EmployeeNameAndInitials from 'components/project/ProjectTable/EmployeeNameAndInitials/EmployeeNameAndInitials';
import DataCategories from 'components/project/PrpTableDataCategories/DataCategories';
import ProjectTableContent from 'components/project/ProjectTable/ProjectTableContent/ProjectTableContent';
import BillingType from 'types/project/enums/BillingType';
import TotalsTable, {
  transformToSubTotalsDevelopersData,
  transformToTotalsData
} from './TotalsTable/TotalsTable';
import MainSpinner from '../../sharedComponents/Spinner/MainSpinner';

type ProjectTableProps = {
  projectId: number;
  month: number;
  year: number;
};

const ProjectTable = ({ projectId, month, year }: ProjectTableProps) => {
  const dispatch = useThunkAppDispatch();
  const [searchedEmployee, setSearchedEmployee] = useState<EmployeeInfoDto | null>(null);
  const [areEmployeesVisible, setAreEmployeesVisible] = useState(true);
  const [isFinancialInfoVisible, setIsFinancialInfoVisible] = useState(true);
  const forecastBillingType = BillingType.FORECAST;
  const nonBillableBillingType = BillingType.NON_BILLABLE;
  const [isUpdateForecastModalOpen, setIsUpdateForecastModalOpen] = useState(false);
  const fetchedEmployeeRates = useAppSelector(
    memoize((state: RootState) => state.employeeRatesSlice.data)
  );
  const [isEditBillingPeriodPopUpOpen, setIsEditBillingPeriodPopUpOpen] = useState(false);
  const [editBillingPeriodDate, setEditBillingPeriodDate] = useState('');
  const [editBillingPeriodId, setEditBillingPeriodId] = useState(0);
  const [isEditSprintLengthPopUpOpen, setIsEditSprintLengthPopUpOpen] = useState(false);
  const [editSprintLengthDate, setEditSprintLengthDate] = useState('');
  const [editSprintLengthId, setEditSprintLengthId] = useState(0);
  const [isUpdateDiscountPopUpOpen, setIsUpdateDiscountPopUpOpen] = useState(false);
  const [updateDiscount, setUpdateDiscount] = useState<DiscountDto>(defaultUpdateDiscountItem);
  const [isEditRateModalOpen, setIsEditRateModalOpen] = useState(false);
  const [selectedEmployeeId, setSelectedEmployeeId] = useState(0);
  const [searchValue, setSearchValue] = useState<string[]>([]);

  const refreshProjectData = async () => {
    const currentParams = {
      projectId,
      month,
      year
    };
    dispatch(getProjectTable(currentParams))
      .then(unwrapResult)
      .catch(() => {
        logError('Error fetching project table');
      });

    dispatch(getProjectTotals(currentParams))
      .then(unwrapResult)
      .catch(() => {
        logError('Error fetching project totals');
      });
  };

  useEffect(() => {
    refreshProjectData();
  }, [month, fetchedEmployeeRates]);

  const projectTableState = useAppSelector((state: RootState) => state.projectTable.data);
  const selectedProject = useAppSelector((state: RootState) => state.project.selectedProject);
  const projectTotalsState = useAppSelector((state: RootState) => state.projectTotals.data);

  const projectTableLoading = useAppSelector((state: RootState) => state.projectTable.loading);
  const selectedProjectLoading = useAppSelector((state: RootState) => state.project.readLoading);
  const projectTotalsLoading = useAppSelector((state: RootState) => state.projectTotals.loading);
  const loading = projectTableLoading || projectTotalsLoading || selectedProjectLoading;

  useEffect(() => {
    const currentParams = {
      projectId,
      month,
      year
    };
    dispatch(getProjectTable(currentParams))
      .then()
      .catch(() => {
        logError('Error fetching project table');
      });
    if (projectTableState.month !== 0) {
      dispatch(getInvoice(projectTableState))
        .then(unwrapResult)
        .catch(() => logError('Error fetching projects'));
    }
  }, []);

  const refreshInvoice = () => {
    dispatch(getInvoice(projectTableState))
      .then(unwrapResult)
      .catch(() => logError('Error fetching invoice'));
  };

  const onConfirmUpdatingForecast = async () => {
    await refreshProjectData();
    await refreshInvoice();
  };

  const [matchingSprints, setMatchingSprints] = useState<
    { sprintNumber: number; weeks: BillingPeridWeeksDto[]; sprintId: number; note: string }[]
  >([]);
  const [matchingTotals, setMatchingTotals] = useState<weekTotalsDto[]>(
    projectTotalsState.weekTotals
  );
  const [developersWeekTotals, setDevelopersWeekTotals] = useState<weekTotalsDevelopersOnly[]>([]);
  const monthName = new Date(year, month - 1, 1).toLocaleString('en-US', {
    month: 'long',
    year: 'numeric'
  });

  const onSearchChange = (event: SyntheticEvent<Element, Event>, value: EmployeeInfoDto | null) => {
    if (event) setSearchedEmployee(value);
  };

  const onEmployeesSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setAreEmployeesVisible(event.target.checked);
  };

  const onFinancialSwitchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsFinancialInfoVisible(event.target.checked);
  };

  const employeesToDisplay = searchedEmployee
    ? [searchedEmployee]
    : projectTableState.employeesInfo;

  const handleEditBillingPeriodPopUp = (date: string, id: number, isForecastFrozen: boolean) => {
    if (isForecastFrozen) {
      notifyUserWith(GenericErrorMessage.FailedUpdatingBillingPeriodLength);
      setIsEditBillingPeriodPopUpOpen(false);
    } else {
      setIsEditBillingPeriodPopUpOpen(true);
    }
    setEditBillingPeriodDate(date);
    setEditBillingPeriodId(id);
    refreshProjectData();
  };
  const handleEditSprintLengthPopUp = (date: string, sprintLength: number) => {
    setIsEditSprintLengthPopUpOpen(true);
    setEditSprintLengthDate(date);
    setEditSprintLengthId(sprintLength);
    refreshProjectData();
  };

  const handleEditRatePopUp = (employeeId: number) => {
    setSelectedEmployeeId(employeeId);
    setIsEditRateModalOpen(true);
  };

  const closeModal = () => {
    setIsEditBillingPeriodPopUpOpen(false);
    setIsEditSprintLengthPopUpOpen(false);
    setIsUpdateDiscountPopUpOpen(false);
  };

  useEffect(() => {
    const weeks: number[] = [];
    const updatedMatchingSprints: MatchingSprintDto[] = projectTableState.sprints
      .map((sprint) => {
        const matchingWeeks = projectTableState.billingPeriods
          .flatMap((billingPeriod) => billingPeriod.billingPeriodWeeks)
          .filter(
            (week) =>
              sprint.sprintStartWeekId <= week.weekId && sprint.sprintEndWeekId >= week.weekId
          );
        matchingWeeks.map((matchingWeek) => {
          return weeks.push(matchingWeek.weekId);
        });

        return matchingWeeks.length > 0
          ? {
              sprintNumber: sprint.sprintNumber,
              weeks: matchingWeeks,
              sprintId: sprint.sprintId,
              note: sprint.note
            }
          : { sprintNumber: 0, weeks: [], sprintId: 0, note: '' };
      })
      .filter((matchingSprint) => matchingSprint !== null);
    const updatedTotals = projectTotalsState.weekTotals.filter((week) =>
      weeks.includes(week.weekId)
    );
    const updatedDevelopersTotals = projectTotalsState.weekTotalsDevelopersOnly.filter((week) =>
      weeks.includes(week.weekId)
    );
    setMatchingSprints(updatedMatchingSprints);
    setMatchingTotals(updatedTotals);
    setDevelopersWeekTotals(updatedDevelopersTotals);
  }, [projectTableState, projectTotalsState]);

  const displayPreInvoice = selectedProject && selectedProject.billingType === forecastBillingType;
  const displayInvoiceSection =
    selectedProject && selectedProject.billingType !== nonBillableBillingType;

  const filterDevelopersTableRows = () => {
    const actualsButtonClicked = searchValue.includes(ProjectFilterName.Actuals);

    const discountsButtonClicked = searchValue.includes(ProjectFilterName.Discounts);

    const differenceButtonClicked = searchValue.includes(ProjectFilterName.Difference);

    const forecastButtonClicked = searchValue.includes(ProjectFilterName.Forecast);

    if (searchValue.length === 4) {
      return projectTableRows;
    }
    const excludedNames: Array<string> = [];
    if (!actualsButtonClicked) {
      excludedNames.push('Actuals');
    }
    if (!discountsButtonClicked) {
      excludedNames.push('Total Discounts');
    }
    if (!differenceButtonClicked) {
      excludedNames.push('Difference');
    }
    if (!forecastButtonClicked) {
      excludedNames.push('Forecast');
    }
    return projectTableRows.filter((row) => !excludedNames.includes(row.name));
  };

  const discountTableRows = () => [
    { id: 0, name: 'Pre-Discounts' },
    { id: 1, name: 'Discounts' }
  ];

  const [forecastData, setForecastData] = useState<{
    employeeActual: EmployeesActualDto | undefined;
    weekStartDate: string;
    sprintNumber: number;
  } | null>(null);

  const handleModalClose = () => {
    setIsUpdateForecastModalOpen(false);
  };

  const [billingPeriodWeeks, setBillingPeriodWeeks] = useState<BpWeeksDto[]>([]);

  const filtersKeyName = 'prpFilters';

  const saveToLocalStorage = (
    key: string,
    arr: {
      id: number;
      filters: string[];
    }[]
  ) => {
    localStorage.setItem(key, JSON.stringify(arr));
  };
  const getFromLocalStorage = (key: string) => {
    const storedData = localStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : null;
  };
  const storedFilters: { id: number; filters: string[] }[] = getFromLocalStorage(filtersKeyName);

  const checkIfProjectIdExistsIsSaved = () => {
    return storedFilters?.some((filter) => filter.id === projectId);
  };

  useEffect(() => {
    if (storedFilters && checkIfProjectIdExistsIsSaved()) {
      setSearchValue(
        storedFilters?.find((filter) => filter.id === projectId)?.filters ?? searchValue
      );
    } else {
      setSearchValue([
        ProjectFilterName.Forecast,
        ProjectFilterName.Actuals,
        ProjectFilterName.Difference,
        ProjectFilterName.Discounts
      ]);
    }
  }, []);

  useEffect(() => {
    if (searchValue.length <= 0) return;
    if (storedFilters) {
      if (checkIfProjectIdExistsIsSaved()) {
        const updatedStoredItems = storedFilters.filter((item) => item.id !== projectId);
        saveToLocalStorage(filtersKeyName, [
          ...updatedStoredItems,
          { id: projectId, filters: searchValue }
        ]);
      } else {
        saveToLocalStorage(filtersKeyName, [
          ...storedFilters,
          { id: projectId, filters: searchValue }
        ]);
      }
    } else {
      saveToLocalStorage(filtersKeyName, [{ id: projectId, filters: searchValue }]);
    }
  }, [searchValue]);

  const calculateHowManyWeeksInBillingPeriod = () => {
    const countBillingPeriodWeeks: BpWeeksDto[] = new Array(
      projectTableState.billingPeriods.length
    ).fill(0);
    projectTableState.billingPeriods.map((billing, index) => {
      countBillingPeriodWeeks[index] = {
        billingPeriodId: billing.billingPeriodId,
        numberOfWeeks: 0
      };
      return matchingSprints.map((sprint) =>
        billing.billingPeriodWeeks.map((billingWeeks) =>
          sprint.weeks.forEach((week) => {
            if (billingWeeks.weekId === week.weekId) {
              countBillingPeriodWeeks[index] = {
                billingPeriodId: billing.billingPeriodId,
                numberOfWeeks: countBillingPeriodWeeks[index].numberOfWeeks + 1
              };
            }
          })
        )
      );
    });
    return countBillingPeriodWeeks;
  };

  useEffect(() => {
    setBillingPeriodWeeks(calculateHowManyWeeksInBillingPeriod());
  }, [projectTableState, matchingSprints]);

  if (projectTableState.sprints.length === 0) {
    return (
      <StyledProjectTable>
        <tbody>
          <tr>
            <th className="stick-utils-button-wrapper">
              <h2>No data exists for the selected month</h2>
            </th>
          </tr>
        </tbody>
      </StyledProjectTable>
    );
  }

  return (
    <>
      <>
        {isUpdateForecastModalOpen && forecastData?.employeeActual && (
          <ModalWindow
            onClose={handleModalClose}
            headerTitle={UpdateForecastFormNames.UpdateForecastTitle}
            style={{
              width: updateForecastModalWindowWidth,
              minHeight: updateForecastModalWindowHeight
            }}
          >
            <UpdateForecastForm
              onClose={handleModalClose}
              onConfirm={onConfirmUpdatingForecast}
              employeeActuals={forecastData?.employeeActual}
              weekStartDate={forecastData?.weekStartDate || ''}
              sprintNumber={forecastData?.sprintNumber || 0}
            />
          </ModalWindow>
        )}
        {isEditBillingPeriodPopUpOpen && (
          <ModalWindow
            onClose={closeModal}
            headerTitle={ProjectModalWindowTitle.EditBillingPeriodFormTitle}
            style={{
              width: allocationPublicHolidayModalWidth,
              height: uploadActualsModalHeight
            }}
          >
            <EditBillingPeriodForm
              startDate={editBillingPeriodDate}
              onClose={closeModal}
              editBillingPeriodId={editBillingPeriodId}
              refreshProjectData={refreshProjectData}
            />
          </ModalWindow>
        )}
        {isEditSprintLengthPopUpOpen && (
          <ModalWindow
            onClose={closeModal}
            headerTitle={ProjectModalWindowTitle.EditSprintLengthFormTitle}
            style={{
              width: allocationPublicHolidayModalWidth,
              minHeight: updateSprintModalHeight
            }}
          >
            <EditSprintLengthForm
              startDate={editSprintLengthDate}
              onClose={closeModal}
              sprintLengthId={editSprintLengthId}
              refreshProjectData={refreshProjectData}
            />
          </ModalWindow>
        )}
        {isUpdateDiscountPopUpOpen && (
          <ModalWindow
            onClose={closeModal}
            headerTitle={ProjectModalWindowTitle.UpdateDiscountFormTitle}
            style={{ width: topPanelModalWindowWidth }}
          >
            <UpdateDiscountForm
              onClose={closeModal}
              discountItem={updateDiscount}
              refreshProjectData={refreshProjectData}
              projectBillingType={selectedProject?.billingType}
            />
          </ModalWindow>
        )}
        {isEditRateModalOpen && (
          <ModalWindow
            onClose={() => setIsEditRateModalOpen(false)}
            headerTitle={EditEmployeeRateFormNames.EditEmployeeRateModalTitle}
            style={{
              width: editRateModalWindowWidth,
              minHeight: editRateModalWindowHeight
            }}
          >
            <EditEmployeeRateForm
              onClose={() => setIsEditRateModalOpen(false)}
              item={selectedProject}
              employeeId={selectedEmployeeId}
            />
          </ModalWindow>
        )}
      </>

      {loading ? (
        <MainSpinner loading />
      ) : (
        <StyledProjectTable>
          <thead>
            <tr className="table-header">
              <th className="table-right-header">
                <AutocompleteEmployeesSearch
                  employeesData={projectTableState.employeesInfo}
                  onChange={onSearchChange}
                />
                <PrpTableFilters
                  searchValues={searchValue}
                  setSearchValue={setSearchValue}
                  onEmployeesSwitchChange={onEmployeesSwitchChange}
                  onFinancialSwitchChange={onFinancialSwitchChange}
                />
              </th>
              <th className="table-header-left">
                <div className="table-header-month">
                  <div className="table-header-month-wrapper">
                    <p className="inter-h3">{monthName}</p>
                  </div>
                  <div className="table-billing-row">
                    {projectTableState.billingPeriods.map((billing) => {
                      return (
                        <BillingPeriodColumnHeader
                          billing={billing}
                          billingPeriodWeeks={billingPeriodWeeks}
                          refreshProjectData={refreshProjectData}
                          matchingSprints={matchingSprints}
                          handleEditBillingPeriodPopUp={handleEditBillingPeriodPopUp}
                          handleEditSprintLengthPopUp={handleEditSprintLengthPopUp}
                        />
                      );
                    })}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="table-content-employee-row">
              {areEmployeesVisible &&
                employeesToDisplay.map((employee) => {
                  return (
                    <td className="table-content-wrapper" key={employee.employeeName}>
                      <div className="table-right-content">
                        <EmployeeNameAndInitials employee={employee} />
                        {isFinancialInfoVisible && (
                          <div className="table-content-cost">
                            <ProjectRateTooltip
                              label={
                                <button
                                  type="button"
                                  className="rates-button"
                                  onClick={() => {
                                    handleEditRatePopUp(employee.employeeId);
                                  }}
                                >
                                  <p className="inter-p3 employee-current-rate">
                                    ${Number(employee.employeeCurrentRate)}
                                  </p>
                                  {employee.employeeRates.length > 1 && (
                                    <p className="inter-caption_medium employee-default-rate">
                                      {Number(employee.employeeDefaultRate)}
                                    </p>
                                  )}
                                </button>
                              }
                              projectRate={[
                                `$${employee.employeeCurrentRate}`,
                                ...employee.employeeRates.map((rate) => {
                                  if (rate.endDate)
                                    return `$${rate.rate} (from ${formatDate(
                                      rate.startDate
                                    )} to ${formatDate(rate.endDate)})`;
                                  return `$${rate.rate} (from ${formatDate(rate.startDate)})`;
                                }),
                                `$${employee.employeeDefaultRate ?? 0}`
                              ]}
                              placement="bottom"
                            />
                          </div>
                        )}
                        <DataCategories
                          isFinancialInfoVisible={isFinancialInfoVisible}
                          filterDevelopersTableRows={filterDevelopersTableRows}
                        />
                      </div>
                      <ProjectTableContent
                        matchingSprints={matchingSprints}
                        projectTableState={projectTableState}
                        employee={employee}
                        isFinancialInfoVisible={isFinancialInfoVisible}
                        filters={searchValue}
                        refreshProjectData={refreshProjectData}
                        setIsUpdateDiscountPopUpOpen={setIsUpdateDiscountPopUpOpen}
                        setUpdateDiscount={setUpdateDiscount}
                        setIsUpdateForecastModalOpen={setIsUpdateForecastModalOpen}
                        setForecastData={setForecastData}
                      />
                    </td>
                  );
                })}
            </tr>
            <TotalsTable
              title="Week Totals"
              filterDevelopersTableRows={filterDevelopersTableRows}
              dataToDisplay={transformToTotalsData(matchingTotals, (week) => week.weekId)}
              filters={searchValue}
              subTotalsDevelopers={transformToSubTotalsDevelopersData(
                developersWeekTotals,
                (week) => week.weekId
              )}
              billingPeriodWeeks={billingPeriodWeeks}
              borderType={TotalsTableBorderType.WeekTotals}
              subTotalsDevelopersTitle="Developers Week Totals"
              subTotalsDiscountsTitle=""
              filterDiscountsTableRows={discountTableRows}
              disableFinancialInfo={!isFinancialInfoVisible}
            />
            <TotalsTable
              title="Billing Period Totals"
              filterDevelopersTableRows={filterDevelopersTableRows}
              dataToDisplay={transformToTotalsData(
                projectTotalsState.billingPeriodTotals,
                (bp) => bp.billingPeriodId
              )}
              filters={searchValue}
              billingPeriodWeeks={billingPeriodWeeks}
              subTotalsDevelopers={transformToSubTotalsDevelopersData(
                projectTotalsState.billingPeriodTotalsDevelopersOnly,
                (bp) => bp.billingPeriodId
              )}
              borderType={TotalsTableBorderType.BillingPeriod}
              subTotalsDiscountsTitle=""
              filterDiscountsTableRows={discountTableRows}
              disableFinancialInfo={!isFinancialInfoVisible}
            />
            {displayInvoiceSection && (
              <InvoiceTable
                matchingSprints={matchingSprints}
                projectTableState={projectTableState}
                refreshProjectData={refreshProjectData}
                isPreInvoice={displayPreInvoice}
              />
            )}
          </tbody>
        </StyledProjectTable>
      )}
    </>
  );
};
export default ProjectTable;
