import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import * as yup from 'yup';
import { sprintNotePattern } from 'constants/validationConstants';

const EditSprintLengthFormValidation = () => {
  return yup.object().shape({
    amount: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(FormValidationMessage.BillingPeriodError),
    note: yup
      .string()
      .nullable()
      .matches(sprintNotePattern, FormValidationMessage.SprintNoteLengthError)
  });
};

export default EditSprintLengthFormValidation;
