import CalendarDictionariesDto from 'types/allocation/dto/calendarDto/CalendarDictionariesDto';

const calendarSelectorByWeekId = (calendarData: CalendarDictionariesDto, weekId: number) => {
  const matchingWeekData = Object.entries(calendarData.calendar)
    .flatMap(([, yearData]) => yearData.flatMap((monthData) => monthData.weeks))
    .find((weekData) => weekData.id === weekId);

  if (matchingWeekData) {
    return {
      startDate: matchingWeekData.startDate,
      endDate: matchingWeekData.workWeekEndDate
    };
  }

  return {
    startDate: null,
    endDate: null
  };
};

export default calendarSelectorByWeekId;
