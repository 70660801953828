import Grid from '@mui/material/Grid';
import { RootState } from 'store';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import SpinnerButton from 'components/sharedComponents/Spinner/SpinnerButton';
import StyledDetailsContent from 'components/employee/EmployeesDeatails/StyledDetailsContetnt';
import { ClientNoteDto } from 'types/clients/dto/ClientNoteDto';
import ClientDetailsProject from 'components/clients/ClientDetails/ClientDetailsProject';
import ClientDetailsNotes from 'components/clients/ClientDetails/ClientDetailsNotes';
import ClientNotesAddForm from 'components/clients/ClientNoteAddForm/ClientNotesAddForm';
import PermissionsName from 'types/generalEnums/PermissionsName';
import { useEffect } from 'react';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import getNotes from 'store/action/clientActions/getNotes';
import doesUserHavePermissions from 'helpers/permissionService';
import { unwrapResult } from '@reduxjs/toolkit';
import { memoize } from 'proxy-memoize';
import { ClientProjectDto } from 'types/clients/dto/ClientProjectDto';

type ClientDetailsContentProps = {
  item: ClientsDto;
  notesManagementPermissions: PermissionsName[];
};

const ClientDetailsContent = ({ item, notesManagementPermissions }: ClientDetailsContentProps) => {
  const loadingNotes = useAppSelector(memoize((state: RootState) => state.notes.readLoading));

  const client = useAppSelector(
    memoize((state: RootState) => state.clients.data.find((e: ClientsDto) => e.id === item.id))
  );

  const dispatch = useThunkAppDispatch();

  useEffect(() => {
    if (item) {
      dispatch(getNotes(item))
        .then(unwrapResult)
        .then()
        .catch(() => {
          logError('Error fetching notes');
        });
    }
  }, [item]);

  const notes = useAppSelector(
    memoize((state: RootState) =>
      state.notes.data.filter((note: ClientNoteDto) => note.clientId === item?.id)
    )
  );
  const user = useAppSelector((state: RootState) => state.auth.user);

  const canAddNotes = doesUserHavePermissions(user, notesManagementPermissions);

  const sortProjectsByName = (c: ClientsDto): ClientsDto => {
    const sortedProjects: ClientProjectDto[] = [...c.projects].sort((a, b) =>
      a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
    );

    return { ...c, projects: sortedProjects };
  };

  return (
    <StyledDetailsContent key={item.key}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} className="contact-information">
          <p className="inter-caption_medium">Contact Information</p>
        </Grid>
        <Grid item xs={6} md={4} className="contact-details">
          <h3 className="inter-caption_medium">Name</h3>
          <p className="inter-p3 client-name"> {client?.contactName}</p>
        </Grid>
        <Grid item xs={6} md={4} className="contact-details">
          <h3 className="inter-caption_medium">Position</h3>
          <p className="inter-p3 client-position"> {client?.contactPosition}</p>
        </Grid>
        <Grid item xs={6} md={4} className="contact-details">
          <h3 className="inter-caption_medium">Phone</h3>
          <p className="inter-p3 client-phone"> {client?.contactPhone}</p>
        </Grid>
        <Grid item xs={6} md={4} className="contact-details">
          <h3 className="inter-caption_medium">Email</h3>
          <p className="inter-p3"> {client?.contactEmail}</p>
        </Grid>
        {item?.projects.length > 0 ? (
          <Grid item xs={12} md={12}>
            <ClientDetailsProject item={sortProjectsByName(item)} />
          </Grid>
        ) : null}
        {canAddNotes ? (
          <Grid item xs={12} md={12}>
            <ClientNotesAddForm client={item} />
          </Grid>
        ) : null}
        {notes.length > 0 ? (
          <Grid item xs={12} md={12}>
            <ClientDetailsNotes item={item} />
          </Grid>
        ) : (
          <SpinnerButton loading={loadingNotes} className="client-notes-spinner" />
        )}
      </Grid>
    </StyledDetailsContent>
  );
};

export default ClientDetailsContent;
