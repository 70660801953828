import ThemeProvider from '@mui/material/styles/ThemeProvider';
import customTheme from 'styles/mui/customTheme';
import LeftSideNavBar from 'components/sharedComponents/LeftsideNavBar/LeftSideNavBar';
import { ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';
import RoutingPath from 'routes/routing';
import Box from '@mui/material/Box';
import { leftMenuBigWidth } from 'components/sharedComponents/StyleConsts/styleConsts';
import Wrapper from './Wrapper';

type MainLayoutProps = {
  children: ReactNode;
};

const MainLayout = ({ children }: MainLayoutProps) => {
  const location = useLocation();
  const showLeftSideNavBar =
    location.pathname !== RoutingPath.LogInPage &&
    location.pathname !== RoutingPath.DownTimeErrorPage &&
    location.pathname !== RoutingPath.RedirectPage;

  const [menuLeftMargin, setMenuLeftMargin] = useState(leftMenuBigWidth);

  return (
    <ThemeProvider theme={customTheme}>
      <Wrapper>
        <div className="wrapper-flex">
          {showLeftSideNavBar && <LeftSideNavBar setMenuLeftMargin={setMenuLeftMargin} />}
          <Box
            component="main"
            sx={{ flexGrow: 1, marginLeft: showLeftSideNavBar ? menuLeftMargin : '0' }}
          >
            {children}
          </Box>
        </div>
      </Wrapper>
    </ThemeProvider>
  );
};

export default MainLayout;
