import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import MainLayout from 'layouts/MainLayout';
import RootRouter from 'routes/RootRouter';
import { StrictMode } from 'react';
import ModalProvider from 'modals/ModalProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './store';

const App = () => {
  return (
    <StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <ModalProvider>
            <MainLayout>
              <RootRouter />
              <ToastContainer />
            </MainLayout>
          </ModalProvider>
        </Provider>
      </BrowserRouter>
    </StrictMode>
  );
};

export default App;
