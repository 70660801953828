import MAX_TIME_STRING from 'constants/maxTimeString';
import formatDate from 'helpers/formatDate';

function formatToEndAllocationDate(stringDate: string, format?: string) {
  let formattedDate = new Date(stringDate).toLocaleString('en-us', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  });

  if (format) {
    formattedDate = formatDate(format, formattedDate);
  }

  return new Date(`${formattedDate} ${MAX_TIME_STRING}`);
}

export default formatToEndAllocationDate;
