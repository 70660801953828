import {
  getCurrencyIfValueExistOrPlaceholder,
  getHoursIfValueExistOrPlaceholder
} from 'helpers/formatCurrencyAndHours';

type DiscountsButtonProps = {
  condition: boolean;
  onClick: () => void;
  toolTip: JSX.Element | null;
  duration: number | null;
  cost: number | null;
  isDisabled: boolean;
  customClass: string | null;
  shouldShowTooltipIfNotAButton?: boolean;
  disableFinancialInfo?: boolean;
  icon?: JSX.Element | null;
};

const OptionalButton = ({
  condition,
  onClick,
  duration,
  cost,
  toolTip,
  isDisabled,
  customClass,
  shouldShowTooltipIfNotAButton,
  disableFinancialInfo,
  icon
}: DiscountsButtonProps) => {
  const handleClick = () => {
    onClick();
  };

  return (
    <div className={`sprint-row-cell-outer ${disableFinancialInfo && 'small-button'}`}>
      <div className="sprint-row-cell-inner">
        {condition ? (
          <button
            disabled={isDisabled}
            type="button"
            className={`discount-button ${customClass}`}
            onClick={handleClick}
          >
            <div className="tooltip-container">{toolTip}</div>
            <div>
              <div className={`${icon ? 'optional-button-icon' : ''}`}>
                {icon}
                <p className="inter-caption_medium duration">
                  {getHoursIfValueExistOrPlaceholder(duration)}
                </p>
              </div>
              {!disableFinancialInfo && (
                <p className="inter-caption_medium cost">
                  {getCurrencyIfValueExistOrPlaceholder(cost)}
                </p>
              )}
            </div>
          </button>
        ) : (
          <div className={`${shouldShowTooltipIfNotAButton ? 'tooltip-cell-container' : ''}`}>
            {shouldShowTooltipIfNotAButton && <div className="tooltip-container">{toolTip}</div>}
            <div>
              <p className="inter-caption_medium duration">
                {getHoursIfValueExistOrPlaceholder(duration)}
              </p>
              {!disableFinancialInfo && (
                <p className="inter-caption_medium cost">
                  {getCurrencyIfValueExistOrPlaceholder(cost)}
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OptionalButton;
