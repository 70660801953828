import { EmployeeFormDto } from 'types/employee/dto/EmployeeFormDto';

const AddEmployeeFormDefaultValue: EmployeeFormDto = {
  id: null,
  name: '',
  positionId: null,
  seniorityId: null,
  email: '',
  weeklyCapacity: null,
  active: true,
  hasSystemAccess: true,
  permissionIds: [],
  force: false
};

export default AddEmployeeFormDefaultValue;
