import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import GenericSearchComponent from 'components/sharedComponents/SearchComponents/GenericSeacrchComponent';
import EmployeesList from 'components/employee/EmployeesList/EmployeesList';
import filterObjectList from 'helpers/filterObjectList';
import EmployeeStatusFilter from 'types/employee/enums/EmployeeStatusFilter';
import { RootState } from 'store';
import MainSpinner from 'components/sharedComponents/Spinner/MainSpinner';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import ClientsList from 'components/clients/ClientsList/ClietnsList';
import PageType from 'types/generalEnums/PageType';
import StyledSharedListItem from 'components/sharedComponents/SharedListItem/StyledSharedListItem';
import { useSelector } from 'react-redux';

type ItemsSharedListItem = {
  items: ClientsDto[] | EmployeeDto[];
  type: PageType.Employees | PageType.Clients;
  setItemsCount: Dispatch<SetStateAction<number>>;
};
const SharedListItem = ({ items, type, setItemsCount }: ItemsSharedListItem): JSX.Element => {
  const [filter, setFilter] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState<EmployeeStatusFilter>(EmployeeStatusFilter.All);
  const [filteredItems, setFilteredItems] = useState<typeof items | null>(null);
  const loadingClients = useSelector((state: RootState) => state.clients.readLoading);
  const loadingEmployees = useSelector((state: RootState) => state.employees.readLoading);
  const loadingDictionaries = useSelector((state: RootState) => state.dictionaries.loading);
  const filteredProperty = ['name'];
  const filterChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setFilter(event.target.value);
  }, []);
  const changeEmployeeStatus = (status: string) => {
    if (status === 'All') {
      setStatusFilter(EmployeeStatusFilter.All);
      setFilteredItems(items);
      return;
    }
    setStatusFilter(
      status === 'Active' ? EmployeeStatusFilter.Active : EmployeeStatusFilter.InActive
    );
    const filteredItemsEm = (items as EmployeeDto[]).filter((item) =>
      status === EmployeeStatusFilter.Active ? item.active : !item.active
    );
    setFilteredItems(filteredItemsEm);
  };

  useEffect(() => {
    if (filteredItems && filteredItems.length === items.length) {
      setStatusFilter(EmployeeStatusFilter.All);
    }
  }, [filteredItems, items]);

  useEffect(() => {
    const filteredItemsEm =
      statusFilter === 'All'
        ? items
        : (items as EmployeeDto[]).filter((item) =>
            statusFilter === EmployeeStatusFilter.Active ? item.active : !item.active
          );
    setFilteredItems(
      filterObjectList(filter, filteredProperty, filteredItemsEm) as EmployeeDto[] | ClientsDto[]
    );
  }, [filter, items, statusFilter]);

  useEffect(() => {
    setItemsCount(filteredItems?.length || 0);
  }, [filteredItems]);

  return (
    <StyledSharedListItem>
      <MainSpinner loading={loadingEmployees || loadingDictionaries || loadingClients} />
      <GenericSearchComponent
        value={filter ?? ''}
        onChange={filterChangeHandler}
        onClear={() => {
          setFilter('');
        }}
        type={type}
        statusFilter={statusFilter ?? EmployeeStatusFilter.All}
        setStatusFilter={changeEmployeeStatus}
      />
      {type === PageType.Employees && (
        <div className="list-item-scroll">
          <EmployeesList filteredItems={filteredItems as EmployeeDto[]} />
        </div>
      )}
      {type === PageType.Clients && (
        <div className="list-item-scroll">
          <ClientsList filteredItems={filteredItems as ClientsDto[]} />
        </div>
      )}
    </StyledSharedListItem>
  );
};

export default SharedListItem;
