import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { EmployeeRateState } from 'store/slicesState/employeeRate';
import EmployeeRatesDto from 'types/project/dto/EmployeeRatesDto';
import getEmployeeRates from 'store/action/projectActions/getEmployeeRates';
import addEmployeeRate from 'store/action/projectActions/addEmployeeRate';

export const employeeRateInitialState: EmployeeRateState = {
  data: [],
  loading: false,
  error: null
};
export type DispatchError = {
  errorMessage: string;
};

const employeeRateSlice = createSlice({
  name: 'employeeRates',
  initialState: employeeRateInitialState,
  reducers: {
    getEmployeeRate: (state, action: PayloadAction<EmployeeRatesDto[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployeeRates.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getEmployeeRates.fulfilled, (state, action) => {
      state.error = null;
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getEmployeeRates.rejected, (state) => {
      state.error = 'getEmployeeRates error in slice';
      state.loading = false;
    });
    builder.addCase(addEmployeeRate.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(addEmployeeRate.fulfilled, (state, action) => {
      state.data.push(action.payload);
      state.error = null;
      state.loading = false;
    });
    builder.addCase(addEmployeeRate.rejected, (state) => {
      state.error = 'addEmployeeRate error in slice';
      state.loading = false;
    });
  }
});

export const { getEmployeeRate } = employeeRateSlice.actions;
export const SELECT_EMPLOYEE_RATES = (root: RootState) => root.employeeRatesSlice;
export default employeeRateSlice.reducer;
