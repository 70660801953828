import React, { ReactNode, useEffect, useRef } from 'react';
import StyledModalWindow from 'modals/StyledModalWindow';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { crossGrey, dotsBlack, iconMore } from 'shared/PrpIcon/icons.constants';
import GenericPopupWithButtons from 'modals/GenericPopupWithButtons';
import allocationDeletePopupItem from 'types/allocation/enums/AllocationDeletePopUpItem';

type ModalWindowProps = {
  children?: ReactNode;
  onClose?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  headerTitle?: string;
  style?: React.CSSProperties;
  className?: string;
  onMore?: () => void;
  showMenuButtons?: boolean;
  menuButtons?: {
    id: number;
    label: string;
    icon: string;
  }[];
  onClickFunctions?: (() => void)[];
  disableScroll?: boolean;
};

const ModalWindow = ({
  children,
  onClose,
  headerTitle,
  style,
  className,
  onMore,
  showMenuButtons,
  menuButtons,
  onClickFunctions,
  disableScroll
}: ModalWindowProps) => {
  const height = style?.height || '';
  const heightValue = Number(height.toString().substring(0, height.toString().length - 2));
  const modalBackgroundRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event: React.MouseEvent) {
      if (modalBackgroundRef.current && event.target === modalBackgroundRef.current) {
        if (onClose) {
          onClose();
        }
      }
    }
    document.addEventListener('mousedown', handleClickOutside as unknown as EventListener);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside as unknown as EventListener);
    };
  }, [modalBackgroundRef]);

  return (
    <StyledModalWindow className={className}>
      <div className="modal-body" ref={modalBackgroundRef}>
        <div
          className={`${
            height !== '' && Number(heightValue) < 400 ? 'small-modal-content' : 'modal-content'
          }`}
          style={style}
        >
          <div className="modal-row">
            <h3 className="inter-h4 title">{headerTitle}</h3>
            {onMore ? (
              <GenericPopupWithButtons
                buttonIcon={iconMore}
                buttonClassName={onClose ? 'btn-more-with-close' : 'btn-close'}
                items={allocationDeletePopupItem}
                onClickFunctions={onMore ? [onMore] : []}
              />
            ) : null}
            {showMenuButtons && (
              <GenericPopupWithButtons
                buttonLabel=""
                buttonIcon={dotsBlack}
                buttonClassName="invoice-menu-button"
                items={menuButtons || []}
                onClickFunctions={onClickFunctions || []}
              />
            )}
            {onClose ? (
              <button
                onClick={onClose}
                className={onMore ? 'btn-close-with-dots' : 'btn-close'}
                type="button"
              >
                <PrpIcon src={crossGrey} height="36px" width="36px" />
              </button>
            ) : null}
          </div>
          <div className={`${disableScroll ? '' : 'modal-scroll-content'}`}>{children}</div>
        </div>
      </div>
    </StyledModalWindow>
  );
};
export default ModalWindow;
