import { createAsyncThunk } from '@reduxjs/toolkit';
import clientsApiService from 'api/clientsApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import mapToClientDto from 'store/mappers/clients/clientsMapper';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/employee/employeeSlice';
import { ClientFormDto } from 'types/clients/dto/ClientFormDto';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import ClientsAction from 'types/clients/enums/Clients';

const addClient = createAsyncThunk<
  ClientsDto,
  ClientFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(ClientsAction.ADD_CLIENT, async (data: ClientFormDto) => {
  const clientDto = mapToClientDto(data);
  const response = await clientsApiService.addClient(clientDto);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});
export default addClient;
