import Initials from 'components/sharedComponents/Initials/Initials';
import StyledAllocationEmployeesDetails from 'components/allocations/AllocationEmployeesDetails/StyledAllocationEmployeesDetails';
import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RoutingPath from 'routes/routing';

type ResourceProps = {
  id: number;
  name?: string;
  title?: string;
  position: string;
  seniority: string;
  capacity: number;
};

const AllocationEmployeesDetails = ({ resource }: { resource: ResourceProps }) => {
  const positionDisplayName = resource.position;
  const seniorityDisplayName = resource.seniority;
  const [openTooltip, setOpenTooltip] = useState(false);
  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };
  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };

  const navigate = useNavigate();

  const onEmployeeNameClick = () => {
    navigate(RoutingPath.EmployeesPageDetails.replace(':employeeId', String(resource.id)));
  };

  return (
    <StyledAllocationEmployeesDetails>
      <CustomTooltip
        onClose={handleCloseTooltip}
        onOpen={handleOpenTooltip}
        isTooltipOpen={openTooltip}
        title={`Weekly capacity: ${resource.capacity} hours`}
        placement="bottom"
        className="employee-tooltip"
        isEmployeeTooltip
        label={
          <StyledAllocationEmployeesDetails onClick={onEmployeeNameClick}>
            <Initials name={resource.name ?? ''} className="img" />
            <div className="employee-shifts-info">
              <div className="employee-shifts-name">{resource.name}</div>
              <div className="employee-shifts-title inter-caption_medium ">{`${positionDisplayName}, ${seniorityDisplayName}`}</div>
            </div>
          </StyledAllocationEmployeesDetails>
        }
      />
    </StyledAllocationEmployeesDetails>
  );
};

export default AllocationEmployeesDetails;
