import { createAsyncThunk } from '@reduxjs/toolkit';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import InvoiceAction from 'types/project/enums/Invoice';
import invoiceSummaryApiService from 'api/invoiceSummaryApiService';
import { InvoiceFixedPriceDto } from 'types/project/dto/FixedInvoiceDto';

const getFixedInvoiceDetails = createAsyncThunk<
  InvoiceFixedPriceDto,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(InvoiceAction.GET_FIXED_INVOICE_SUMMARY, async (billingPeriodId) => {
  const response = await invoiceSummaryApiService.getFixedInvoiceSummary(billingPeriodId);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default getFixedInvoiceDetails;
