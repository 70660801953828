import { createAsyncThunk } from '@reduxjs/toolkit';
import clientsApiService from 'api/clientsApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import getReactItemsWithKey from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/employee/employeeSlice';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import ClientsAction from 'types/clients/enums/Clients';

const getClients = createAsyncThunk<
  ClientsDto[],
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(ClientsAction.FETCH_ALL, async () => {
  const response = await clientsApiService.fetchClients();
  if (!response) notifyUser();
  return getReactItemsWithKey(response);
});

export default getClients;
