import { EmployeeInfoDto } from 'types/PrpTable/dto/ProjectTableDto';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { searchGrey } from 'shared/PrpIcon/icons.constants';
import InputAdornment from '@mui/material/InputAdornment';
import { SyntheticEvent } from 'react';
import StyledAutocompleteEmployeesSearch from 'components/sharedComponents/SearchComponents/StyledAutocompleteEmployeesSearch';

type AutocompleteEmployeesSearchProps = {
  employeesData: EmployeeInfoDto[];
  className?: string;
  onChange: (event: SyntheticEvent<Element, Event>, value: EmployeeInfoDto | null) => void;
};

const AutocompleteEmployeesSearch = ({
  employeesData,
  className,
  onChange
}: AutocompleteEmployeesSearchProps) => {
  return (
    <StyledAutocompleteEmployeesSearch>
      <FormControl>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={employeesData}
          sx={{ width: 311.5 }}
          onChange={onChange}
          className={className || 'autocomplete'}
          getOptionLabel={(option) => option.employeeName}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Search"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <PrpIcon src={searchGrey} />
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </FormControl>
    </StyledAutocompleteEmployeesSearch>
  );
};

export default AutocompleteEmployeesSearch;
