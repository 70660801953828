import styled from 'styled-components';

const StyledAllocationModalWindowWithTabs = styled.div({
  '.tab': {
    textTransform: 'capitalize'
  },
  '.tabel-panel': {
    padding: '24px 24px 24px 0'
  }
});

export default StyledAllocationModalWindowWithTabs;
