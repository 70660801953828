import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import mapToClientDto from 'store/mappers/clients/clientsMapper';
import { ClientFormDto } from 'types/clients/dto/ClientFormDto';
import { ClientsDto } from 'types/clients/dto/ClientsDto';

const clientsApiService: IApiService = {
  fetchClients: async () => {
    try {
      const response = await apiRequest<ClientsDto[]>({
        method: 'GET',
        endpoint: `${API_URL}/clients`
      });
      return response;
    } catch (error) {
      logError('Error in fetchClients');
      throw new Error('Error in fetchClients');
    }
  },
  updateClients: async (data: ClientFormDto) => {
    try {
      const clientsDto = mapToClientDto(data);
      const response = await apiRequest<ClientsDto>({
        method: 'PUT',
        endpoint: `${API_URL}/clients/${data.id}`,
        requestBody: clientsDto
      });
      return response;
    } catch (error) {
      logError('Error in updateCliens');
      throw new Error('Error in updateCliens');
    }
  },
  addClient: async (data: ClientFormDto) => {
    try {
      const addClinetDto = mapToClientDto(data);
      const response = await apiRequest<ClientsDto>({
        method: 'POST',
        endpoint: `${API_URL}/clients`,
        requestBody: addClinetDto
      });
      return response;
    } catch (error) {
      logError('Error in addClient');
      throw new Error('Error in addClient');
    }
  }
};

interface IApiService {
  fetchClients: () => Promise<ClientsDto[]>;
  updateClients: (data: ClientFormDto) => Promise<ClientsDto>;
  addClient: (data: ClientFormDto) => Promise<ClientsDto>;
}

export default clientsApiService;
