import { createAsyncThunk } from '@reduxjs/toolkit';
import allocationsDictionariesApiService from 'api/allocationsDictionaryApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import getAllocationsDictionariesWithKeys from 'store/mappers/allocations/allocationsDictionariesMapper';
import { DispatchError } from 'store/slices/authSlice';
import AllocationsDictionariesDto from 'types/allocation/dto/AllocationsDictionariesDto';
import AllocationFilterDashboardNames from 'types/allocation/enums/AllocationFilterDashboardNames';
import DictionariesAction from 'types/employee/enums/Dictionaries';

const getAllocationsDictionaries = createAsyncThunk<
  AllocationsDictionariesDto,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(DictionariesAction.FETCH_ALLOCATIONS_DICTIONARIES, async () => {
  const response = await allocationsDictionariesApiService.fetchAllocationsDictionaries();
  if (!response) notifyUser();
  const responseWithFilters = {
    ...response,
    filterNames: [
      AllocationFilterDashboardNames.EmployeeLabel,
      AllocationFilterDashboardNames.Clientlabel,
      AllocationFilterDashboardNames.ProjectLabel,
      AllocationFilterDashboardNames.PositionsLabel
    ].map((filterName, index) => ({
      id: index,
      name: filterName,
      displayName: filterName
    }))
  };

  return getAllocationsDictionariesWithKeys(responseWithFilters);
});

export default getAllocationsDictionaries;
