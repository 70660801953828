import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import { useState } from 'react';
import { LOW_EMPHASISE_COLOR } from 'constants/colors';

type ProjectRateTooltipProps = {
  icon?: string;
  className?: string;
  projectRate: string[];
  label?: string | JSX.Element;
  placement: string;
};
const ProjectRateTooltip = ({
  icon,
  className,
  projectRate,
  label,
  placement
}: ProjectRateTooltipProps) => {
  const [isReasonTooltipOpen, setReasonOpenTooltip] = useState(false);
  const handleReasonTooltipClose = () => {
    setReasonOpenTooltip(false);
  };
  const handleReasonTooltipOpen = () => {
    setReasonOpenTooltip(true);
  };

  const getDefaultRate = (rate: string[]) => {
    return rate.slice(-1);
  };

  const getRatesHistory = (rate: string[]) => {
    return rate.slice(1, -1).map((item) => <p>{item}</p>);
  };

  const getCurrentRate = (rate: string[]) => {
    return rate[0];
  };

  return (
    <CustomTooltip
      isTooltipOpen={isReasonTooltipOpen}
      onClose={handleReasonTooltipClose}
      onOpen={handleReasonTooltipOpen}
      title={
        <div>
          <div style={{ display: 'flex', flexDirection: 'row' }} className="content-row">
            <p className="inter-caption_m_bold">Project Rate: {getCurrentRate(projectRate)}</p>
          </div>
          <hr
            className="straight-line"
            style={{
              background: LOW_EMPHASISE_COLOR,
              color: LOW_EMPHASISE_COLOR,
              borderColor: LOW_EMPHASISE_COLOR,
              height: '1px',
              margin: '0px'
            }}
          />
          <div>
            <p className="inter-caption_m_bold">Project Rate History</p>
            {getRatesHistory(projectRate)}
          </div>
          <hr
            style={{
              background: LOW_EMPHASISE_COLOR,
              color: LOW_EMPHASISE_COLOR,
              borderColor: LOW_EMPHASISE_COLOR,
              height: '1px'
            }}
          />
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <p className="inter-caption_m_bold">Default Rate: {getDefaultRate(projectRate)}</p>
          </div>
        </div>
      }
      className={className}
      placement={placement}
      label={label}
      icon={icon}
    />
  );
};
export default ProjectRateTooltip;
