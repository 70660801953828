import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import * as yup from 'yup';

const AllocationDeletePublicHolidayValidation = () => {
  return yup.object().shape({
    deleteAllHolidaysThisDay: yup.boolean().required(FormValidationMessage.TypeChoiceError)
  });
};

export default AllocationDeletePublicHolidayValidation;
