import { useEffect, useState } from 'react';
import SharedListItem from 'components/sharedComponents/SharedListItem/SharedListItem';
import TopPanel from 'components/sharedComponents/TopPanel/TopPanel';
import SharedDetailsComponent from 'components/sharedComponents/SharedDetailsComponent/SharedDetailsComponent';
import { selectEmployees } from 'shared/PrpIcon/icons.constants';
import EmptyView from 'components/sharedComponents/EmptyView/EmptyView';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { unwrapResult } from '@reduxjs/toolkit';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import getEmployees from 'store/action/employeeActions/getEmployee';
import getDictionaries from 'store/action/employeeActions/getDictionaries';
import PageType from 'types/generalEnums/PageType';
import { RootState } from 'store';
import { useNavigate, useParams } from 'react-router-dom';
import cleanSelectedEmployees from 'store/action/employeeActions/cleanSelectedEmployee';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { selectEmployee } from 'store/slices/employee/employeeSlice';
import RoutingPath from 'routes/routing';
import { pageDetailsMinHeightOverride } from 'components/sharedComponents/StyleConsts/styleConsts';
import PermissionsName from 'types/generalEnums/PermissionsName';

const EmployeePage = (): JSX.Element => {
  const dispatch = useThunkAppDispatch();
  const selectedEmployee = useAppSelector((state: RootState) => state.employees.selectedEmployee);
  const employeeState = useAppSelector((state: RootState) => state.employees);
  const param = useParams<{ employeeId: string }>();
  const [itemsCount, setItemsCount] = useState(employeeState.data.length || 0);
  const navigation = useNavigate();

  useEffect(() => {
    dispatch(getDictionaries())
      .then(unwrapResult)
      .then(() => {
        dispatch(getEmployees())
          .then(unwrapResult)
          .catch(() => logError('Error fetching employees'));
      })
      .catch(() => {
        logError('Error fetching dictionaries');
      });
  }, []);

  const navigateOnResorceNotFound = () => {
    if (param.employeeId && employeeState.data.length > 0) {
      const employeeId = parseInt(param.employeeId, 10);
      const doesEmployeeExist = !!employeeState.data.find((e) => e.id === employeeId);
      if (doesEmployeeExist) {
        dispatch(selectEmployee(employeeId));
      } else {
        navigation(RoutingPath.NotFoundPage);
      }
    } else {
      dispatch(cleanSelectedEmployees());
    }
  };

  useEffect(() => {
    navigateOnResorceNotFound();
  }, [param.employeeId, employeeState.data]);

  useEffect(() => {
    return () => {
      dispatch(cleanSelectedEmployees());
    };
  }, []);
  const employeeManagementPermissions = [PermissionsName.ManageEmployees];
  return (
    <div className="main-section">
      <TopPanel
        title="Employees"
        itemsCount={itemsCount}
        addButtonTitle="Add employee"
        type={PageType.Employees}
        showAddButtonPermissions={employeeManagementPermissions}
      />
      <div className="content-section">
        <SharedListItem
          setItemsCount={setItemsCount}
          items={employeeState.data}
          type={PageType.Employees}
        />
        <div className="main-section-details">
          <SharedDetailsComponent
            item={selectedEmployee}
            style={{ minHeight: pageDetailsMinHeightOverride }}
            type={PageType.Employees}
            detailsManagementPermissions={employeeManagementPermissions}
          />
        </div>
        {!selectedEmployee ? (
          <EmptyView
            title="Select an Employee"
            description="Please, select an Employee on the left to see the Employee’s details here. "
          >
            <PrpIcon src={selectEmployees} width="196px" height="118px" />
          </EmptyView>
        ) : null}
      </div>
    </div>
  );
};

export default EmployeePage;
