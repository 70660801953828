import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import * as yup from 'yup';

const invalidDateTest = () => {
  return yup
    .string()
    .test('valid-date', FormValidationMessage.DateError, (value) => value !== 'Invalid Date')
    .required(FormValidationMessage.DateError);
};

export const dateBeforeProjectStartDate = (
  date: string,
  minDate: Date,
  selectedProject: number | null
) => {
  return (
    minDate.setHours(0, 0, 0, 0) > new Date(date).setHours(0, 0, 0, 0) && selectedProject !== null
  );
};

export const endDateBeforeStartDate = (startDate: Date, endDate: Date) => {
  return startDate.setHours(0, 0, 0, 0) > new Date(endDate).setHours(0, 0, 0, 0);
};

export default invalidDateTest;
