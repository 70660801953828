import PageType from 'types/generalEnums/PageType';

const formatDate = (
  dateString: string | null,
  type?: string,
  endDatePicker = false,
  time = false
): string => {
  if (dateString === null) {
    return '-';
  }
  if (dateString === '') {
    return dateString;
  }
  const originalDate = new Date(dateString);
  const date = new Date(originalDate.getTime());

  let formattedDate = date.toLocaleString('en-us', {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  });

  if (endDatePicker) {
    const isMidnight = date.getHours() === 0 && date.getMinutes() === 0 && date.getSeconds() === 0;
    const options: Intl.DateTimeFormatOptions = isMidnight
      ? { month: 'short', day: 'numeric', year: 'numeric' }
      : { month: 'short', day: 'numeric', year: 'numeric', timeZone: 'UTC' };

    formattedDate = date.toLocaleString('en-us', options);
    return formattedDate;
  }

  if (time) {
    formattedDate = date.toLocaleString('en-us', {
      hour: 'numeric',
      minute: 'numeric',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
    const reversedFormattedDate = formattedDate.split(',');
    return `${reversedFormattedDate[2]}, ${reversedFormattedDate[0]} ${reversedFormattedDate[1]}`;
  }

  if (type === PageType.ProjectTable) {
    formattedDate = date.toLocaleString('en-us', {
      month: 'short',
      day: 'numeric'
    });
  }
  return formattedDate;
};

export default formatDate;
