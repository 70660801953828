export enum ProjectStatusName {
  Active = 'Active',
  Closed = 'Closed',
  Waiting = 'Waiting'
}

export enum ProjectStatusNumber {
  Active = 0,
  Closed = 1,
  Waiting = 2
}
