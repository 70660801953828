import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import UpdateForecastDto from 'types/project/dto/UpdateForecastDto';

const updateForecastApiService: IApiService = {
  updateForecast: async (data) => {
    try {
      const response = await apiRequest<UpdateForecastDto>({
        method: 'POST',
        endpoint: `${API_URL}/forecasts/${data.forecastId}`,
        requestBody: data
      });
      return response;
    } catch (error) {
      logError('Error in updateForecast');
      throw new Error('Error in updateForecast');
    }
  }
};

interface IApiService {
  updateForecast: (data: UpdateForecastDto) => Promise<UpdateForecastDto>;
}

export default updateForecastApiService;
