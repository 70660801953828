import EditEmployeeRateFormDto from 'types/project/dto/EditEmployeeRateFormDto';
import AddEmployeeRateDto from 'types/project/dto/AddEmployeeRateDto';

const mapToAddEmployeeRateDto = (data: EditEmployeeRateFormDto): AddEmployeeRateDto => {
  return {
    employeeId: data.employeeId,
    projectId: data.projectId,
    rate: data.rate,
    startWeekId: data.startWeekId,
    reason: data.reason
  } as AddEmployeeRateDto;
};

export default mapToAddEmployeeRateDto;
