import AllocationDto from 'types/allocation/dto/AllocationDto';
import AllocationsDictionariesDto from 'types/allocation/dto/AllocationsDictionariesDto';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import selectSeniorityPropertyById from 'store/dictionarySelectors/selectorSeniority';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import { DictionariesState } from 'store/slicesState/dictionaries';

export type ResourcesData = {
  id: number;
  name: string;
  title: string;
  position: string;
  seniority: string;
  capacity: number;
};

const allocationDataToResourcesTransformer = (
  employeeInformation: AllocationDto,
  activeEmployees: AllocationsDictionariesDto,
  allEmployees: EmployeeDto[],
  stateEmployee: DictionariesState
) => {
  if (!employeeInformation || !employeeInformation.employeeAllocations) {
    return [];
  }
  const resources: ResourcesData[] = [];

  employeeInformation.employeeAllocations.forEach(
    ({ employeeId, employeeName, employeePositionName }) => {
      const currentActiveEmployee = activeEmployees.activeEmployeeNames.find(
        (employee) => employee.name === employeeName
      );
      const matchedPosition = activeEmployees.positions.find(
        (position) => position.name === employeePositionName
      );
      const matchedSeniorityId = allEmployees?.find(
        (employee) => employee.name === employeeName
      )?.seniorityId;
      const matchedCapacity = allEmployees?.find(
        (employee) => employee.name === employeeName
      )?.weeklyCapacity;
      const seniorityDisplayName = selectSeniorityPropertyById(
        stateEmployee,
        matchedSeniorityId || 0,
        DictionariesSelector.DisplayName
      );
      if (currentActiveEmployee) {
        resources.push({
          id: employeeId,
          name: employeeName,
          title: employeePositionName,
          position: matchedPosition?.displayName || '',
          seniority: seniorityDisplayName || '',
          capacity: matchedCapacity || 0
        });
      }
    }
  );
  return resources;
};

export default allocationDataToResourcesTransformer;
