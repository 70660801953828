import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { GenerateInvoiceDto } from 'types/invoice/GenerateInvoiceDto';
import { GenerateInvoiceResponseDto } from 'types/invoice/GenerateInvoiceResponseDto';
import mapToGenerateInvoiceResponseDto from 'store/mappers/project/invoiceGenerateMapper';

const generateInvoiceApiService: IApiService = {
  generateInvoice: async (data: GenerateInvoiceDto) => {
    try {
      const generateInvoiceResponseDto = mapToGenerateInvoiceResponseDto(data);
      return await apiRequest<GenerateInvoiceResponseDto>({
        method: 'POST',
        endpoint: `${API_URL}/billing-periods/${data.billingPeriodId}/invoice`,
        requestBody: generateInvoiceResponseDto
      });
    } catch (error) {
      logError('Error in generateInvoice');
      throw new Error('Error in generateInvoice');
    }
  }
};

interface IApiService {
  generateInvoice: (data: GenerateInvoiceDto) => Promise<GenerateInvoiceResponseDto>;
}

export default generateInvoiceApiService;
