import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import SpinnerButton from 'components/sharedComponents/Spinner/SpinnerButton';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { RootState } from 'store';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import FormAmountPicker from 'components/sharedComponents/FormComponents/FormAmountPicker/FormAmountPicker';
import formatDate from 'helpers/formatDate';
import ButtonsText from 'types/generalEnums/ButtonsText';
import PrpTableComponentNames from 'types/PrpTable/enums/projectTableComponentNames';
import EditSprintLengthFormDto from 'types/PrpTable/dto/EditSprintLengthFormDto';
import { useEffect, useState } from 'react';
import updateSprintLength from 'store/action/projectActions/updateSprintLength';
import getSprintLength from 'store/action/projectActions/getSprintLength';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import EditSprintLengthDefaultValue from 'components/project/ProjectTable/EditSprintLengthForm/EditSprintLengthFormDefaultValue';
import EditSprintLengthFormValidation from 'components/project/ProjectTable/EditSprintLengthForm/EditSprintLengthFormValidation';
import FormTextField from 'components/sharedComponents/FormComponents/FormTextField/FormTextField';
import InputType from 'types/generalEnums/InputType';
import {
  textFildInputPropsHeight,
  textFildInputPropsWidth
} from 'components/allocations/consts/styleConst';

type EditSprintLengthFormProps = {
  startDate: string | null;
  onClose: () => void;
  sprintLengthId: number;
  refreshProjectData: () => void;
};

const EditSprintLengthForm = ({
  startDate,
  onClose,
  sprintLengthId,
  refreshProjectData
}: EditSprintLengthFormProps) => {
  const dispatch = useThunkAppDispatch();
  useEscapeKey(onClose);
  const methods = useForm<EditSprintLengthFormDto>({
    defaultValues: EditSprintLengthDefaultValue,
    // TODO 29679 fix unknown
    resolver: yupResolver(
      EditSprintLengthFormValidation()
    ) as unknown as Resolver<EditSprintLengthFormDto>
  });
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = methods;

  const [sprintLength, setSprintLength] = useState(0);
  const [isSprintLengthLoading, setIsSprintLengthLoading] = useState(false);

  useEffect(() => {
    setIsSprintLengthLoading(true);
    setValue('sprintId', sprintLengthId);

    dispatch(getSprintLength(sprintLengthId))
      .unwrap()
      .then((result) => {
        setSprintLength(result.sprintLength);
        setValue(PrpTableComponentNames.SprintNoteName, result.note);
        setIsSprintLengthLoading(false);
      })
      .catch((error) => {
        logError(error);
      });
  }, [sprintLengthId]);

  const onSubmit: SubmitHandler<EditSprintLengthFormDto> = async (
    data: EditSprintLengthFormDto
  ) => {
    if (data) {
      await dispatch(updateSprintLength(data)).unwrap();
      refreshProjectData();
      onClose();
    }
  };

  const loadingPublicHoliday = useAppSelector(
    (state: RootState) => state.allocationPublicHoliday.saveLoading
  );
  const textFieldInputProps = {
    style: {
      height: textFildInputPropsHeight,
      width: textFildInputPropsWidth
    }
  };
  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className="modal-container">
          <Grid item xs={12} md={12} className="edit-billing-row start-date-row">
            <p className="inter-p3"> Start date </p>
            <p className="inter-p3"> {formatDate(startDate)} </p>
          </Grid>
          <Grid item xs={12} md={12} className="edit-billing-row">
            <p className="inter-p3"> Amount of weeks </p>
            {isSprintLengthLoading ? (
              <SpinnerButton loading={isSprintLengthLoading} />
            ) : (
              <FormAmountPicker
                register={register}
                required
                value={sprintLength}
                name={PrpTableComponentNames.EditSprintLengthName}
                error={errors.amount?.message}
              />
            )}
          </Grid>
          <Grid item xs={12} md={12}>
            <p className="inter-p3 label">{PrpTableComponentNames.SprintNoteLabel}</p>
            <FormTextField
              name={PrpTableComponentNames.SprintNoteName}
              type={InputType.Text}
              required={false}
              register={register}
              className="sprint-text-field"
              inputProps={textFieldInputProps}
              error={errors.note?.message}
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.Update}
          isSpinnerButtonAdded
          loading={loadingPublicHoliday}
          isCancelButtonVisible
          submitButtonClassName="add-button"
          onClick={onClose}
          spinnerClassName="spinner-allocation-public-holiday"
          className="add-buttons-row"
        />
      </StyledForm>
    </FormProvider>
  );
};

export default EditSprintLengthForm;
