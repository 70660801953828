import { createAsyncThunk } from '@reduxjs/toolkit';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import SprintLengthAction from 'types/project/enums/SprintLength';
import EditSprintLengthDto from 'types/PrpTable/dto/EditSprintLengthDto';
import sprintLengthApiService from 'api/sprintLengthApiService';

const getSprintLength = createAsyncThunk<
  EditSprintLengthDto,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(SprintLengthAction.UPDATE_SPRINT_LENGTH, async (sprintId: number) => {
  const response = await sprintLengthApiService.getSprintLength(sprintId);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});
export default getSprintLength;
