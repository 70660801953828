enum EmployeeFormComponentNames {
  Position = 'positionId',
  Seniority = 'seniorityId',
  Capacity = 'weeklyCapacity',
  Permissions = 'permissionIds',
  Email = 'email',
  Activity = 'active',
  Access = 'hasSystemAccess',
  ActivityLabel = 'Active',
  InactivityLabel = 'Inactive',
  ActivityTitle = 'Employee Status',
  AccessLabel = 'Yes',
  NoAccessLabel = 'No',
  AccessTitle = 'System access',
  EmailLabel = 'Email',
  PositionLabel = 'Position',
  SeniorityLabel = 'Seniority',
  CapacityLabel = 'Weekly capacity (hours)',
  Name = 'name',
  NameLabel = 'Name'
}

export default EmployeeFormComponentNames;
