import { binGrey } from 'shared/PrpIcon/icons.constants';

const allocationDeletePopupItem = [
  {
    id: 0,
    label: 'Delete',
    icon: binGrey
  }
];

export default allocationDeletePopupItem;
