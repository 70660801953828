import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';

const unfreezeForecastApiService: IApiService = {
  unfreezeForecast: async (billingPeriodId: number) => {
    try {
      const response = await apiRequest<void>({
        method: 'POST',
        endpoint: `${API_URL}/billing-periods/${billingPeriodId}/unfreeze-forecast`
      });
      return response;
    } catch (error) {
      logError('Error in unfreezeForecast');
      throw new Error('Error in unfreezeForecast');
    }
  }
};
interface IApiService {
  unfreezeForecast: (billingPeriodId: number) => Promise<void>;
}
export default unfreezeForecastApiService;
