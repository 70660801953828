const colorsForPicker = [
  '#D3DAE7',
  '#DCD3E7',
  '#FCDEB7',
  '#FCD0B7',
  '#F7E4AF',
  '#F6F7AF',
  '#D1E5B8',
  '#B8E5CB',
  '#AFF1D1',
  '#AFE1F1',
  '#BADEFB',
  '#BAC8FB',
  '#D4D0FF',
  '#E4D0FF',
  '#FACAEF',
  '#FACAD3'
];

export default colorsForPicker;
