import ButtonsText from 'types/generalEnums/ButtonsText';
import ModalWindow from 'modals/ModalWindow';
import { bookingClashFormPadding } from 'constants/styleConstants';
import {
  bookingClashModalHeight,
  bookingClashModalWidth
} from 'components/sharedComponents/StyleConsts/styleConsts';
import StyledClashForm from './StyledClashForm';

type BookingClashFormProps = {
  onClose: () => void;
  onSubmit: () => void;
  header: string;
  body: string;
};

const ErrorConfirmModal = ({ onClose, onSubmit, header, body }: BookingClashFormProps) => {
  return (
    <ModalWindow
      onClose={onClose}
      headerTitle={header}
      style={{
        width: bookingClashModalWidth,
        minHeight: bookingClashModalHeight,
        padding: bookingClashFormPadding
      }}
    >
      <StyledClashForm>
        <p className="warning-text">{body}</p>
        <div className="submit-buttons-row">
          <button type="button" onClick={onSubmit} className="add-button">
            <p className="inter-caption"> {ButtonsText.Confirm} </p>
          </button>
          <button type="button" onClick={onClose} className="cancel-button">
            <p className="inter-caption">{ButtonsText.Cancel}</p>
          </button>
        </div>
      </StyledClashForm>
    </ModalWindow>
  );
};

export default ErrorConfirmModal;
