import createTheme from '@mui/material/styles/createTheme';
import { BORDERS_COLOR, PRIMARY_COLOR, SURFACE_COLOR, TEXT_COLOR } from 'constants/colors';
import { GREY_TWO_FIVE } from 'constants/groupColors';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: BORDERS_COLOR
    },
    secondary: {
      main: BORDERS_COLOR
    }
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          '& .MuiSwitch-switchBase': {
            '&.Mui-checked': {
              color: SURFACE_COLOR,
              '& + .MuiSwitch-track': {
                backgroundColor: TEXT_COLOR,
                opacity: 1
              }
            }
          },
          '& .MuiSwitch-track': {
            backgroundColor: GREY_TWO_FIVE,
            opacity: 1
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          margin: '5px 10px 5px 10px',
          '&.Mui-selected': {
            backgroundColor: BORDERS_COLOR,
            color: TEXT_COLOR,
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: BORDERS_COLOR
            }
          },
          '&:focus': {
            outline: 'none',
            boxShadow: 'none'
          },
          '&:hover': {
            backgroundColor: 'none'
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: TEXT_COLOR,
          '&:focus': {
            backgroundColor: BORDERS_COLOR
          }
        },
        input: {
          fontSize: '15px'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '15px'
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.MuiButtonBase-root.MuiRadio-root.Mui-checked': {
            color: TEXT_COLOR
          }
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: PRIMARY_COLOR,
          height: 3
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: PRIMARY_COLOR,
            fontWeight: '700'
          }
        }
      }
    }
  }
});

export default theme;
