import { createAsyncThunk } from '@reduxjs/toolkit';
import allocationsPublicHolidayApiService from 'api/allocationPublicHolidayApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import AllocationPublicHolidayDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayDto';
import AllocationPublicHolidayFormDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayFormDto';
import AllocationsAction from 'types/allocation/enums/Allocations';

const updatePublicHoliday = createAsyncThunk<
  AllocationPublicHolidayDto,
  AllocationPublicHolidayFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(AllocationsAction.FETCH_PUBLIC_HOLIDAY, async (data: AllocationPublicHolidayFormDto) => {
  const response = await allocationsPublicHolidayApiService.updatePublicHoliday(data);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default updatePublicHoliday;
