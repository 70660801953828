import { useMemo, useState } from 'react';
import formatDate from 'helpers/formatDate';
import getPrpTableCellClass from 'helpers/getPrpTableCellClass';
import {
  addAdditionalCostModalHeight,
  deleteInvoiceModalHeight,
  invoiceStatusModalHeight,
  invoiceStatusModalWidth
} from 'components/sharedComponents/StyleConsts/styleConsts';
import ModalWindow from 'modals/ModalWindow';
import {
  formatToUsd,
  getCurrencyIfValueExistOrPlaceholder,
  getHoursIfValueExistOrPlaceholder
} from 'helpers/formatCurrencyAndHours';
import IntensityEnum from 'types/PrpTable/enums/intensityEnum';
import { addGrey, iconComment } from 'shared/PrpIcon/icons.constants';
import { InvoiceSummaryDto } from 'types/project/dto/InvoiceSummaryDto';
import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import { PreInvoiceSummaryDto } from 'types/project/dto/PreinvoiceSummaryDto';
import { InvoiceDictionariesDto, InvoiceStatus } from 'types/invoice/InvoiceDictionariesDto';
import { useAppSelector } from 'store/redux-hooks/hooks';
import { RootState } from 'store';
import PermissionsName from 'types/generalEnums/PermissionsName';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import AddEditAdditionalCostForm from 'components/project/ProjectDetails/InvoiceDetails/AdditionalCost/AddEditAdditionalCostForm';
import DeleteAdditionalCostForm from 'components/project/ProjectDetails/InvoiceDetails/AdditionalCost/DeleteAdditionalCostForm';
import { getInvoiceTitleOrTotalsTitle } from 'components/project/ProjectTable/PreInvoice/InvoiceTable';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import BillingType from 'types/project/enums/BillingType';
import InvoiceType from 'types/project/enums/InvoiceType';
import StyledInvoiceDetails from './StyledInvoiceDetails';
import InvoiceStatusForm from './InvoiceStatusForm/InvoiceStatusForm';

type InvoiceSectionProps = {
  title: string;
  subTitle: string | null;
  rightSection: {
    leftDescription: string | null;
    duration: number | null;
    cost: number;
    intensity: IntensityEnum | null;
    className?: string;
  }[];
};

const InvoiceSection = ({ title, subTitle, rightSection }: InvoiceSectionProps) => {
  return (
    <div className="invoice-section-wrapper">
      <div className="section-left-column">
        <p className="section-title">{title}</p>
        <p className="section-subtitle">{subTitle}</p>
      </div>
      <div className="section-right-column">
        {rightSection.map((section) => (
          <div className="section-right-row">
            <p className="inter-caption_medium left-description">{section.leftDescription}</p>
            <div className="section-right-details">
              <div
                className={`${section.className ?? ''} ${getPrpTableCellClass({
                  intensity: section.intensity ?? undefined
                })}`}
              >
                <p className="inter-caption_medium text-right duration">
                  {getHoursIfValueExistOrPlaceholder(section.duration)}
                </p>
                <p className="inter-caption_medium text-right invoice-cost">
                  {getCurrencyIfValueExistOrPlaceholder(section.cost)}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

type InvoiceDetailsProps = {
  invoiceId: number | null;
  onMainModalClose: () => void;
  billingType: BillingType;
  preinvoiceSummaryData?: PreInvoiceSummaryDto;
  invoiceSummaryData?: InvoiceSummaryDto;
  invoiceDictionariesState: InvoiceDictionariesDto;
  refetchPreInvoiceOrInvoiceDetails: (billingPeriodId?: number) => void;
  invoiceType: string;
  billingPeriodId: number;
};

export const getInvoiceStatusName = (status: string | undefined): string => {
  switch (status) {
    case InvoiceStatus.APPROVED:
      return 'Approved';
    case InvoiceStatus.REJECTED:
      return 'Rejected';
    case InvoiceStatus.READY_FOR_APPROVAL:
      return 'Ready For Approval';
    case InvoiceStatus.ISSUED:
      return 'Issued';
    default:
      return 'New';
  }
};

export const getInvoiceStatusClassName = (status: string | undefined) => {
  switch (status) {
    case InvoiceStatus.APPROVED:
      return 'greenCircle';
    case InvoiceStatus.REJECTED:
      return 'redCircle';
    case InvoiceStatus.READY_FOR_APPROVAL:
      return 'orangeCircle';
    case InvoiceStatus.ISSUED:
      return 'blackCircle';
    default:
      return 'greyCircle';
  }
};

const InvoiceDetails = ({
  invoiceId,
  billingType,
  preinvoiceSummaryData,
  invoiceSummaryData,
  invoiceDictionariesState,
  onMainModalClose,
  refetchPreInvoiceOrInvoiceDetails,
  invoiceType,
  billingPeriodId
}: InvoiceDetailsProps) => {
  const [isEditInvoiceStatusModalVisible, setIsEditInvoiceStatusModalVisible] = useState(false);
  const [isTimeOffTooltipOpen, setIsTimeOffTooltipOpen] = useState(false);
  const [isAdditionalCostModalOpen, setIsAdditionalCostModalOpen] = useState(false);
  const [isDeleteAdditionalCostModalOpen, setIsDeleteAdditionalCostModalOpen] = useState(false);
  const [isEditModeVisible, setIsEditModeVisible] = useState(false);
  const isPreinvoice = billingType === BillingType.FORECAST;
  const doesDifferenceExist = !!preinvoiceSummaryData?.differenceForPreviousBillingPeriod;
  const doDiscountsExist = !!preinvoiceSummaryData?.discountsForPreviousBillingPeriod;
  const doesPreDiscountsExist = !!preinvoiceSummaryData?.invoiceTotal?.preDiscountCost;
  const user = useAppSelector((state: RootState) => state.auth.user);

  const canChangeInvoiceStatus = user?.permissions.some(
    (permission) =>
      permission.name === PermissionsName.ProjectManager ||
      permission.name === PermissionsName.IssueInvoices ||
      permission.name === PermissionsName.ApproveInvoices
  );

  const preinvoiceGetSafeDate = (date: string | undefined) => {
    return date || '';
  };
  const preinvoiceGetCostOrDuration = (data: number | undefined) => {
    return data || 0;
  };
  const currentStatus = isPreinvoice
    ? preinvoiceSummaryData?.invoiceTotal.invoiceStatus
    : invoiceSummaryData?.invoiceTotal.invoiceStatus;

  const billingPeriodStart = preinvoiceGetSafeDate(
    isPreinvoice
      ? preinvoiceSummaryData?.billingPeriodStartDate
      : invoiceSummaryData?.billingPeriodStartDate
  );
  const billingPeriodEnd = preinvoiceGetSafeDate(
    isPreinvoice
      ? preinvoiceSummaryData?.billingPeriodEndDate
      : invoiceSummaryData?.billingPeriodEndDate
  );
  const nextBillingPeriodStart = preinvoiceGetSafeDate(
    preinvoiceSummaryData?.forecastForNextBillingPeriod?.startDate
  );
  const nextBillingPeriodEnd = preinvoiceGetSafeDate(
    preinvoiceSummaryData?.forecastForNextBillingPeriod?.endDate
  );
  const invoiceTotalsStartDate = isPreinvoice
    ? preinvoiceGetSafeDate(preinvoiceSummaryData?.invoiceTotal.startDate)
    : preinvoiceGetSafeDate(invoiceSummaryData?.invoiceTotal.startDate);
  const invoiceTotalsEndDate = isPreinvoice
    ? preinvoiceGetSafeDate(preinvoiceSummaryData?.invoiceTotal.endDate)
    : preinvoiceGetSafeDate(invoiceSummaryData?.invoiceTotal.endDate);
  const previousBillingPeriodStart = preinvoiceGetSafeDate(
    preinvoiceSummaryData?.differenceForPreviousBillingPeriod?.startDate
  );
  const previousBillingPeriodEnd = preinvoiceGetSafeDate(
    preinvoiceSummaryData?.differenceForPreviousBillingPeriod?.endDate
  );
  const invoiceTotal = preinvoiceGetCostOrDuration(
    isPreinvoice
      ? preinvoiceSummaryData?.invoiceTotal.invoiceCost
      : invoiceSummaryData?.invoiceTotal.invoiceCost
  );

  const firstSectionDuration = preinvoiceGetCostOrDuration(
    isPreinvoice
      ? preinvoiceSummaryData?.forecastForNextBillingPeriod?.duration
      : invoiceSummaryData?.actualsDuration
  );
  const firstSectionCost = preinvoiceGetCostOrDuration(
    isPreinvoice
      ? preinvoiceSummaryData?.forecastForNextBillingPeriod?.cost
      : invoiceSummaryData?.actualsCost
  );

  const forecastDuration = preinvoiceGetCostOrDuration(
    preinvoiceSummaryData?.differenceForPreviousBillingPeriod?.forecastDuration
  );
  const forecastCost = preinvoiceGetCostOrDuration(
    isPreinvoice
      ? preinvoiceSummaryData?.differenceForPreviousBillingPeriod?.forecastCost
      : invoiceSummaryData?.actualsCost
  );
  const actualsDuration = preinvoiceGetCostOrDuration(
    preinvoiceSummaryData?.differenceForPreviousBillingPeriod?.actualsDuration
  );
  const actualsCost = preinvoiceGetCostOrDuration(
    preinvoiceSummaryData?.differenceForPreviousBillingPeriod?.actualsCost
  );
  const differenceDuration = preinvoiceGetCostOrDuration(
    preinvoiceSummaryData?.differenceForPreviousBillingPeriod?.differenceDuration
  );
  const differenceCost = preinvoiceGetCostOrDuration(
    preinvoiceSummaryData?.differenceForPreviousBillingPeriod?.differenceCost
  );
  const discountDuration = preinvoiceGetCostOrDuration(
    isPreinvoice
      ? preinvoiceSummaryData?.discountsForPreviousBillingPeriod?.discountDuration
      : invoiceSummaryData?.discountDuration
  );
  const discountCost = preinvoiceGetCostOrDuration(
    isPreinvoice
      ? preinvoiceSummaryData?.discountsForPreviousBillingPeriod?.discountCost
      : invoiceSummaryData?.discountCost
  );
  const doesInvoiceNoteExist = useMemo(() => {
    return isPreinvoice
      ? !!preinvoiceSummaryData?.invoiceTotal.notes
      : !!invoiceSummaryData?.invoiceTotal.notes;
  }, [preinvoiceSummaryData, invoiceSummaryData]);
  const noteText = useMemo(() => {
    return isPreinvoice
      ? preinvoiceSummaryData?.invoiceTotal.notes
      : invoiceSummaryData?.invoiceTotal.notes;
  }, [preinvoiceSummaryData, invoiceSummaryData]);
  useEscapeKey(() => {
    if (isEditInvoiceStatusModalVisible) {
      setIsEditInvoiceStatusModalVisible(false);
    } else if (isDeleteAdditionalCostModalOpen) {
      setIsDeleteAdditionalCostModalOpen(false);
    } else if (isAdditionalCostModalOpen) {
      setIsAdditionalCostModalOpen(false);
    } else {
      onMainModalClose();
    }
  });
  const invoiceStatusElement = () => {
    return (
      <div className="invoice-status-wrapper">
        {doesInvoiceNoteExist && (
          <CustomTooltip
            onClose={() => setIsTimeOffTooltipOpen(false)}
            onOpen={() => setIsTimeOffTooltipOpen(true)}
            isTooltipOpen={isTimeOffTooltipOpen}
            title={<div className="note-text">{noteText}</div>}
            placement="bottom"
            icon={iconComment}
          />
        )}
        <div className={getInvoiceStatusClassName(currentStatus)} />
        {getInvoiceStatusName(currentStatus)}
      </div>
    );
  };
  const onInvoiceStatusFormClose = (isFormSubmitted: boolean) => {
    if (isFormSubmitted) {
      refetchPreInvoiceOrInvoiceDetails(billingPeriodId);
      onMainModalClose();
    }
    setIsEditInvoiceStatusModalVisible(false);
  };

  const onAddAdditionalCostFormClose = (isFormSubmitted: boolean) => {
    if (isFormSubmitted) {
      refetchPreInvoiceOrInvoiceDetails(billingPeriodId);
    }
    setIsAdditionalCostModalOpen(false);
  };

  const onEditAdditionalCostButtonClick = () => {
    setIsEditModeVisible(true);
    setIsAdditionalCostModalOpen(true);
  };

  const onDeleteAdditionalCostFormClose = (isFormSubmitted: boolean) => {
    if (isFormSubmitted) {
      refetchPreInvoiceOrInvoiceDetails(billingPeriodId);
    }
    setIsDeleteAdditionalCostModalOpen(false);
  };

  const isAddAdditionalCostButtonVisible = !isPreinvoice
    ? invoiceSummaryData?.invoiceTotal.additionalCostAmount === null
    : preinvoiceSummaryData?.invoiceTotal.additionalCostAmount === null;
  const isAdditionalCostInfoVisible = !isPreinvoice
    ? invoiceSummaryData?.invoiceTotal.additionalCostAmount !== null &&
      invoiceSummaryData?.invoiceTotal.additionalCostAmount !== 0
    : preinvoiceSummaryData?.invoiceTotal.additionalCostAmount !== null &&
      preinvoiceSummaryData?.invoiceTotal.additionalCostAmount !== 0;

  const additionalCostInfo = {
    additionalCostAmount:
      (!isPreinvoice
        ? invoiceSummaryData?.invoiceTotal.additionalCostAmount
        : preinvoiceSummaryData?.invoiceTotal.additionalCostAmount) || 0,
    additionalCostNotes:
      (!isPreinvoice
        ? invoiceSummaryData?.invoiceTotal.additionalCostNotes
        : preinvoiceSummaryData?.invoiceTotal.additionalCostNotes) || '',
    invoiceId: invoiceId || 0
  };

  const getInvoiceStatusTitle = () => {
    if (isPreinvoice) {
      if (invoiceType === InvoiceType.CORRECTION_INVOICE) {
        return 'Change Correction Invoice Status';
      }
      return 'Change Preinvoice Status';
    }
    return 'Change Invoice Status';
  };

  return (
    <StyledInvoiceDetails>
      <div className="invoice-bp-time">
        Billing Period:
        <p className="bp-date">
          {formatDate(billingPeriodStart)} - {formatDate(billingPeriodEnd, '', true)}
        </p>
      </div>
      <InvoiceSection
        title={isPreinvoice ? 'Forecast for the next billing period' : 'Billing period Actuals'}
        subTitle={
          isPreinvoice
            ? `${formatDate(nextBillingPeriodStart)} - ${formatDate(
                nextBillingPeriodEnd,
                '',
                true
              )}`
            : null
        }
        rightSection={[
          {
            leftDescription: null,
            duration: firstSectionDuration,
            cost: firstSectionCost,
            intensity: null,
            className: 'bold-text-duration'
          }
        ]}
      />
      {isPreinvoice && doesPreDiscountsExist && (
        <InvoiceSection
          title="Pre-discount"
          subTitle={`${formatDate(billingPeriodStart)} - ${formatDate(billingPeriodEnd, '', true)}`}
          rightSection={[
            {
              leftDescription: null,
              duration: preinvoiceSummaryData?.invoiceTotal?.preDiscountDuration ?? 0,
              cost: preinvoiceSummaryData?.invoiceTotal?.preDiscountCost ?? 0,
              intensity: IntensityEnum.BETWEEN_5_AND_10,
              className: 'bold-red-duration'
            }
          ]}
        />
      )}
      {isPreinvoice && doesDifferenceExist && (
        <InvoiceSection
          title="Difference for the previous billing period"
          subTitle={`${formatDate(previousBillingPeriodStart)} - ${formatDate(
            previousBillingPeriodEnd,
            '',
            true
          )}`}
          rightSection={[
            {
              leftDescription: 'Forecast',
              duration: forecastDuration,
              cost: forecastCost,
              intensity: null
            },
            {
              leftDescription: 'Actuals',
              duration: actualsDuration,
              cost: actualsCost,
              intensity: null
            },
            {
              leftDescription: 'Difference',
              duration: differenceDuration,
              cost: differenceCost,
              intensity: IntensityEnum.BETWEEN_5_AND_10,
              className: differenceDuration > 0 ? 'bold-green-duration' : 'bold-red-duration'
            }
          ]}
        />
      )}
      {doDiscountsExist && (
        <InvoiceSection
          title={
            isPreinvoice ? 'Discounts for the previous billing period' : 'Billing Period Discounts'
          }
          subTitle={
            isPreinvoice
              ? `${formatDate(previousBillingPeriodStart)} - ${formatDate(
                  previousBillingPeriodEnd,
                  '',
                  true
                )}`
              : `${formatDate(billingPeriodStart)} - ${formatDate(billingPeriodEnd, '', true)}`
          }
          rightSection={[
            {
              leftDescription: null,
              duration: discountDuration,
              cost: discountCost,
              intensity: IntensityEnum.BETWEEN_5_AND_10,
              className: 'bold-red-duration'
            }
          ]}
        />
      )}

      {isAddAdditionalCostButtonVisible && (
        <div className="additional-cost-section-wrapper">
          <button
            className="adding-button"
            type="button"
            onClick={() => setIsAdditionalCostModalOpen(true)}
          >
            <PrpIcon src={addGrey} />
            <p>Add Additional Costs</p>
          </button>
        </div>
      )}
      {/* TODO: will be done in 35875 and 35876 */}
      {isAdditionalCostInfoVisible && (
        <div className="invoice-section-wrapper">
          <div className="section-left-column">
            <div>
              <p className="section-title">Additional costs</p>
              <p className="section-subtitle">
                {formatDate(invoiceTotalsStartDate)} - {formatDate(invoiceTotalsEndDate, '', true)}
              </p>
            </div>
            <div className="additional-cost-details">
              <p className="section-title">Details</p>
              <p className="section-subtitle">
                {invoiceSummaryData?.invoiceTotal.additionalCostNotes ||
                  preinvoiceSummaryData?.invoiceTotal.additionalCostNotes}
              </p>
            </div>
            <div className="additional-cost-buttons">
              <button
                type="button"
                className="edit-button"
                onClick={onEditAdditionalCostButtonClick}
              >
                Edit
              </button>
              <button
                type="button"
                className="delete-button"
                onClick={() => setIsDeleteAdditionalCostModalOpen(true)}
              >
                Delete
              </button>
            </div>
          </div>
          <div className="section-right-column">
            <div className="section-right-row">
              <div className="section-right-details additional-cost">
                <div className="bold-text-duration">
                  <p className="inter-caption_medium text-right duration">
                    $
                    {Number(
                      invoiceSummaryData?.invoiceTotal.additionalCostAmount ||
                        preinvoiceSummaryData?.invoiceTotal.additionalCostAmount
                    ).toFixed(2)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="invoice-section-wrapper invoice-summary-container">
        <div className="section-left-column">
          <p className="invoice-summary-title bold">
            {getInvoiceTitleOrTotalsTitle(invoiceType, isPreinvoice, true)}
          </p>
          <p className="section-subtitle">
            {formatDate(invoiceTotalsStartDate)} - {formatDate(invoiceTotalsEndDate, '', true)}
          </p>
        </div>
        <div className="section-right-column">
          <div className="section-right-row">
            {canChangeInvoiceStatus ? (
              <button
                type="button"
                className="invoice-status-button"
                onClick={() => setIsEditInvoiceStatusModalVisible(true)}
              >
                {invoiceStatusElement()}
              </button>
            ) : (
              invoiceStatusElement()
            )}
            <div className="section-right-details">
              <p className="invoice-total-amount">{formatToUsd(invoiceTotal)}</p>
            </div>
          </div>
        </div>
      </div>
      {isEditInvoiceStatusModalVisible && (
        <ModalWindow
          onClose={() => setIsEditInvoiceStatusModalVisible(false)}
          headerTitle={getInvoiceStatusTitle()}
          style={{
            width: invoiceStatusModalWidth,
            height: invoiceStatusModalHeight
          }}
        >
          <InvoiceStatusForm
            invoiceId={invoiceId}
            currentStatus={currentStatus || ''}
            statuses={invoiceDictionariesState.permittedStatuses}
            onClose={onInvoiceStatusFormClose}
          />
        </ModalWindow>
      )}
      {isAdditionalCostModalOpen && invoiceId && (
        <ModalWindow
          onClose={() => setIsAdditionalCostModalOpen(false)}
          headerTitle={isEditModeVisible ? 'Edit Additional Costs' : 'Add Additional Costs'}
          style={{
            width: invoiceStatusModalWidth,
            height: addAdditionalCostModalHeight
          }}
        >
          <AddEditAdditionalCostForm
            onClose={onAddAdditionalCostFormClose}
            editMode={isEditModeVisible}
            additionalCostInfo={additionalCostInfo}
          />
        </ModalWindow>
      )}
      {isDeleteAdditionalCostModalOpen && (
        <ModalWindow
          onClose={() => setIsDeleteAdditionalCostModalOpen(false)}
          headerTitle="Delete Additional Costs"
          style={{
            width: invoiceStatusModalWidth,
            height: deleteInvoiceModalHeight
          }}
        >
          <DeleteAdditionalCostForm
            onClose={onDeleteAdditionalCostFormClose}
            invoiceId={invoiceId}
          />
        </ModalWindow>
      )}
    </StyledInvoiceDetails>
  );
};
export default InvoiceDetails;
