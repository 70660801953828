import { ClientFormDto } from 'types/clients/dto/ClientFormDto';
import { ClientsDto } from 'types/clients/dto/ClientsDto';

const mapToClientDto = (data: ClientFormDto): ClientsDto => {
  return {
    id: data.id ?? null,
    name: data.name ?? '',
    contactName: data.contactName ?? '',
    contactPosition: data.contactPosition ?? '',
    contactPhone: data.contactPhone ?? '',
    contactEmail: data.contactEmail ?? '',
    colorHex: data.colorHex ?? '',
    projects: data.projects
  } as ClientsDto;
};

export default mapToClientDto;
