enum AllocationsFormComponentNames {
  PeopleRecourses = 'employeeId',
  PeopleRecoursesLabel = 'People / Resources',
  HoursWeekly = 'hours',
  HoursWeeklyLabel = 'Hours',
  MinutesWeekly = 'minutes',
  MinutesWeeklyLabel = 'Minutes',
  StartDate = 'startDate',
  FromDateLabel = 'From',
  EndDate = 'endDate',
  ToDateLabel = 'To',
  Project = 'projectId',
  ProjectLabel = 'Project',
  BookingDetails = 'details',
  BookingDetailsLabel = 'Details',
  Tentative = 'tentative',
  TentativeLabel = 'Tentative',
  Confirmed = 'confirmed',
  ConfirmedLabel = 'Confirmed',
  Type = 'type',
  TypeLabel = 'Type',
  TimeOffDetails = 'details',
  TimeOffDetailsLabel = 'Details',
  AutoDiscount = 'isAutoDiscountApplied',
  AutoDiscountLabel = 'Apply auto-discount',
  AutoDiscountDuration = 'autoDiscountDuration',
  AutoDiscountDurationLabel = 'Discount Hours'
}
export default AllocationsFormComponentNames;
