import AllocationPublicHolidayFormDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayFormDto';

const AllocationPublicHolidayDefaultValue: AllocationPublicHolidayFormDto = {
  id: null,
  date: '',
  details: '',
  dbid: null
};

export default AllocationPublicHolidayDefaultValue;
