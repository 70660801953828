import styled from 'styled-components';
import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { BACKGROUND_COLOR, ON_PRIMARY_COLOR, TEXT_COLOR } from 'constants/colors';

// Styled component for a main div element of LeftsideNavBar component
const StyledLeftSideNavBar = styled.div({
  zIndex: '1',
  backgroundColor: BACKGROUND_COLOR,
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  color: 'palevioletred',
  maxWidth: '220px',
  borderRight: 'solid 1px #dadaea',
  height: '100vh',
  '.nav-list': {
    marginTop: '44px',
    height: '210px',
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'space-between',
    li: {
      padding: '8px',
      height: '36px',
      display: 'flex',
      gap: '12px',
      alignItems: 'center',
      listStyleType: 'none',
      color: 'black',
      borderRadius: borderRadiusCommon
    }
  },
  '.hide-button-wrapper': {
    position: 'fixed',
    bottom: '10px',
    left: '15px',
    '.hide-button': {
      display: 'flex',
      alignItems: 'center',
      background: 'none',
      border: 'none',
      cursor: 'pointer'
    }
  },
  '.nav-link': {
    textDecoration: 'none',
    color: TEXT_COLOR,
    display: 'flex',
    gap: '12px',
    cursor: 'pointer',
    padding: '8px'
  },
  '.active-link': {
    color: ON_PRIMARY_COLOR,
    backgroundColor: TEXT_COLOR,
    '.bar-link-title': {
      color: ON_PRIMARY_COLOR,
      margin: '0',
      alignSelf: 'center'
    }
  },
  '.bar-icon': {
    alignSelf: 'center'
  },
  '.hide-button-icon': {
    marginRight: '12px'
  }
});

export default StyledLeftSideNavBar;
