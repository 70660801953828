import { FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { LOW_EMPHASISE_COLOR } from 'constants/colors';
import { useEffect } from 'react';
import { Control, Controller, useFormContext } from 'react-hook-form';
import { ProjectDictionariesDto } from 'types/project/dto/ProjectDictionariesDto';
import ProjectFormDto from 'types/project/dto/ProjectFormDto';
import ProjectFormComponentNames from 'types/project/enums/ProjectFormComponentNames';

type FormRadioButtonsProps = {
  dictionaries: ProjectDictionariesDto;
  control: Control<ProjectFormDto>;
  name: keyof ProjectFormDto;
  label: string;
  className?: string;
  disabled?: boolean;
  setDependentTextInput: (value: boolean) => void;
  radioButtonIdForDependentInput: number;
};

const FormRadioButtons = ({
  name,
  dictionaries,
  label,
  control,
  className,
  disabled = false,
  setDependentTextInput,
  radioButtonIdForDependentInput
}: FormRadioButtonsProps) => {
  const getDisplayNameById = (id: number) => {
    const billingPeriodType = dictionaries.billingPeriodTypes.find((type) => type.id === id);
    return billingPeriodType?.displayName || '';
  };
  const { setValue, watch } = useFormContext();
  const billableName = watch(ProjectFormComponentNames.BillableName);

  const handleRadioChange = (value: number) => {
    if (value === radioButtonIdForDependentInput) {
      setDependentTextInput(true);
    } else {
      setDependentTextInput(false);
      setValue(ProjectFormComponentNames.CustomBillingPeriodName, null);
    }
  };

  useEffect(() => {
    if (!billableName) {
      setValue(name, 0);
      handleRadioChange(0);
    }
  }, [billableName, setValue, name]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl>
          <p className="inter-caption_medium" style={{ color: LOW_EMPHASISE_COLOR }}>
            {label}
          </p>
          <RadioGroup
            name={field.name}
            value={field.value}
            className={className}
            onChange={(e) => {
              const valueTarget = parseInt(e.target.value, 10);
              field.onChange(valueTarget);
              handleRadioChange(valueTarget);
            }}
          >
            {dictionaries?.billingPeriodTypes?.map((type) => (
              <FormControlLabel
                key={type.id}
                value={type.id}
                control={<Radio disabled={disabled} />}
                label={getDisplayNameById(type.id)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )}
    />
  );
};
export default FormRadioButtons;
