import { createAsyncThunk } from '@reduxjs/toolkit';
import allocationsPublicHolidayApiService from 'api/allocationPublicHolidayApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import mapToAllocationPublicHolidayDto from 'store/mappers/allocations/allocationPublicHolidayMapper';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import AllocationPublicHoliday from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayDto';
import AllocationPublicHolidayFormDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayFormDto';
import AllocationsAction from 'types/allocation/enums/Allocations';

const addAllocationPublicHoliday = createAsyncThunk<
  AllocationPublicHoliday,
  AllocationPublicHolidayFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(AllocationsAction.ADD_ALLOCATION_PUBLIC_HOLIDAY, async (data: AllocationPublicHolidayFormDto) => {
  const allocationPublicHolidayDto = mapToAllocationPublicHolidayDto(data);
  const response = await allocationsPublicHolidayApiService.addPublicHoliday(
    allocationPublicHolidayDto
  );
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});
export default addAllocationPublicHoliday;
