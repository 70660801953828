import styled from 'styled-components';
import { BACKGROUND_COLOR, BORDERS_COLOR, LOW_EMPHASISE_COLOR } from 'constants/colors';
import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';

const Wrapper = styled.main({
  width: '100%',
  height: '100vh',
  color: 'grey',
  backgroundColor: BACKGROUND_COLOR,
  '.spinner-item': {
    display: 'flex',
    justifyContent: 'center',
    height: 'calc(100vh - 255px)'
  },
  '.wrapper-flex': {
    backgroundColor: BACKGROUND_COLOR,
    display: 'flex',
    paddingBottom: '0px',
    minHeight: '100vh'
  },
  '.main-section': {
    flexGrow: '1',
    '.content-section': {
      backgroundColor: BACKGROUND_COLOR,
      display: 'flex',
      width: '100%',
      position: 'relative'
    },
    '.main-section-details': {
      minHeight: '410px',
      maxHeight: 'auto'
    }
  },
  '.project-section-spinner': {
    height: 'calc(100vh - 180px)'
  },
  '.allocation-section': {
    padding: '20px',
    width: '1370px'
  },
  '.allocation-mainscreen': {
    backgroundColor: BACKGROUND_COLOR
  },
  '.filter-buttons': {
    display: 'flex'
  },
  '.clear-filters-button': {
    display: 'flex',
    padding: '8px',
    width: '130px',
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    p: {
      marginLeft: '-2px',
      marginRight: 'auto',
      marginTop: '6px',
      color: LOW_EMPHASISE_COLOR
    }
  },
  '.allocations-button': {
    width: '200px',
    background: 'none',
    border: '1px solid',
    cursor: 'pointer',
    borderRadius: borderRadiusCommon,
    padding: '10px',
    marginBottom: '15px',
    p: {
      margin: 0
    }
  },
  '.mbsc-eventcalendar': {
    maxHeight: 'calc(100vh - 245px)',
    maxWidth: '1350px',
    borderRadius: borderRadiusCommon,
    borderTop: 'solid 1px',
    borderLeft: 'solid 1px',
    borderRight: 'solid 1px',
    borderColor: BORDERS_COLOR,
    '& .mbsc-calendar-controls': {
      backgroundColor: BACKGROUND_COLOR
    },
    '& .mbsc-timeline-column': {
      width: '30px'
    },
    '& .mbsc-timeline-header-column': {
      width: '30px'
    },
    '& .mbsc-windows.mbsc-calendar-wrapper-fixed': {
      borderBottom: 'solid 3px',
      borderColor: BORDERS_COLOR
    },
    '& .mbsc-timeline-header-week-text': {
      padding: '0',
      marginLeft: '3px',
      fontSize: '13px',
      color: LOW_EMPHASISE_COLOR
    },
    '& .mbsc-timeline-header-date-text': {
      fontSize: '13px'
    }
  },
  '.allocation-filtering-popup': {
    '.title': {
      width: '41px',
      margin: 0
    }
  },
  '.window-with-tabs': {
    '.btn-close': {
      right: '45px'
    }
  },
  '.removing-button': {
    display: 'flex',
    alignItems: 'center',
    background: 'none',
    gap: '10px',
    border: 'none',
    ':hover': {
      cursor: 'pointer'
    },
    zIndex: '1'
  },
  '.delete-modal': {
    '.modal-body': {
      paddingTop: '30vh'
    }
  },
  '.calendar-header': {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  '.mbsc-windows.mbsc-calendar-button.mbsc-button.mbsc-font': {
    '.mbsc-icon>svg': {
      marginTop: '5px'
    }
  },

  '.mbsc-timeline-event': {
    paddingTop: '3px'
  }
});

export default Wrapper;
