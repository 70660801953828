import { useCallback } from 'react';
import Initials from 'components/sharedComponents/Initials/Initials';
import { useNavigate } from 'react-router-dom';
import RoutingPath from 'routes/routing';
import { RootState } from 'store';
import selectPositionPropertyById from 'store/dictionarySelectors/selectorPosition';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import { useAppDispatch, useAppSelector } from 'store/redux-hooks/hooks';
import { selectEmployee } from 'store/slices/employee/employeeSlice';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import StyledItemCard from 'components/sharedComponents/SharedItemCardStyle/StyledItemCard';
import selectSeniorityPropertyById from 'store/dictionarySelectors/selectorSeniority';

type EmployeeCardProps = {
  isSelected?: boolean;
  item: EmployeeDto;
  itemId: number;
};

const EmployeeCard = ({ isSelected, itemId, item }: EmployeeCardProps): JSX.Element => {
  const navigation = useNavigate();
  const stateEmployee = useAppSelector((state: RootState) => state.dictionaries);
  const dispatch = useAppDispatch();

  const cardClickHandler = useCallback(() => {
    navigation(`${RoutingPath.EmployeesPage}/${itemId}`);
    dispatch(selectEmployee(itemId));
  }, [navigation]);

  const cardInactive = item?.active ? {} : { opacity: '0.6' };
  const positionDisplayName = selectPositionPropertyById(
    stateEmployee,
    item?.positionId,
    DictionariesSelector.DisplayName
  );

  const seniorityDisplayName = selectSeniorityPropertyById(
    stateEmployee,
    item?.seniorityId,
    DictionariesSelector.DisplayName
  );

  return (
    <StyledItemCard
      onClick={cardClickHandler}
      style={cardInactive}
      className={`card ${isSelected ? 'selected' : ''}`}
    >
      <Initials name={item?.name || null} />
      <div className="info-box employee-card">
        <h3 className="inter-p3 info-box_text employee-name">{item?.name}</h3>
        <p className="inter-caption_medium info-box_text position">{`${positionDisplayName}, ${seniorityDisplayName}`}</p>
      </div>
      {item?.active === false ? <p className="inter-caption_medium inactive">Inactive</p> : null}
    </StyledItemCard>
  );
};

export default EmployeeCard;
