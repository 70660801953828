import { createAsyncThunk } from '@reduxjs/toolkit';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import EditBillingPeriodDto from 'types/PrpTable/dto/EditBillingPeriodDto';
import EditBillingPeriodFormDto from 'types/PrpTable/dto/EditBillingPeriodFormDto';
import billingApiService from 'api/billingPeriodApiService';
import BillingAction from 'types/project/enums/Billing';

const updateBillingPeriod = createAsyncThunk<
  EditBillingPeriodDto,
  EditBillingPeriodFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(BillingAction.UPDATE_BILLING, async (data: EditBillingPeriodFormDto) => {
  const response = await billingApiService.updateBillingPeriod(data);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});
export default updateBillingPeriod;
