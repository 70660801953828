import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';

const generateForecastApiService: IApiService = {
  generateForecast: async (billingPeriodId: number) => {
    try {
      const response = await apiRequest<void>({
        method: 'POST',
        endpoint: `${API_URL}/billing-periods/${billingPeriodId}/generate-forecasts`
      });
      return response;
    } catch (error) {
      logError('Error in generateForecast');
      throw new Error('Error in generateForecast');
    }
  }
};

interface IApiService {
  generateForecast: (billingPeriodId: number) => Promise<void>;
}

export default generateForecastApiService;
