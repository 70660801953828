import StyledMainSpinner from 'components/sharedComponents/Spinner/StyledMainSpinner';

type MainSpinnerProps = {
  style?: React.CSSProperties;
  loading?: boolean;
  className?: string;
};
const MainSpinner = ({ style, loading, className }: MainSpinnerProps) => {
  return loading ? (
    <StyledMainSpinner className={className}>
      <div className="spinner" style={style} />
    </StyledMainSpinner>
  ) : null;
};

export default MainSpinner;
