import { ProjectDictionariesState } from 'store/slicesState/projectDictionaries';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';

const selectprojectManagersPropertyById = (
  state: ProjectDictionariesState,
  id: number,
  property: DictionariesSelector
): string | null => {
  const projectManagersDictionary = state.data?.projectManagers;
  if (!projectManagersDictionary) {
    return null;
  }
  const projectManager = projectManagersDictionary.find((p) => p.id === Number(id));
  return projectManager?.[property] ?? null;
};
export default selectprojectManagersPropertyById;
