import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { Grid } from '@mui/material';
import ButtonsText from 'types/generalEnums/ButtonsText';
import FormDatePicker from 'components/sharedComponents/FormComponents/FormDatePicker/FormDatePicker';
import UploadActualsModalNames from 'types/project/enums/UploadActualsModalNames';
import UploadActualsFormValidation from 'components/project/UploadActuals/UploadActualsFormValidation';
import UploadActualsDto from 'types/project/dto/UploadActualsDto';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import { useEffect, useState } from 'react';
import uploadActuals from 'store/action/projectActions/uploadActuals';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { useThunkAppDispatch } from 'store/redux-hooks/hooks';
import { convertToDateTimeString } from 'helpers/dateUtils';
import OnlyDateFilter from 'types/generalEnums/DatePickerFilterType';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';

type UploadActualsForProps = {
  onClose: () => void;
  actualsUploadedSuccessfully: () => void;
};

const UploadActualsForm = ({ onClose, actualsUploadedSuccessfully }: UploadActualsForProps) => {
  const methods = useForm({
    // TODO fix unknown
    resolver: yupResolver(UploadActualsFormValidation()) as unknown as Resolver<UploadActualsDto>
  });

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = methods;
  const validateDates = () => {
    if (watch('startDate') !== undefined && watch('endDate') !== undefined) {
      const startDateObj = new Date(watch('startDate'));
      const endDateObj = new Date(watch('endDate'));

      const thirtyDaysLater = new Date(startDateObj);
      thirtyDaysLater.setDate(thirtyDaysLater.getDate() + 30);

      if (endDateObj <= thirtyDaysLater) {
        if (endDateObj < startDateObj) setShowErrorMessage(true);
        else setShowErrorMessage(false);
      } else {
        setShowErrorMessage(true);
      }
    } else {
      setShowErrorMessage(false);
    }
  };
  useEffect(() => {
    validateDates();
  }, [watch('startDate'), watch('endDate')]);
  useEscapeKey(onClose);
  const dispatch = useThunkAppDispatch();
  const onSubmit: SubmitHandler<UploadActualsDto> = async () => {
    validateDates();
    try {
      const startDate = convertToDateTimeString(watch('startDate'));
      const endDate = convertToDateTimeString(watch('endDate'));
      await dispatch(uploadActuals({ startDate, endDate }))
        .unwrap()
        .then(() => {
          actualsUploadedSuccessfully();
          onClose();
        });
    } catch (error) {
      logError('Error uploading actuals');
    }
  };
  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className="modal-container">
          <Grid item xs={12} md={12} className="start-date">
            <p className="upload-actuals-modal-subtitle">Select a date range for the import</p>
            <div className="import-dates">
              <FormDatePicker
                name={UploadActualsModalNames.StartDate}
                control={control}
                label={UploadActualsModalNames.StartDateLabel}
                className="actuals-form-date"
                error={errors.startDate?.message}
                filter={OnlyDateFilter.Mondays}
              />
              <FormDatePicker
                name={UploadActualsModalNames.EndDate}
                control={control}
                label={UploadActualsModalNames.EndDateLabel}
                className="actuals-form-date"
                error={errors.endDate?.message}
                filter={OnlyDateFilter.Sundays}
              />
            </div>
            {showErrorMessage ? (
              <p className="error-message">{FormValidationMessage.DateRangeError}</p>
            ) : null}
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.ImportActuals}
          isCancelButtonVisible
          submitButtonClassName="add-button"
          onClick={onClose}
          cancelButtonClassName="cancel-button cancel-upload-button"
          isSubmitButtonDisabled={showErrorMessage}
        />
      </StyledForm>
    </FormProvider>
  );
};

export default UploadActualsForm;
