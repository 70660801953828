import * as yup from 'yup';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';

const AllocationBookingDeleteValidation = () => {
  return yup.object().shape({
    deleteFutureBookings: yup.boolean().required(FormValidationMessage.TypeChoiceError)
  });
};

export default AllocationBookingDeleteValidation;
