import { useCallback, useEffect, useState } from 'react';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import DetailsHeader from 'components/sharedComponents/DetailsHeader/DetailsHeader';
import EmployeesDetailsContent from 'components/employee/EmployeesDeatails/EmployeesDetailsContent';
import EmployeeChangeForm from 'components/employee/EmployeeChangeForm/EmployeeChangeForm';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import ClientDetailsContent from 'components/clients/ClientDetails/ClientDetailsContent';
import PageType from 'types/generalEnums/PageType';
import StyledSharedDetailsComponent from 'components/sharedComponents/SharedDetailsComponent/StyledSharedDetailsComponent';
import PermissionsName from 'types/generalEnums/PermissionsName';
import ClientDescriptions from 'components/clients/ClientDescriptions/ClientDescription';

type SharedDetailsComponentProps = {
  item: EmployeeDto | ClientsDto | null;
  style?: React.CSSProperties;
  type: PageType.Employees | PageType.Clients;
  detailsManagementPermissions: PermissionsName[];
};

const SharedDetailsComponent = ({
  item,
  style,
  type,
  detailsManagementPermissions
}: SharedDetailsComponentProps) => {
  const [showForm, setShowForm] = useState<boolean>(false);
  const [currentItemId, setCurrentItemId] = useState<number | null>(null);

  const onChangeClickHandler = useCallback(() => {
    setShowForm(true);
  }, []);

  const onCancelClickHandler = useCallback(() => {
    setShowForm(false);
  }, []);

  useEffect(() => {
    if (item && item.id !== currentItemId) {
      setShowForm(false);
      setCurrentItemId(item.id);
    }
  }, [item, currentItemId]);

  if (!item) return null;

  return (
    <StyledSharedDetailsComponent style={style}>
      {showForm ? (
        (type === PageType.Employees && (
          <EmployeeChangeForm item={item as EmployeeDto} onCancel={onCancelClickHandler} />
        )) ||
        (type === PageType.Clients && (
          <ClientDescriptions item={item as ClientsDto} onCancel={onCancelClickHandler} />
        ))
      ) : (
        <div>
          {type === PageType.Employees && (
            <div>
              <DetailsHeader
                item={item}
                onChangeClickHandler={onChangeClickHandler}
                type={PageType.Employees}
                detailsManagementPermissions={detailsManagementPermissions}
              />
              <EmployeesDetailsContent item={item as EmployeeDto} />
            </div>
          )}
          {type === PageType.Clients && (
            <div>
              <DetailsHeader
                item={item}
                onChangeClickHandler={onChangeClickHandler}
                type={PageType.Clients}
                detailsManagementPermissions={detailsManagementPermissions}
              />
              <ClientDetailsContent
                item={item as ClientsDto}
                notesManagementPermissions={detailsManagementPermissions}
              />
            </div>
          )}
        </div>
      )}
    </StyledSharedDetailsComponent>
  );
};

export default SharedDetailsComponent;
