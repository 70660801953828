import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { BORDERS_COLOR, LOW_EMPHASISE_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledFormSelectPermissions = styled.div({
  '.permission-chip-row': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  '.permission-chip': {
    marginRight: '5px',
    marginBottom: '5px',
    borderRadius: borderRadiusCommon,
    backgroundColor: BORDERS_COLOR
  },
  '.label': {
    color: LOW_EMPHASISE_COLOR
  }
});

export default StyledFormSelectPermissions;
