import { Grid } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import AllocationFilterDashboardDefaultValue from 'components/allocations/AllocationFilterDashboard/AllocationFilterDashboardDefaultValue';
import AllocationFilterMultiSelect from 'components/allocations/AllocationFilterDashboardItems/AllocationFilterMultiSelect/AllocationFilterMultiSelect';
import StyledAllocationFilterDashboard from 'components/allocations/AllocationFilterDashboard/StyledAllocationFilterDashboard';
import { useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import getAllocationsDictionaries from 'store/action/allocationsAction/getAllocationsDictionaries';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import AllocationsFilterDashboardDto from 'types/allocation/dto/AllocationsFilterDashboardDto';
import AllocationFilterDashboardNames from 'types/allocation/enums/AllocationFilterDashboardNames';
import { DictionaryItemDto } from 'types/employee/enums/DictionaryItemDto';
import AllocationFilterSelect from 'components/allocations/AllocationFilterDashboardItems/AllocationFilterSelect';
import defaultFilteringValue from 'components/allocations/consts/defaultFilteringValue';
import AllocationFilterSelectedName from 'types/allocation/enums/AllocationFilterSelectedName';
import { setAllocationPageParams } from 'store/slices/allocations/allocationsSlice';
import AllocationParamsDto from 'types/allocation/dto/AllocationParamsDto';
import ButtonsText from 'types/generalEnums/ButtonsText';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';

export const getDictionaryName = (
  selected: AllocationFilterSelectedName
): keyof AllocationsFilterDashboardDto => {
  const names: Record<AllocationFilterSelectedName, keyof AllocationsFilterDashboardDto> = {
    [AllocationFilterSelectedName.Employees]: AllocationFilterDashboardNames.AllEmployeeNames,
    [AllocationFilterSelectedName.Clients]: AllocationFilterDashboardNames.ClientNames,
    [AllocationFilterSelectedName.Projects]: AllocationFilterDashboardNames.ProjectNames,
    [AllocationFilterSelectedName.Positions]: AllocationFilterDashboardNames.Positions
  };
  return names[selected];
};

type AllocationFilterDashboardProps = {
  onClose: () => void;
  lastDaySelectedMonth: React.MutableRefObject<string>;
  firstDaySelectedMonth: React.MutableRefObject<string>;
  filterParams?: AllocationParamsDto | null;
  setFilterCategory: (filterCategory: string) => void;
  setFilterCount: (filterCount: number) => void;
  onFinishSubmittingFiltersForm: (
    filterType: string,
    selectedValues: DictionaryItemDto[],
    startDate: string,
    endDate: string
  ) => void;
};

const AllocationFilterDashboard = ({
  onClose,
  lastDaySelectedMonth,
  firstDaySelectedMonth,
  filterParams,
  setFilterCategory,
  setFilterCount,
  onFinishSubmittingFiltersForm
}: AllocationFilterDashboardProps) => {
  const methods = useForm<AllocationsFilterDashboardDto>({
    defaultValues: AllocationFilterDashboardDefaultValue
  });

  const { handleSubmit, control } = methods;

  const dispatch = useThunkAppDispatch();
  useEffect(() => {
    dispatch(getAllocationsDictionaries())
      .then(unwrapResult)
      .catch(() => logError('Error fetching allocationDictionaries'));
  }, []);

  const allocationDictionariesState = useAppSelector(
    (state: RootState) => state.allocationsDictionaries.data
  );
  const [selectedCategory, setSelectedCategory] = useState<number>(
    filterParams?.filter || defaultFilteringValue.id
  );

  const getFilterLabel = (selected: AllocationFilterSelectedName) => {
    const labels = {
      [AllocationFilterSelectedName.Employees]: AllocationFilterDashboardNames.EmployeeLabel,
      [AllocationFilterSelectedName.Clients]: AllocationFilterDashboardNames.Clientlabel,
      [AllocationFilterSelectedName.Projects]: AllocationFilterDashboardNames.ProjectLabel,
      [AllocationFilterSelectedName.Positions]: AllocationFilterDashboardNames.PositionsLabel
    };
    return labels[selected];
  };

  const chosenFilterValues = (
    allocationDictionariesState[getDictionaryName(selectedCategory)] as DictionaryItemDto[]
  )?.filter((filterValue: DictionaryItemDto) => filterParams?.ids?.includes(filterValue.id));

  const [selectedValue, setSelectedValue] = useState<DictionaryItemDto[]>([
    ...(chosenFilterValues || [])
  ]);

  useEffect(() => {
    setFilterCategory(getFilterLabel(selectedCategory));
  }, []);

  const handleCategoryChange = (selected: number) => {
    setFilterCategory(getFilterLabel(selected));
    setSelectedCategory(selected);
    setSelectedValue([]);
  };

  const onSubmit: SubmitHandler<AllocationsFilterDashboardDto> = async (
    data: AllocationsFilterDashboardDto
  ) => {
    try {
      const allocationParms = {
        startDate: firstDaySelectedMonth.current,
        endDate: lastDaySelectedMonth.current,
        filter: data.filterNames,
        ids: selectedValue.map((item) => item.id)
      };
      onFinishSubmittingFiltersForm(
        getFilterLabel(selectedCategory),
        selectedValue,
        firstDaySelectedMonth.current,
        lastDaySelectedMonth.current
      );
      dispatch(setAllocationPageParams(allocationParms));
      onClose();
    } catch (error) {
      logError('Error filtering allocations');
    }
  };
  useEscapeKey(onClose);
  useEffect(() => {
    setFilterCount(selectedValue.length || 0);
  }, [selectedValue]);

  return (
    <FormProvider {...methods}>
      <StyledAllocationFilterDashboard onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className="modal-container">
          <Grid item xs={12} md={12}>
            <AllocationFilterSelect
              className="allocation-category-selector"
              label={AllocationFilterDashboardNames.CategoryLabel}
              title={AllocationFilterDashboardNames.FilterNames}
              control={control}
              options={allocationDictionariesState.filterNames}
              onChange={handleCategoryChange}
              filterParamsValue={filterParams?.filter}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <AllocationFilterMultiSelect
              control={control}
              dictionaries={allocationDictionariesState}
              name={getDictionaryName(selectedCategory)}
              className="allocation-category-selector"
              label={AllocationFilterDashboardNames.SearchLabel}
              selectedValue={selectedValue}
              setSelectedValue={setSelectedValue}
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.Apply}
          isCancelButtonVisible
          submitButtonClassName="button"
          onClick={onClose}
          className="button-row"
        />
      </StyledAllocationFilterDashboard>
    </FormProvider>
  );
};

export default AllocationFilterDashboard;
