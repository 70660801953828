import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import { EmployeeFormDto } from 'types/employee/dto/EmployeeFormDto';

const EmployeeFormDefaultValues = (item: EmployeeDto): EmployeeFormDto => {
  return {
    id: item.id,
    name: item.name ?? '',
    positionId: item.positionId ?? null,
    seniorityId: item.seniorityId ?? null,
    email: item.email ?? '',
    weeklyCapacity: item.weeklyCapacity ?? null,
    active: item.active ?? false,
    hasSystemAccess: item.hasSystemAccess ?? false,
    permissionIds: item.permissionIds ?? [],
    force: item.force ?? false
  };
};

export default EmployeeFormDefaultValues;
