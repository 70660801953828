import { AllocationsDeleteTimeOffDto } from 'types/allocation/dto/allocationTimeOffDto/allocationDeleteTimeOffDto/AllocationDeleteTimeOffDto';
import AllocationDeleteTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/allocationDeleteTimeOffDto/AllocationDeleteTimeOffFormDto';
import { AllocationTimeOffDto } from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffDto';
import AllocationTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffFormDto';

const mapToTimeOffDto = (data: AllocationTimeOffFormDto): AllocationTimeOffDto => {
  return {
    employeeId: data.employeeId ?? null,
    startDate: data.startDate ?? '',
    endDate: data.endDate ?? '',
    type: data.type ?? '',
    details: data.details ?? '',
    id: data.id ?? null
  } as AllocationTimeOffDto;
};

export const mapToDeleteTimeOffDto = (
  data: AllocationDeleteTimeOffFormDto
): AllocationsDeleteTimeOffDto => {
  return {
    timeOffId: data.timeOffId ?? null,
    deleteFutureTimeOffs: data.deleteFutureTimeOffs ?? ''
  } as AllocationsDeleteTimeOffDto;
};

export default mapToTimeOffDto;
