import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { FormProvider, useForm, Controller, SubmitHandler, Resolver } from 'react-hook-form';
import { useThunkAppDispatch, useAppSelector } from 'store/redux-hooks/hooks';
import ButtonsText from 'types/generalEnums/ButtonsText';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import deleteAllocationBooking from 'store/action/allocationsAction/deleteAllocationBooking';
import { AllocationsDeleteBookingFormDto } from 'types/allocation/dto/allocationDeleteBookingDto/AllocationsDeleteBookingFormDto';
import AllocationDeleteFormNames from 'types/allocation/enums/AllocationDeleteFormComponentsName';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import AllocationBookingDeleteValidation from './AllocationBookingDeleteValidation';
import AllocationBookingDeleteFormDefaultValue from './AllocationBookingDeleteDefaultValue';

type AllocationBookingDeleteFormProps = {
  onCloseDeleteWindow: (isSubmitted: boolean) => void;
};

const AllocationBookingDeleteForm = ({ onCloseDeleteWindow }: AllocationBookingDeleteFormProps) => {
  const dispatch = useThunkAppDispatch();
  const dbid = useAppSelector((state: RootState) => state.allocationBooking.data.id);
  useEscapeKey(() => {
    onCloseDeleteWindow(false);
  });
  const methods = useForm({
    defaultValues: AllocationBookingDeleteFormDefaultValue(dbid),
    // TODO fix unknown
    resolver: yupResolver(
      AllocationBookingDeleteValidation()
    ) as unknown as Resolver<AllocationsDeleteBookingFormDto>
  });
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = methods;

  const onSubmit: SubmitHandler<AllocationsDeleteBookingFormDto> = async (
    data: AllocationsDeleteBookingFormDto
  ) => {
    try {
      await dispatch(deleteAllocationBooking(data)).then(() => onCloseDeleteWindow(true));
    } catch (error) {
      logError('Error deleting booking');
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} columns={20} className="modal-container">
          <Grid item xs={16} md={16}>
            <p className="inter-p3 delete-descriotion">
              {AllocationDeleteFormNames.DeleteBookingTitle}
            </p>
          </Grid>
          <Grid item xs={20} md={20}>
            <Controller
              name={AllocationDeleteFormNames.DeleteBookingName}
              control={control}
              render={({ field }) => (
                <FormControl>
                  <RadioGroup
                    name={AllocationDeleteFormNames.DeleteBookingName}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      label={AllocationDeleteFormNames.DeleteBookingFirstLabel}
                      value={false}
                      className="delete-radio-button delete-radio-button-first"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      label={AllocationDeleteFormNames.DeleteBookingSecondLabel}
                      value
                      className="delete-radio-button "
                    />
                  </RadioGroup>
                  {errors && (
                    <span className="error-message">{errors.deleteFutureBookings?.message}</span>
                  )}
                </FormControl>
              )}
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.Delete}
          isCancelButtonVisible
          submitButtonClassName="add-button"
          onClick={() => onCloseDeleteWindow(false)}
        />
      </StyledForm>
    </FormProvider>
  );
};

export default AllocationBookingDeleteForm;
