enum PermissionsName {
  ManageClients = 'MANAGE_CLIENTS',
  ManageEmployees = 'MANAGE_EMPLOYEES',
  ProjectManager = 'PROJECT_MANAGER',
  ManageProjects = 'MANAGE_PROJECTS',
  ManagerAllocations = 'MANAGE_ALLOCATIONS',
  AssignedToProjects = 'ASSIGNED_TO_PROJECTS',
  UnfreezeForecast = 'UNFREEZE_FORECAST',
  ApproveInvoices = 'APPROVE_INVOICES',
  IssueInvoices = 'ISSUE_INVOICES'
}

export default PermissionsName;
