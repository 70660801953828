import { InputBase } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import { ChangeEvent } from 'react';
import { crossGrey, searchGrey } from 'shared/PrpIcon/icons.constants';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import StyledSearchItem from './StyledSearchItem';

type SearchItemProps = {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
  className?: string;
};
const SearchItem = ({ value, onChange, className, onClear }: SearchItemProps) => {
  return (
    <StyledSearchItem className={className}>
      <FormControl>
        <InputBase
          id="input-with-icon-adornment"
          value={value}
          onChange={onChange}
          placeholder="Search"
          className="search-input"
          startAdornment={
            <InputAdornment position="start">
              <PrpIcon src={searchGrey} />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end" onClick={onClear} className="delete-icon">
              <PrpIcon width="25" height="25" src={crossGrey} />
            </InputAdornment>
          }
        />
      </FormControl>
    </StyledSearchItem>
  );
};

export default SearchItem;
