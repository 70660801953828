import SpinnerButton from 'components/sharedComponents/Spinner/SpinnerButton';
import ButtonsText from 'types/generalEnums/ButtonsText';

type CustomModalButtonProps = {
  submitButtonClassName?: string;
  cancelButtonClassName?: string;
  onClick?: () => void;
  submitButtonText: string;
  cancelButtonText?: string;
  isSpinnerButtonAdded?: boolean;
  loading?: boolean;
  isCancelButtonVisible?: boolean;
  spinnerClassName?: string;
  className?: string;
  isSubmitButtonDisabled?: boolean;
};

const CustomModalButtons = ({
  submitButtonClassName,
  submitButtonText,
  cancelButtonClassName = 'cancel-button',
  cancelButtonText = ButtonsText.Cancel,
  onClick,
  isSpinnerButtonAdded,
  loading,
  isCancelButtonVisible,
  spinnerClassName = 'spinner-button-wrap',
  className,
  isSubmitButtonDisabled
}: CustomModalButtonProps) => {
  return (
    <div className={className || 'add-buttons-row'}>
      <button type="submit" disabled={isSubmitButtonDisabled} className={submitButtonClassName}>
        {isSpinnerButtonAdded && loading && (
          <div className={spinnerClassName}>
            <SpinnerButton loading={loading} />
          </div>
        )}
        <p className="inter-caption"> {submitButtonText} </p>
      </button>
      {isCancelButtonVisible && (
        <button type="button" onClick={onClick} className={cancelButtonClassName}>
          <p className="inter-caption"> {cancelButtonText} </p>
        </button>
      )}
    </div>
  );
};

export default CustomModalButtons;
