import AllocationTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffFormDto';

const AllocationTimeOffEditFormDefaultValue = (
  item: AllocationTimeOffFormDto
): AllocationTimeOffFormDto => {
  return {
    id: item.id,
    employeeId: item.employeeId,
    startDate: item.startDate,
    endDate: item.endDate,
    details: item.details,
    type: item.type
  };
};

export default AllocationTimeOffEditFormDefaultValue;
