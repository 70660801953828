import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { Grid } from '@mui/material';
import ProjectFormComponentNames from 'types/project/enums/ProjectFormComponentNames';
import FormTextField from 'components/sharedComponents/FormComponents/FormTextField/FormTextField';
import InputType from 'types/generalEnums/InputType';
import ButtonsText from 'types/generalEnums/ButtonsText';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AddAdditionalCostFormDto } from 'types/project/dto/AddAdditionalCostFormDto';
import AdditionalCostFormDefaultValues from 'components/project/ProjectDetails/InvoiceDetails/AdditionalCost/AdditionalCostFormDefaultValues';
import AdditionalCostFormValidation from 'components/project/ProjectDetails/InvoiceDetails/AdditionalCost/AdditionalCostFormValidation';
import { useThunkAppDispatch } from 'store/redux-hooks/hooks';
import editAdditionalCost from 'store/action/invoiceAction/editAdditionalCost';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import FormInputNumber from 'components/sharedComponents/FormComponents/FormInput/FormInputNumber';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import { useEffect } from 'react';

type AddEditAdditionalCostFormProps = {
  onClose: (isFormSubmitted: boolean) => void;
  editMode?: boolean;
  additionalCostInfo: AddAdditionalCostFormDto;
};

const AddEditAdditionalCostForm = ({
  onClose,
  editMode,
  additionalCostInfo
}: AddEditAdditionalCostFormProps) => {
  const methods = useForm({
    defaultValues: AdditionalCostFormDefaultValues(additionalCostInfo),
    // TODO fix unknown 34916
    resolver: yupResolver(
      AdditionalCostFormValidation()
    ) as unknown as Resolver<AddAdditionalCostFormDto>
  });
  const {
    handleSubmit,
    register,
    getValues,
    watch,
    setValue,
    clearErrors,
    trigger,
    formState: { errors }
  } = methods;
  useEscapeKey(() => onClose(false));
  const dispatch = useThunkAppDispatch();
  const onSubmit: SubmitHandler<AddAdditionalCostFormDto> = async (
    data: AddAdditionalCostFormDto
  ) => {
    try {
      await dispatch(editAdditionalCost(data)).unwrap();
      onClose(true);
    } catch (error) {
      logError('Error updating additional cost');
    }
  };
  useEffect(() => {
    if (watch(ProjectFormComponentNames.AdditionalCostName).toString() === '') {
      clearErrors(ProjectFormComponentNames.AdditionalCostName);
      setValue(ProjectFormComponentNames.AdditionalCostName, 0);
      trigger(ProjectFormComponentNames.AdditionalCostName);
    } else if (watch(ProjectFormComponentNames.AdditionalCostName) > 0) {
      clearErrors(ProjectFormComponentNames.AdditionalCostName);
    }
  }, [watch(ProjectFormComponentNames.AdditionalCostName)]);

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className="modal-container">
          <Grid item xs={12} md={12}>
            <FormInputNumber
              name={ProjectFormComponentNames.AdditionalCostName}
              label={ProjectFormComponentNames.AdditionalCostLabel}
              required
              register={register}
              getValues={getValues}
              error={errors.additionalCostAmount?.message}
              className="invoice-additional-cost-input"
              isDecimal
              minValue={0}
              numberOfDigitsBeforeDecimal={12}
              numberOfDigitsAfterDecimal={2}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormTextField
              name={ProjectFormComponentNames.AdditionalNoteName}
              label={ProjectFormComponentNames.AdditionalNoteLabel}
              type={InputType.Text}
              required
              register={register}
              className="invoice-status-note-input"
              error={errors.additionalCostNotes?.message}
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={editMode ? ButtonsText.SaveChanges : ButtonsText.AddAdditionalCosts}
          isCancelButtonVisible
          submitButtonClassName="add-button"
          onClick={() => onClose(false)}
          className="add-buttons-row"
        />
      </StyledForm>
    </FormProvider>
  );
};

export default AddEditAdditionalCostForm;
