import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import allocationAddBooking from 'store/action/allocationsAction/allocationAddBooking';
import deleteAllocationBooking from 'store/action/allocationsAction/deleteAllocationBooking';
import getOneBooking from 'store/action/allocationsAction/getBooking';
import updateBooking from 'store/action/allocationsAction/updateBooking';
import { AllocationBookingState } from 'store/slicesState/allocationBooking';
import { AllocationsAddBookingDto } from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingDto';

const initialState: AllocationBookingState = {
  data: {
    id: 0,
    hours: 0,
    minutes: 0,
    projectId: 0,
    employeeId: 0,
    startDate: '',
    endDate: '',
    details: '',
    confirmed: false,
    weekId: 0,
    isAutoDiscountApplied: false,
    autoDiscountDuration: 0
  },
  readLoading: false,
  saveLoading: false,
  error: null
};

export type DispatchError = {
  errorMessage: string;
};

const allocationBookingSlice = createSlice({
  name: 'allocationBooking',
  initialState,
  reducers: {
    getBooking: (state, action: PayloadAction<AllocationsAddBookingDto>) => {
      state.data = action.payload;
    },
    updateOneBookig: (state, action: PayloadAction<AllocationsAddBookingDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(allocationAddBooking.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(allocationAddBooking.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(allocationAddBooking.rejected, (state) => {
      state.error = 'addAllocationBooking error in slice';
      state.saveLoading = false;
    });
    builder.addCase(updateBooking.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(updateBooking.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(updateBooking.rejected, (state) => {
      state.error = 'updateBooking error in slice';
      state.saveLoading = false;
    });
    builder.addCase(getOneBooking.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getOneBooking.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.readLoading = false;
    });
    builder.addCase(getOneBooking.rejected, (state) => {
      state.error = 'getBooking error in slice';
      state.readLoading = false;
    });
    builder.addCase(deleteAllocationBooking.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(deleteAllocationBooking.fulfilled, (state) => {
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(deleteAllocationBooking.rejected, (state) => {
      state.error = 'deleteBooking error in slice';
      state.saveLoading = false;
    });
  }
});

export const { getBooking, updateOneBookig } = allocationBookingSlice.actions;
export default allocationBookingSlice.reducer;
