import { closeGrey, saveGrey } from 'shared/PrpIcon/icons.constants';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import StyledFormChangeHeader from 'components/sharedComponents/FormComponents/FormChangeHeader/StyledFormChangeHeader';
import { useAppSelector } from 'store/redux-hooks/hooks';
import { RootState } from 'store';
import SpinnerButton from 'components/sharedComponents/Spinner/SpinnerButton';
import FormInput from 'components/sharedComponents/FormComponents/FormInput/FormInput';
import EmployeeFormComponentNames from 'types/employee/enums/EmployeeFormComponentNames';
import InputType from 'types/generalEnums/InputType';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import ClientFormComponentNames from 'types/clients/enums/ClientFormComponentNames';
import PageType from 'types/generalEnums/PageType';
import ButtonsText from 'types/generalEnums/ButtonsText';

type FormChangeHeaderProps<T extends FieldValues> = {
  onCancel: () => void;
  onSave: () => void;
  register: UseFormRegister<T>;
  errors?: string;
  type: PageType.Employees | PageType.Clients;
};

const FormChangeHeader = <T extends FieldValues>({
  onCancel,
  onSave,
  register,
  errors,
  type
}: FormChangeHeaderProps<T>) => {
  const loadingEmployee = useAppSelector((state: RootState) => state.employees.saveLoading);
  const loadingClients = useAppSelector((state: RootState) => state.clients.saveLoading);

  return (
    <StyledFormChangeHeader>
      {type === PageType.Employees && (
        <FormInput
          name={EmployeeFormComponentNames.Name}
          type={InputType.Text}
          required
          register={register}
          error={errors}
          className="input-form input-form-header"
        />
      )}
      {type === PageType.Clients && (
        <FormInput
          name={ClientFormComponentNames.Name}
          type={InputType.Text}
          required
          register={register}
          error={errors}
          className="input-form input-form-header"
        />
      )}
      <div className="btn-group">
        <button onClick={onCancel} type="button">
          <PrpIcon src={closeGrey} width="20px" height="20px" />
          <p className="inter-caption_medium">{ButtonsText.Cancel}</p>
        </button>
        <button onClick={onSave} type="submit" className="save-button">
          <PrpIcon src={saveGrey} width="20px" height="20px" />
          <p className="inter-caption_medium">Save</p>
          <div className="spinner-button-row">
            <SpinnerButton loading={loadingEmployee || loadingClients} />
          </div>
        </button>
      </div>
    </StyledFormChangeHeader>
  );
};

export default FormChangeHeader;
