import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { ALERT_COLOR, PRIMARY_COLOR, SURFACE_COLOR, LOW_EMPHASISE_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledClientsNotesAddForm = styled.form({
  p: {
    color: LOW_EMPHASISE_COLOR,
    marginTop: '20px',
    marginBottom: '4px'
  },
  '.note-form-button': {
    backgroundColor: PRIMARY_COLOR,
    padding: '8px 32px',
    color: SURFACE_COLOR,
    borderRadius: borderRadiusCommon,
    border: 'none',
    position: 'relative',
    cursor: 'pointer',
    p: {
      margin: '0',
      color: SURFACE_COLOR
    }
  },
  '.note-form-button-wrapper': {
    marginTop: '54px'
  },
  '.spinner-button-wrap': {
    position: 'absolute',
    zIndex: '100',
    backgroundColor: PRIMARY_COLOR,
    width: '30px'
  },
  '.errorerror': {
    color: ALERT_COLOR
  }
});

export default StyledClientsNotesAddForm;
