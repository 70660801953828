enum AllocationsAction {
  FETCH_ALL = 'allocations/fetchAll',
  ADD_ALLOCATION_PUBLIC_HOLIDAY = 'allocation/addPublicHoliday',
  CLEAN_ALL = 'CLEAN_ALL',
  FETCH_PUBLIC_HOLIDAY = 'allocation/fetchPublicHoliday',
  ADD_BOOKING = 'allocation/addBooking',
  UPDATE_BOOKING = 'allocation/updateBooking',
  DELETE_BOOKING = 'allocation/deleteBooking',
  GET_BOOKING = 'allocation/getBooking',
  ADD_TIME_OFF = 'allocation/addTimeOff',
  DELETE_HOLIDAY = 'allocation/deleteHoliday',
  GET_TIME_OFF = 'allocation/getTimeOff',
  DELETE_TIME_OFF = 'allocation/deleteTimeOff',
  UPDATE_TIME_OFF = 'allocation/updateTimeOff',
  GET_PUBLIC_HOLIDAY = 'allocation/getHoliday'
}

export default AllocationsAction;
