import { ACCESS_TOKEN_LOCAL_STORAGE_KEY } from 'environment/environment';
import isEmptyObject from 'helpers/isEmptyObject';
import RoutingPath from 'routes/routing';
import { ApiError, logError, notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { ErrorType } from 'types/ApiError/ErrorEnums';
import GenericErrorMessage from 'types/generalEnums/GenericErrorMessage';

type ApiRequestArgs<T> = {
  method: string;
  endpoint: string;
  requestBody?: T;
  headers?: Record<string, string>;
};

export type ApiResponse<T> = {
  data: T;
};
const getRequestUrl = (url: string, queryObject: Record<string, string>): string => {
  const search = new URLSearchParams(queryObject).toString();
  /* eslint-disable */
  return url + (search ? '?' + search : '');
};

const apiRequest = async <T>(
  { method, endpoint, requestBody }: ApiRequestArgs<T>,
  headers = {}
): Promise<T> => {
  const baseUrl = endpoint;
  const url =
    method === 'GET' ? getRequestUrl(baseUrl, requestBody as Record<string, string>) : baseUrl;

  const token = localStorage.getItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
  if (token) {
    headers = { ...headers, Authorization: `Bearer ${token}` };
  }

  const responseJson: T = await fetch(url, {
    method,
    mode: 'cors',
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    ...(method !== 'GET' ? { body: JSON.stringify(requestBody) } : {})
  })
    .then((response) => {
      if (response.ok && response.status === 200) {
        return response.json();
      }
      if (response.ok && response.status === 204) {
        return response.status;
      }
      if (!response.ok) {
        return Promise.reject(response);
      }
    })
    .catch(async (error) => {
      logError('Error in apiRequest');
      // if 'error' can be converted to json
      if (error.status === 401) {
        window.location.href = RoutingPath.LogInPage;
      }
      if (error.status === 503) {
        window.location.href = RoutingPath.DownTimeErrorPage;
      }
      if (error.status === 404) {
        window.location.href = RoutingPath.NotFoundPage;
      }
      if (!!error.json) {
        const errorJson = (await error.json()) as ApiError;
        if (errorJson) {
          // if error is confirm type we rethrow it to be handled by the caller
          if (errorJson.type === ErrorType.Confirmation) {
            throw new ApiError(errorJson);
          }
          // display error message from the API if it exists in the JSON
          if ('message' in errorJson) {
            if ('errorsMap' in errorJson && !isEmptyObject(errorJson.errorsMap)) {
              notifyUser(errorJson.message, JSON.stringify(errorJson.errorsMap));
            } else {
              notifyUser(GenericErrorMessage.SomethingWentWrong, errorJson.message, false);
            }
          }
        }
      } else {
        logError('No message found to display');
        notifyUser();
      }
      throw new Error('Error in apiRequest');
    });
  return responseJson;
};

export default apiRequest;
