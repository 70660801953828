import ForecastStatus from 'types/project/enums/ForecastStatus';
import OptionalButton from 'components/project/ProjectTable/OptionalButton/OptionalButton';
import VacationToolTip from 'components/project/ProjectTable/VacationTooltip/VacationTooltip';
import ValuesCell from 'components/project/ProjectTable/ValuesCell/ValuesCell';
import {
  getCurrencyIfValueExistOrPlaceholder,
  getHoursIfValueExistOrPlaceholder,
  getNegativeValueOrNull
} from 'helpers/formatCurrencyAndHours';
import getPrpTableCellClass from 'helpers/getPrpTableCellClass';
import DiscountTypeEnum from 'types/PrpTable/enums/discountTypeEnum';
import DiscountReasonTooltip from 'components/project/ProjectTable/DiscountReasonTooltip/DiscountReasonTooltip';
import { iconComment } from 'shared/PrpIcon/icons.constants';
import {
  BillingPeridWeeksDto,
  EmployeeInfoDto,
  EmployeesActualDto,
  ProjectTableDto
} from 'types/PrpTable/dto/ProjectTableDto';
import { DiscountDto } from 'types/project/dto/UpdateDiscountFormDto';
import MatchingSprintDto from 'types/project/dto/MatchingSprintDto';
import IntensityEnum from 'types/PrpTable/enums/intensityEnum';
import { notifyUserWith } from 'shared/errorHandling/ErrorToast/errorHandling';
import GenericErrorMessage from 'types/generalEnums/GenericErrorMessage';
import { Dispatch, SetStateAction } from 'react';
import ProjectFilterName from 'types/project/enums/ProjectFilterNames';

type ProjectTableContentProps = {
  matchingSprints: {
    sprintNumber: number;
    weeks: BillingPeridWeeksDto[];
    sprintId: number;
    note: string;
  }[];
  projectTableState: ProjectTableDto;
  employee: EmployeeInfoDto;
  isFinancialInfoVisible: boolean;
  filters: string[];
  refreshProjectData: () => void;
  setIsUpdateDiscountPopUpOpen: Dispatch<SetStateAction<boolean>>;
  setUpdateDiscount: Dispatch<SetStateAction<DiscountDto>>;
  setIsUpdateForecastModalOpen: Dispatch<SetStateAction<boolean>>;
  setForecastData: Dispatch<
    SetStateAction<{
      employeeActual: EmployeesActualDto | undefined;
      weekStartDate: string;
      sprintNumber: number;
    } | null>
  >;
};

const ProjectTableContent = ({
  matchingSprints,
  projectTableState,
  employee,
  isFinancialInfoVisible,
  refreshProjectData,
  setUpdateDiscount,
  setIsUpdateDiscountPopUpOpen,
  setIsUpdateForecastModalOpen,
  setForecastData,
  filters
}: ProjectTableContentProps) => {
  const handleUpdateDiscountPopUp = (discount: DiscountDto) => {
    setIsUpdateDiscountPopUpOpen(true);
    setUpdateDiscount(discount);
    refreshProjectData();
  };

  const handleForecastClick = (
    weekStartDate: string,
    sprintNumber: number,
    employeeActual?: EmployeesActualDto
  ) => {
    setForecastData({
      employeeActual,
      weekStartDate,
      sprintNumber
    });
    if (employeeActual?.forecastDuration && employeeActual?.forecastCost) {
      setIsUpdateForecastModalOpen(true);
    } else if (employeeActual?.forecastId == null) {
      notifyUserWith(GenericErrorMessage.ForecastDoesNotExist);
    } else {
      notifyUserWith(GenericErrorMessage.RegenerateForecastToEditForecast);
    }
  };

  const handleDiscountClick = (
    employeeActual: EmployeesActualDto | undefined,
    week: BillingPeridWeeksDto,
    sprint: MatchingSprintDto
  ) => {
    const correctRate = employee.employeeRates?.find(
      (rate) => rate.startDate <= week.weekStartDate
    )?.rate;
    const discountData: DiscountDto = {
      id: employeeActual?.discountId || 0,
      date: week.weekStartDate,
      name: employee?.employeeName || '',
      position: employee.employeePositionName,
      rate: correctRate || employee.employeeCurrentRate,
      sprintNo: sprint.sprintNumber,
      forecast: employeeActual?.forecastDuration || 0,
      actuals: employeeActual?.actualDuration || 0,
      employeeId: employee?.employeeId || 0,
      projectId: projectTableState.projectId,
      weekId: week.weekId,
      repeatForWeeksInBillingPeriod: false,
      discountDuration: employeeActual?.totalDiscountDuration || 0,
      discountReason: employeeActual?.discountReason || '',
      discountCost: employeeActual?.totalDiscountCost || 0,
      discountId: employeeActual?.discountId || 0,
      discountCreatedByEmployeeWithName: employeeActual?.discountCreatedByEmployeeWithName || '',
      discountCreated: employeeActual?.discountCreated || '',
      differenceIntensity: employeeActual?.differenceIntensity || IntensityEnum.BELOW_5,
      discountType: employeeActual?.discountType || DiscountTypeEnum.DEFAULT
    };
    handleUpdateDiscountPopUp(discountData);
  };

  const getDiscountIcon = (discountType?: DiscountTypeEnum | null): JSX.Element | null => {
    switch (discountType) {
      case DiscountTypeEnum.AUTO_DISCOUNT:
        return <p className="auto-discount-type-icon">auto</p>;
      case DiscountTypeEnum.PRE_DISCOUNT:
        return <p className="pre-discount-type-icon">pre</p>;
      default:
        return null;
    }
  };

  const actualsButtonClicked = filters.includes(ProjectFilterName.Actuals);

  const discountsButtonClicked = filters.includes(ProjectFilterName.Discounts);

  const differenceButtonClicked = filters.includes(ProjectFilterName.Difference);

  const forecastButtonClicked = filters.includes(ProjectFilterName.Forecast);

  return (
    <div className="table-content-actuals">
      {projectTableState.billingPeriods.length === 0 && (
        <div className="sprint-wrapper actuals-border">
          <div className="sprint-row-actuals">
            <div className="table-content-type-details">
              <div className="empty-cell" />
            </div>
          </div>
        </div>
      )}
      {projectTableState.billingPeriods.map((billing) => {
        return (
          <div className="sprint-wrapper actuals-border" key={billing.billingPeriodId}>
            {matchingSprints
              .filter((sprint) =>
                sprint.weeks.some((week) =>
                  billing.billingPeriodWeeks.some(
                    (billingWeeks) => week.weekId === billingWeeks.weekId
                  )
                )
              )
              .map((sprint) => (
                <div className="sprint-row-actuals" key={sprint.sprintNumber}>
                  {billing.billingPeriodWeeks.map((billingWeeks) =>
                    sprint.weeks.map((week) => {
                      const employeeActual = week.employeesActual.find(
                        (w) => w.employeeId === employee.employeeId
                      );
                      if (billingWeeks.weekId !== week.weekId) return '';
                      const isStatusEditable =
                        billing.billingPeriodForecastStatus === ForecastStatus.EDITABLE;
                      const employeeVacation = projectTableState.employeesInfo
                        .find((emp) => emp.employeeId === employeeActual?.employeeId)
                        ?.employeeVacations.find((vacation) => vacation.weekId === week.weekId);
                      return (
                        <div key={week.weekId} className="table-content-type-details">
                          {forecastButtonClicked && (
                            <OptionalButton
                              condition={!!employeeActual && isStatusEditable}
                              onClick={() =>
                                handleForecastClick(
                                  week.weekStartDate,
                                  sprint.sprintNumber,
                                  employeeActual
                                )
                              }
                              duration={
                                employeeActual?.forecastDuration ??
                                (employeeActual?.actualDuration ? 0 : null)
                              }
                              cost={
                                employeeActual?.forecastCost ??
                                (employeeActual?.actualCost ? 0 : null)
                              }
                              isDisabled={!isStatusEditable}
                              customClass=""
                              toolTip={
                                employeeVacation ? (
                                  <VacationToolTip daysOff={employeeVacation.days} />
                                ) : null
                              }
                              shouldShowTooltipIfNotAButton={!!employeeActual}
                              disableFinancialInfo={!isFinancialInfoVisible}
                            />
                          )}
                          {actualsButtonClicked && (
                            <ValuesCell
                              duration={getHoursIfValueExistOrPlaceholder(
                                employeeActual?.actualDuration ??
                                  (employeeActual?.forecastDuration ? 0 : null)
                              )}
                              cost={getCurrencyIfValueExistOrPlaceholder(
                                employeeActual?.actualCost
                              )}
                              disableFinancialInfo={!isFinancialInfoVisible}
                            />
                          )}
                          {differenceButtonClicked && (
                            <ValuesCell
                              duration={getHoursIfValueExistOrPlaceholder(
                                employeeActual?.differenceDuration
                              )}
                              cost={getCurrencyIfValueExistOrPlaceholder(
                                employeeActual?.differenceCost
                              )}
                              className={getPrpTableCellClass({
                                intensity: employeeActual?.differenceIntensity,
                                differenceDuration: employeeActual?.differenceDuration
                              })}
                              disableFinancialInfo={!isFinancialInfoVisible}
                            />
                          )}
                          {discountsButtonClicked && (
                            <OptionalButton
                              condition={!!employeeActual}
                              onClick={() => handleDiscountClick(employeeActual, week, sprint)}
                              duration={getNegativeValueOrNull(
                                employeeActual?.totalDiscountDuration,
                                employeeActual?.discountType === DiscountTypeEnum.AUTO_DISCOUNT
                              )}
                              cost={getNegativeValueOrNull(
                                employeeActual?.totalDiscountCost,
                                employeeActual?.discountType === DiscountTypeEnum.AUTO_DISCOUNT
                              )}
                              toolTip={
                                employeeActual && employeeActual.discountReason ? (
                                  <DiscountReasonTooltip
                                    name={employeeActual.discountCreatedByEmployeeWithName}
                                    placement="bottom"
                                    message={employeeActual.discountReason}
                                    icon={iconComment}
                                    messageDateTime={employeeActual.discountCreated}
                                  />
                                ) : null
                              }
                              customClass={getPrpTableCellClass({
                                discountDuration: employeeActual?.totalDiscountDuration
                              })}
                              isDisabled={false}
                              icon={getDiscountIcon(employeeActual?.discountType)}
                              disableFinancialInfo={!isFinancialInfoVisible}
                            />
                          )}
                        </div>
                      );
                    })
                  )}
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
};

export default ProjectTableContent;
