import { FormControl, Select, MenuItem } from '@mui/material';
import defaultFilteringValue from 'components/allocations/consts/defaultFilteringValue';
import StyledFormSelectController from 'components/sharedComponents/FormComponents/FormSelectDictionaries/StyledFormSelectController';
import { Control, Controller, useFormContext } from 'react-hook-form';
import AllocationsFilterDashboardDto from 'types/allocation/dto/AllocationsFilterDashboardDto';
import { DictionaryItemDto } from 'types/employee/enums/DictionaryItemDto';
import AllocationFilterDashboardNames from 'types/allocation/enums/AllocationFilterDashboardNames';
import { useState } from 'react';

type AllocationFilterSelectProps = {
  control: Control<AllocationsFilterDashboardDto>;
  options: DictionaryItemDto[];
  title: keyof AllocationsFilterDashboardDto;
  label: string;
  className?: string;
  filterParamsValue?: number | null;
  onChange: (selected: number) => void;
};

const AllocationFilterSelect = ({
  title,
  control,
  options,
  label,
  className,
  onChange,
  filterParamsValue
}: AllocationFilterSelectProps) => {
  if (!options || options.length === 0) {
    return null;
  }

  const handleSelectChange = (selectedValue: number) => {
    if (onChange) {
      onChange(selectedValue);
    }
  };
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const { setValue } = useFormContext();

  if (!isSelected && filterParamsValue) {
    setValue(AllocationFilterDashboardNames.FilterNames, filterParamsValue);
    setIsSelected(true);
  }

  return (
    <StyledFormSelectController>
      <Controller
        name={title}
        control={control}
        render={({ field }) => {
          const value = field.value as number;
          const isValidValue = options.some((item) => item.id === value);
          return (
            <FormControl>
              <p className="inter-caption_medium">{label}</p>
              <Select
                {...field}
                value={isValidValue ? value : defaultFilteringValue.id}
                className={className}
                onChange={(event) => {
                  const selectValue = event.target.value as number;
                  field.onChange(selectValue);
                  handleSelectChange(selectValue);
                }}
              >
                {options.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }}
      />
    </StyledFormSelectController>
  );
};

export default AllocationFilterSelect;
