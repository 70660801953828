type ObjectType = {
  // TODO: refactor not to use `any`, then remove eslint ignore comment below
  // eslint-disable-next-line
  [key: string]: any;
};

const filterObjectList = (
  filter: string | null,
  properties: string[],
  list: ObjectType[]
): ObjectType[] => {
  if (!filter) return list;
  return list.filter((item) =>
    properties.some((prop) => item[prop]?.toLowerCase()?.includes(filter.toLowerCase()))
  );
};

export default filterObjectList;
