import { BillingPeridWeeksDto, ProjectTableDto } from 'types/PrpTable/dto/ProjectTableDto';
import { useEffect, useState } from 'react';
import { unwrapResult } from '@reduxjs/toolkit';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import getInvoice from 'store/action/projectActions/getInvoice';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import { RootState } from 'store';
import ModalWindow from 'modals/ModalWindow';
import InvoiceDetailsFormNames from 'types/project/enums/InvoiceDetailsFormNames';
import {
  allocationDeleteTimeOffModalWidth,
  allocationModalWindowHeight,
  deleteInvoiceModalHeight,
  invoiceStatusModalWidth,
  preinvoiceDetailsModalWindowHeight,
  preinvoiceDetailsModalWindowMinHeight,
  preinvoiceDetailsModalWindowWidth,
  selectInvoiceTypeModalMaxHeight,
  selectInvoiceTypeModalMinHeight
} from 'components/sharedComponents/StyleConsts/styleConsts';
import getPreinvoiceDetails from 'store/action/projectActions/getPreinvoiceSummary';
import {
  getCurrencyIfValueExistOrPlaceholder,
  getHoursIfValueExistOrPlaceholder
} from 'helpers/formatCurrencyAndHours';
import InvoiceDetails, {
  getInvoiceStatusClassName,
  getInvoiceStatusName
} from 'components/project/ProjectDetails/InvoiceDetails/InvoiceDetails';
import getInvoiceDetails from 'store/action/projectActions/getInvoiceSummary';
import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import { binGrey, iconComment, penGrey } from 'shared/PrpIcon/icons.constants';
import DeleteInvoiceForm from 'components/project/ProjectTable/PreInvoice/DeleteInvoiceForm';
import SelectInvoiceTypeForm from 'components/project/ProjectDetails/SelectInvoiceType/SelectInvoiceTypeForm';
import CreateEditInvoiceFixedPriceForm from 'components/project/ProjectDetails/InvoiceDetails/CreateInvoiceFixedPrice/CreateEditInvoiceFixedPriceForm';
import InvoiceModalType from 'types/project/enums/InvoiceModalType';
import FixedInvoiceDetails from 'components/project/ProjectDetails/InvoiceDetails/FixedInvoiceDetails';
import getInvoiceDictionaries from 'store/action/invoiceAction/getInvoiceDictionaries';
import { InvoiceStatus } from 'types/invoice/InvoiceDictionariesDto';
import InvoiceType from 'types/project/enums/InvoiceType';
import getFixedInvoiceDetails from 'store/action/projectActions/getFixedInvoiceSummary';
import InvoiceTitle from 'types/project/enums/InvoiceTitle';
import BillingType from 'types/project/enums/BillingType';
import MainSpinner from '../../../sharedComponents/Spinner/MainSpinner';

export const getInvoiceTitleOrTotalsTitle = (
  invoiceType: string,
  isPreInvoice: boolean | null,
  isInvoiceDetails?: boolean
): InvoiceTitle | InvoiceDetailsFormNames => {
  const fixedInvoiceDetailsTotalsTitle = isPreInvoice
    ? InvoiceDetailsFormNames.FixedPricePreInvoiceDetailsTotalTitle
    : InvoiceDetailsFormNames.FixedPriceInvoiceDetailsTotalTitle;
  const fixedInvoiceTitle = isPreInvoice
    ? InvoiceTitle.FixedPricePreinvoice
    : InvoiceTitle.FixedPriceInvoice;
  const correctionInvoiceTitle = InvoiceTitle.CorrectionInvoice;
  switch (invoiceType) {
    case InvoiceType.FIXED_PRICE_INVOICE:
      return isInvoiceDetails ? fixedInvoiceDetailsTotalsTitle : fixedInvoiceTitle;
    case InvoiceType.CORRECTION_INVOICE:
      return isInvoiceDetails
        ? InvoiceDetailsFormNames.CorrectionInvoiceDetailsTotalTitle
        : correctionInvoiceTitle;
    case InvoiceType.PRE_INVOICE:
      return isInvoiceDetails
        ? InvoiceDetailsFormNames.PreinvoiceDetailsTotalTitle
        : InvoiceTitle.Preinvoice;
    default:
      return isInvoiceDetails
        ? InvoiceDetailsFormNames.InvoiceDetailsTotalTitle
        : InvoiceTitle.Invoice;
  }
};
type PreInvoiceProps = {
  projectTableState: ProjectTableDto;
  matchingSprints: { sprintNumber: number; weeks: BillingPeridWeeksDto[] }[];
  refreshProjectData: () => Promise<void>;
  isPreInvoice: boolean | null;
};
const InvoiceTable = ({
  projectTableState,
  matchingSprints,
  refreshProjectData,
  isPreInvoice
}: PreInvoiceProps) => {
  const [isPreinvoiceDetailsModalOpen, setIsPreinvoiceDetailsModalOpen] = useState(false);
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState<number | null>(null);
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<number | null>(null);
  const [isTimeOffTooltipOpen, setIsTimeOffTooltipOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isDeleteInvoiceModalOpen, setIsDeleteInvoiceModalOpen] = useState(false);
  const [isEditInvoiceModalOpen, setIsEditInvoiceModalOpen] = useState(false);
  const selectedProject = useAppSelector((state: RootState) => state.project.selectedProject);
  const [modalTypeVisible, setModalTypeVisible] = useState<InvoiceModalType | null>(null);
  const dispatch = useThunkAppDispatch();
  const handleModalClose = () => {
    setIsEditInvoiceModalOpen(false);
    setIsDeleteInvoiceModalOpen(false);
    setIsPreinvoiceDetailsModalOpen(false);
    refreshProjectData();
    dispatch(getInvoice(projectTableState))
      .then(unwrapResult)
      .catch(() => logError('Error fetching projects'));
  };
  const firstWeekNumber = 1;
  const preinvoiceSummary = useAppSelector((state: RootState) => state.preinvoice);
  const invoiceSummaryState = useAppSelector((state: RootState) => state.invoiceSummary);
  const invoiceDictionariesState = useAppSelector((state: RootState) => state.invoiceDictionaries);
  const invoiceState = useAppSelector((state: RootState) => state.invoice);
  const fixedInvoiceSummaryData = useAppSelector(
    (state: RootState) => state.fixedInvoiceSummary
  ).data;
  const refetchPreInvoiceOrInvoiceDetails = (billingPeriodId?: number) => {
    dispatch(getInvoice(projectTableState))
      .then(unwrapResult)
      .catch(() => logError('Error fetching projects'));
    const doesPreinvoiceExist = invoiceState.data.some(
      (invoice) =>
        (invoice.invoiceType === InvoiceType.PRE_INVOICE ||
          invoice.invoiceType === InvoiceType.CORRECTION_INVOICE) &&
        invoice.billingPeriodId === billingPeriodId
    );
    const doesFixedInvoiceExist = invoiceState.data.some(
      (invoice) =>
        invoice.invoiceType === InvoiceType.FIXED_PRICE_INVOICE &&
        invoice.billingPeriodId === billingPeriodId
    );
    const doesInvoiceExist = invoiceState.data.some(
      (invoice) =>
        invoice.invoiceType === InvoiceType.INVOICE && invoice.billingPeriodId === billingPeriodId
    );

    if (isPreInvoice && !!billingPeriodId && doesPreinvoiceExist) {
      dispatch(getPreinvoiceDetails(billingPeriodId || 0))
        .then(unwrapResult)
        .catch(() => logError('Error fetching preinvoice details'));
    }
    if (!!billingPeriodId && doesFixedInvoiceExist) {
      dispatch(getFixedInvoiceDetails(billingPeriodId || 0))
        .then(unwrapResult)
        .catch(() => logError('Error fetching fixed invoice details'));
    }
    if (!!billingPeriodId && doesInvoiceExist && !isPreInvoice) {
      dispatch(getInvoiceDetails(billingPeriodId || 0))
        .then(unwrapResult)
        .catch(() => logError('Error fetching invoice details'));
    }
  };

  const getSelectedBillingPeriod =
    projectTableState.billingPeriods.find((b) => b.billingPeriodId === selectedBillingPeriod) ||
    null;

  useEffect(() => {
    if (selectedBillingPeriod === null || modalTypeVisible !== null) return;

    refetchPreInvoiceOrInvoiceDetails(selectedBillingPeriod || 0);
  }, [selectedBillingPeriod]);

  const isAnyInvoiceVisible = (index: number) => {
    const selectedInvoice = invoiceState.data[index || 0];
    return (
      selectedInvoice?.permissions.canGenerateInvoice ||
      selectedInvoice?.permissions.canGeneratePreInvoice ||
      selectedInvoice?.permissions.canGenerateCorrectionInvoice ||
      selectedInvoice?.permissions.canGenerateFixedPriceInvoice
    );
  };

  const isGenerateInvoiceButtonDisabled = (index: number) =>
    !invoiceState.data[index] || invoiceState.data[index].canDisplaySummary;
  const canInvoiceBeEditedOrDeleted = (status: string) => {
    return status === InvoiceStatus.NEW || status === InvoiceStatus.REJECTED;
  };
  const isMenuButtonVisible = (isFixedInvoice: boolean) => {
    let status: boolean;
    if (isFixedInvoice) {
      status = canInvoiceBeEditedOrDeleted(fixedInvoiceSummaryData?.invoiceTotal.invoiceStatus);
    } else {
      status = canInvoiceBeEditedOrDeleted(
        isPreInvoice
          ? preinvoiceSummary.data?.invoiceTotal.invoiceStatus
          : invoiceSummaryState.data?.invoiceTotal.invoiceStatus
      );
    }
    return status;
  };
  useEffect(() => {
    dispatch(getInvoice(projectTableState))
      .then(unwrapResult)
      .catch(() => logError('Error fetching projects'));
  }, [projectTableState, preinvoiceSummary, invoiceSummaryState]);

  const countWeeksInDisplayedBillingPeriod = (): number[] => {
    const countBillingPeriodWeeks: number[] = new Array(
      projectTableState.billingPeriods.length
    ).fill(0);
    projectTableState.billingPeriods.map((billing, index) => {
      countBillingPeriodWeeks[index] = 0;
      return matchingSprints.map((sprint) =>
        billing.billingPeriodWeeks.map((billingWeeks) =>
          sprint.weeks.forEach((week) => {
            if (billingWeeks.weekId === week.weekId) {
              countBillingPeriodWeeks[index] += 1;
            }
          })
        )
      );
    });
    return countBillingPeriodWeeks;
  };

  const onDetailsButtonClick = (billingPeriodId: number, invoiceId: number) => {
    setSelectedBillingPeriod(billingPeriodId);
    setSelectedInvoiceId(invoiceId);
    refetchPreInvoiceOrInvoiceDetails(billingPeriodId);
    if (invoiceId !== 0) {
      dispatch(getInvoiceDictionaries(invoiceId))
        .then(unwrapResult)
        .catch(() => {
          logError('Error fetching invoice dictionaries');
        });
    }
    setIsPreinvoiceDetailsModalOpen(true);
  };
  const onGenerateButtonClick = (billingPeriodId: number, index: number) => {
    setModalTypeVisible(
      selectedProject?.billingType === BillingType.FIXED_PRICE
        ? InvoiceModalType.FIXED_PRICE_INVOICE
        : InvoiceModalType.SELECT_INVOICE
    );
    setSelectedIndex(index);
    setSelectedBillingPeriod(billingPeriodId);
  };

  const summaryText = isPreInvoice ? 'Forecast and preinvoice' : 'Actuals and invoice';
  const onOpenTooltip = (index: number) => {
    setSelectedIndex(index);
    setIsTimeOffTooltipOpen(true);
  };
  const onCloseTooltip = () => {
    setSelectedIndex(null);
    setIsTimeOffTooltipOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setIsDeleteInvoiceModalOpen(true);
  };

  const handleEditButtonClick = () => {
    setIsEditInvoiceModalOpen(true);
  };
  const onDeleteInvoiceFormClose = (isSubmitted: boolean) => {
    setIsDeleteInvoiceModalOpen(false);
    if (isSubmitted) {
      setIsPreinvoiceDetailsModalOpen(false);
      refreshProjectData();
    }
  };
  const onEditInvoiceFormClose = () => {
    setIsEditInvoiceModalOpen(false);
  };
  const onSelectInvoiceTypeFormClose = () => {
    setModalTypeVisible(null);
  };
  const onInvoiceFixedPriceFormClose = (isFormSubmitted: boolean) => {
    setModalTypeVisible(null);
    if (isFormSubmitted) refreshProjectData();
  };
  const isFixedInvoice: boolean = selectedProject?.billingType === BillingType.FIXED_PRICE;
  const getInvoiceDetailsTitle = (): InvoiceDetailsFormNames => {
    const invoiceType =
      invoiceState?.data &&
      invoiceState.data.find((invoice) => invoice.id === selectedInvoiceId)?.invoiceType;
    switch (invoiceType) {
      case InvoiceType.FIXED_PRICE_INVOICE:
        return isPreInvoice
          ? InvoiceDetailsFormNames.FixedPricePreInvoiceDetailsTitle
          : InvoiceDetailsFormNames.FixedPriceInvoiceDetailsTitle;
      case InvoiceType.CORRECTION_INVOICE:
        return InvoiceDetailsFormNames.CorrectionInvoiceDetailsTitle;
      case InvoiceType.PRE_INVOICE:
        return InvoiceDetailsFormNames.PreinvoiceDetailsTitle;
      default:
        return InvoiceDetailsFormNames.InvoiceDetailsTitle;
    }
  };
  const menuItems = isFixedInvoice
    ? [
        {
          id: 0,
          label: 'Edit',
          icon: penGrey
        },
        {
          id: 1,
          label: 'Delete',
          icon: binGrey
        }
      ]
    : [
        {
          id: 0,
          label: 'Delete',
          icon: binGrey
        }
      ];

  const getInvoiceTitle = () => {
    if (isFixedInvoice) {
      return 'Fixed Price Invoice';
    }
    if (isPreInvoice) {
      return 'PreInvoice';
    }
    return 'Invoice';
  };

  const getInvoiceTotalTitle = () => {
    if (isFixedInvoice) {
      return 'Fixed Price Invoice total';
    }
    if (isPreInvoice) {
      return 'PreInvoice total';
    }
    return 'Invoice total';
  };

  const renderInvoiceDetails = () => {
    if (invoiceState.readLoading) {
      return <MainSpinner loading />;
    }

    const commonProps = {
      refetchPreInvoiceOrInvoiceDetails,
      onMainModalClose: handleModalClose,
      invoiceState: invoiceState.data,
      invoiceDictionariesState: invoiceDictionariesState.data,
      invoiceId: selectedInvoiceId,
      billingPeriodId: selectedBillingPeriod || 0,
      projectTableState,
      isPreInvoice
    };

    if (isFixedInvoice) {
      return <FixedInvoiceDetails {...commonProps} />;
    }

    return (
      <InvoiceDetails
        {...commonProps}
        billingType={selectedProject?.billingType || BillingType.ACTUALS}
        preinvoiceSummaryData={preinvoiceSummary.data}
        invoiceSummaryData={invoiceSummaryState.data}
        invoiceType={
          invoiceState.data.find((invoice) => invoice.id === selectedInvoiceId)?.invoiceType ||
          InvoiceType.INVOICE
        }
      />
    );
  };

  return (
    <tr className="preinvoice-table">
      <td className="table-content-wrapper">
        <div className="table-right-content-with-background">
          <div className="preinvoice-content">
            <p className="inter-caption_bold preinvoice-title">{getInvoiceTitle()}</p>
            {!isFixedInvoice && (
              <p className="inter-caption_medium preinvoice-text">
                {summaryText} amount for the next billing period
              </p>
            )}
          </div>
          <div className="table-content-types">
            <p className="inter-caption_medium">{isPreInvoice ? 'Forecast' : 'Actuals'}</p>
            <p className="inter-caption_medium">{getInvoiceTotalTitle()}</p>
          </div>
        </div>
        <div className="table-content-preinvoice">
          {projectTableState.billingPeriods.map((billing, index) => {
            let countWeeks = 0;
            return (
              <div className="sprint-wrapper actuals-border" key={billing.billingPeriodId}>
                {matchingSprints.map((sprint) => (
                  <div className="sprint-row-preinvoices" key={sprint.sprintNumber}>
                    {billing.billingPeriodWeeks.map((billingWeeks) =>
                      sprint.weeks.map((week) => {
                        if (billingWeeks.weekId !== week.weekId) {
                          return '';
                        }
                        const currentInvoice = invoiceState.data[index];
                        countWeeks += 1;
                        if (
                          countWeeks === firstWeekNumber &&
                          countWeeks !== countWeeksInDisplayedBillingPeriod()[index]
                        ) {
                          return (
                            <div className="table-content-type-details">
                              <div className="invoice-status-details">
                                <div className="invoice-status">
                                  {!!currentInvoice?.notes && currentInvoice?.notes !== '' && (
                                    <CustomTooltip
                                      onClose={onCloseTooltip}
                                      onOpen={() => onOpenTooltip(index)}
                                      isTooltipOpen={
                                        isTimeOffTooltipOpen && selectedIndex === index
                                      }
                                      title={<div>{currentInvoice?.notes}</div>}
                                      placement="bottom"
                                      icon={iconComment}
                                    />
                                  )}
                                  <div
                                    className={getInvoiceStatusClassName(
                                      currentInvoice?.invoiceStatus
                                    )}
                                  />
                                  {getInvoiceStatusName(currentInvoice?.invoiceStatus)}
                                </div>
                              </div>
                            </div>
                          );
                        }
                        if (countWeeks === countWeeksInDisplayedBillingPeriod()[index]) {
                          const currentInvoiceNotes = currentInvoice?.notes;
                          const currentInvoiceStatus = currentInvoice?.invoiceStatus;
                          return (
                            <div className="table-content-type-details">
                              <p
                                className={`inter-caption_m_bold ${
                                  countWeeks === 1
                                    ? 'forecast-hours-for-one-week'
                                    : 'forecast-hours'
                                }`}
                              >
                                {getHoursIfValueExistOrPlaceholder(currentInvoice?.duration, '0h')}
                              </p>
                              <p
                                className={`inter-caption_medium ${
                                  countWeeks === 1 ? 'cost-for-one-week' : 'cost'
                                }`}
                              >
                                {getCurrencyIfValueExistOrPlaceholder(
                                  currentInvoice?.foreseenCost,
                                  '$0'
                                )}
                              </p>
                              {isGenerateInvoiceButtonDisabled(index) ? (
                                <div
                                  className={`${
                                    countWeeks === 1
                                      ? 'invoice-status-details-for-one-week'
                                      : 'invoice-status-details'
                                  }`}
                                >
                                  {countWeeks === firstWeekNumber && (
                                    <div className="invoice-status-small">
                                      {!!currentInvoiceNotes && currentInvoiceNotes !== '' && (
                                        <CustomTooltip
                                          onClose={onCloseTooltip}
                                          onOpen={() => onOpenTooltip(index)}
                                          isTooltipOpen={
                                            isTimeOffTooltipOpen && selectedIndex === index
                                          }
                                          title={<div>{currentInvoiceNotes}</div>}
                                          placement="bottom"
                                          icon={iconComment}
                                        />
                                      )}
                                      <div
                                        className={getInvoiceStatusClassName(currentInvoiceStatus)}
                                      />
                                      {getInvoiceStatusName(currentInvoiceStatus)}
                                    </div>
                                  )}
                                  <div
                                    className={`${
                                      countWeeks === 1
                                        ? 'details-container-for-one-week'
                                        : 'details-container'
                                    }`}
                                  >
                                    <p
                                      className={`inter-caption_medium ${
                                        currentInvoiceNotes !== null
                                          ? 'invoice-total-with-note'
                                          : 'invoice-total'
                                      }`}
                                    >
                                      {getCurrencyIfValueExistOrPlaceholder(
                                        currentInvoice?.invoiceTotal,
                                        '$0'
                                      )}
                                    </p>
                                    <button
                                      type="button"
                                      className="inter-caption_action details"
                                      onClick={() =>
                                        onDetailsButtonClick(
                                          billing.billingPeriodId,
                                          currentInvoice.id
                                        )
                                      }
                                    >
                                      Details
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <button
                                  type="button"
                                  className={`${
                                    (isGenerateInvoiceButtonDisabled(index) ||
                                      !isAnyInvoiceVisible(index)) &&
                                    'generate-disabled'
                                  } ${
                                    countWeeks === 1
                                      ? 'generate-invoice-btn-for-one-week'
                                      : 'generate-invoice-btn'
                                  }`}
                                  disabled={
                                    isGenerateInvoiceButtonDisabled(index) ||
                                    !isAnyInvoiceVisible(index)
                                  }
                                  onClick={() =>
                                    onGenerateButtonClick(billing.billingPeriodId, index)
                                  }
                                >
                                  Generate invoice
                                </button>
                              )}
                            </div>
                          );
                        }
                        return <div className="table-content-type-details" />;
                      })
                    )}
                  </div>
                ))}
              </div>
            );
          })}
          {isPreinvoiceDetailsModalOpen && selectedProject?.billingType && (
            <ModalWindow
              onClose={handleModalClose}
              headerTitle={getInvoiceDetailsTitle()}
              style={{
                width: preinvoiceDetailsModalWindowWidth,
                minHeight: preinvoiceDetailsModalWindowMinHeight,
                maxHeight: preinvoiceDetailsModalWindowHeight
              }}
              showMenuButtons={isMenuButtonVisible(isFixedInvoice)}
              menuButtons={menuItems}
              onClickFunctions={
                isFixedInvoice
                  ? [handleEditButtonClick, handleDeleteButtonClick]
                  : [handleDeleteButtonClick]
              }
            >
              {renderInvoiceDetails()}
            </ModalWindow>
          )}
        </div>
        {isDeleteInvoiceModalOpen && (
          <ModalWindow
            onClose={() => setIsDeleteInvoiceModalOpen(false)}
            headerTitle={`Delete ${getInvoiceTitleOrTotalsTitle(
              invoiceState.data.find((invoice) => invoice.id === selectedInvoiceId)?.invoiceType ||
                '',
              isPreInvoice
            )}`}
            style={{
              width: invoiceStatusModalWidth,
              height: deleteInvoiceModalHeight
            }}
          >
            <DeleteInvoiceForm
              onClose={onDeleteInvoiceFormClose}
              invoiceId={selectedInvoiceId || 0}
              isPreInvoice={isPreInvoice}
              invoiceTitle={getInvoiceTitleOrTotalsTitle(
                invoiceState.data.find((invoice) => invoice.id === selectedInvoiceId)
                  ?.invoiceType || '',
                isPreInvoice
              )}
              invoiceType={
                invoiceState.data.find((invoice) => invoice.id === selectedInvoiceId)
                  ?.invoiceType || ''
              }
            />
          </ModalWindow>
        )}
        {modalTypeVisible === InvoiceModalType.SELECT_INVOICE && (
          <ModalWindow
            onClose={onSelectInvoiceTypeFormClose}
            headerTitle="Select Invoice Type"
            style={{
              width: invoiceStatusModalWidth,
              minHeight: selectInvoiceTypeModalMinHeight,
              maxHeight: selectInvoiceTypeModalMaxHeight
            }}
          >
            <SelectInvoiceTypeForm
              onClose={onSelectInvoiceTypeFormClose}
              refreshProjectData={refreshProjectData}
              billingPeriodId={selectedBillingPeriod || 0}
              billingType={selectedProject?.billingType || null}
              invoicePermissions={invoiceState.data[selectedIndex || 0]?.permissions}
            />
          </ModalWindow>
        )}
        {(modalTypeVisible === InvoiceModalType.FIXED_PRICE_INVOICE || isEditInvoiceModalOpen) && (
          <ModalWindow
            onClose={
              isEditInvoiceModalOpen
                ? onEditInvoiceFormClose
                : () => onInvoiceFixedPriceFormClose(false)
            }
            headerTitle={
              isEditInvoiceModalOpen
                ? `Edit ${getInvoiceTitleOrTotalsTitle(
                    invoiceState.data.find((invoice) => invoice.id === selectedInvoiceId)
                      ?.invoiceType || '',
                    isPreInvoice
                  )} Details`
                : getInvoiceDetailsTitle()
            }
            style={{
              width: allocationDeleteTimeOffModalWidth,
              height: allocationModalWindowHeight
            }}
          >
            <CreateEditInvoiceFixedPriceForm
              onClose={
                isEditInvoiceModalOpen ? onEditInvoiceFormClose : onInvoiceFixedPriceFormClose
              }
              selectedBillingPeriod={getSelectedBillingPeriod}
              isEditMode={isEditInvoiceModalOpen}
              invoiceId={selectedInvoiceId || 0}
            />
          </ModalWindow>
        )}
      </td>
    </tr>
  );
};
export default InvoiceTable;
