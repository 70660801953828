import StyledErrorToast from 'shared/errorHandling/ErrorToast/StyledErrorToast';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { toastError, toastSuccess } from 'shared/PrpIcon/icons.constants';
import GenericErrorMessage from 'types/generalEnums/GenericErrorMessage';

type ErrorToastProps = {
  mainMessage: string;
  messageReason: string;
};

type SuccessToastProps = {
  mainMessage: string;
};

function ErrorToast({ mainMessage, messageReason }: ErrorToastProps) {
  return (
    <StyledErrorToast>
      <PrpIcon src={toastError} />
      <div className="toast-row">
        <p className="inter-caption_bold main-error-message">{mainMessage}</p>
        <p className="inter-p3 message">{messageReason}</p>
      </div>
    </StyledErrorToast>
  );
}

function SuccessToast({ mainMessage }: SuccessToastProps) {
  return (
    <div>
      <PrpIcon src={toastSuccess} />
      <p className="inter-caption_bold main-success-message">{mainMessage}</p>
    </div>
  );
}

const toastConfig = {
  position: toast.POSITION.TOP_RIGHT,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  autoClose: 5000,
  icon: false,
  className: 'my-toast',
  bodyClassName: 'my-toast-body'
};

export default function showToast(
  type = 'error',
  mainMessage: string = GenericErrorMessage.SomethingWentWrong,
  message: string = GenericErrorMessage.ContactAdministrator
) {
  switch (type) {
    case 'error':
      toast.dismiss();
      toast.error(<ErrorToast mainMessage={mainMessage} messageReason={message} />, {
        ...toastConfig
      });
      break;
    case 'success':
      toast.dismiss();
      toast.success(<SuccessToast mainMessage={mainMessage} />, {
        ...toastConfig
      });
      break;
    default:
      break;
  }
}
