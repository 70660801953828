import styled from 'styled-components';
import { BACKGROUND_COLOR } from 'constants/colors';

const StyledChangeMonthSwitch = styled.div({
  backgroundColor: BACKGROUND_COLOR,
  '.month-switch': {
    display: 'flex',
    flexDirection: 'row',
    p: { margin: '-4px 0 0 0' }
  },
  button: {
    border: 'none',
    background: 'none',
    cursor: 'pointer'
  }
});

export default StyledChangeMonthSwitch;
