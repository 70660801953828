import StyledInitials from 'components/sharedComponents/Initials/StyledInitials';

type FirstInitialsProps = {
  name: string | null;
  className?: string;
};

const getInitials = (title: string | null): string => {
  if (title === null) {
    return '';
  }
  const words = title.split(/\s+/);
  if (words.length === 1) {
    return words[0][0];
  }
  if (words.length === 2) {
    return words[0][0] + words[1][0];
  }
  return words[0][0] + words[1][0] + words[2][0];
};

const Initials = ({ name, className }: FirstInitialsProps) => {
  return (
    <StyledInitials className={className}>
      <p className="inter-caption_medium  name">{getInitials(name)}</p>
    </StyledInitials>
  );
};

export default Initials;
