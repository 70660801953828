import { TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledEmptyView = styled.div({
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  h3: {
    color: TEXT_COLOR,
    textAlign: 'center'
  },
  p: {
    margin: '0',
    color: TEXT_COLOR,
    width: '217px',
    textAlign: 'center'
  }
});

export default StyledEmptyView;
