import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { PreInvoiceSummaryDto } from 'types/project/dto/PreinvoiceSummaryDto';

const preinvoiceSummaryApiService: IApiService = {
  getPreinvoiceSummary: async (billingPeriodId: number) => {
    try {
      const response = await apiRequest<PreInvoiceSummaryDto>({
        method: 'GET',
        endpoint: `${API_URL}/billing-periods/${billingPeriodId}/pre-invoice/summary`
      });
      return response;
    } catch (error) {
      logError('Error in getPreinvoiceSummary');
      throw new Error('Error in getPreinvoiceSummary');
    }
  }
};

interface IApiService {
  getPreinvoiceSummary: (billingPeriodId: number) => Promise<PreInvoiceSummaryDto>;
}

export default preinvoiceSummaryApiService;
