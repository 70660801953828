enum InvoiceTitle {
  Invoice = 'Invoice',
  Preinvoice = 'Preinvoice',
  CorrectionInvoice = 'Correction Invoice',
  CorrectionPreInvoice = 'Correction preinvoice',
  FixedPriceInvoice = 'Fixed Price Invoice',
  FixedPricePreinvoice = 'Fixed Price Preinvoice'
}

export default InvoiceTitle;
