import { detailsPattern } from 'constants/validationConstants';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import * as yup from 'yup';

const AllocationPublicHolidayFormValidation = () => {
  return yup.object().shape({
    date: yup.string().required(FormValidationMessage.RequiredError),
    details: yup.string().matches(detailsPattern, FormValidationMessage.MaxLengthError)
  });
};

export default AllocationPublicHolidayFormValidation;
