import { SelectChangeEvent, FormControl, Select } from '@mui/material';
import {
  menuColorPickerWidth,
  menuColorPickerPadding,
  menuColorPickerMargin
} from 'components/clients/StyleConsts/styleConsts';
import StyledColorPicker, {
  StyledMenuItem
} from 'components/sharedComponents/ColorPicker/StyledColorPicker';
import { useState, useEffect } from 'react';
import {
  Control,
  useFormContext,
  Controller,
  FieldValues,
  Path,
  useController
} from 'react-hook-form';

type DependentColorPicker<T extends FieldValues> = {
  label: string;
  control: Control<T>;
  name: Path<T>;
  error?: string;
  colors: string[];
  dependentComponentName: string;
  editForm?: boolean;
};

const DependentColorPicker = <T extends FieldValues>({
  label,
  control,
  name,
  error,
  colors,
  dependentComponentName,
  editForm
}: DependentColorPicker<T>) => {
  const {
    field: { value }
  } = useController({ name, control });
  const { watch, setValue } = useFormContext();
  const watchName = watch(dependentComponentName);
  const [selectedColor, setSelectedColor] = useState<string | null>(value);

  useEffect(() => {
    if (!editForm) {
      setSelectedColor(null);
    }
  }, [watchName]);

  const getValidColor = (color: string | null): string | null => {
    if (color && colors.includes(color)) {
      return color;
    }
    return null;
  };

  const handleColorChange = (event: SelectChangeEvent) => {
    const color = event.target.value;
    const validColor = getValidColor(color);
    setSelectedColor(validColor);
    setValue(name.toString(), validColor);
  };

  const selectedValue = getValidColor(selectedColor) ?? '';

  return (
    <StyledColorPicker>
      <Controller
        name={name}
        control={control}
        render={() => (
          <FormControl>
            <p className="inter-caption_medium label">{label}</p>
            <Select
              value={selectedValue}
              className="select-color"
              onChange={handleColorChange}
              renderValue={() => (
                <div
                  style={{
                    backgroundColor: selectedValue || ''
                  }}
                  className="rendered-color"
                />
              )}
              MenuProps={{
                MenuListProps: {
                  style: {
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'row',
                    width: menuColorPickerWidth,
                    listStyle: 'none',
                    justifyContent: 'center',
                    padding: menuColorPickerPadding,
                    margin: menuColorPickerMargin
                  }
                }
              }}
            >
              {colors.map((color) => (
                <StyledMenuItem
                  key={color}
                  value={color}
                  style={{
                    backgroundColor: color,
                    border: selectedValue === color ? '2px solid black' : ''
                  }}
                  className="menu-item"
                />
              ))}
            </Select>
            {error && <p className="error inter-caption_medium">{error}</p>}
          </FormControl>
        )}
      />
    </StyledColorPicker>
  );
};
export default DependentColorPicker;
