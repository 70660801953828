import { ProjectDictionariesState } from 'store/slicesState/projectDictionaries';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';

const selectClientsPropertyById = (
  state: ProjectDictionariesState,
  id: number,
  property: DictionariesSelector.Name | DictionariesSelector.DisplayName
): string | null => {
  const clientsDictionary = state.data?.clientNames;
  if (!clientsDictionary) {
    return null;
  }
  const client = clientsDictionary.find((cl) => cl.id === Number(id));
  return client?.[property] ?? null;
};

export default selectClientsPropertyById;
