import IntensityEnum from '../types/PrpTable/enums/intensityEnum';

const redIntensityDictionary = {
  [IntensityEnum.BELOW_5]: '',
  [IntensityEnum.BETWEEN_5_AND_10]: 'difference-red',
  [IntensityEnum.ABOVE_10]: 'difference-red-background'
};

const greenIntensityDictionary = {
  [IntensityEnum.BELOW_5]: '',
  [IntensityEnum.BETWEEN_5_AND_10]: 'difference-green',
  [IntensityEnum.ABOVE_10]: 'difference-green-background'
};

type getPrpTableCellModeProps = {
  intensity?: IntensityEnum;
  discountDuration?: number;
  differenceDuration?: number;
};

const getPrpTableCellClass = ({
  intensity,
  discountDuration,
  differenceDuration
}: getPrpTableCellModeProps) => {
  if (intensity)
    return !!differenceDuration && differenceDuration > 0
      ? greenIntensityDictionary[intensity]
      : redIntensityDictionary[intensity];
  return discountDuration && discountDuration > 0 ? 'difference-orange' : '';
};

export default getPrpTableCellClass;
