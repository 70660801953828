import { ProjectDictionariesState } from 'store/slicesState/projectDictionaries';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';

const selectClientColorsById = (
  state: ProjectDictionariesState,
  id: number,
  property: DictionariesSelector.Name | DictionariesSelector.DisplayName
): string | null => {
  const clientColorsDictionary = state.data?.clientColors;
  if (!clientColorsDictionary) {
    return null;
  }
  const clientColor = clientColorsDictionary.find((cl) => cl.id === Number(id));

  return clientColor?.[property] ?? null;
};

export default selectClientColorsById;
