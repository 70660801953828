import {
  projectNamePattern,
  projectCodePattern,
  maxStartingSprintNumber,
  minStartingSprintNumber
} from 'constants/validationConstants';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import * as yup from 'yup';

const ProjectFormValidation = (
  customBillingPeriodWeeksMin: number,
  customBillingPeriodWeeksMax: number,
  isCustomBillingPeriodEnabled: boolean,
  isEditMode?: boolean
) => {
  return yup.object().shape({
    clientId: yup.mixed().nullable().required(FormValidationMessage.ClientNameError),
    colorHex: yup.string().required(FormValidationMessage.ClientColorError),
    name: yup
      .string()
      .required(FormValidationMessage.ProjectNameError)
      .matches(projectNamePattern, FormValidationMessage.ProjectNameLengthError),
    code: yup
      .string()
      .trim()
      .required(FormValidationMessage.ProjectCodeError)
      .matches(projectCodePattern, FormValidationMessage.ProjectCodeMinMaxLengthError),
    azdoAlias: yup.string().trim().required(FormValidationMessage.AzdoAliasError).min(3).max(256),
    startDate: yup.string().required(FormValidationMessage.DateError),
    customBillingPeriodWeeks: isCustomBillingPeriodEnabled
      ? yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .min(
            customBillingPeriodWeeksMin,
            FormValidationMessage.CustomBillingPeriodWeeksMaxLengthError
          )
          .max(
            customBillingPeriodWeeksMax,
            FormValidationMessage.CustomBillingPeriodWeeksMaxLengthError
          )
          .required(FormValidationMessage.CustomBillingPeriodWeeksMaxLengthError)
      : yup.number().nullable(),
    startingSprintNumber: isEditMode
      ? yup.number().nullable()
      : yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .max(maxStartingSprintNumber, FormValidationMessage.StartSprintNumberError)
          .min(minStartingSprintNumber, FormValidationMessage.StartSprintNumberError)
          .required(FormValidationMessage.StartSprintNumberError),
    billingType: yup.string().required(FormValidationMessage.BillingTypeError),
    projectManagerId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .nullable()
      .required(FormValidationMessage.ProjectManagerError)
  });
};

export default ProjectFormValidation;
