import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import FormInput from 'components/sharedComponents/FormComponents/FormInput/FormInput';
import FormInputNumber from 'components/sharedComponents/FormComponents/FormInput/FormInputNumber';
import FormSelectPosition from 'components/employee/EmployeeFormItems/EmployeeFormSelectController/FormSelectPosition';
import FormSelectSeniority from 'components/employee/EmployeeFormItems/EmployeeFormSelectController/FormSelectSeniority';
import FormSelectPermissions from 'components/employee/EmployeeFormItems/EmployeeFormSelectPermissions/FormSelectPermissions';
import FormSwitch from 'components/sharedComponents/FormComponents/FormSwitch/FormSwitch';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import addEmployee from 'store/action/employeeActions/addEmployee';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import { EmployeeFormDto } from 'types/employee/dto/EmployeeFormDto';
import EmployeeFormComponentNames from 'types/employee/enums/EmployeeFormComponentNames';
import InputType from 'types/generalEnums/InputType';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { setEmployees } from 'store/slices/employee/employeeSlice';
import makeEmployeeInactiveModalConfig from 'components/employee/const/employeeConst';
import EmployeeFormsValidation from 'components/employee/EmployeeChangeForm/EmployeeFormsValidation';
import EmployeeAddFormDefaultValue from 'components/employee/EmployeeAddForm/EmployeeAddFormDefaultValue';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import FormTypeName from 'types/generalEnums/FormTypeName';
import ButtonsText from 'types/generalEnums/ButtonsText';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import { useEffect } from 'react';

type EmployeeAddFormProps = {
  onClose: () => void;
};

const EmployeeAddForm = ({ onClose }: EmployeeAddFormProps) => {
  const employees = useSelector((state: RootState) => state.employees.data);
  const maxHours = 60;
  const minHours = 1;
  const methods = useForm({
    defaultValues: EmployeeAddFormDefaultValue,
    // TODO fix unknown
    resolver: yupResolver(
      EmployeeFormsValidation(FormTypeName.AddForm, minHours, maxHours)
    ) as unknown as Resolver<EmployeeFormDto>
  });
  const {
    control,
    handleSubmit,
    register,
    getValues,
    trigger,
    watch,
    clearErrors,
    formState: { errors }
  } = methods;
  const loadingEmployee = useAppSelector((state: RootState) => state.employees.saveLoading);
  const dispatch = useThunkAppDispatch();
  const dictionaries = useAppSelector((state: RootState) => state.dictionaries.data);
  const employeeCapacity = watch(EmployeeFormComponentNames.Capacity);
  useEscapeKey(onClose);
  const onSubmit: SubmitHandler<EmployeeFormDto> = async (data: EmployeeFormDto) => {
    try {
      const newEmployee: EmployeeDto = await dispatch(addEmployee(data)).unwrap();
      methods.reset();
      dispatch(setEmployees([...employees, newEmployee]));
      onClose();
    } catch (error) {
      logError('Error adding employee');
    }
  };
  useEffect(() => {
    clearErrors(EmployeeFormComponentNames.Capacity);
    if (employeeCapacity !== null) trigger(EmployeeFormComponentNames.Capacity);
  }, [employeeCapacity]);

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className="modal-container">
          <Grid item xs={10} md={6}>
            <FormInput
              label={EmployeeFormComponentNames.NameLabel}
              name={EmployeeFormComponentNames.Name}
              type={InputType.Text}
              required
              register={register}
              error={errors.name?.message}
              className="input-form"
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormSelectPosition
              title={EmployeeFormComponentNames.Position}
              label={EmployeeFormComponentNames.PositionLabel}
              control={control}
              dictionaries={dictionaries}
              error={errors.positionId?.message}
              className="form-select"
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormSelectSeniority
              title={EmployeeFormComponentNames.Seniority}
              label={EmployeeFormComponentNames.SeniorityLabel}
              control={control}
              dictionaries={dictionaries}
              error={errors.seniorityId?.message}
              className="form-select"
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormInput
              label={EmployeeFormComponentNames.EmailLabel}
              name={EmployeeFormComponentNames.Email}
              type={InputType.Text}
              required
              register={register}
              error={errors.email?.message}
              className="input-form"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormSelectPermissions
              control={control}
              dictionaries={dictionaries}
              name={EmployeeFormComponentNames.Permissions}
              error={errors.permissionIds?.message}
              className="form-permission-select"
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormInputNumber
              label={EmployeeFormComponentNames.CapacityLabel}
              name={EmployeeFormComponentNames.Capacity}
              required
              register={register}
              getValues={getValues}
              error={errors.weeklyCapacity?.message}
              className="input-capacity input-form"
              minValue={minHours}
              maxValue={maxHours}
              isDecimal
              numberOfDigitsAfterDecimal={2}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormSwitch
              labelOn={EmployeeFormComponentNames.ActivityLabel}
              labelOff={EmployeeFormComponentNames.InactivityLabel}
              name={EmployeeFormComponentNames.Activity}
              title={EmployeeFormComponentNames.ActivityTitle}
              modalConfig={makeEmployeeInactiveModalConfig}
              control={control}
              initialValue
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <FormSwitch
              labelOn={EmployeeFormComponentNames.AccessLabel}
              labelOff={EmployeeFormComponentNames.NoAccessLabel}
              name={EmployeeFormComponentNames.Access}
              title={EmployeeFormComponentNames.AccessTitle}
              control={control}
              initialValue
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.Add}
          isSpinnerButtonAdded
          loading={loadingEmployee}
          isCancelButtonVisible
          submitButtonClassName="add-button button-text"
          onClick={onClose}
        />
      </StyledForm>
    </FormProvider>
  );
};

export default EmployeeAddForm;
