import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import uploadActualsApiService from 'api/updateActuals';
import UploadActuals from 'types/uploadActuals/uploadActuals';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { ImportActualsStatusResponseDto } from 'types/uploadActuals/ImportActualsStatusResponseDto';

const getImportAzdoStatus = createAsyncThunk<
  ImportActualsStatusResponseDto,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(UploadActuals.IMPORT_ACTUALS_STATUS, async () => {
  const response = await uploadActualsApiService.getImportStatus();
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default getImportAzdoStatus;
