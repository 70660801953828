import { BACKGROUND_COLOR } from 'constants/colors';
import { GREY_SIXTY, VIOLET_BLACK } from 'constants/groupColors';
import styled from 'styled-components';

const StyledMainSpinner = styled.div({
  position: 'absolute',
  alignSelf: 'center',
  height: '100%',
  zIndex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: BACKGROUND_COLOR,
  width: '100%',
  top: '0',
  left: '0',
  '.spinner': {
    pointerEvents: 'none',
    width: '50px',
    height: '50px',
    border: '8px solid transparent',
    borderColor: GREY_SIXTY,
    borderTopColor: VIOLET_BLACK,
    borderRadius: '50%',
    animation: 'spinner 1s linear infinite',
    alignSelf: 'center'
  },
  '@keyframes spinner': {
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
});
export default StyledMainSpinner;
