import { FormControlLabel, Switch } from '@mui/material';
import ProjectTableMultiSelect from 'components/sharedComponents/SharedMultiSelect/ProjectTableMultiSelect';
import ProjectFilterName from 'types/project/enums/ProjectFilterNames';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';

type prpFiltersProps = {
  onEmployeesSwitchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFinancialSwitchChange: (event: ChangeEvent<HTMLInputElement>) => void;
  searchValues: string[];
  setSearchValue: Dispatch<SetStateAction<string[]>>;
};
const PrpTableFilters = ({
  onEmployeesSwitchChange,
  onFinancialSwitchChange,
  setSearchValue,
  searchValues
}: prpFiltersProps) => {
  return (
    <>
      <div className="show-employees-row">
        <FormControlLabel
          value="start"
          control={
            <Switch
              onChange={onEmployeesSwitchChange}
              inputProps={{ 'aria-label': 'show employees' }}
              defaultChecked
            />
          }
          label="Show Employees"
          labelPlacement="start"
        />
        <FormControlLabel
          value="start"
          control={
            <Switch
              onChange={onFinancialSwitchChange}
              inputProps={{ 'aria-label': 'show financial values' }}
              defaultChecked
            />
          }
          label="Show $"
          labelPlacement="start"
        />
      </div>
      <ProjectTableMultiSelect
        items={[
          ProjectFilterName.Forecast,
          ProjectFilterName.Actuals,
          ProjectFilterName.Difference,
          ProjectFilterName.Discounts
        ]}
        setSearchValue={setSearchValue}
        searchValue={searchValues}
      />
    </>
  );
};

export default PrpTableFilters;
