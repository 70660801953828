import {
  ALERT_COLOR,
  BACKGROUND_COLOR,
  BORDERS_COLOR,
  CAUTION_HOVER,
  GREEN_TEXT_COLOR,
  LOW_EMPHASISE_COLOR,
  TEXT_COLOR
} from 'constants/colors';
import styled from 'styled-components';

const StyledInvoiceDetails = styled.div({
  paddingInline: '16px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  '.invoice-bp-time': {
    display: 'flex',
    gap: '7px',
    fontSize: '14px',
    alignItems: 'center',
    lineHeight: '20px'
  },
  '.bp-date': {
    fontWeight: 500
  },
  '.invoice-section-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    width: 'calc(100% - 32px)',
    border: '1px solid',
    borderRadius: 6,
    borderColor: BORDERS_COLOR,
    padding: 16
  },
  '.fixed-invoice-amount': {
    textAlign: 'right',
    color: TEXT_COLOR,
    fontWeight: 'bold'
  },
  '.additional-cost-section-wrapper': {
    marginTop: '10px',
    marginBottom: '10px',
    '.adding-button': {
      cursor: 'pointer',
      width: '200px',
      textAlign: 'left',
      display: 'flex',
      backgroundColor: 'transparent',
      border: 'none',
      p: {
        marginLeft: '8px',
        marginTop: '3px'
      }
    }
  },
  '.section-left-column': {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    '.section-title': {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 500
    },
    '.section-subtitle': {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 400,
      color: LOW_EMPHASISE_COLOR
    }
  },
  '.section-left-column-fixed-invoice': {
    width: '100%',
    '.section-title': {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 500
    },
    '.section-subtitle': {
      fontSize: '12px',
      lineHeight: '18px',
      fontWeight: 400,
      color: LOW_EMPHASISE_COLOR,
      marginTop: '5px',
      textAlign: 'justify'
    }
  },
  '.section-right-column': {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    gap: '12px',
    '.section-right-row': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  '.difference-orange': {
    p: {
      color: CAUTION_HOVER
    }
  },

  '.difference-red': {
    p: {
      color: ALERT_COLOR
    }
  },

  '.difference-red-background': {
    p: {
      color: ALERT_COLOR
    },
    '.sprint-row-cell-inner': {
      borderRadius: '5px',
      backgroundColor: '#FF466720'
    }
  },
  '.text-right': {
    textAlign: 'right',
    color: LOW_EMPHASISE_COLOR
  },
  'invoice-summary-title': {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 700
  },
  '.invoice-total-amount': {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700
  },
  '.invoice-summary-container': {
    backgroundColor: BACKGROUND_COLOR,
    marginTop: '4px',
    marginBottom: '2px'
  },
  '.bold': {
    fontWeight: 700
  },
  '.bold-text-duration': {
    '.duration': {
      fontWeight: 700,
      color: TEXT_COLOR,
      fontSize: '14px'
    }
  },
  '.bold-red-duration': {
    '.duration': {
      fontWeight: 700,
      color: ALERT_COLOR,
      fontSize: '14px'
    }
  },
  '.bold-green-duration': {
    '.duration': {
      fontWeight: 700,
      color: GREEN_TEXT_COLOR,
      fontSize: '14px'
    },
    '.invoice-cost': {
      fontWeight: 600,
      color: GREEN_TEXT_COLOR,
      fontSize: '12px'
    }
  },
  '.left-description': {
    color: LOW_EMPHASISE_COLOR
  },
  '.invoice-status-button': {
    border: 'none',
    backgroundColor: 'transparent',
    padding: '4px',
    cursor: 'pointer',
    color: LOW_EMPHASISE_COLOR
  },
  '.invoice-status-wrapper': {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    fontSize: '12px'
  },
  '.greenCircle': {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#00B341'
  },
  '.redCircle': {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#FF4667'
  },
  '.orangeCircle': {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#FFA540'
  },
  '.greyCircle': {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: '#DADAEA'
  },
  '.blackCircle': {
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: 'black'
  },
  '.invoice-status-select': {
    width: '381px',
    height: '48px',
    marginTop: '12px'
  },
  '.invoice-status-note-input': {
    width: '381px',
    textarea: {
      padding: '14px'
    }
  },
  '.button-with-icon': {
    backgroundColor: 'transparent',
    border: 'none'
  },
  '.additional-cost': {
    marginLeft: 'auto'
  },
  '.additional-cost-buttons': {
    marginTop: '10px',
    marginLeft: '-5px',
    button: {
      cursor: 'pointer',
      border: 'none',
      backgroundColor: 'transparent'
    },
    '.edit-button': {
      color: TEXT_COLOR,
      fontWeight: 'bolder',
      marginRight: '5px'
    },
    '.delete-button': {
      color: ALERT_COLOR,
      fontWeight: 'bolder',
      marginRight: '5px'
    }
  },
  '.additional-cost-details': {
    marginTop: '8px',
    '.section-subtitle': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      width: '480px'
    }
  }
});

export default StyledInvoiceDetails;
