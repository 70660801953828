import { InvoiceFixedPriceFormDto } from 'types/project/dto/InvoiceFixedPriceFormDto';
import { AddEditInvoiceFixedPriceDto } from 'types/project/dto/AddEditInvoiceFixedPriceDto';

const mapToCreateFixedInvoiceDto = (
  data: InvoiceFixedPriceFormDto
): AddEditInvoiceFixedPriceDto => {
  return {
    amount: data.amount,
    description: data.description
  } as AddEditInvoiceFixedPriceDto;
};

export default mapToCreateFixedInvoiceDto;
