import { Select, SelectChangeEvent } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import EmployeeStatusFilter from 'types/employee/enums/EmployeeStatusFilter';
import StyledSelectItem from './StyledSelectItem';

type SelectItemProps = {
  className?: string;
  statusFilter: EmployeeStatusFilter;
  setStatusFilter?: (statusFilter: string) => void;
};

const SelectItem = ({ className, statusFilter, setStatusFilter }: SelectItemProps) => {
  const handelChange = (event: SelectChangeEvent) => {
    if (setStatusFilter) {
      setStatusFilter(event.target.value);
    }
  };

  return (
    <StyledSelectItem className={className}>
      <FormControl size="small">
        <Select
          value={statusFilter}
          onChange={handelChange}
          label={EmployeeStatusFilter.All}
          labelId="select-label"
          defaultValue={EmployeeStatusFilter.All}
          sx={{ '& > fieldset': { border: 'none' } }}
        >
          <MenuItem value={EmployeeStatusFilter.All}>{EmployeeStatusFilter.All}</MenuItem>
          <MenuItem value={EmployeeStatusFilter.Active}>{EmployeeStatusFilter.Active}</MenuItem>
          <MenuItem value={EmployeeStatusFilter.InActive}>{EmployeeStatusFilter.InActive}</MenuItem>
        </Select>
      </FormControl>
    </StyledSelectItem>
  );
};

export default SelectItem;
