import { createAsyncThunk } from '@reduxjs/toolkit';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import DictionariesAction from 'types/employee/enums/Dictionaries';
import { InvoiceDictionariesDto } from 'types/invoice/InvoiceDictionariesDto';
import invoiceDictionariesApiService from 'api/invoiceDictionaryApiService';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';

const getInvoiceDictionaries = createAsyncThunk<
  InvoiceDictionariesDto,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(DictionariesAction.FETCH_INVOICE_DICTIONARIES, async (invoiceId: number) => {
  const response = await invoiceDictionariesApiService.fetchInvoiceDictionaries(invoiceId);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default getInvoiceDictionaries;
