import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { PRIMARY_COLOR, SURFACE_COLOR, TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledFormModalWindow = styled.div({
  p: {
    color: TEXT_COLOR
  },

  '.modal-button': {
    backgroundColor: PRIMARY_COLOR,
    padding: '14px 32px 14px 32px',
    color: SURFACE_COLOR,
    borderRadius: borderRadiusCommon,
    border: 'none',
    marginTop: '32px',
    cursor: 'pointer'
  }
});

export default StyledFormModalWindow;
