import * as yup from 'yup';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import { detailsPattern } from 'constants/validationConstants';

const CreateInvoiceFixedPriceFormValidation = () => {
  return yup.object().shape({
    amount: yup.number().required(FormValidationMessage.DiscountError),
    description: yup
      .string()
      .required(FormValidationMessage.ReasonError)
      .matches(detailsPattern, FormValidationMessage.MaxLengthError)
  });
};

export default CreateInvoiceFixedPriceFormValidation;
