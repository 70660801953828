import { MbscCalendarDayData } from '@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-day';
import Popover from '@mui/material/Popover';
import StyledAllocationEventcalendarHeader from 'components/allocations/AllocationEventcalendarHeader/StyledAllocationEventcalendarHeader';
import { allocationPublicHolidayPopoverPadding } from 'components/allocations/consts/styleConst';
import { getCalendarDayNumber, getCalendarDayName } from 'helpers/dateUtils';
import allocationFormatDate from 'pages/AllocationsPage/helpers/allocationFormatDate';
import React, { useState } from 'react';
import AllocationComponentsTitles from 'types/allocation/enums/AllocationComponentsTitles';

type AllocationEventcalendarHeaderProps = {
  handleDateClick: (selectedDate: string) => void;
};

const AllocationEventcalendarHeader = ({
  calendarDate,
  handleDateClick: handlePopoverClick
}: {
  calendarDate: MbscCalendarDayData;
} & AllocationEventcalendarHeaderProps) => {
  const [popoverElement, setPopoverElement] = useState<HTMLButtonElement | null>(null);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const showPublicHolidayButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsButtonClicked(true);
    setPopoverElement(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setIsButtonClicked(false);
    setPopoverElement(null);
  };

  const handelModalWindowOpen = () => {
    handlePopoverClick(allocationFormatDate(calendarDate.date));
    setPopoverElement(null);
    setIsButtonClicked(false);
  };

  const isOpen = popoverElement !== null && popoverElement !== undefined;

  return (
    <StyledAllocationEventcalendarHeader>
      <button
        aria-describedby={isOpen ? 'simple-popover' : ''}
        onClick={showPublicHolidayButton}
        type="button"
        className={`button ${isButtonClicked ? 'clicked' : ''}`}
      >
        <div className="text-row">
          <p className="inter-caption_medium">{getCalendarDayNumber(calendarDate.date)}</p>
          <p className="inter-caption_medium day">{getCalendarDayName(calendarDate.date)}</p>
        </div>
      </button>
      <Popover
        id="simple-popover"
        open={isOpen}
        anchorEl={popoverElement}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
      >
        <button
          onClick={handelModalWindowOpen}
          type="button"
          style={{ border: 'none', background: 'none', cursor: 'pointer' }}
        >
          <p className="inter-p3" style={{ padding: allocationPublicHolidayPopoverPadding }}>
            {AllocationComponentsTitles.AllocationPublicHolidayPopUpText}
          </p>
        </button>
      </Popover>
    </StyledAllocationEventcalendarHeader>
  );
};
export default AllocationEventcalendarHeader;
