import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import AddClientFormDefaultValue from 'components/clients/ClientAddForm/ClientAddFormDefaultValue';
import ClientFormsValidation from 'components/clients/ClientChangeForm/ClientChangeFormValidation';
import FormInput from 'components/sharedComponents/FormComponents/FormInput/FormInput';
import { Control, FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import addClient from 'store/action/clientActions/addClient';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import { setClient } from 'store/slices/clients/clientsSlice';
import { ClientFormDto } from 'types/clients/dto/ClientFormDto';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import ClientFormComponentNames from 'types/clients/enums/ClientFormComponentNames';
import InputType from 'types/generalEnums/InputType';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import ColorPicker from 'components/sharedComponents/ColorPicker/ColorPicker';
import FormTypeName from 'types/generalEnums/FormTypeName';
import ButtonsText from 'types/generalEnums/ButtonsText';
import colorsForPicker from 'constants/colorsForPicker';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import getClients from 'store/action/clientActions/getClients';

type ClientAddFormProps = {
  onClose: () => void;
};

const ClientAddForm = ({ onClose }: ClientAddFormProps) => {
  const methods = useForm({
    // TODO: fix unknown
    defaultValues: AddClientFormDefaultValue,
    resolver: yupResolver(
      ClientFormsValidation(FormTypeName.AddForm)
    ) as unknown as Resolver<ClientFormDto>
  });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors }
  } = methods;

  const loadingClient = useAppSelector((state: RootState) => state.clients.saveLoading);
  const dispatch = useThunkAppDispatch();
  useEscapeKey(onClose);
  const clients = useSelector((state: RootState) => state.clients.data);
  const onSubmit: SubmitHandler<ClientFormDto> = async (data: ClientFormDto) => {
    try {
      const newClient: ClientsDto = await dispatch(addClient(data)).unwrap();
      methods.reset();
      dispatch(setClient([...clients, newClient]));
      dispatch(getClients())
        .then(onClose)
        .catch(() => {
          logError('Error fetching clients');
        });
    } catch (error) {
      logError('Error adding client');
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)} className="modal-container">
        <Grid container spacing={2}>
          <Grid item xs={10} md={6}>
            <FormInput
              label={ClientFormComponentNames.NameLabel}
              name={ClientFormComponentNames.Name}
              type={InputType.Text}
              required
              register={register}
              className="input-form"
              error={errors.name?.message}
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <ColorPicker<ClientFormDto, ClientFormComponentNames.ColorHexName>
              label={ClientFormComponentNames.ColorHexLabel}
              name={ClientFormComponentNames.ColorHexName}
              control={control as Control<ClientFormDto>}
              error={errors.colorHex?.message}
              colors={colorsForPicker}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <h3 className="inter-caption_bold">Contact Information</h3>
          </Grid>
          <Grid item xs={10} md={6}>
            <FormInput
              label={ClientFormComponentNames.ContactNameLabel}
              name={ClientFormComponentNames.ContactName}
              type={InputType.Text}
              required
              register={register}
              className="input-form"
              error={errors.contactName?.message}
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormInput
              label={ClientFormComponentNames.ContactPositionLabel}
              name={ClientFormComponentNames.ContactPosition}
              type={InputType.Text}
              required
              register={register}
              className="input-form"
              error={errors.contactPosition?.message}
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormInput
              label={ClientFormComponentNames.ContactPhoneLabel}
              name={ClientFormComponentNames.ContactPhone}
              type={InputType.Text}
              required
              register={register}
              className="input-form"
              error={errors.contactPhone?.message}
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormInput
              label={ClientFormComponentNames.ContactEmailLabel}
              name={ClientFormComponentNames.ContactEmail}
              type={InputType.Text}
              required
              register={register}
              className="input-form"
              error={errors.contactEmail?.message}
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.Add}
          isSpinnerButtonAdded
          loading={loadingClient}
          isCancelButtonVisible
          submitButtonClassName="add-button"
          onClick={onClose}
          className="add-buttons-row"
        />
      </StyledForm>
    </FormProvider>
  );
};

export default ClientAddForm;
