import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { ApiError, logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import { useSelector } from 'react-redux';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import InputType from 'types/generalEnums/InputType';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import AllocationsFormComponentNames from 'types/allocation/dto/enums/AllocationsFormComponentNames';
import FormSwitch from 'components/sharedComponents/FormComponents/FormSwitch/FormSwitch';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import {
  calculateFridayWeeksFromNow,
  calculateTotalTime,
  calculateTotalWorkingDays,
  makeDecemberLastMonthInMaxYear
} from 'helpers/dateUtils';
import ButtonsText from 'types/generalEnums/ButtonsText';
import AllocationsAddBookingFormDto from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingFormDto';
import selectEmployeeWeeklyCapacitiesById from 'store/dictionarySelectors/selectorWeeklyCapacities';
import FormTimePicker from 'components/sharedComponents/FormComponents/FormTimePicker/FormTimePicker';
import allocationAddBooking from 'store/action/allocationsAction/allocationAddBooking';
import getAllocationsDictionaries from 'store/action/allocationsAction/getAllocationsDictionaries';
import FormTextField from 'components/sharedComponents/FormComponents/FormTextField/FormTextField';
import { unwrapResult } from '@reduxjs/toolkit';
import FormAutocomplete from 'components/sharedComponents/FormComponents/FormAutocomplete/FormAutocomplete';
import AllocationOnCellClickBookingValue from 'components/allocations/AllocationBooking/AllocationAddBookingForm/AllocationOnCellClickBookingValue';
import getProjectDictionaries from 'store/action/projectActions/getProjectDictionaries';
import getAllProjects from 'store/action/projectActions/getAllProjects';
import filterProjectsByName from 'components/allocations/consts/filterProjectsByName';
import ErrorConfirmModal from 'components/sharedComponents/ErrorConfirmModal/ErrorConfirmModal';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import { dateBeforeProjectStartDate, endDateBeforeStartDate } from 'helpers/invalidDateTest';
import { infoGrey } from 'shared/PrpIcon/icons.constants';
import TootlipName from 'components/sharedComponents/Tooltip/TooltipNames';
import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import getAllocationFormTimePickerNames from 'types/allocation/enums/AllocationFormTimePickerNames';
import FormInputNumber from 'components/sharedComponents/FormComponents/FormInput/FormInputNumber';
import { ErrorType } from 'types/ApiError/ErrorEnums';
import { convertDecimalValueToHoursAndMinutes, getMinutes } from 'helpers/calculateTime';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import AllocationBookingFormValidation from 'components/allocations/AllocationBooking/AllocationAddBookingForm/AllocationBookingFormValidation';
import AllocationBookingAddFormDefaultValue from 'components/allocations/AllocationBooking/AllocationAddBookingForm/AllocationBookingAddFormDefaultValue';
import MAX_BOOKING_TIMESPAN_IN_WEEKS from 'components/allocations/consts/dateConstants';
import FormDatePicker from 'components/sharedComponents/FormComponents/FormDatePicker/FormDatePicker';
import OnlyDateFilter from 'types/generalEnums/DatePickerFilterType';
import useEscapeKey from 'store/customHooks/useEscapeKey';

type BookingAddFormProps = {
  onClose: () => void;
  clickCellDate?: string;
  clickCellName?: number;
};

const AllocationBookingAddForm = ({
  onClose,
  clickCellName,
  clickCellDate
}: BookingAddFormProps) => {
  const [isBookingClashModalOpen, setIsBookingClashModalOpen] = useState(false);
  const [bookingError, setBookingError] = useState<ApiError | null>(null);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isAutoDiscountSelected, setIsAutoDiscountSelected] = useState(false);
  const methods = useForm({
    defaultValues:
      clickCellDate && clickCellName
        ? AllocationOnCellClickBookingValue(clickCellDate, clickCellName)
        : AllocationBookingAddFormDefaultValue,
    resolver: yupResolver(
      AllocationBookingFormValidation(isAutoDiscountSelected)
    ) as unknown as Resolver<AllocationsAddBookingFormDto>
  });
  useEscapeKey(onClose);
  const {
    handleSubmit,
    register,
    control,
    watch,
    clearErrors,
    setValue,
    getValues,
    formState: { errors }
  } = methods;
  const dispatch = useThunkAppDispatch();
  useEffect(() => {
    dispatch(getAllocationsDictionaries())
      .then(unwrapResult)
      .catch(() => logError('Error fetching allocationDictionaries'));
    dispatch(getAllProjects())
      .then(unwrapResult)
      .catch(() => logError('Error fetching projects'));
  }, []);
  const allocationsDictionaries = useAppSelector(
    (state: RootState) => state.allocationsDictionaries.data
  );
  const projectState = useAppSelector((state: RootState) => state.project.data);
  useEffect(() => {
    dispatch(getProjectDictionaries())
      .then(unwrapResult)
      .then(() => {
        dispatch(getAllProjects())
          .then(unwrapResult)
          .catch(() => logError('Error fetching projects'));
      })
      .catch(() => {
        logError('Error fetching project dictionaries');
      });
  }, []);

  const closedProjects = projectState
    .filter((project) => project.status === 1)
    .map((project) => project.name);

  const filteredProjects = filterProjectsByName(
    allocationsDictionaries.projectNames,
    closedProjects
  );
  const selectedEmployeeId = watch(AllocationsFormComponentNames.PeopleRecourses) ?? 0;
  const selectedEmployeeWeeklyCapacity = useSelector((state: RootState) =>
    selectEmployeeWeeklyCapacitiesById(
      state.allocationsDictionaries,
      selectedEmployeeId,
      DictionariesSelector.DisplayName
    )
  );
  const selectedEmployeeCapacityMinutes = selectedEmployeeWeeklyCapacity ? 0 : null;
  const [weeklyHours, setWeeklyHours] = useState<number | null>(selectedEmployeeWeeklyCapacity);

  useEffect(() => {
    setWeeklyHours(selectedEmployeeWeeklyCapacity);
  }, [selectedEmployeeWeeklyCapacity]);

  const startDateWatch = watch(AllocationsFormComponentNames.StartDate);
  const projectWatch = watch(AllocationsFormComponentNames.Project);
  const weeklyHour = watch(AllocationsFormComponentNames.HoursWeekly) || 0;
  const weeklyMins = watch(AllocationsFormComponentNames.MinutesWeekly) || 0;
  const autoDiscountDuration = watch(AllocationsFormComponentNames.AutoDiscountDuration);
  const calendarState = useAppSelector((state: RootState) => state.calendarDictionaries.data);
  const calendarMinDate = calendarState?.minDate ? new Date(calendarState.minDate) : new Date();
  const projectData = useMemo(
    () => projectState.find((project) => project.id === projectWatch),
    [projectState, projectWatch]
  );
  const startDateFromProject = projectData ? new Date(projectData.startDate) : new Date();
  const startSelectedDate = watch(AllocationsFormComponentNames.StartDate);
  const endSelectedDate = watch(AllocationsFormComponentNames.EndDate);
  const minDate = projectWatch ? startDateFromProject : calendarMinDate;
  const maxDate = startSelectedDate
    ? calculateFridayWeeksFromNow(MAX_BOOKING_TIMESPAN_IN_WEEKS, new Date(startSelectedDate))
    : makeDecemberLastMonthInMaxYear(new Date(calendarState?.maxDate ?? new Date()));
  const numOfDaysSelected = calculateTotalWorkingDays(
    new Date(startSelectedDate),
    new Date(endSelectedDate)
  );
  const totalTime = calculateTotalTime(numOfDaysSelected, Number(weeklyHour), Number(weeklyMins));
  const totalDiscountTime = calculateTotalTime(
    numOfDaysSelected,
    convertDecimalValueToHoursAndMinutes(Number(autoDiscountDuration)).hours,
    convertDecimalValueToHoursAndMinutes(Number(autoDiscountDuration)).minutes
  );
  useEffect(() => {
    if (weeklyMins && weeklyHour === 0 && weeklyMins >= 30) {
      clearErrors(AllocationsFormComponentNames.HoursWeekly);
    }
  }, [weeklyHours, weeklyMins]);
  const loadingBooking = useAppSelector((state: RootState) => state.allocationBooking.saveLoading);
  const isDiscountErrorVisible =
    getMinutes(Number(weeklyHour)) + weeklyMins < getMinutes(Number(autoDiscountDuration)) &&
    isAutoDiscountSelected;
  const onSubmit: SubmitHandler<AllocationsAddBookingFormDto> = async (
    data: AllocationsAddBookingFormDto
  ) => {
    if (dateBeforeProjectStartDate(startDateWatch, minDate, projectWatch) || isDiscountErrorVisible)
      return;
    try {
      data.startDate = data.startDate.endsWith('Z') ? data.startDate : `${data.startDate}Z`;
      data.endDate = data.endDate.endsWith('Z') ? data.endDate : `${data.endDate}Z`;
      await dispatch(allocationAddBooking({ data, force: isBookingClashModalOpen })).unwrap();
      onClose();
    } catch (error) {
      if (error instanceof ApiError && error.type === ErrorType.Confirmation) {
        setIsBookingClashModalOpen(true);
        setBookingError(error);
      }

      logError('Error adding booking');
    }
  };
  const closeModal = () => {
    setIsBookingClashModalOpen(false);
  };
  const handleClashSubmit = useCallback(async () => {
    const formData = watch();
    await onSubmit(formData);
    closeModal();
  }, [watch, onSubmit, closeModal]);
  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };
  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };
  useEffect(() => {
    setValue(AllocationsFormComponentNames.HoursWeekly, weeklyHours || 0);
    setValue(AllocationsFormComponentNames.MinutesWeekly, selectedEmployeeCapacityMinutes || 0);
  }, [weeklyHours, selectedEmployeeCapacityMinutes]);
  const autoDiscountWatch = watch(AllocationsFormComponentNames.AutoDiscount);
  useEffect(() => {
    setIsAutoDiscountSelected(autoDiscountWatch);
  }, [autoDiscountWatch]);
  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} columns={20} className="modal-container">
          <Grid item xs={20} md={20}>
            <FormAutocomplete
              title={AllocationsFormComponentNames.PeopleRecourses}
              label={AllocationsFormComponentNames.PeopleRecoursesLabel}
              control={control}
              options={allocationsDictionaries.bookableEmployeeNames || []}
              className="allocation-form-select"
              error={errors.employeeId?.message}
            />
          </Grid>
          <Grid item xs={11} md={11}>
            <div className="time-input-row">
              <FormTimePicker
                dataToDisplay={getAllocationFormTimePickerNames()}
                required
                register={register}
                className="input-form-time"
                error={errors.hours?.message}
                hoursValue={weeklyHour}
                minsValue={weeklyMins}
                caption="per week"
              />
            </div>
          </Grid>
          <Grid item xs={10} md={9}>
            <div className="booking-dates">
              <FormDatePicker
                name={AllocationsFormComponentNames.StartDate}
                control={control}
                label={AllocationsFormComponentNames.FromDateLabel}
                className="allocation-form-date"
                error={errors.startDate?.message}
                minDate={minDate}
                maxDate={maxDate}
                filter={OnlyDateFilter.Mondays}
              />
              <FormDatePicker
                name={AllocationsFormComponentNames.EndDate}
                control={control}
                label={AllocationsFormComponentNames.ToDateLabel}
                className="allocation-form-date"
                error={errors.endDate?.message}
                minDate={minDate}
                maxDate={maxDate}
                filter={OnlyDateFilter.Fridays}
                isEndDatePicker
              />
            </div>
            {endDateBeforeStartDate(new Date(startSelectedDate), new Date(endSelectedDate)) && (
              <span className="error-message error inter-caption_medium">
                {FormValidationMessage.EndDateBeforeStartDateError}
              </span>
            )}
            {dateBeforeProjectStartDate(startDateWatch, minDate, projectWatch) && (
              <span className="error-message error inter-caption_medium">
                {FormValidationMessage.DateBeforeProjectStartDateError}
              </span>
            )}
          </Grid>
          <Grid item xs={16} md={10}>
            <p className="inter-caption-grey total-time-caption">
              Total: {totalTime[0]}h {totalTime[1]}m ({numOfDaysSelected} days)
            </p>
          </Grid>
          <Grid item xs={16} md={20}>
            <div className="switch-row inter-caption_medium">
              <p className="inter-caption_medium booking-confirmed-label">
                {AllocationsFormComponentNames.TentativeLabel}
              </p>
              <FormSwitch name={AllocationsFormComponentNames.Confirmed} control={control} />
              <p className="inter-caption_medium booking-confirmed-label">
                {AllocationsFormComponentNames.ConfirmedLabel}
              </p>
            </div>
          </Grid>
          <Grid item xs={16} md={20}>
            <div className="switch-row inter-caption_medium">
              <FormSwitch name={AllocationsFormComponentNames.AutoDiscount} control={control} />
              <p className="inter-caption_medium booking-confirmed-label">
                {AllocationsFormComponentNames.AutoDiscountLabel}
              </p>
              <CustomTooltip
                icon={infoGrey}
                onClose={handleCloseTooltip}
                onOpen={handleOpenTooltip}
                isTooltipOpen={openTooltip}
                title={TootlipName.ApplyAutoDiscountTitle}
                placement="bottom"
              />
            </div>
          </Grid>
          {autoDiscountWatch && (
            <div className="auto-discount-container">
              <Grid item xs={12} md={7}>
                <div className="discount-time-input-row">
                  <FormInputNumber
                    label={AllocationsFormComponentNames.AutoDiscountDurationLabel}
                    name={AllocationsFormComponentNames.AutoDiscountDuration}
                    required
                    register={register}
                    getValues={getValues}
                    error={errors.autoDiscountDuration?.message}
                    minValue={0}
                    className="discount-input-form"
                    isDecimal
                    numberOfDigitsAfterDecimal={2}
                  />
                </div>
              </Grid>
              <Grid item xs={16} md={16}>
                <p className="inter-caption-grey total-time-caption">
                  Total discounted: {totalDiscountTime[0]}h {totalDiscountTime[1]}m (out of{' '}
                  {totalTime[0]}h {totalTime[1]}m)
                </p>
              </Grid>
              {isDiscountErrorVisible && (
                <Grid item xs={16} md={16}>
                  <p className="discount-error">{FormValidationMessage.DiscountTimeError}</p>
                </Grid>
              )}
            </div>
          )}
          <Grid item xs={20} md={20}>
            <FormAutocomplete
              control={control}
              title={AllocationsFormComponentNames.Project}
              label={AllocationsFormComponentNames.ProjectLabel}
              options={filteredProjects || []}
              className="allocation-form-select"
              error={errors.projectId?.message}
            />
          </Grid>
          <Grid item xs={16} md={20}>
            <FormTextField
              label={AllocationsFormComponentNames.BookingDetailsLabel}
              name={AllocationsFormComponentNames.BookingDetails}
              type={InputType.Text}
              required
              register={register}
              className="input-form-allocation-details"
              error={errors.details?.message}
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.AddBooking}
          isSpinnerButtonAdded
          loading={loadingBooking}
          isCancelButtonVisible
          submitButtonClassName="add-button"
          onClick={onClose}
          spinnerClassName="spinner-allocation-form"
          isSubmitButtonDisabled={endDateBeforeStartDate(
            new Date(startSelectedDate),
            new Date(endSelectedDate)
          )}
        />
      </StyledForm>
      {isBookingClashModalOpen && bookingError && (
        <ErrorConfirmModal
          onClose={closeModal}
          onSubmit={handleClashSubmit}
          header={bookingError.header}
          body={bookingError.message}
        />
      )}
    </FormProvider>
  );
};

export default AllocationBookingAddForm;
