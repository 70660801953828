export enum ErrorType {
  Confirmation = 'CONFIRMATION',
  INFORMATION = 'INFORMATION'
}

export enum ErrorStatus {
  BAD_REQUEST = 'BAD_REQUEST',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR'
}
