import { addGrey, dropdownArrow } from 'shared/PrpIcon/icons.constants';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import EmployeeAddForm from 'components/employee/EmployeeAddForm/EmployeeAddForm';
import { ModalContext, ModalContextType } from 'modals/ModalProvider';
import ModalWindow from 'modals/ModalWindow';
import PageType from 'types/generalEnums/PageType';
import { useNavigate } from 'react-router-dom';
import {
  allocationModalWindowWidth,
  topPanelModalWindowHeight,
  topPanelModalWindowWidth,
  uploadActualsModalHeight,
  uploadActualsModalWidth
} from 'components/sharedComponents/StyleConsts/styleConsts';
import EmployeeModalWindowTitle from 'types/employee/enums/EmployeeModalWindowTitle';
import { ACCESS_TOKEN_LOCAL_STORAGE_KEY } from 'environment/environment';
import RoutingPath from 'routes/routing';
import ClientAddForm from 'components/clients/ClientAddForm/ClientAddForm';
import ClientModalWindowTitle from 'types/clients/enums/ClientModalWindowTitle';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import logOut from 'store/action/authorizationAction/logOut';
import { RootState, ThunkAppDispatch } from 'store';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { useAppSelector } from 'store/redux-hooks/hooks';
import getUser from 'store/action/authorizationAction/getUsers';
import Initials from 'components/sharedComponents/Initials/Initials';
import PermissionsName from 'types/generalEnums/PermissionsName';
import doesUserHavePermissions from 'helpers/permissionService';
import AddProjectForm from 'components/project/AddProjectForm/AddProjectForm';
import ProjectModalWindowTitle from 'types/project/enums/ProjectModalWindowTitle';
import AllocationsModalWindowTitle from 'types/allocation/dto/enums/AllocationsModalWindowTitle';
import AllocationBookingAddForm from 'components/allocations/AllocationBooking/AllocationAddBookingForm/AllocationBookingAddForm';
import GenericPopupWithButtons from 'modals/GenericPopupWithButtons';
import allocationPopupItems from 'types/allocation/enums/AllocationsPopUpItems';
import AllocationTimeOffAddForm from 'components/allocations/AllocationTimeOff/AllocationTimeOffAddForm/AllocationTimeOffAddForm';
import UploadActualsForm from 'components/project/UploadActuals/UploadActualsForm';
import getImportAzdoStatus from 'store/action/projectActions/getImportAzdoStatus';
import ImportActualsStatusEnum from 'types/uploadActuals/uploadActualsEnum';
import getStatusColor from 'helpers/getStatusColor';
import { getLastImportDateOrPlaceholder } from 'helpers/dateUtils';
import StyledTopPanel from './StyledTopPanel';

type TopPanelProps = {
  title?: string;
  itemsCount?: number;
  addButtonTitle?: string;
  showAddButtonPermissions: PermissionsName[];
  type:
    | PageType.Employees
    | PageType.Clients
    | PageType.Project
    | PageType.ProjectDetails
    | PageType.Allocation;
  className?: string;
  isBookingModalOpen?: boolean;
  isTimeOffModalOpen?: boolean;
  setIsTimeOffModalOpen?: Dispatch<SetStateAction<boolean>>;
  setIsBookingModalOpen?: Dispatch<SetStateAction<boolean>>;
  setIsBookingOrTimeOffAddedByButton?: Dispatch<SetStateAction<boolean>>;
  onCellClickWindowOpen?: boolean;
};

export const getImportStatusName = (status: string | undefined): string => {
  switch (status) {
    case ImportActualsStatusEnum.IN_PROGRESS:
      return 'In Progress';
    case ImportActualsStatusEnum.FAILED:
      return 'Failed';
    case ImportActualsStatusEnum.COMPLETED:
      return 'Completed';
    default:
      return 'Not imported yet';
  }
};

const TopPanel = ({
  title,
  itemsCount,
  addButtonTitle,
  type,
  showAddButtonPermissions,
  className,
  isTimeOffModalOpen,
  isBookingModalOpen,
  setIsBookingModalOpen,
  setIsTimeOffModalOpen,
  setIsBookingOrTimeOffAddedByButton,
  onCellClickWindowOpen
}: TopPanelProps): JSX.Element => {
  const [isUploadActualsModalOpen, setIsUploadActualsModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch<ThunkAppDispatch>();
  const actualsUploadedSuccessfully = () => {
    dispatch(getImportAzdoStatus())
      .then(unwrapResult)
      .catch(() => {
        logError('Error fetching import status');
      });
  };
  const handleAddBookingButtonClick = () => {
    if (setIsBookingModalOpen && setIsBookingOrTimeOffAddedByButton) {
      setIsBookingModalOpen(true);
      setIsBookingOrTimeOffAddedByButton(true);
    }
    setIsModalOpen(true);
  };
  const handleAddTimeOffButtonClick = () => {
    if (setIsTimeOffModalOpen && setIsBookingOrTimeOffAddedByButton) {
      setIsTimeOffModalOpen(true);
      setIsBookingOrTimeOffAddedByButton(true);
    }
    setIsModalOpen(true);
  };
  const { showModal } = useContext(ModalContext) as ModalContextType;
  const user = useAppSelector((state: RootState) => state.auth.user);
  const importState = useAppSelector((state: RootState) => state.actuals.data);
  const canUserImportActuals = user?.permissions.some(
    (permission) => permission.name === PermissionsName.ProjectManager
  );

  useEffect(() => {
    showModal(isBookingModalOpen);
  }, [isBookingModalOpen, showModal]);

  const closeModal = () => {
    if (setIsBookingModalOpen) {
      setIsBookingModalOpen(false);
    }
    if (setIsTimeOffModalOpen) {
      setIsTimeOffModalOpen(false);
    }
    if (setIsBookingOrTimeOffAddedByButton) {
      setIsBookingOrTimeOffAddedByButton(false);
    }
    setIsModalOpen(false);
    setIsUploadActualsModalOpen(false);
  };
  const navigate = useNavigate();

  const logoutHandler = () => {
    dispatch(logOut())
      .then(unwrapResult)
      .then(() => {
        localStorage.removeItem(ACCESS_TOKEN_LOCAL_STORAGE_KEY);
        navigate(RoutingPath.LogInPage);
      })
      .catch(() => {
        logError('Logout failed');
      });
  };

  useEffect(() => {
    dispatch(getUser())
      .then(unwrapResult)
      .catch(() => {
        logError('Error fetching user');
      });
    dispatch(getImportAzdoStatus())
      .then(unwrapResult)
      .catch(() => {
        logError('Error fetching import status');
      });
  }, []);

  const showAddButton = doesUserHavePermissions(user, showAddButtonPermissions);
  const onProjectDetailsClick = () => {
    navigate(RoutingPath.ProjectsPage, { replace: true });
  };

  return (
    <StyledTopPanel className={className}>
      <div className="top-div">
        <button className="logout-button" type="button" onClick={logoutHandler}>
          <p className="inter-p3"> Log out </p>
        </button>
        <Initials name={user?.name ?? ''} />
      </div>
      <div className="bottom-div">
        {type === PageType.ProjectDetails ? (
          <button type="button" className="project-details-button" onClick={onProjectDetailsClick}>
            <PrpIcon src={dropdownArrow} />
            <p className="inter-p3"> Back to all projects </p>
          </button>
        ) : null}
        {type === PageType.ProjectDetails ? null : (
          <>
            <h1 className="inter-h2 ">{title}</h1>
            {type === PageType.Allocation ? null : <div className="items-count">{itemsCount}</div>}
            {showAddButton && type === PageType.Allocation ? (
              <div>
                <GenericPopupWithButtons
                  buttonLabel={addButtonTitle || ''}
                  buttonIcon={addGrey}
                  buttonClassName="adding-button"
                  items={allocationPopupItems}
                  onClickFunctions={[handleAddBookingButtonClick, handleAddTimeOffButtonClick]}
                />
                {isBookingModalOpen && !onCellClickWindowOpen ? (
                  <ModalWindow
                    onClose={closeModal}
                    headerTitle={AllocationsModalWindowTitle.BookingFormTitle}
                    style={{ width: allocationModalWindowWidth }}
                  >
                    <AllocationBookingAddForm onClose={closeModal} />
                  </ModalWindow>
                ) : null}
                {isTimeOffModalOpen && !onCellClickWindowOpen ? (
                  <ModalWindow
                    onClose={closeModal}
                    headerTitle={AllocationsModalWindowTitle.TimeOffFormTitle}
                    style={{ width: allocationModalWindowWidth }}
                  >
                    <AllocationTimeOffAddForm onClose={closeModal} />
                  </ModalWindow>
                ) : null}
              </div>
            ) : null}
            {showAddButton && !(type === PageType.Allocation) ? (
              <div className="header-with-buttons">
                <button
                  className="adding-button"
                  type="button"
                  onClick={handleAddBookingButtonClick}
                >
                  <PrpIcon src={addGrey} />
                  <p>{addButtonTitle}</p>
                </button>
                <div>
                  {type === PageType.Employees && isModalOpen && (
                    <ModalWindow
                      onClose={closeModal}
                      headerTitle={EmployeeModalWindowTitle.EmployeeAddFormTitle}
                      style={{ width: topPanelModalWindowWidth }}
                    >
                      <EmployeeAddForm onClose={closeModal} />
                    </ModalWindow>
                  )}
                  {type === PageType.Clients && isModalOpen && (
                    <ModalWindow
                      onClose={closeModal}
                      headerTitle={ClientModalWindowTitle.ClientAddFormTitle}
                      style={{ width: topPanelModalWindowWidth, height: topPanelModalWindowHeight }}
                    >
                      <ClientAddForm onClose={closeModal} />
                    </ModalWindow>
                  )}
                  {type === PageType.Project && isModalOpen && (
                    <ModalWindow
                      onClose={closeModal}
                      headerTitle={ProjectModalWindowTitle.ProjectAddFormTitle}
                      style={{ width: topPanelModalWindowWidth }}
                    >
                      <AddProjectForm onClose={closeModal} />
                    </ModalWindow>
                  )}
                  {type === PageType.Project && (
                    <>
                      <button
                        className={
                          canUserImportActuals ? 'upload-button' : 'upload-button-disabled'
                        }
                        type="button"
                        disabled={!canUserImportActuals}
                        onClick={() => setIsUploadActualsModalOpen(true)}
                      >
                        <p>Import Actuals From AzDO</p>
                      </button>
                      {importState.importStatus && (
                        <div className="status-row">
                          <div
                            style={{
                              backgroundColor: getStatusColor(importState.importStatus)
                            }}
                            className="status-icon"
                          />
                          <p className="status">
                            {getImportStatusName(importState.importStatus)}{' '}
                            {getLastImportDateOrPlaceholder(importState.lastImportDate)}
                          </p>
                        </div>
                      )}
                    </>
                  )}
                  {isUploadActualsModalOpen ? (
                    <ModalWindow
                      onClose={closeModal}
                      headerTitle={ProjectModalWindowTitle.UploadActualsFormTitle}
                      style={{ width: uploadActualsModalWidth, height: uploadActualsModalHeight }}
                      disableScroll
                    >
                      <UploadActualsForm
                        onClose={closeModal}
                        actualsUploadedSuccessfully={actualsUploadedSuccessfully}
                      />
                    </ModalWindow>
                  ) : null}
                </div>
              </div>
            ) : null}
          </>
        )}
      </div>
    </StyledTopPanel>
  );
};

export default TopPanel;
