import * as yup from 'yup';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import { reasonPattern } from 'constants/validationConstants';

const EditEmployeeRateValidation = (minRate: number, maxRate: number) => {
  return yup.object().shape({
    startDate: yup.string().required(FormValidationMessage.DateError),
    rate: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(FormValidationMessage.RateRangeError)
      .min(minRate, FormValidationMessage.RateRangeError)
      .max(maxRate, FormValidationMessage.RateRangeError),
    reason: yup
      .string()
      .required(FormValidationMessage.ReasonError)
      .matches(reasonPattern, FormValidationMessage.ReasonMaxLengthError)
  });
};

export default EditEmployeeRateValidation;
