export const TEXT_COLOR = '#404055';
export const LOW_EMPHASISE_COLOR = '#868698';
export const BORDERS_COLOR = '#DADAEA';
export const BACKGROUND_COLOR = '#F7F7FA';
export const SURFACE_COLOR = '#FFFFFF';
export const ON_PRIMARY_COLOR = '#FFFFFF';
export const PRIMARY_COLOR = '#20202C';
export const ELEMENTS_COLOR = '#EBEBFD';
export const ALERT_COLOR = '#FF4667';
export const ALERT_HOVER_COLOR = '#E03E5B';
export const ALERT_BACKGROUND_COLOR = '#FBDBE1';
export const SUCCESS_COLOR = '#46D6B3';
export const SUCCESS_HOVER_COLOR = '#42BA9D';
export const SUCCESS_BACKGROUND_COLOR = '#B4F2E3';
export const CAUTION_COLOR = '#FFC046';
export const CAUTION_HOVER_COLOR = '#E7B14A';
export const CAUTION_BACKGROUND_COLOR = '#FFECC8';
export const DARK_GREY = '#A9A9A9';
export const LIGHT_GREY = '#F9F8F780';
export const CAUTION_HOVER = '#E09C39';
export const GREEN_BACKGROUND_COLOR = '#DAF5EC';
export const GREEN_TEXT_COLOR = '#2C816D';
