import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { LOW_EMPHASISE_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledFormAutocomplete = styled.div({
  '& .MuiAutocomplete-root .MuiOutlinedInput-root': {
    borderRadius: borderRadiusCommon,
    paddingTop: '0px',
    paddingBottom: '0px'
  },
  '.label': {
    color: LOW_EMPHASISE_COLOR
  }
});

export default StyledFormAutocomplete;
