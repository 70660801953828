import {
  notFoundPageImgHeight,
  notFoundPageImgWidth
} from 'components/sharedComponents/StyleConsts/styleConsts';
import StyledNotFoundPage from 'pages/NotFoundPage/StyledNotFoundPage';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { notFound } from 'shared/PrpIcon/icons.constants';

const NotFoundPage = () => {
  return (
    <StyledNotFoundPage className="main-section">
      <div className="content">
        <PrpIcon
          src={notFound}
          width={notFoundPageImgWidth}
          height={notFoundPageImgHeight}
          className="notfound-page-img"
        />
        <h3 className="inter-h2">Oops!</h3>
        <p className="inter-p3">The page doesn’t exist</p>
      </div>
    </StyledNotFoundPage>
  );
};

export default NotFoundPage;
