import * as yup from 'yup';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';

const TimeOffFormDeleteValidation = () => {
  return yup.object().shape({
    deleteFutureTimeOffs: yup.boolean().required(FormValidationMessage.TypeChoiceError)
  });
};

export default TimeOffFormDeleteValidation;
