import AllocationsAddBookingFormDto from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingFormDto';
import { getTargetDayOfWeek } from 'helpers/dateUtils';
import WeekDayNumber from 'helpers/weekDayEnum';

const AllocationOnCellClickBookingValue = (
  clickCellDate: string,
  clickCellName: number
): AllocationsAddBookingFormDto => {
  const defaultStartDate = getTargetDayOfWeek(clickCellDate, WeekDayNumber.MONDAY);
  const defaultEndDate = getTargetDayOfWeek(clickCellDate, WeekDayNumber.FRIDAY);
  return {
    id: null,
    hours: null,
    projectId: null,
    employeeId: clickCellName,
    startDate: defaultStartDate,
    endDate: defaultEndDate,
    minutes: null,
    details: '',
    confirmed: true,
    weekId: null,
    isAutoDiscountApplied: false,
    autoDiscountDuration: 0
  };
};

export default AllocationOnCellClickBookingValue;
