import { FormControl, InputBaseComponentProps, TextField } from '@mui/material';
import StyledFormTextField from 'components/sharedComponents/FormComponents/FormTextField/StyledFormTextField';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';

type FormTextFieldProps<T extends FieldValues> = {
  label?: string;
  name: string;
  type: string;
  required: boolean;
  register: UseFormRegister<T>;
  className?: string;
  error?: string;
  inputProps?: InputBaseComponentProps & React.InputHTMLAttributes<HTMLInputElement>['style'];
};

const FormTextField = <T extends FieldValues>({
  label,
  name,
  type,
  required,
  register,
  error,
  className,
  inputProps
}: FormTextFieldProps<T>) => {
  return (
    <FormControl>
      <StyledFormTextField>
        <p className="inter-caption_medium label">{label}</p>
        <TextField
          id={name as Path<T>}
          {...register(name as Path<T>, { required })}
          type={type}
          className={className}
          maxRows={4}
          multiline
          inputProps={inputProps}
        />
        {error && <p className="error inter-caption_medium">{error}</p>}
      </StyledFormTextField>
    </FormControl>
  );
};

export default FormTextField;
