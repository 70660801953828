const allocationFormatDate = (date: Date, addOneDay = true) => {
  const originalDate = new Date(date);

  if (addOneDay) {
    originalDate.setDate(originalDate.getDate() + 1);
  } else {
    originalDate.setDate(originalDate.getDate());
  }

  const formattedDate = originalDate.toISOString().slice(0, 10);
  return formattedDate;
};

export default allocationFormatDate;
