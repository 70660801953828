import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import addAllocationPublicHoliday from 'store/action/allocationsAction/addAllocationPublicHoliday';
import deletePublicHoliday from 'store/action/allocationsAction/deletePublicHoliday';
import getOnePublicHoliday from 'store/action/allocationsAction/getPublicHoliday';
import updatePublicHoliday from 'store/action/allocationsAction/updatePublicHoliday';
import { AllocationPublicHolidayState } from 'store/slicesState/allocationPublicHoliday';
import AllocationPublicHolidayDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayDto';

const initialState: AllocationPublicHolidayState = {
  data: {
    id: 0,
    date: '',
    details: '',
    dbid: 0
  },
  readLoading: false,
  saveLoading: false,
  error: null
};

export type DispatchError = {
  errorMessage: string;
};

const allocationPublicHolidaySlice = createSlice({
  name: 'allocationPublicHoliday',
  initialState,
  reducers: {
    updateHoliday: (state, action: PayloadAction<AllocationPublicHolidayDto>) => {
      state.data = action.payload;
    },
    addHoliday: (state, action: PayloadAction<AllocationPublicHolidayDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addAllocationPublicHoliday.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(addAllocationPublicHoliday.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(addAllocationPublicHoliday.rejected, (state) => {
      state.error = 'addAllocationPublicHoliday error in slice';
      state.saveLoading = false;
    });
    builder.addCase(updatePublicHoliday.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(updatePublicHoliday.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(updatePublicHoliday.rejected, (state) => {
      state.error = 'updatePublicHoliday error in slice';
      state.saveLoading = false;
    });
    builder.addCase(getOnePublicHoliday.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getOnePublicHoliday.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.readLoading = false;
    });
    builder.addCase(getOnePublicHoliday.rejected, (state) => {
      state.error = 'getPublicHoliday error in slice';
      state.readLoading = false;
    });
    builder.addCase(deletePublicHoliday.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(deletePublicHoliday.fulfilled, (state) => {
      state.error = null;
      state.readLoading = false;
    });
    builder.addCase(deletePublicHoliday.rejected, (state) => {
      state.error = 'deletePublicHoliday error in slice';
      state.readLoading = false;
    });
  }
});

export const { updateHoliday, addHoliday } = allocationPublicHolidaySlice.actions;
export default allocationPublicHolidaySlice.reducer;
