import { GREY_SIXTY, VIOLET_BLACK } from 'constants/groupColors';
import styled from 'styled-components';

const StyledSpinnerButton = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '.spinner-button': {
    pointerEvents: 'none',
    width: '15px',
    height: '15px',
    border: '3px solid transparent',
    borderColor: GREY_SIXTY,
    borderTopColor: VIOLET_BLACK,
    borderRadius: '50%',
    animation: 'spinner 1s linear infinite'
  },
  '@keyframes spinner-button': {
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
});

export default StyledSpinnerButton;
