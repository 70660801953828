import styled from 'styled-components';
import { BACKGROUND_COLOR, LOW_EMPHASISE_COLOR, SURFACE_COLOR, TEXT_COLOR } from 'constants/colors';
import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';

const StyledTopPanel = styled.header({
  backgroundColor: BACKGROUND_COLOR,
  color: TEXT_COLOR,
  padding: '6px 20px',
  height: '128px',
  maxWidth: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: 'solid 1px #dadaea',
  '.top-div': {
    display: 'flex',
    gap: '26px',
    alignSelf: 'end',
    '.user-img': {
      width: '32px',
      height: '32px',
      background: TEXT_COLOR,
      borderRadius: '50%'
    }
  },
  '.bottom-div': {
    width: '100%',
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    alignSelf: 'start',
    '.items-count': {
      color: SURFACE_COLOR,
      padding: '4px 10px',
      backgroundColor: TEXT_COLOR,
      borderRadius: '20px'
    }
  },
  '.logout-button': {
    borderRadius: borderRadiusCommon,
    backgroundColor: TEXT_COLOR,
    border: 'none',
    padding: '5px 10px',
    cursor: 'pointer',
    p: {
      margin: '0px',
      color: SURFACE_COLOR
    }
  },
  '.adding-button': {
    display: 'flex',
    alignItems: 'center',
    background: 'none',
    gap: '10px',
    border: 'none',
    ':hover': {
      cursor: 'pointer'
    }
  },
  '.project-details-button': {
    border: 'none',
    background: 'none',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer'
  },
  '.header-with-buttons': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '.upload-button, .upload-button-disabled': {
      fontWeight: '600',
      color: TEXT_COLOR,
      backgroundColor: 'transparent',
      border: 'none',
      cursor: 'pointer'
    },
    '.upload-button-disabled': {
      color: LOW_EMPHASISE_COLOR,
      cursor: 'none'
    }
  },
  '.status-row': {
    display: 'flex',
    marginTop: '-20px',
    '.status': {
      fontSize: '12px',
      marginLeft: '-6px'
    }
  },
  '.color': {
    width: '6px',
    height: '32px',
    borderRadius: borderRadiusCommon,
    alignSelf: 'center',
    marginRight: '12px'
  },
  '.status-icon': {
    width: '12px',
    height: '12px',
    borderRadius: '100%',
    alignSelf: 'center',
    marginRight: '12px'
  }
});

export default StyledTopPanel;
