import AllocationTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffFormDto';

const AllocationOnCellClickTimeOffValue = (
  clickCellDate: string,
  clickCellName: number
): AllocationTimeOffFormDto => {
  return {
    employeeId: clickCellName,
    startDate: clickCellDate,
    endDate: clickCellDate,
    details: '',
    type: '',
    id: null
  };
};

export default AllocationOnCellClickTimeOffValue;
