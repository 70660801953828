import { createAsyncThunk } from '@reduxjs/toolkit';
import projectDictionariesApiService from 'api/projectDicitionaryApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import getProjectDictionariesWithKeys from 'store/mappers/project/projectDictionariesMapper';
import { DispatchError } from 'store/slices/authSlice';
import DictionariesAction from 'types/employee/enums/Dictionaries';
import { ProjectDictionariesDto } from 'types/project/dto/ProjectDictionariesDto';

const getProjectDictionaries = createAsyncThunk<
  ProjectDictionariesDto,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(DictionariesAction.FETCH_PROJECT_DICTIONARIES, async () => {
  const response = await projectDictionariesApiService.fetchProjectDictionaries();
  if (!response) notifyUser();
  return getProjectDictionariesWithKeys(response);
});

export default getProjectDictionaries;
