import { ReactItem } from 'types/ReactItem';

export type InvoiceDictionariesDto = {
  permittedStatuses: InvoiceStatus[];
} & ReactItem;

export enum InvoiceStatus {
  NEW = 'NEW',
  READY_FOR_APPROVAL = 'READY_FOR_APPROVAL',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  ISSUED = 'ISSUED'
}
