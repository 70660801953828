import { createAsyncThunk } from '@reduxjs/toolkit';
import notesApiService from 'api/notesApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import getReactItemsWithKey from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/employee/employeeSlice';
import { ClientNoteDto } from 'types/clients/dto/ClientNoteDto';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import NotesAction from 'types/clients/enums/Notes';

const getNotes = createAsyncThunk<
  ClientNoteDto[],
  ClientsDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(NotesAction.FETCH_ALL, async (data: ClientsDto) => {
  const response = await notesApiService.fetchClientsNotes(data);
  if (!response) notifyUser();
  return getReactItemsWithKey(response);
});

export default getNotes;
