import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { RootState } from 'store';
import selectprojectManagersPropertyById from 'store/dictionarySelectors/selectorProjectManagers';
import { useAppSelector } from 'store/redux-hooks/hooks';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import { ProjectDto } from 'types/project/dto/ProjectDto';
import selectClientsPropertyById from 'store/dictionarySelectors/selectorClients';
import Initials from 'components/sharedComponents/Initials/Initials';
import selectStatusPropertyById from 'store/dictionarySelectors/selectorStatus';
import getStatusColor from 'helpers/getStatusColor';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ProjectListMultiplyButton from 'components/project/ProjectList/ProjectListMultiplyButton';
import StyledProjectList from 'components/project/ProjectList/StyledProjectList';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { selectProject } from 'store/slices/project/projectSlice';
import RoutingPath from 'routes/routing';
import selectClientColorsById from 'store/dictionarySelectors/selectorClientColors';
import ProjectListColumnNames from 'types/project/enums/ProjectListColumnNames';
import { clockGrey } from 'shared/PrpIcon/icons.constants';
import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import AllocationFilterDashboardNames from 'types/allocation/enums/AllocationFilterDashboardNames';
import allocationFormatDate from 'pages/AllocationsPage/helpers/allocationFormatDate';
import { getFirstDayOfMonth, getLastDayOfMonth } from 'helpers/dateUtils';
import getSearchParams from 'helpers/setSearchParams';
import { ProjectDictionariesState } from 'store/slicesState/projectDictionaries';

type ProjectListProps = {
  items: ProjectDto[];
  setItemsCount: Dispatch<SetStateAction<number>>;
  projectDictionariesState: ProjectDictionariesState;
};

const ProjectList = ({ items, setItemsCount, projectDictionariesState }: ProjectListProps) => {
  const user = useAppSelector((state: RootState) => state.auth.user);
  const [showUserProjects, setShowUserProjects] = useState(false);
  const [showBillable, setShowBillable] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [filteredProjects, setFilteredProjects] = useState<ProjectDto[]>(items);
  const [isGoToAllocationsTooltipOpen, setIsGoToAllocationsTooltipOpen] = useState<number | null>(
    null
  );
  const [selectedColumn, setSelectedColumn] = useState<ProjectListColumnNames>(
    ProjectListColumnNames.ProjectName
  );
  const navigate = useNavigate();

  const getProjectManagerDisplayName = (projectId: number) => {
    return selectprojectManagersPropertyById(
      projectDictionariesState,
      projectId,
      DictionariesSelector.DisplayName
    );
  };

  const getClientDisplayName = (projectId: number) => {
    return selectClientsPropertyById(
      projectDictionariesState,
      projectId,
      DictionariesSelector.DisplayName
    );
  };

  const getStatusDisplayName = (projectId: number): string => {
    const displayName = selectStatusPropertyById(
      projectDictionariesState,
      projectId,
      DictionariesSelector.DisplayName
    );
    return displayName ?? '';
  };

  const getClientColorDisplayName = (id: number | undefined): string => {
    const displayName =
      id !== undefined
        ? selectClientColorsById(projectDictionariesState, id, DictionariesSelector.DisplayName)
        : null;

    return displayName ?? '';
  };

  const filterByStatus = (projects: ProjectDto[]) => {
    if (selectedStatus.length === 0) {
      return projects;
    }
    return projects.filter((pr: ProjectDto) =>
      selectedStatus.includes(getStatusDisplayName(pr.status))
    );
  };

  const filterProjects = () => {
    let projects = items;

    if (showUserProjects && !showBillable) {
      projects = projects.filter((pr) => pr.projectManagerId === user?.id && !pr.billable);
    } else if (showUserProjects) {
      projects = projects.filter((pr) => pr.projectManagerId === user?.id);
    } else if (!showBillable) {
      projects = projects.filter((pr) => !pr.billable);
    }
    setItemsCount(filterByStatus(projects).length);
    return filterByStatus(projects);
  };

  useEffect(() => {
    setSelectedColumn(ProjectListColumnNames.ProjectName);
    setFilteredProjects(filterProjects());
  }, [showUserProjects, showBillable, items, selectedStatus]);

  const toggleFilter = () => {
    setShowUserProjects(!showUserProjects);
  };

  const toggleBillable = () => {
    setShowBillable(!showBillable);
  };

  const handleStatusChange = (_e: unknown, value: string[]) => {
    setSelectedStatus(value);
  };

  const dispatch = useDispatch();

  const goToProjectDetails = (project: ProjectDto) => {
    dispatch(selectProject(project.id));
    navigate(RoutingPath.ProjectDetailsPage.replace(':projectId', String(project.id)));
  };

  const onColumnTitleClick = (title: ProjectListColumnNames) => {
    if (selectedColumn !== title) {
      setSelectedColumn(title);
      let comparator: (a: ProjectDto, b: ProjectDto) => number;

      switch (title) {
        case ProjectListColumnNames.ClientName:
          comparator = (a, b) =>
            (getClientDisplayName(a.clientId)?.toUpperCase() || '') <
            (getClientDisplayName(b.clientId)?.toUpperCase() || '')
              ? -1
              : 1;
          break;
        case ProjectListColumnNames.ProjectCode:
          comparator = (a, b) => (a.code.toUpperCase() < b.code.toUpperCase() ? -1 : 1);
          break;
        case ProjectListColumnNames.ProjectManager:
          comparator = (a, b) =>
            (getProjectManagerDisplayName(a.projectManagerId)?.toUpperCase() || '') <
            (getProjectManagerDisplayName(b.projectManagerId)?.toUpperCase() || '')
              ? -1
              : 1;
          break;
        case ProjectListColumnNames.ProjectStatus:
          comparator = (a, b) =>
            (getStatusDisplayName(a.status).toUpperCase() || '') <
            (getStatusDisplayName(b.status).toUpperCase() || '')
              ? -1
              : 1;
          break;
        default:
          comparator = (a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1);
          break;
      }
      setFilteredProjects([...filteredProjects].sort(comparator));
    } else {
      setFilteredProjects(filterProjects());
      setSelectedColumn(ProjectListColumnNames.ProjectName);
    }
  };

  const tooltipText = 'Open this project in allocation view';
  const projectName = items.find((project) => project.id === isGoToAllocationsTooltipOpen)?.name;
  const filterName = AllocationFilterDashboardNames.ProjectLabel;
  const allocationPageParams = {
    filterType: filterName,
    value: projectName || '',
    startDate: allocationFormatDate(getFirstDayOfMonth(), true),
    endDate: allocationFormatDate(getLastDayOfMonth(), false)
  };

  const goToAllocations = () => {
    if (!isGoToAllocationsTooltipOpen) {
      return;
    }
    const searchParams = getSearchParams(allocationPageParams);
    const url = `${RoutingPath.AllocationPage}?${searchParams.toString()}`;
    navigate(url);
  };

  return (
    <StyledProjectList>
      <div className="button-row">
        <button
          type="button"
          onClick={toggleFilter}
          className={`button ${showUserProjects ? 'active' : ''}`}
        >
          <p className="inter-caption_medium">
            {showUserProjects ? 'All Projects' : 'My Projects'}
          </p>
        </button>
        <button
          type="button"
          onClick={toggleBillable}
          className={`button ${!showBillable ? 'active' : ''}`}
        >
          <p className="inter-caption_medium">
            {showBillable ? 'Internal Projects' : 'All Projects'}
          </p>
        </button>
        <ProjectListMultiplyButton
          selectedStatus={selectedStatus}
          handleStatusChange={handleStatusChange}
        />
      </div>
      <div className="scroll-container">
        <TableContainer>
          <Table sx={{ minWidth: 450 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <button
                    type="button"
                    className={`column-button ${
                      selectedColumn === ProjectListColumnNames.ProjectName ? 'selected' : ''
                    }`}
                    onClick={() => onColumnTitleClick(ProjectListColumnNames.ProjectName)}
                  >
                    <p className="inter-caption_medium">{ProjectListColumnNames.ProjectName}</p>
                  </button>
                </TableCell>
                <TableCell>
                  <button
                    type="button"
                    className={`column-button ${
                      selectedColumn === ProjectListColumnNames.ClientName ? 'selected' : ''
                    }`}
                    onClick={() => onColumnTitleClick(ProjectListColumnNames.ClientName)}
                  >
                    <p className="inter-caption_medium">{ProjectListColumnNames.ClientName}</p>
                  </button>
                </TableCell>
                <TableCell>
                  <button
                    type="button"
                    className={`column-button ${
                      selectedColumn === ProjectListColumnNames.ProjectCode ? 'selected' : ''
                    }`}
                    onClick={() => onColumnTitleClick(ProjectListColumnNames.ProjectCode)}
                  >
                    <p className="inter-caption_medium">{ProjectListColumnNames.ProjectCode}</p>
                  </button>
                </TableCell>
                <TableCell>
                  <button
                    type="button"
                    className={`column-button ${
                      selectedColumn === ProjectListColumnNames.ProjectManager ? 'selected' : ''
                    }`}
                    onClick={() => onColumnTitleClick(ProjectListColumnNames.ProjectManager)}
                  >
                    <p className="inter-caption_medium">{ProjectListColumnNames.ProjectManager}</p>
                  </button>
                </TableCell>
                <TableCell>
                  <button
                    type="button"
                    className={`column-button ${
                      selectedColumn === ProjectListColumnNames.ProjectStatus ? 'selected' : ''
                    }`}
                    onClick={() => onColumnTitleClick(ProjectListColumnNames.ProjectStatus)}
                  >
                    <p className="inter-caption_medium">{ProjectListColumnNames.ProjectStatus}</p>
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredProjects.map((pr) => {
                const {
                  colorHex,
                  clientId,
                  projectManagerId,
                  status: statusId,
                  name,
                  code,
                  id
                } = pr;

                return (
                  <TableRow key={pr.key} hover onClick={() => goToProjectDetails(pr)}>
                    <TableCell scope="row" className="name-row">
                      <div style={{ backgroundColor: colorHex }} className="color" />
                      <p className="inter-p3">{name}</p>
                    </TableCell>
                    <TableCell align="left">
                      <div className="name-row">
                        <div
                          style={{ backgroundColor: getClientColorDisplayName(clientId) }}
                          className="color"
                        />
                        <p className="inter-p3">{getClientDisplayName(clientId)}</p>
                      </div>
                    </TableCell>
                    <TableCell align="left">{code}</TableCell>
                    <TableCell align="left">
                      <div className="name-row">
                        {projectManagerId ? (
                          <Initials
                            name={String(getProjectManagerDisplayName(projectManagerId))}
                            className="icon"
                          />
                        ) : null}
                        <p className="inter-p3">{getProjectManagerDisplayName(projectManagerId)}</p>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className="name-row">
                        <div
                          style={{
                            backgroundColor: getStatusColor(getStatusDisplayName(statusId))
                          }}
                          className="status-icon"
                        />
                        <p className="inter-p3"> {getStatusDisplayName(statusId)}</p>
                      </div>
                    </TableCell>
                    <TableCell align="left">
                      <div className="name-row">
                        <CustomTooltip
                          onClose={() => setIsGoToAllocationsTooltipOpen(null)}
                          onOpen={() => setIsGoToAllocationsTooltipOpen(id)}
                          isTooltipOpen={isGoToAllocationsTooltipOpen === id}
                          title={<div className="note-text">{tooltipText}</div>}
                          placement="bottom"
                          icon={clockGrey}
                          onClick={goToAllocations}
                          buttonClassName="column-button"
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </StyledProjectList>
  );
};
export default ProjectList;
