import * as yup from 'yup';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';

const UploadActualsFormValidation = () => {
  return yup.object().shape({
    startDate: yup.string().required(FormValidationMessage.DateError),
    endDate: yup.string().required(FormValidationMessage.DateError)
  });
};

export default UploadActualsFormValidation;
