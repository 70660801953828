import EditEmployeeRateFormDto from 'types/project/dto/EditEmployeeRateFormDto';
import EmployeeRatesDto from 'types/project/dto/EmployeeRatesDto';

const EditEmployeeRateDefaultValue = (): EditEmployeeRateFormDto => {
  return {
    id: 0,
    employeeId: 0,
    projectId: 101,
    rate: 0,
    defaultRate: 0,
    startDate: '',
    reason: '',
    startWeekId: 0
  };
};

export const EmployeeRateDefault: EmployeeRatesDto[] = [
  {
    id: 0,
    startWeekId: 0,
    startDate: new Date().toDateString(),
    employeeId: 0,
    projectId: 0,
    rate: 0,
    defaultRate: 0,
    reason: ''
  }
];

export default EditEmployeeRateDefaultValue;
