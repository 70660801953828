import { Grid } from '@mui/material';
import ClientNotesDefaultValues from 'components/clients/ClientNoteAddForm/ClientNotesFormDefaultValues';
import StyledClientNotesAddForm from 'components/clients/ClientNoteAddForm/StyledClientNotesAddForm';
import FormTextField from 'components/sharedComponents/FormComponents/FormTextField/FormTextField';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState, ThunkAppDispatch } from 'store';
import addNotes from 'store/action/clientActions/addNotes';
import { useAppSelector } from 'store/redux-hooks/hooks';
import { addNote } from 'store/slices/clients/notesSlice';
import { ClientNoteDto } from 'types/clients/dto/ClientNoteDto';
import { ClientNoteFormDto } from 'types/clients/dto/ClientNoteFormDto';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import ClientFormComponentNames from 'types/clients/enums/ClientFormComponentNames';
import InputType from 'types/generalEnums/InputType';
import { yupResolver } from '@hookform/resolvers/yup';
import ClientNotesFormsValidation from 'components/clients/ClientNoteAddForm/ClientNotesFormValidation';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import ButtonsText from 'types/generalEnums/ButtonsText';

type ClientNotesAddFormProps = {
  client: ClientsDto;
};

const ClientNotesAddForm = ({ client }: ClientNotesAddFormProps) => {
  const methods = useForm<ClientNoteFormDto>({
    // TODO: fix unknown
    defaultValues: ClientNotesDefaultValues(client),
    resolver: yupResolver(ClientNotesFormsValidation()) as unknown as Resolver<ClientNoteFormDto>
  });
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = methods;
  const dispatch = useDispatch<ThunkAppDispatch>();

  const notes = useSelector((state: RootState) => state.notes.data);
  const onSubmit: SubmitHandler<ClientNoteFormDto> = async (data: ClientNoteFormDto) => {
    try {
      const newNote: ClientNoteDto = await dispatch(addNotes(data)).unwrap();
      dispatch(addNote([...notes, newNote]));
      reset();
    } catch (error) {
      logError('Error in add note form');
    }
  };

  const loadingNotes = useAppSelector((state: RootState) => state.notes.saveLoading);

  return (
    <FormProvider {...methods}>
      <StyledClientNotesAddForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={0}>
          <Grid item xs={10} md={10}>
            <FormTextField
              label={ClientFormComponentNames.ContentLabel}
              name={ClientFormComponentNames.Content}
              type={InputType.Text}
              required
              register={register}
              error={errors.content?.message}
            />
          </Grid>
          <Grid item xs={3} md={2} className="note-form-button-wrapper">
            <CustomModalButtons
              submitButtonText={ButtonsText.Add}
              isSpinnerButtonAdded
              loading={loadingNotes}
              submitButtonClassName="note-form-button"
              spinnerClassName="spinner-button-wrap"
            />
          </Grid>
        </Grid>
      </StyledClientNotesAddForm>
    </FormProvider>
  );
};
export default ClientNotesAddForm;
