import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import { maxLengthForTextFields } from 'constants/validationConstants';
import * as yup from 'yup';

const ClientNotesFormValidation = () => {
  return yup.object().shape({
    content: yup
      .string()
      .trim()
      .max(maxLengthForTextFields, FormValidationMessage.NotesLengthError)
      .required(FormValidationMessage.NoteError)
  });
};

export default ClientNotesFormValidation;
