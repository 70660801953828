import { TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledDownTimeErrorPage = styled.div({
  p: {
    margin: '0',
    textAlign: 'center',
    color: TEXT_COLOR,
    marginTop: '20px',
    alignSelf: 'center',
    width: '350px'
  },
  '.content-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '90%'
  },
  width: '100%',
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  '.login-image': {
    alignSelf: 'center'
  }
});

export default StyledDownTimeErrorPage;
