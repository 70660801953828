enum EditEmployeeRateFormNames {
  NewRateTitle = 'New Rate',
  NewRate = 'rate',
  StartDateTitle = 'Apply From',
  StartDate = 'startDate',
  ReasonTitle = 'Reason',
  Reason = 'reason',
  EditEmployeeRateModalTitle = 'Edit Project Rate'
}

export default EditEmployeeRateFormNames;
