import formatDate from 'helpers/formatDate';
import Moment from 'moment';

export const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
};
export const getNextMonth = () => {
  const currentDate = new Date();
  return new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
};
export const getLastDayOfMonth = () => {
  const nextMonth = getNextMonth();
  return new Date(nextMonth.getTime() - 1);
};

export const getToday = () => {
  const currentDate = new Date();
  return currentDate.toISOString();
};

export const getTimeZone = () => {
  const offset = new Date().getTimezoneOffset();
  return Number((offset < 0 ? '+' : '-') + Math.abs(offset / 60));
};

export const getTargetDayOfWeek = (dateString: string, targetDay?: number): string => {
  if (!targetDay) {
    targetDay = 1;
  }
  const date = new Date(dateString);
  const dayOfWeek = date.getDay();
  const daysUntilTargetDay = targetDay - dayOfWeek;
  const targetDate = new Date(date);
  targetDate.setDate(date.getDate() + daysUntilTargetDay);
  targetDate.setHours(date.getHours() + getTimeZone(), 0, 0, 0);
  return targetDate.toISOString().slice(0, -1);
};

export const getCurrentYear = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return currentYear;
};

export const getCurrentMonth = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  return currentMonth;
};

export const convertToDateTimeString = (selectedDate: string) => {
  const convertedDate = new Date(selectedDate);
  const convertedDateString = convertedDate.toISOString().slice(0, -1);
  return convertedDateString;
};

export const getCalendarDayName = (calendarDate: Date) => {
  const date = new Date(calendarDate);
  const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' });
  return dayOfWeek;
};

export const getCalendarDayNumber = (calendarDate: Date) => {
  const date = new Date(calendarDate);
  const dayOfMonth = date.toLocaleDateString('en-US', { day: 'numeric' });
  return dayOfMonth;
};

export const differenceInTime = (startDate: Date, endDate: Date) => {
  return new Date(endDate).getTime() - new Date(startDate).getTime();
};

export const differenceInDays = (startDate: Date, endDate: Date) => {
  if (!startDate || !endDate) {
    return 0;
  }

  return Math.floor(differenceInTime(startDate, endDate) / (1000 * 3600 * 24) + 1);
};

const countWeekendDays = (startDate: Date, endDate: Date) => {
  let count = 0;
  for (
    let currentDate = new Date(startDate);
    currentDate <= endDate;
    currentDate.setDate(currentDate.getDate() + 1)
  ) {
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) {
      count += 1;
    }
  }

  return count;
};

export const differenceInBusinessDays = (startDate: Date, endDate: Date) => {
  if (!startDate || !endDate) {
    return 0;
  }

  return differenceInDays(startDate, endDate) - countWeekendDays(startDate, endDate);
};

export const calculateTotalWorkingDays = (startDay: Date, endDay: Date) => {
  const weekendDays = [0, 6];
  const dayDifference = Math.ceil((endDay.getTime() - startDay.getTime()) / (1000 * 60 * 60 * 24));
  let workingDays = 0;
  for (let i = 0; i < dayDifference; i += 1) {
    const currentDate = new Date(startDay.getTime() + i * (1000 * 60 * 60 * 24));
    if (!weekendDays.includes(currentDate.getDay())) {
      workingDays += 1;
    }
  }
  return workingDays;
};

export const calculateTotalTime = (numOfDays: number, hoursWeekly: number, minsWeekly: number) => {
  const hours = (hoursWeekly / 5) * numOfDays + Math.floor(((minsWeekly / 5) * numOfDays) / 60);
  const minutes = ((minsWeekly / 5) * numOfDays) % 60;
  return [hours, minutes];
};

export const convertDateToISO = (date: string): string => {
  const parsedDate = new Date(date);
  const timeZoneOffset = parsedDate.getTimezoneOffset();
  parsedDate.setMinutes(parsedDate.getMinutes() - timeZoneOffset);
  const isoDate = parsedDate.toISOString();
  return isoDate;
};

export const makeDecemberLastMonthInMaxYear = (date: Date) => {
  if (date.getMonth() === 0) {
    date.setFullYear(date.getFullYear() - 1);
  }

  date.setMonth(11);
  date.setDate(31);
  return date;
};

export const getDifferenceInHours = (startDate: Date, endDate: Date) => {
  return Math.floor(differenceInDays(startDate, endDate) * 8);
};

export const getDateOrPlaceholder = (
  date: string | null | undefined,
  isEndDate?: boolean,
  placeholder = ''
) => (date ? formatDate(date, '', isEndDate) : placeholder);

export const countDaysInMonth = (start: Date, end: Date, targetMonth: number): number => {
  const targetMonthIndex = targetMonth - 1;
  let count = 0;

  for (let current = new Date(start); current <= end; current.setDate(current.getDate() + 1)) {
    if (current.getMonth() === targetMonthIndex) {
      count += 1;
    }
  }

  return count;
};

export const calculateFridayWeeksFromNow = (weeks: number, startDate = new Date()) => {
  const fridayIndex = 5;
  const daysToFriday = fridayIndex - startDate.getDay();

  const moment = Moment(startDate)
    .add(weeks - 1, 'weeks')
    .add(daysToFriday, 'days');

  return moment.toDate();
};

export const getLastImportDateOrPlaceholder = (date: string | undefined) => {
  return date
    ? `${new Date(date || '').getHours()}:${new Date(date || '').getMinutes()}, ${formatDate(
        date || ''
      )}`
    : '-';
};
