import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import CalendarDictionariesDto from 'types/allocation/dto/calendarDto/CalendarDictionariesDto';

const calendarDictionariesApiService: IApiService = {
  fetchCalendarDictionaries: async () => {
    try {
      const response = await apiRequest<CalendarDictionariesDto>({
        method: 'GET',
        endpoint: `${API_URL}/dictionaries/calendar`
      });
      return response;
    } catch (error) {
      logError('Error in fetchCalendarDictionaries');
      throw new Error('Error in fetchCalendarDictionaries');
    }
  }
};

interface IApiService {
  fetchCalendarDictionaries: () => Promise<CalendarDictionariesDto>;
}

export default calendarDictionariesApiService;
