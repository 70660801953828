import { addFormInputBoderColor } from 'constants/styleConstants';
import {
  borderRadiusCommon,
  marginTopCommon
} from 'components/sharedComponents/StyleConsts/styleConsts';
import { ALERT_COLOR, LOW_EMPHASISE_COLOR, TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledEmployeeChangeForm = styled.form({
  p: {
    color: LOW_EMPHASISE_COLOR,
    margin: '0px'
  },
  '.input-form': {
    width: '300px',
    '& .MuiInputBase-input': {
      width: '300px'
    }
  },
  '.input-capacity': {
    width: '208px'
  },
  '.MuiFormControlLabel-label': {
    lineHeight: '18px',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Inter',
    color: TEXT_COLOR
  },
  '.MuiGrid-item': {
    paddingBottom: '10px'
  },
  '.error': {
    marginTop: '5px',
    color: ALERT_COLOR
  },
  '.position-select': {
    width: '208px',
    height: '35px',
    borderRadius: borderRadiusCommon,
    marginTop: marginTopCommon
  },
  '.seniority-select': {
    width: '187px',
    height: '35px',
    borderRadius: borderRadiusCommon,
    marginTop: marginTopCommon
  },
  '.MuiInputBase-input': {
    borderColor: addFormInputBoderColor
  },
  '.permission-select': {
    width: '755px',
    borderRadius: borderRadiusCommon,
    marginTop: marginTopCommon
  }
});

export default StyledEmployeeChangeForm;
