import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import mapToTimeOffDto from 'store/mappers/allocations/allocationTimeOffMapper';
import { AllocationTimeOffDto } from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffDto';
import AllocationTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffFormDto';
import { AllocationsDeleteTimeOffDto } from 'types/allocation/dto/allocationTimeOffDto/allocationDeleteTimeOffDto/AllocationDeleteTimeOffDto';
import AllocationDeleteTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/allocationDeleteTimeOffDto/AllocationDeleteTimeOffFormDto';

const allocationsTimeOffApiService: IApiService = {
  getTimeOff: async (id: number) => {
    try {
      const response = await apiRequest<AllocationTimeOffDto>({
        method: 'GET',
        endpoint: `${API_URL}/allocations/time-off/${id}`
      });
      return response;
    } catch (error) {
      logError('Error in fetchTimeOff');
      throw new Error('Error in fetchTime');
    }
  },
  addTimeOff: async (data: AllocationTimeOffFormDto) => {
    const addTimeOffDto = mapToTimeOffDto(data);
    try {
      const response = await apiRequest<AllocationTimeOffDto>({
        method: 'POST',
        endpoint: `${API_URL}/allocations/time-off`,
        requestBody: addTimeOffDto
      });
      return response;
    } catch (error) {
      logError('Error in creating time-off');
      throw new Error('Error in creating time-off');
    }
  },
  deleteTimeOff: async (data: AllocationDeleteTimeOffFormDto) => {
    try {
      const response = await apiRequest<AllocationsDeleteTimeOffDto>({
        method: 'DELETE',
        endpoint: `${API_URL}/allocations/time-off/${data.timeOffId}?deleteFutureTimeOffs=${data.deleteFutureTimeOffs}`
      });
      return response;
    } catch (error) {
      logError('Error in deleting time-off');
      throw new Error('Error in deleting time-off');
    }
  },
  updateTimeOff: async (data: AllocationTimeOffFormDto) => {
    const updateTimeOffDto = mapToTimeOffDto(data);
    try {
      const response = await apiRequest<AllocationTimeOffDto>({
        method: 'PUT',
        endpoint: `${API_URL}/allocations/time-off/${data.id}`,
        requestBody: updateTimeOffDto
      });
      return response;
    } catch (error) {
      logError('Error in updating time-off');
      throw new Error('Error in creating time-off');
    }
  }
};

interface IApiService {
  getTimeOff: (id: number) => Promise<AllocationTimeOffDto>;
  addTimeOff: (data: AllocationTimeOffFormDto) => Promise<AllocationTimeOffDto>;
  deleteTimeOff: (data: AllocationDeleteTimeOffFormDto) => Promise<AllocationsDeleteTimeOffDto>;
  updateTimeOff: (data: AllocationTimeOffFormDto) => Promise<AllocationTimeOffDto>;
}

export default allocationsTimeOffApiService;
