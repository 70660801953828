import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { InvoiceDictionariesDto } from 'types/invoice/InvoiceDictionariesDto';

const invoiceDictionariesApiService: IApiService = {
  fetchInvoiceDictionaries: async (invoiceId: number) => {
    try {
      return await apiRequest<InvoiceDictionariesDto>({
        method: 'GET',
        endpoint: `${API_URL}/dictionaries/invoice/${invoiceId}`
      });
    } catch (error) {
      logError('Error in fetchInvoiceDictionaries');
      throw new Error('Error in fetchInvoiceDictionaries');
    }
  }
};

interface IApiService {
  fetchInvoiceDictionaries: (invoiceId: number) => Promise<InvoiceDictionariesDto>;
}

export default invoiceDictionariesApiService;
