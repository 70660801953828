import styled from 'styled-components';
import { SURFACE_COLOR } from 'constants/colors';
import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';

const StyledSharedDetailsComponent = styled.div({
  position: 'relative',
  padding: '24px',
  margin: '16px 20px',
  width: '765px',
  backgroundColor: SURFACE_COLOR,
  borderRadius: borderRadiusCommon
});

export default StyledSharedDetailsComponent;
