import doesValueExist from './doesValueExist';

const safeRound = (val: number | null | undefined, fractionDigits = 2) => {
  return val ? +val.toFixed(fractionDigits) : 0;
};

const formatCurrency = (amount: number, currency: string, region: string) => {
  return new Intl.NumberFormat(region, { style: 'currency', currency }).format(amount);
};

const formatToUsd = (val: number) => formatCurrency(Number(val), 'USD', 'en-US');

const getHourOrPlaceholder = (val: number | null | undefined, placeholder = '-') => {
  return doesValueExist(val) ? `${safeRound(val)}h` : placeholder;
};

const getHoursIfValueExistOrPlaceholder = (val: number | null | undefined, placeholder = '-') =>
  doesValueExist(val) ? `${safeRound(val)}h` : placeholder;

const getCurrencyIfValueExistOrPlaceholder = (val: number | null | undefined, placeholder = '-') =>
  doesValueExist(val) ? formatToUsd(val ?? 0) : placeholder;

const getNegativeValueOrNull = (
  value: number | undefined,
  isDiscountAdded?: boolean
): number | null => {
  if (!!value && value !== 0) {
    return -value;
  }
  if (isDiscountAdded && (value === 0 || value === undefined)) {
    return 0;
  }
  return null;
};

export {
  formatCurrency,
  formatToUsd,
  getHourOrPlaceholder,
  getCurrencyIfValueExistOrPlaceholder,
  getHoursIfValueExistOrPlaceholder,
  getNegativeValueOrNull
};
