import { ProjectDto } from 'types/project/dto/ProjectDto';
import ProjectFormDto from 'types/project/dto/ProjectFormDto';

const ProjectChangeFormDefaultValues = (item: ProjectDto): ProjectFormDto => {
  return {
    id: item.id,
    name: item.name ?? '',
    code: item.code ?? '',
    clientId: item.clientId ?? null,
    billable: item.billable ?? false,
    billingType: item.billingType ?? null,
    billingPeriodType: item.billingPeriodType ?? null,
    projectManagerId: item.projectManagerId ?? null,
    colorHex: item.colorHex ?? '',
    status: item.status ?? null,
    customBillingPeriodWeeks: item.customBillingPeriodWeeks ?? null,
    startDate: item.startDate ?? '',
    endDate: item.endDate ?? '',
    clientName: item.clientName ?? '',
    clientColorHex: item.clientColorHex ?? '',
    projectManagerName: item.projectManagerName ?? '',
    azdoAlias: item.azdoAlias ?? '',
    actualsLastModified: item.actualsLastModified ?? '',
    startingSprintNumber: null
  };
};

export default ProjectChangeFormDefaultValues;
