const projectTableRows = [
  {
    name: 'Forecast',
    id: 0
  },
  {
    name: 'Actuals',
    id: 1
  },
  {
    name: 'Difference',
    id: 2
  },
  {
    name: 'Total Discounts',
    id: 3
  }
];

export default projectTableRows;
