import { TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledDetailsHeader = styled.div({
  display: 'flex',
  margin: '0px 0px 10px 15px',
  justifyContent: 'space-between',
  h2: {
    color: TEXT_COLOR
  },
  '.button-group': {
    border: 'none',
    background: 'none',
    cursor: 'pointer',
    img: {
      alignSelf: 'center'
    }
  },
  '.name-header': {
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    maxWidth: '95%',
    wordWrap: 'break-word'
  }
});

export default StyledDetailsHeader;
