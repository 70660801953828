import FormModalConfig from 'types/generalEnums/FormModalConfigType';

const makeEmployeeInactiveModalConfig: FormModalConfig = {
  btnText: 'Make Inactive',
  description:
    'This action will restrict the possibility to add the employee to the projects, but you still will have the employee’s data available in Employees list. ',
  headerTitle: 'Make an Employee Inactive?'
};

export default makeEmployeeInactiveModalConfig;
