import { AuthorizationDto } from 'types/authorization/AuthorizationsDto';
import PermissionsName from 'types/generalEnums/PermissionsName';

const doesUserHavePermissions = (
  user: AuthorizationDto | null,
  permissionNames: PermissionsName[]
) => {
  if (user === null) return false;
  return user.permissions.some((p) => permissionNames.includes(p.name as PermissionsName));
};

export default doesUserHavePermissions;
