import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import getDictionaries from 'store/action/employeeActions/getDictionaries';
import { DictionariesState } from 'store/slicesState/dictionaries';
import { EmployeeDictionariesDto } from 'types/employee/dto/EmployeeDictionariesDto';

const initialState: DictionariesState = {
  data: { permissions: [], seniorities: [], positions: [] },
  loading: false,
  error: null
};
export type DispatchError = {
  errorMessage: string;
};

const dictionariesSlice = createSlice({
  name: 'dictionaries',
  initialState,
  reducers: {
    getDictionary: (state, action: PayloadAction<EmployeeDictionariesDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getDictionaries.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getDictionaries.fulfilled, (state, action) => {
      state.error = null;
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getDictionaries.rejected, (state) => {
      state.error = 'getDictionaries error in slice';
      state.loading = false;
    });
  }
});

export const { getDictionary } = dictionariesSlice.actions;
export const SELECT_DICTIONARIES = (root: RootState) => root.dictionaries;
export default dictionariesSlice.reducer;
