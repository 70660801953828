import {
  emailPattern,
  emailSymbolLength,
  clientNamePattern,
  namePattern,
  maxLengthForTextFields,
  phonePattern,
  positionPattern
} from 'constants/validationConstants';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import * as yup from 'yup';
import FormTypeName from 'types/generalEnums/FormTypeName';

export type ClientFormType = FormTypeName.AddForm | FormTypeName.EditForm;

const ClientFormsValidation = (clientFormType: ClientFormType) => {
  const baseSchema = yup.object().shape({
    name: yup
      .string()
      .required(FormValidationMessage.NameError)
      .matches(clientNamePattern, FormValidationMessage.ClientNameLengthError),
    contactEmail: yup
      .string()
      .trim()
      .lowercase()
      .email(FormValidationMessage.EmailError)
      .max(emailSymbolLength, FormValidationMessage.EmailError)
      .matches(emailPattern, FormValidationMessage.EmailError)
      .required(FormValidationMessage.RequiredError),
    contactName: yup
      .string()
      .required(FormValidationMessage.NameError)
      .matches(namePattern, FormValidationMessage.ContactNameLengthError),
    contactPosition: yup
      .string()
      .required(FormValidationMessage.ClientPositionError)
      .typeError(FormValidationMessage.PositionError)
      .max(maxLengthForTextFields, FormValidationMessage.ClientPositionLengthError)
      .matches(positionPattern, FormValidationMessage.PositionError),
    contactPhone: yup
      .string()
      .required(FormValidationMessage.ClientPhoneError)
      .matches(phonePattern, FormValidationMessage.ClientPhoneError)
      .max(maxLengthForTextFields, FormValidationMessage.ClientPhoneLengthError)
  });

  const getClientValidationSchema =
    clientFormType === FormTypeName.AddForm
      ? baseSchema.concat(
          yup.object().shape({
            colorHex: yup.string().required(FormValidationMessage.ClientColorError)
          })
        )
      : baseSchema;

  return getClientValidationSchema;
};

export default ClientFormsValidation;
