import { createAsyncThunk } from '@reduxjs/toolkit';
import allocationsTimeOffApiService from 'api/allocationTimeOffApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store/index';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import { AllocationsDeleteTimeOffDto } from 'types/allocation/dto/allocationTimeOffDto/allocationDeleteTimeOffDto/AllocationDeleteTimeOffDto';
import AllocationDeleteTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/allocationDeleteTimeOffDto/AllocationDeleteTimeOffFormDto';
import AllocationsAction from 'types/allocation/enums/Allocations';

const deleteAllocationTimeOff = createAsyncThunk<
  AllocationsDeleteTimeOffDto,
  AllocationDeleteTimeOffFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(AllocationsAction.DELETE_TIME_OFF, async (data: AllocationDeleteTimeOffFormDto) => {
  const response = await allocationsTimeOffApiService.deleteTimeOff(data);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});
export default deleteAllocationTimeOff;
