import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import addEmployee from 'store/action/employeeActions/addEmployee';
import cleanSelectedEmployees from 'store/action/employeeActions/cleanSelectedEmployee';
import getEmployees from 'store/action/employeeActions/getEmployee';
import updateEmployee from 'store/action/employeeActions/updateEmployee';
import { EmployeeState } from 'store/slicesState/employee';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';

const initialState: EmployeeState = {
  data: [],
  readLoading: false,
  saveLoading: false,
  error: null,
  selectedEmployee: null
};
export type DispatchError = {
  errorMessage: string;
};

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    getEmployee: (state, action: PayloadAction<EmployeeDto[]>) => {
      state.data = action.payload;
    },
    setEmployeeFilter: (state, action: PayloadAction<EmployeeDto[]>) => {
      state.data = action.payload;
    },
    setEmployees: (state, action: PayloadAction<EmployeeDto[]>) => {
      state.data = action.payload;
    },
    selectEmployee: (state, action: PayloadAction<number | null>) => {
      state.selectedEmployee =
        state.data.find((employee) => employee.id === action.payload) || null;
    },
    cleanSelectedEmployee: (state) => {
      state.selectedEmployee = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployees.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.readLoading = false;
    });
    builder.addCase(getEmployees.rejected, (state) => {
      state.readLoading = false;
    });
    builder.addCase(updateEmployee.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      const updatedEmployee = action.payload;
      const index = state.data.findIndex((employee) => employee.id === updatedEmployee.id);
      if (index !== -1) {
        state.data[index] = updatedEmployee;
      }
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(updateEmployee.rejected, (state) => {
      state.error = 'updateEmployee error in slice';
      state.saveLoading = false;
    });
    builder.addCase(addEmployee.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(addEmployee.fulfilled, (state, action) => {
      state.data.push(action.payload);
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(addEmployee.rejected, (state) => {
      state.error = 'addEmployee error in slice';
      state.saveLoading = false;
    });
    builder.addCase(cleanSelectedEmployees, (state) => {
      state.selectedEmployee = null;
    });
  }
});

export const {
  getEmployee,
  setEmployeeFilter,
  setEmployees,
  selectEmployee,
  cleanSelectedEmployee
} = employeesSlice.actions;
export const SELECT_EMPLOYEES = (root: RootState) => root.employees;
export default employeesSlice.reducer;
