import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { BORDERS_COLOR, ON_PRIMARY_COLOR, TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledSearchItem = styled.div({
  '.search-field': {
    maxWidth: '294px',
    marginBottom: '16px',
    border: '1px solid',
    borderRadius: borderRadiusCommon,
    backgroundColor: ON_PRIMARY_COLOR,
    borderColor: BORDERS_COLOR,
    padding: '8px',
    color: TEXT_COLOR
  },
  '.MuiInputAdornment-positionEnd': {
    marginLeft: '45px'
  },
  '.search-input': {
    color: TEXT_COLOR
  },
  '& .MuiInput-root': {
    color: TEXT_COLOR
  }
});
export default StyledSearchItem;
