import { useState } from 'react';
import Initials from 'components/sharedComponents/Initials/Initials';
import formatDate from 'helpers/formatDate';
import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import StyledTitleContainer from './StyledTitleContainer';

type DiscountReasonTooltipProps = {
  name: string;
  icon?: string;
  className?: string;
  label?: string;
  placement: string;
  message: string;
  messageDateTime: string;
};
const DiscountReasonTooltip = ({
  name,
  icon,
  className,
  label,
  placement,
  message,
  messageDateTime
}: DiscountReasonTooltipProps) => {
  const [isReasonTooltipOpen, setReasonOpenTooltip] = useState(false);

  const handleReasonTooltipClose = () => {
    setReasonOpenTooltip(false);
  };

  const handleReasonTooltipOpen = () => {
    setReasonOpenTooltip(true);
  };

  return (
    <CustomTooltip
      isTooltipOpen={isReasonTooltipOpen}
      onClose={handleReasonTooltipClose}
      onOpen={handleReasonTooltipOpen}
      title={
        <StyledTitleContainer>
          <div className="initials-container initials">
            <Initials name={name} className="img" />
            <div>
              <p className="inter-p3 ">{name}</p>
              <p className="inter-caption-grey"> {formatDate(messageDateTime, '', false, true)}</p>
            </div>
          </div>
          <div>
            <p className="inter-p3">{message}</p>
          </div>
        </StyledTitleContainer>
      }
      className={className}
      placement={placement}
      icon={icon}
      label={label}
      reasonTooltip
    />
  );
};
export default DiscountReasonTooltip;
