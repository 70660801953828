import { BillingPeriodsDto } from 'types/PrpTable/dto/ProjectTableDto';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { dotsGrey, lockerGrey } from 'shared/PrpIcon/icons.constants';
import unlock from 'shared/PrpIcon/svg/unlock.svg';
import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import generateForecast from 'store/action/projectActions/generateForecast';
import freezeForecast from 'store/action/projectActions/freezeForecast';
import unfreezeForecast from 'store/action/projectActions/unfreezeForecast';
import OptionalMenuItem from 'components/project/ProjectTable/OptionalMenuItem/OptionalMenuItem';
import PermissionsName from 'types/generalEnums/PermissionsName';
import { RootState } from 'store';

type GenerateForecastButtonProps = {
  billing: BillingPeriodsDto;
  refreshProjectData: () => void;
  bpLength: number;
};

const GenerateForecastButton = ({
  billing,
  refreshProjectData,
  bpLength
}: GenerateForecastButtonProps) => {
  const [generateForecastAnchorEl, setGenerateForecastAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const [frozenForecastAnchorEl, setFrozenForecastAnchorEl] = useState<null | HTMLElement>(null);
  const [regenerateForecastAnchorEl, setRegenerateForecastAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const user = useAppSelector((state: RootState) => state.auth.user);

  const openForecastMenu = (event: React.MouseEvent<HTMLElement>) => {
    setGenerateForecastAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setGenerateForecastAnchorEl(null);
    setFrozenForecastAnchorEl(null);
    setRegenerateForecastAnchorEl(null);
  };

  const openFrozenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setFrozenForecastAnchorEl(event.currentTarget);
  };

  const openRegenerateMenu = (event: React.MouseEvent<HTMLElement>) => {
    setRegenerateForecastAnchorEl(event.currentTarget);
  };

  const dispatch = useThunkAppDispatch();

  const onGenerateForecastButtonClick = async () => {
    try {
      await dispatch(generateForecast(billing.billingPeriodId)).unwrap();
      refreshProjectData();
    } catch (error) {
      logError('Error generating forecast');
    }
    handleClose();
  };

  const onFreezeForecastButtonClick = async () => {
    try {
      await dispatch(freezeForecast(billing.billingPeriodId)).unwrap();
      refreshProjectData();
    } catch (error) {
      logError('Error freezing forecast');
    }
    handleClose();
  };

  const onUnfreezeForecastButtonClick = async () => {
    try {
      await dispatch(unfreezeForecast(billing.billingPeriodId)).unwrap();
      refreshProjectData();
    } catch (error) {
      logError('Error unfreezing forecast');
    }
    handleClose();
  };

  const isStatusNew = billing.billingPeriodForecastStatus === 'NEW';
  const isStatusEditable = billing.billingPeriodForecastStatus === 'EDITABLE';
  const isStatusFrozen = billing.billingPeriodForecastStatus === 'FROZEN';
  const hasUnfreezePermission = !!user?.permissions.find(
    (permission) => permission.name === PermissionsName.UnfreezeForecast
  );
  const hasGeneratePermission = !!user?.permissions.find(
    (permission) => permission.name === PermissionsName.ProjectManager
  );
  const hasRegeneratePermission = !!user?.permissions.find(
    (permission) => permission.name === PermissionsName.ProjectManager
  );
  const hasFreezePermission = !!user?.permissions.find(
    (permission) => permission.name === PermissionsName.ProjectManager
  );
  return (
    <p className="inter-caption_medium billing-forecast" id={bpLength.toString()}>
      {isStatusFrozen ? (
        <div className="forecast-button-container">
          {bpLength > 1 ? (
            <>
              <PrpIcon src={lockerGrey} className="locker-icon" />
              <button
                type="button"
                disabled={!hasUnfreezePermission}
                className={!hasUnfreezePermission ? 'forecast-button-disabled' : 'forecast-button'}
                onClick={openFrozenMenu}
              >
                Forecast is Frozen
              </button>
            </>
          ) : (
            <button
              type="button"
              onClick={openFrozenMenu}
              disabled={!hasUnfreezePermission}
              className={!hasUnfreezePermission ? 'forecast-button-disabled' : 'forecast-button'}
            >
              <PrpIcon src={lockerGrey} className="locker-icon" />
            </button>
          )}
          <Menu
            id="positioned-menu"
            aria-labelledby="positioned-button"
            open={Boolean(frozenForecastAnchorEl)}
            onClose={handleClose}
            anchorEl={frozenForecastAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <MenuItem
              style={{ fontSize: '14px', height: '20px' }}
              onClick={onUnfreezeForecastButtonClick}
            >
              <ListItemIcon>
                <PrpIcon src={unlock} className="unlock-icon" />
              </ListItemIcon>
              <Typography variant="inherit">Unfreeze forecast</Typography>
            </MenuItem>
          </Menu>
        </div>
      ) : null}
      {!isStatusFrozen && bpLength > 1 ? (
        <div>
          {isStatusNew ? (
            <button
              type="button"
              disabled={!hasGeneratePermission}
              className={!hasGeneratePermission ? 'forecast-button-disabled' : 'forecast-button'}
              onClick={onGenerateForecastButtonClick}
            >
              Generate Forecast
            </button>
          ) : null}
          {isStatusEditable && hasRegeneratePermission && !hasFreezePermission ? (
            <button
              type="button"
              className="forecast-button"
              onClick={onGenerateForecastButtonClick}
            >
              Regenerate Forecast
            </button>
          ) : null}
          {isStatusEditable && hasFreezePermission ? (
            <div className="freeze-button-container">
              <button
                type="button"
                className="forecast-button"
                onClick={onFreezeForecastButtonClick}
              >
                Freeze Forecast
              </button>
              {hasRegeneratePermission ? (
                <div>
                  <button type="button" className="forecast-button" onClick={openRegenerateMenu}>
                    <PrpIcon src={dotsGrey} className="locker-icon" />
                  </button>
                  <Menu
                    id="positioned-menu"
                    aria-labelledby="positioned-button"
                    open={Boolean(regenerateForecastAnchorEl)}
                    onClose={handleClose}
                    anchorEl={regenerateForecastAnchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <MenuItem
                      style={{ fontSize: '14px', height: '20px' }}
                      onClick={onGenerateForecastButtonClick}
                    >
                      Regenerate Forecast
                    </MenuItem>
                  </Menu>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
      {!isStatusFrozen && bpLength <= 1 ? (
        <div>
          <button
            type="button"
            onClick={openForecastMenu}
            disabled={!hasGeneratePermission && !hasRegeneratePermission && !hasFreezePermission}
            className={
              !hasGeneratePermission && !hasRegeneratePermission && !hasFreezePermission
                ? 'forecast-button-disabled'
                : 'forecast-button'
            }
          >
            <PrpIcon src={dotsGrey} className="locker-icon" />
          </button>
          <Menu
            id="positioned-menu"
            aria-labelledby="positioned-button"
            open={Boolean(generateForecastAnchorEl)}
            onClose={handleClose}
            anchorEl={generateForecastAnchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <OptionalMenuItem
              shouldRender={isStatusNew && hasGeneratePermission}
              label="Generate Forecast"
              onClick={onGenerateForecastButtonClick}
            />
            <OptionalMenuItem
              shouldRender={isStatusEditable && hasRegeneratePermission}
              label="Regenerate Forecast"
              onClick={onGenerateForecastButtonClick}
            />
            <OptionalMenuItem
              shouldRender={isStatusEditable && hasFreezePermission}
              label="Freeze Forecast"
              onClick={onFreezeForecastButtonClick}
            />
          </Menu>
        </div>
      ) : null}
    </p>
  );
};

export default GenerateForecastButton;
