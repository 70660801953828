import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import getAllAllocations from 'store/action/allocationsAction/getAllAllocations';
import { AllocationsState } from 'store/slicesState/allocations';
import AllocationDto from 'types/allocation/dto/AllocationDto';
import AllocationParamsDto from 'types/allocation/dto/AllocationParamsDto';

const initialState: AllocationsState = {
  data: {
    startDate: '',
    endDate: '',
    employeeAllocations: []
  },
  allocationPageParams: null,
  readLoading: false,
  saveLoading: false,
  error: null
};

export type DispatchError = {
  errorMessage: string;
};

const allocationsSlice = createSlice({
  name: 'allocations',
  initialState,
  reducers: {
    getAllocations: (state, action: PayloadAction<AllocationDto>) => {
      state.data = action.payload;
    },
    setAllocationPageParams: (state, action: PayloadAction<AllocationParamsDto>) => {
      state.allocationPageParams = action.payload;
    },
    cleanAllocationPageParams: (state) => {
      state.allocationPageParams = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllAllocations.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getAllAllocations.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.readLoading = false;
    });
    builder.addCase(getAllAllocations.rejected, (state) => {
      state.error = 'getAllAllocations error in slice';
      state.readLoading = false;
    });
  }
});

export const { getAllocations, setAllocationPageParams, cleanAllocationPageParams } =
  allocationsSlice.actions;
export default allocationsSlice.reducer;
