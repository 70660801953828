import { AddAdditionalCostFormDto } from 'types/project/dto/AddAdditionalCostFormDto';

const AdditionalCostFormDefaultValues = (additionalCostData: AddAdditionalCostFormDto) => {
  return {
    additionalCostAmount: additionalCostData.additionalCostAmount,
    additionalCostNotes: additionalCostData.additionalCostNotes,
    invoiceId: additionalCostData.invoiceId
  };
};
export default AdditionalCostFormDefaultValues;
