import AllocationsFormComponentNames from 'types/allocation/dto/enums/AllocationsFormComponentNames';
import { FormTimePickerLabelsAndNames } from 'components/sharedComponents/FormComponents/FormTimePicker/FormTimePicker';

const getAllocationFormTimePickerNames = (
  minsMaxLimit?: number,
  minsMinLimit?: number,
  hoursMaxLimit?: number
): FormTimePickerLabelsAndNames => {
  return {
    hoursLabel: AllocationsFormComponentNames.HoursWeeklyLabel,
    hoursName: AllocationsFormComponentNames.HoursWeekly,
    minsLabel: AllocationsFormComponentNames.MinutesWeeklyLabel,
    minsName: AllocationsFormComponentNames.MinutesWeekly,
    hoursMaxLimit,
    minsMaxLimit,
    minsMinLimit
  };
};

export default getAllocationFormTimePickerNames;
