import { createAsyncThunk } from '@reduxjs/toolkit';
import employeeApiService from 'api/employeeApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToEmployee, mapToEmployeeDto } from 'store/mappers/employee/employeeMapper';
import { DispatchError } from 'store/slices/employee/employeeSlice';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import { EmployeeFormDto } from 'types/employee/dto/EmployeeFormDto';
import EmployeeAction from 'types/employee/enums/Employee';

const addEmployee = createAsyncThunk<
  EmployeeDto,
  EmployeeFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(EmployeeAction.ADD_EMPLOYEE, async (data: EmployeeFormDto) => {
  const employeeDto = mapToEmployeeDto(data);
  const response = await employeeApiService.addEmployee(employeeDto);
  if (!response) notifyUser();
  return addKeyToEmployee(response);
});
export default addEmployee;
