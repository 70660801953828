import { createAsyncThunk } from '@reduxjs/toolkit';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import billingApiService from 'api/billingPeriodApiService';
import BillingAction from 'types/project/enums/Billing';
import BillingPeriodDto from '../../../types/PrpTable/dto/BillingPeriodDto';

const getBillingPeriod = createAsyncThunk<
  BillingPeriodDto,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(BillingAction.GET_BILLING, async (id: number) => {
  const response = await billingApiService.getBillingPeriod(id);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});
export default getBillingPeriod;
