import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { ProjectTableDto } from 'types/PrpTable/dto/ProjectTableDto';
import { InvoiceDto } from 'types/project/dto/InvoiceDto';
import { UpdateInvoiceStatusDto } from 'types/invoice/updateInvoiceStatusDto';
import { InvoiceStatusFormDto } from 'types/project/dto/InvoiceStatusFormDto';
import mapToUpdateInvoiceStatusDto from 'store/mappers/project/invoiceStatusMapper';
import { InvoiceFixedPriceFormDto } from 'types/project/dto/InvoiceFixedPriceFormDto';
import mapToCreateFixedInvoiceDto from 'store/mappers/project/fixedInvoiceMapper';
import { AddEditInvoiceFixedPriceDto } from 'types/project/dto/AddEditInvoiceFixedPriceDto';

const invoiceApiService: IApiService = {
  getInvoice: async (data: ProjectTableDto) => {
    try {
      const response = await apiRequest<InvoiceDto[]>({
        method: 'GET',
        endpoint: `${API_URL}/projects/${data.projectId}/invoices?year=${data.year}&month=${data.month}`
      });
      return response;
    } catch (error) {
      logError('Error in getInvoice');
      throw new Error('Error in getInvoice');
    }
  },
  updateInvoiceStatus: async (data: InvoiceStatusFormDto) => {
    try {
      const updateInvoiceStatusDto = mapToUpdateInvoiceStatusDto(data);
      const response = await apiRequest<UpdateInvoiceStatusDto>({
        method: 'PUT',
        endpoint: `${API_URL}/invoices/${data.invoiceId}`,
        requestBody: updateInvoiceStatusDto
      });
      return response;
    } catch (error) {
      logError('Error in updateInvoiceStatus');
      throw new Error('Error in updateInvoiceStatus');
    }
  },
  deleteInvoice: async (invoiceId: number) => {
    try {
      await apiRequest<void>({
        method: 'DELETE',
        endpoint: `${API_URL}/invoices/${invoiceId}`
      });
    } catch (error) {
      logError('Error in deleteInvoice');
      throw new Error('Error in deleteInvoice');
    }
  },
  createFixedInvoice: async (data: InvoiceFixedPriceFormDto) => {
    try {
      const createFixedInvoiceDto = mapToCreateFixedInvoiceDto(data);
      const response = await apiRequest<AddEditInvoiceFixedPriceDto>({
        method: 'POST',
        endpoint: `${API_URL}/billing-periods/${data.billingPeriodId}/invoice/fixed-price`,
        requestBody: createFixedInvoiceDto
      });
      return response;
    } catch (error) {
      logError('Error in createFixedInvoice');
      throw new Error('Error in createFixedInvoice');
    }
  },
  editFixedInvoice: async (data: InvoiceFixedPriceFormDto) => {
    try {
      const editFixedInvoiceDto = mapToCreateFixedInvoiceDto(data);
      const response = await apiRequest<AddEditInvoiceFixedPriceDto>({
        method: 'PUT',
        endpoint: `${API_URL}/invoices/fixed-price/${data.invoiceId}`,
        requestBody: editFixedInvoiceDto
      });
      return response;
    } catch (error) {
      logError('Error in editFixedInvoice');
      throw new Error('Error in editFixedInvoice');
    }
  }
};

interface IApiService {
  getInvoice: (data: ProjectTableDto) => Promise<InvoiceDto[]>;
  updateInvoiceStatus: (data: InvoiceStatusFormDto) => Promise<UpdateInvoiceStatusDto>;
  deleteInvoice: (invoiceId: number) => void;
  createFixedInvoice: (data: InvoiceFixedPriceFormDto) => Promise<AddEditInvoiceFixedPriceDto>;
  editFixedInvoice: (data: InvoiceFixedPriceFormDto) => Promise<AddEditInvoiceFixedPriceDto>;
}

export default invoiceApiService;
