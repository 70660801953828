import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import getImportAzdoStatus from 'store/action/projectActions/getImportAzdoStatus';
import { ImportActualsStatusResponseDto } from 'types/uploadActuals/ImportActualsStatusResponseDto';
import { ActualsState } from 'store/slicesState/actuals';

const initialState: ActualsState = {
  data: {
    importStatus: '',
    lastImportDate: ''
  },
  readLoading: false,
  saveLoading: false,
  error: null
};
export type DispatchError = {
  errorMessage: string;
};

const actualsSlice = createSlice({
  name: 'actuals',
  initialState,
  reducers: {
    getImportStatus: (state, action: PayloadAction<ImportActualsStatusResponseDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getImportAzdoStatus.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getImportAzdoStatus.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.readLoading = false;
    });
    builder.addCase(getImportAzdoStatus.rejected, (state) => {
      state.readLoading = false;
    });
  }
});

export const { getImportStatus } = actualsSlice.actions;
export default actualsSlice.reducer;
