import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import InvoiceAction from 'types/project/enums/Invoice';
import additionalCostsApiService from 'api/additionalCostsApiService';

const deleteAdditionalCost = createAsyncThunk<
  void,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(InvoiceAction.DELETE_ADDITIONAL_COST, async (invoiceId: number) => {
  await additionalCostsApiService.deleteAdditionalCost(invoiceId);
});

export default deleteAdditionalCost;
