import StyledSpinnerButton from 'components/sharedComponents/Spinner/StyledSpinnerButton';

type SpinnerButtonProps = {
  loading?: boolean;
  className?: string;
};
const SpinnerButton = ({ loading, className }: SpinnerButtonProps) => {
  return loading ? (
    <StyledSpinnerButton className={className}>
      <div className="spinner-button" />
    </StyledSpinnerButton>
  ) : null;
};

export default SpinnerButton;
