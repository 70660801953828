import StyledConformationWindow from 'components/sharedComponents/ConfirmationWindow/StyledConfirmationWindow';
import ConfirmationModalText from 'types/generalEnums/ConfirmationModalText';

type ConfirmationModalProps = {
  handleLeaveForm: () => void;
  handleStayInForm: () => void;
};

const ConfirmationModal = ({ handleLeaveForm, handleStayInForm }: ConfirmationModalProps) => {
  return (
    <StyledConformationWindow>
      <p className="inter-h5">{ConfirmationModalText.Text}</p>
      <button type="button" className="modal-button button-first" onClick={handleLeaveForm}>
        <p>{ConfirmationModalText.ButtonLeaveText}</p>
      </button>
      <button type="button" className="modal-button" onClick={handleStayInForm}>
        <p>{ConfirmationModalText.ButtonStayText}</p>
      </button>
    </StyledConformationWindow>
  );
};

export default ConfirmationModal;
