import { createAsyncThunk } from '@reduxjs/toolkit';
import allocationsBookingApiService from 'api/allocationBookingApiService';
import { ApiError, notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import { AllocationsAddBookingDto } from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingDto';
import AllocationsAddBookingFormDto from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingFormDto';
import AllocationsAction from 'types/allocation/enums/Allocations';

const allocationAddBooking = createAsyncThunk<
  AllocationsAddBookingDto,
  { data: AllocationsAddBookingFormDto; force: boolean },
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: ApiError | DispatchError;
  }
>(AllocationsAction.ADD_BOOKING, async ({ data, force }, { rejectWithValue }) => {
  try {
    const response = await allocationsBookingApiService.addBooking(data, force);
    if (!response) notifyUser();
    return addKeyToReactItem(response);
  } catch (err) {
    if (err instanceof ApiError) return rejectWithValue(err);

    throw err;
  }
});

export default allocationAddBooking;
