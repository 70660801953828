import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import FreezeForecast from 'types/freezeForecast/FreezeForecast';
import freezeForecastApiService from 'api/freezeForecastApiService';

const freezeForecast = createAsyncThunk<
  void,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(FreezeForecast.FREEZE_FORECAST, async (data: number) => {
  await freezeForecastApiService.freezeForecast(data);
});
export default freezeForecast;
