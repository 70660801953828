import { ProjectDto } from 'types/project/dto/ProjectDto';
import ProjectFormDto from 'types/project/dto/ProjectFormDto';

const mapToProjectDto = (data: ProjectFormDto): ProjectDto => {
  return {
    id: data.id ?? null,
    name: data.name ?? '',
    code: data.code ?? '',
    clientId: data.clientId ?? null,
    billable: data.billable ?? false,
    billingType: data.billingType ?? null,
    billingPeriodType: data.billingPeriodType ?? null,
    projectManagerId: data.projectManagerId ?? null,
    colorHex: data.colorHex ?? '',
    status: data.status,
    startDate: data.startDate ?? '',
    endDate: data.endDate ?? '',
    customBillingPeriodWeeks: data.customBillingPeriodWeeks ?? null,
    clientName: data.clientName ?? '',
    clientColorHex: data.clientColorHex ?? '',
    projectManagerName: data.projectManagerName ?? '',
    azdoAlias: data.azdoAlias ?? '',
    actualsLastModified: data.actualsLastModified ?? '',
    startingSprintNumber: data.startingSprintNumber ?? null
  } as ProjectDto;
};
export default mapToProjectDto;
