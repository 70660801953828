import { ALERT_COLOR, PRIMARY_COLOR, SUCCESS_HOVER_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledErrorToast = styled.div({
  display: 'flex',
  flexDirection: 'row',
  '.toast-row': {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '8px'
  },
  p: {
    margin: '0',
    color: PRIMARY_COLOR
  },
  '.main-error-message': {
    color: ALERT_COLOR
  },
  ':.main-success-message': {
    color: SUCCESS_HOVER_COLOR,
    marginLeft: '8px'
  },
  '.message': {
    marginTop: '4px'
  }
});

export default StyledErrorToast;
