import AllocationDeleteTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/allocationDeleteTimeOffDto/AllocationDeleteTimeOffFormDto';

const AllocationTimeOffDeleteFormDefaultValue = (id: number): AllocationDeleteTimeOffFormDto => {
  return {
    timeOffId: id,
    deleteFutureTimeOffs: false
  };
};

export default AllocationTimeOffDeleteFormDefaultValue;
