import StyledList from 'components/employee/EmployeesList/StyledList';
import ClientsCard from 'components/clients/ClientsCard/ClientsCard';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import { useAppSelector } from 'store/redux-hooks/hooks';
import { RootState } from 'store';

type ClientsListProps = {
  filteredItems: ClientsDto[] | null;
};

const ClientsList = ({ filteredItems }: ClientsListProps) => {
  const selectedClient = useAppSelector((state: RootState) => state.clients.selectedClient);
  return (
    <StyledList>
      <div className="employees-list">
        {filteredItems?.map((client) => (
          <ClientsCard
            key={client.key}
            itemId={client.id}
            item={client}
            isSelected={client === selectedClient}
          />
        ))}
      </div>
    </StyledList>
  );
};
export default ClientsList;
