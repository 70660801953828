import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store/index';
import { DispatchError } from 'store/slices/authSlice';
import AllocationsAction from 'types/allocation/enums/Allocations';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import AllocationsDeleteBookingFormDto from 'types/allocation/dto/allocationDeleteBookingDto/AllocationsDeleteBookingFormDto';
import { AllocationsDeleteBookingDto } from 'types/allocation/dto/allocationDeleteBookingDto/AllocationsDeleteBookingDto';
import allocationsBookingApiService from 'api/allocationBookingApiService';

const deleteAllocationBooking = createAsyncThunk<
  AllocationsDeleteBookingDto,
  AllocationsDeleteBookingFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(AllocationsAction.DELETE_BOOKING, async (data: AllocationsDeleteBookingFormDto) => {
  const response = await allocationsBookingApiService.deleteBooking(data);
  if (!response) notifyUser();
  return response;
});
export default deleteAllocationBooking;
