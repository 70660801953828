import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import InvoiceAction from 'types/project/enums/Invoice';
import { InvoiceStatusFormDto } from 'types/project/dto/InvoiceStatusFormDto';
import { UpdateInvoiceStatusDto } from 'types/invoice/updateInvoiceStatusDto';
import invoiceApiService from 'api/invoiceApiServices';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';

const updateInvoiceStatus = createAsyncThunk<
  UpdateInvoiceStatusDto,
  InvoiceStatusFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(InvoiceAction.UPDATE_INVOICE_STATUS, async (data: InvoiceStatusFormDto) => {
  const response = await invoiceApiService.updateInvoiceStatus(data);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});
export default updateInvoiceStatus;
