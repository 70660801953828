import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Grid } from '@mui/material';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import InputType from 'types/generalEnums/InputType';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import AllocationsFormComponentNames from 'types/allocation/dto/enums/AllocationsFormComponentNames';
import getAllocationsDictionaries from 'store/action/allocationsAction/getAllocationsDictionaries';
import FormTextField from 'components/sharedComponents/FormComponents/FormTextField/FormTextField';
import { unwrapResult } from '@reduxjs/toolkit';
import ButtonsText from 'types/generalEnums/ButtonsText';
import FormAutocomplete from 'components/sharedComponents/FormComponents/FormAutocomplete/FormAutocomplete';
import { calculateTotalWorkingDays, makeDecemberLastMonthInMaxYear } from 'helpers/dateUtils';
import addAllocationTimeOff from 'store/action/allocationsAction/addAllocationTimeOff';
import AllocationOnCellClickTimeOffValue from 'components/allocations/AllocationTimeOff/AllocationTimeOffAddForm/AllocationOnCellClickTimeOffValue';
import AllocationTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffFormDto';
import AllocationTimeOffAddFormDefaultValue from 'components/allocations/AllocationTimeOff/AllocationTimeOffAddForm/AllocationTimeOffAddFormDefaultValue';
import TimeOffFormValidation from 'components/allocations/AllocationTimeOff/AllocationTimeOffAddForm/AllocationTimeOffAddFormValidation';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import FormDatePicker from 'components/sharedComponents/FormComponents/FormDatePicker/FormDatePicker';
import { endDateBeforeStartDate } from 'helpers/invalidDateTest';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';

type TimeOffAddFormProps = {
  onClose: () => void;
  clickCellDate?: string;
  clickCellName?: number;
};

const AllocationTimeOffAddForm = ({
  onClose,
  clickCellDate,
  clickCellName
}: TimeOffAddFormProps) => {
  const methods = useForm({
    defaultValues:
      clickCellDate && clickCellName
        ? AllocationOnCellClickTimeOffValue(clickCellDate, clickCellName)
        : AllocationTimeOffAddFormDefaultValue,
    // TODO fix uknown
    resolver: yupResolver(TimeOffFormValidation()) as unknown as Resolver<AllocationTimeOffFormDto>
  });
  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors }
  } = methods;

  const dispatch = useThunkAppDispatch();
  useEffect(() => {
    dispatch(getAllocationsDictionaries())
      .then(unwrapResult)
      .catch(() => logError('Error fetching allocationDictionaries'));
  }, []);
  const allocationsDictionaries = useAppSelector(
    (state: RootState) => state.allocationsDictionaries.data
  );
  useEscapeKey(onClose);
  const calendarState = useAppSelector((state: RootState) => state.calendarDictionaries.data);
  const minYear = calendarState?.minDate ? new Date(calendarState.minDate) : new Date();
  const maxYear = calendarState?.maxDate
    ? makeDecemberLastMonthInMaxYear(new Date(calendarState.maxDate))
    : new Date();
  const startDateWatch = watch(AllocationsFormComponentNames.StartDate);
  const endDateWatch = watch(AllocationsFormComponentNames.EndDate);
  const numOfDaysSelected = calculateTotalWorkingDays(
    new Date(startDateWatch),
    new Date(endDateWatch)
  );
  const onSubmit: SubmitHandler<AllocationTimeOffFormDto> = async (
    data: AllocationTimeOffFormDto
  ) => {
    try {
      data.startDate = data.startDate.endsWith('Z') ? data.startDate : `${data.startDate}Z`;
      data.endDate = data.endDate.endsWith('Z') ? data.endDate : `${data.endDate}Z`;
      await dispatch(addAllocationTimeOff(data)).unwrap();
      onClose();
    } catch (error) {
      logError('Error adding time off');
    }
  };
  const loadingPublicHoliday = useAppSelector(
    (state: RootState) => state.allocationPublicHoliday.saveLoading
  );

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} columns={20} className="modal-container">
          <Grid item xs={20} md={20}>
            <FormAutocomplete
              title={AllocationsFormComponentNames.PeopleRecourses}
              label={AllocationsFormComponentNames.PeopleRecoursesLabel}
              control={control}
              options={allocationsDictionaries.bookableEmployeeNames || []}
              className="allocation-form-select"
              error={errors.employeeId?.message}
            />
          </Grid>
          <Grid item xs={10} md={9}>
            <div className="booking-dates">
              <FormDatePicker
                name={AllocationsFormComponentNames.StartDate}
                control={control}
                label={AllocationsFormComponentNames.FromDateLabel}
                className="allocation-form-date"
                error={errors.startDate?.message}
                minDate={minYear}
                maxDate={maxYear}
                isEndDatePicker
              />
              <FormDatePicker
                name={AllocationsFormComponentNames.EndDate}
                control={control}
                label={AllocationsFormComponentNames.ToDateLabel}
                className="allocation-form-date"
                error={errors.endDate?.message}
                minDate={minYear}
                maxDate={maxYear}
                isEndDatePicker
              />
            </div>
            {endDateBeforeStartDate(new Date(startDateWatch), new Date(endDateWatch)) && (
              <span className="error-message error inter-caption_medium">
                {FormValidationMessage.EndDateBeforeStartDateError}
              </span>
            )}
          </Grid>
          <Grid item xs={16} md={10}>
            <p className="inter-caption-grey total-time-caption total">
              {`Total: ${numOfDaysSelected}d (${numOfDaysSelected * 8}h)`}
            </p>
          </Grid>
          <Grid item xs={20} md={20}>
            <FormAutocomplete
              title={AllocationsFormComponentNames.Type}
              label={AllocationsFormComponentNames.TypeLabel}
              control={control}
              options={allocationsDictionaries.timeOffTypes || []}
              className="allocation-form-select"
              error={errors.type?.message}
              shouldValueBeAString
            />
          </Grid>
          <Grid item xs={16} md={20}>
            <FormTextField
              label={AllocationsFormComponentNames.TimeOffDetailsLabel}
              name={AllocationsFormComponentNames.TimeOffDetails}
              type={InputType.Text}
              register={register}
              className="input-form-allocation-details"
              error={errors.details?.message}
              required={false}
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.AddTimeOff}
          isSpinnerButtonAdded
          loading={loadingPublicHoliday}
          isCancelButtonVisible
          submitButtonClassName="add-button"
          onClick={onClose}
          spinnerClassName="spinner-allocation-form"
          isSubmitButtonDisabled={endDateBeforeStartDate(
            new Date(startDateWatch),
            new Date(endDateWatch)
          )}
        />
      </StyledForm>
    </FormProvider>
  );
};

export default AllocationTimeOffAddForm;
