import { TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledNotFoundPage = styled.div({
  display: 'flex',
  '.content': {
    display: 'flex',
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    flexDirection: 'column',
    alignContent: 'center'
  },
  p: {
    margin: '0px',
    textAlign: 'center',
    color: TEXT_COLOR
  },
  h3: {
    textAlign: 'center',
    color: TEXT_COLOR,
    margin: '0 0 8px 0'
  },
  '.notfound-page-img': {
    alignSelf: 'center',
    marginBottom: '20px'
  }
});

export default StyledNotFoundPage;
