type ValuesCellProps = {
  duration: string;
  cost: string;
  className?: string;
  disableFinancialInfo?: boolean;
};

const ValuesCell = ({ duration, cost, className, disableFinancialInfo }: ValuesCellProps) => {
  return (
    <div
      className={`sprint-row-cell-outer ${className ?? ''} ${disableFinancialInfo && 'small-cell'}`}
    >
      <div className="sprint-row-cell-inner">
        <p className="inter-caption_medium duration">{duration}</p>
        {!disableFinancialInfo && <p className="inter-caption_medium cost">{cost}</p>}
      </div>
    </div>
  );
};

export default ValuesCell;
