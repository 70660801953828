import styled from 'styled-components';
import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { BORDERS_COLOR } from 'constants/colors';

const StyledFormAmountPicker = styled.div({
  input: {
    padding: '6px',
    width: '50px',
    textAlign: 'center'
  },
  '.picker': {
    border: '1px solid',
    borderColor: BORDERS_COLOR,
    borderRadius: borderRadiusCommon,
    display: 'flex',
    flexDirection: 'row'
  },
  '.vertical-line': {
    borderLeft: '1px solid',
    borderColor: BORDERS_COLOR
  }
});

export default StyledFormAmountPicker;
