enum RoutingPath {
  DefaultPage = '/',
  EmployeesPage = '/employees',
  EmployeesPageDetails = '/employees/:employeeId',
  AllocationPage = '/allocations',
  ProjectsPage = '/projects',
  ProjectDetailsPage = '/projects/:projectId',
  ClientsPage = '/clients',
  ClientsPageDetails = '/clients/:clientId',
  NotFoundPage = '/not-found',
  LogInPage = '/login',
  RedirectPage = '/oauth2/redirect',
  DownTimeErrorPage = '/downtime'
}

export default RoutingPath;
