import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import uploadActualsApiService from 'api/updateActuals';
import UploadActuals from 'types/uploadActuals/uploadActuals';
import UploadActualsParamsDto from 'types/project/dto/UploadActualsParamsDto';

const uploadActuals = createAsyncThunk<
  void,
  UploadActualsParamsDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(UploadActuals.UPLOAD_ACTUALS, async (data) => {
  await uploadActualsApiService.uploadActuals(data.startDate, data.endDate);
});
export default uploadActuals;
