import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { ImportActualsStatusResponseDto } from 'types/uploadActuals/ImportActualsStatusResponseDto';

const uploadActualsApiService: IApiService = {
  uploadActuals: async (startDate: string, endDate: string) => {
    try {
      const response = await apiRequest<void>({
        method: 'POST',
        endpoint: `${API_URL}/actuals?startDate=${startDate}&endDate=${endDate}`
      });
      return response;
    } catch (error) {
      logError('Error in updateActuals');
      throw new Error('Error in updateActuals');
    }
  },
  getImportStatus: async () => {
    try {
      const response = await apiRequest<ImportActualsStatusResponseDto>({
        method: 'GET',
        endpoint: `${API_URL}/actuals/import-status`
      });
      return response;
    } catch (error) {
      logError('Error in getImportStatus');
      throw new Error('Error in getImportStatus');
    }
  }
};

interface IApiService {
  uploadActuals: (startDate: string, endDate: string) => Promise<void>;
  getImportStatus: () => Promise<ImportActualsStatusResponseDto>;
}

export default uploadActualsApiService;
