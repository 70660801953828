import { Grid } from '@mui/material';
import StyledClientDetailsNotes from 'components/clients/ClientDetails/StyledClientDetailsNotes';
import FirstLetterOfName from 'components/sharedComponents/Initials/Initials';
import formatDate from 'helpers/formatDate';
import { memoize } from 'proxy-memoize';
import { RootState } from 'store';
import { useAppSelector } from 'store/redux-hooks/hooks';
import { ClientNoteDto } from 'types/clients/dto/ClientNoteDto';
import { ClientsDto } from 'types/clients/dto/ClientsDto';

type ClientDetailsNotesProps = {
  item: ClientsDto;
};

const ClientDetailsNotes = ({ item }: ClientDetailsNotesProps) => {
  const notes = useAppSelector(
    memoize((state: RootState) =>
      state.notes.data.filter((note: ClientNoteDto) => note.clientId === item?.id)
    )
  );

  const sortedNotes = notes.sort(
    (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
  );

  return (
    <StyledClientDetailsNotes>
      <h3 className="inter-caption_medium">Notes</h3>
      {sortedNotes?.map((note) => {
        return (
          <Grid container spacing={2} key={note.key}>
            <Grid item xs={4} md={2} className="clients-comment-item">
              <p className="inter-p3">{formatDate(note.timestamp)}</p>
            </Grid>
            <Grid item xs={6} md={4} className="clients-name-row">
              <FirstLetterOfName name={note?.authorName ?? ''} className="notes-author" />
              <p className="inter-p3 notes-author">{note?.authorName}</p>
            </Grid>
            <Grid item xs={6} md={6}>
              <p className="inter-p3 note-text">{note.content}</p>
            </Grid>
          </Grid>
        );
      })}
    </StyledClientDetailsNotes>
  );
};

export default ClientDetailsNotes;
