import AllocationPublicHolidayDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayDto';
import AllocationPublicHolidayFormDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayFormDto';

const AllocationPublicHolidayEditFormDefaultValues = (
  item: AllocationPublicHolidayDto
): AllocationPublicHolidayFormDto => {
  return {
    id: item.id ?? null,
    date: item.date ?? '',
    details: item.details ?? '',
    dbid: item.id ?? null
  };
};

export default AllocationPublicHolidayEditFormDefaultValues;
