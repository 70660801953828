import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { BORDERS_COLOR, ELEMENTS_COLOR, LOW_EMPHASISE_COLOR, TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledDetailsContent = styled.div({
  p: {
    color: TEXT_COLOR
  },
  h3: {
    color: LOW_EMPHASISE_COLOR
  },
  '.items-details-permissions': {
    backgroundColor: ELEMENTS_COLOR,
    padding: '2px 8px',
    borderRadius: '6px',
    maxWidth: '180px',
    minWidth: '32px',
    margin: '0 5px 5px 0'
  },
  '.switch-row': {
    display: 'flex',
    flexDirection: 'row',
    p: {
      alignSelf: 'center',
      margin: '0'
    }
  },
  '.permissions-row': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '400px'
  },
  '.MuiGrid-container': {
    marginLeft: '0px',
    width: '100%'
  },
  '.email-text': {
    wordBreak: 'break-word'
  },
  '.clients-projects-wrapper': {
    border: '1px solid ',
    borderColor: BORDERS_COLOR,
    marginBottom: '20px',
    borderRadius: borderRadiusCommon,
    paddingBottom: '16px',
    ':last-child': {
      marginBottom: '0'
    }
  },
  '.clients-projects-row': {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '32px'
  },

  '.clients-name-row': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  '.client-notes-spinner': {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    div: {
      width: '25px',
      height: '25px'
    }
  },
  '.notes-author': {
    alignSelf: 'baseline'
  },
  '.client-color': {
    width: '6px',
    height: '32px',
    borderRadius: borderRadiusCommon,
    marginRight: '12px',
    alignSelf: 'center'
  },
  '.client-code-row': {
    display: 'flex'
  },
  '.contact-details': {
    '& .MuiGrid-item': {
      paddingTop: '0'
    }
  },
  '.contact-information': {
    p: {
      margin: 0,
      color: LOW_EMPHASISE_COLOR
    }
  },
  '.client-name, .client-position, .client-phone': {
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    maxWidth: '95%',
    wordWrap: 'break-word'
  }
});

export default StyledDetailsContent;
