import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import getAllocationsDictionaries from 'store/action/allocationsAction/getAllocationsDictionaries';
import { AllocationsDictionariesState } from 'store/slicesState/allocationsDictionaries';
import AllocationsDictionariesDto from 'types/allocation/dto/AllocationsDictionariesDto';

const initialState: AllocationsDictionariesState = {
  data: {
    activeEmployeeNames: [],
    timeOffTypes: [],
    bookableEmployeeNames: [],
    employeeWeeklyCapacities: [],
    clientNames: [],
    projectNames: [],
    positions: [],
    filterNames: [],
    allEmployeeNames: []
  },
  loading: false,
  error: null
};
export type DispatchError = {
  errorMessage: string;
};

const allocationsDictionariesSlice = createSlice({
  name: 'allocationsDictionaries',
  initialState,
  reducers: {
    getAllocationsDictionary: (state, action: PayloadAction<AllocationsDictionariesDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllocationsDictionaries.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllocationsDictionaries.fulfilled, (state, action) => {
      state.error = null;
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getAllocationsDictionaries.rejected, (state) => {
      state.error = 'getProjectDictionaries error in slice';
      state.loading = false;
    });
  }
});

export const { getAllocationsDictionary } = allocationsDictionariesSlice.actions;
export default allocationsDictionariesSlice.reducer;
