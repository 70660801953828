import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { ProjectDictionariesDto } from 'types/project/dto/ProjectDictionariesDto';

const getProjectDictionariesWithKeys = (
  projectDictionaries: ProjectDictionariesDto
): ProjectDictionariesDto => {
  return {
    ...projectDictionaries,
    key: crypto.randomUUID(),
    billingTypes: projectDictionaries.billingTypes?.map((billing) => addKeyToReactItem(billing)),
    billingPeriodTypes: projectDictionaries.billingPeriodTypes?.map((period) =>
      addKeyToReactItem(period)
    ),
    clientNames: projectDictionaries.clientNames?.map((clients) => addKeyToReactItem(clients)),
    projectManagers: projectDictionaries.projectManagers?.map((manager) =>
      addKeyToReactItem(manager)
    )
  };
};
export default getProjectDictionariesWithKeys;
