import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { Grid } from '@mui/material';
import FormInputNumber from 'components/sharedComponents/FormComponents/FormInput/FormInputNumber';
import { maxForecast, minForecast } from 'constants/validationConstants';
import StyledItemCard, {
  updateForecastCardStyle
} from 'components/sharedComponents/SharedItemCardStyle/StyledItemCard';
import Initials from 'components/sharedComponents/Initials/Initials';
import selectPositionPropertyById from 'store/dictionarySelectors/selectorPosition';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import UpdateForecastFormNames from 'types/project/enums/UpdateForecastFormNames';
import UpdateForecastFormDto from 'types/PrpTable/dto/UpdateForecastFormDto';
import UpdateForecastFormValidation from 'components/project/ProjectDetails/UpdateForecast/UpdateForecastFormValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import allocationFormatDate from 'pages/AllocationsPage/helpers/allocationFormatDate';
import formatDate from 'helpers/formatDate';
import UpdateForecastFormDefaultValue from 'components/project/ProjectDetails/UpdateForecast/UpdateForecastFormDefaultValue';
import { unwrapResult } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { EmployeesActualDto } from 'types/PrpTable/dto/ProjectTableDto';
import getEmployees from 'store/action/employeeActions/getEmployee';
import { useThunkAppDispatch } from 'store/redux-hooks/hooks';
import updateForecast from 'store/action/projectActions/updateForecast';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import ButtonsText from 'types/generalEnums/ButtonsText';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import selectSeniorityPropertyById from 'store/dictionarySelectors/selectorSeniority';
import getDictionaries from 'store/action/employeeActions/getDictionaries';

type UpdateForecastFormProps = {
  onClose: () => void;
  employeeActuals: EmployeesActualDto | undefined;
  weekStartDate: string;
  sprintNumber: number;
  onConfirm: () => void;
};

const UpdateForecastForm = ({
  onClose,
  employeeActuals,
  weekStartDate,
  sprintNumber,
  onConfirm
}: UpdateForecastFormProps) => {
  const dispatch = useThunkAppDispatch();
  const employeeListState = useSelector((state: RootState) => state.dictionaries);
  const employees = useSelector((state: RootState) => state.employees.data);
  const selectedEmployee = employees.find(
    (employee) => employee.id === employeeActuals?.employeeId
  );
  const weekDate = allocationFormatDate(new Date(weekStartDate || ''), false);
  const defaultForecastDuration: number = employeeActuals?.forecastDuration || 0;
  const methods = useForm<UpdateForecastFormDto>({
    defaultValues: UpdateForecastFormDefaultValue(defaultForecastDuration),
    resolver: yupResolver(
      UpdateForecastFormValidation(maxForecast, minForecast)
    ) as unknown as Resolver<UpdateForecastFormDto>
  });

  const {
    handleSubmit,
    register,
    getValues,
    watch,
    formState: { errors }
  } = methods;
  useEscapeKey(onClose);
  useEffect(() => {
    dispatch(getDictionaries())
      .then(() => {
        dispatch(getEmployees())
          .then(unwrapResult)
          .catch(() => logError('Error fetching employees'));
      })
      .catch(() => {
        logError('Error fetching dictionaries');
      });
  }, []);

  const onSubmit: SubmitHandler<UpdateForecastFormDto> = async (data: UpdateForecastFormDto) => {
    try {
      await dispatch(
        updateForecast({
          forecastId: `${employeeActuals?.forecastId}`,
          forecastLength: data.forecastLength
        })
      ).then(() => {
        onConfirm();
        onClose();
      });
    } catch (error) {
      logError('Error updating forecast');
    }
  };

  const seniorityDisplayName = selectSeniorityPropertyById(
    employeeListState,
    selectedEmployee?.seniorityId || 0,
    DictionariesSelector.DisplayName
  );

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} columns={20} className="modal-container">
          <Grid item xs={20} md={20} className="update-discount-row update-discount-row-margin">
            <p className="inter-caption-grey_extrasmall">
              Sprint:
              <span className="inter-caption"> {sprintNumber}</span>
            </p>
            <p className="inter-caption-grey_extrasmall">
              Week: <span className="inter-caption"> {formatDate(weekDate)}</span>
            </p>
          </Grid>
          <div className="forecast">
            <StyledItemCard style={updateForecastCardStyle} className="card">
              <Initials name={selectedEmployee?.name ?? ''} className="img" />
              <div className="info-box">
                <h3 className="inter-p3 info-box_text">{selectedEmployee?.name}</h3>
                <p className="inter-caption_medium info-box_text position">
                  {selectedEmployee
                    ? `${selectPositionPropertyById(
                        employeeListState,
                        selectedEmployee.positionId,
                        DictionariesSelector.DisplayName
                      )}, ${seniorityDisplayName}`
                    : null}
                </p>
              </div>
            </StyledItemCard>
            <StyledItemCard style={updateForecastCardStyle} className="card">
              <div className="forecast-container">
                <div className="forecast">
                  <p className="inter-caption_bold">Forecast</p>
                  <p className="inter-caption_m_bold forecast-hours-sum">{`${employeeActuals?.forecastDuration.toFixed(
                    2
                  )}h`}</p>
                </div>
                <p className="inter-caption_medium forecast-hours-sum">{`$${employeeActuals?.forecastCost}`}</p>
              </div>
            </StyledItemCard>
          </div>
          <Grid item xs={20} md={20}>
            <FormInputNumber
              label={UpdateForecastFormNames.NewForecastTitle}
              name={UpdateForecastFormNames.NewForecast}
              required
              register={register}
              getValues={getValues}
              error={errors.forecastLength?.message}
              className="forecast-value"
              placeholder={UpdateForecastFormNames.NewForecastTitle}
              minValue={minForecast}
              maxValue={maxForecast}
              isDecimal
              numberOfDigitsBeforeDecimal={12}
              numberOfDigitsAfterDecimal={2}
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.UpdateForecast}
          isSubmitButtonDisabled={
            defaultForecastDuration === Number(watch(UpdateForecastFormNames.NewForecast))
          }
          isCancelButtonVisible
          submitButtonClassName="add-button button-text"
          cancelButtonClassName="cancel-button button-text"
          onClick={onClose}
          className="add-buttons-row"
        />
      </StyledForm>
    </FormProvider>
  );
};

export default UpdateForecastForm;
