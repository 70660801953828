import { createAsyncThunk } from '@reduxjs/toolkit';
import allocationsTimeOffApiService from 'api/allocationTimeOffApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store/index';
import mapToTimeOffDto from 'store/mappers/allocations/allocationTimeOffMapper';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import { AllocationTimeOffDto } from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffDto';
import AllocationTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffFormDto';
import AllocationsAction from 'types/allocation/enums/Allocations';

const addAllocationTimeOff = createAsyncThunk<
  AllocationTimeOffDto,
  AllocationTimeOffFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(AllocationsAction.ADD_TIME_OFF, async (data: AllocationTimeOffFormDto) => {
  const timeOffDto = mapToTimeOffDto(data);
  const response = await allocationsTimeOffApiService.addTimeOff(timeOffDto);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});
export default addAllocationTimeOff;
