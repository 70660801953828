import { clientNamePattern, emailPattern, emailSymbolLength } from 'constants/validationConstants';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import FormTypeName from 'types/generalEnums/FormTypeName';
import * as yup from 'yup';

export type EmployeeFormType = FormTypeName.EditForm | FormTypeName.AddForm;

const EmployeeFormsValidation = (
  employeeFormType: EmployeeFormType,
  minHours: number,
  maxHours: number
) => {
  const baseSchema = yup.object().shape({
    email: yup
      .string()
      .trim()
      .lowercase()
      .email(FormValidationMessage.EmailError)
      .max(emailSymbolLength, FormValidationMessage.EmailError)
      .matches(emailPattern, FormValidationMessage.EmailError)
      .required(FormValidationMessage.RequiredError),
    name: yup
      .string()
      .required(FormValidationMessage.NameError)
      .matches(clientNamePattern, FormValidationMessage.NameLengthError),
    permissionIds: yup
      .array()
      .min(1, FormValidationMessage.PermissionError)
      .required(FormValidationMessage.RequiredError),
    weeklyCapacity: yup
      .number()
      .min(minHours, FormValidationMessage.CapacityError)
      .max(maxHours, FormValidationMessage.CapacityError)
      .required(FormValidationMessage.RequiredError)
      .positive(FormValidationMessage.CapacityError)
      .typeError(FormValidationMessage.CapacityError)
  });

  const getEmployeeValidationSchema =
    employeeFormType === FormTypeName.AddForm
      ? baseSchema.concat(
          yup.object().shape({
            seniorityId: yup
              .number()
              .typeError(FormValidationMessage.SeniorityError)
              .required(FormValidationMessage.SeniorityError),
            positionId: yup
              .number()
              .typeError(FormValidationMessage.PositionError)
              .required(FormValidationMessage.PositionError)
          })
        )
      : baseSchema;

  return getEmployeeValidationSchema;
};

export default EmployeeFormsValidation;
