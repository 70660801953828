import ButtonsText from 'types/generalEnums/ButtonsText';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import FormInputNumber from 'components/sharedComponents/FormComponents/FormInput/FormInputNumber';
import FormTextField from 'components/sharedComponents/FormComponents/FormTextField/FormTextField';
import InputType from 'types/generalEnums/InputType';
import CreateInvoiceFixedPriceFormDefaultValue from 'components/project/ProjectDetails/InvoiceDetails/CreateInvoiceFixedPrice/CreateInvoiceFixedPriceFormDefaultValue';
import { InvoiceFixedPriceFormDto } from 'types/project/dto/InvoiceFixedPriceFormDto';
import CreateInvoiceFixedPriceFormValidation from 'components/project/ProjectDetails/InvoiceDetails/CreateInvoiceFixedPrice/CreateInvoiceFixedPriceFormValidation';
import InvoiceFixedPriceComponentNames from 'types/project/enums/InvoiceFixedPriceNames';
import { BillingPeriodsDto } from 'types/PrpTable/dto/ProjectTableDto';
import formatDate from 'helpers/formatDate';
import createFixedInvoice from 'store/action/invoiceAction/createFixedInvoice';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import editFixedInvoice from 'store/action/invoiceAction/editFixedInvoice';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import { useEffect } from 'react';
import getFixedInvoiceDetails from 'store/action/projectActions/getFixedInvoiceSummary';
import { unwrapResult } from '@reduxjs/toolkit';
import { RootState } from 'store';
import useEscapeKey from 'store/customHooks/useEscapeKey';

type CreateInvoiceFixedPriceFormProps = {
  onClose: (isFormSubmitted: boolean) => void;
  selectedBillingPeriod: BillingPeriodsDto | null;
  invoiceId: number;
  isEditMode: boolean;
};

const CreateEditInvoiceFixedPriceForm = ({
  onClose,
  selectedBillingPeriod,
  isEditMode,
  invoiceId
}: CreateInvoiceFixedPriceFormProps) => {
  useEscapeKey(() => onClose(false));

  const fixedInvoiceSummaryData = useAppSelector(
    (state: RootState) => state.fixedInvoiceSummary
  ).data;

  const methods = useForm<InvoiceFixedPriceFormDto>({
    defaultValues: CreateInvoiceFixedPriceFormDefaultValue(
      selectedBillingPeriod?.billingPeriodId || 0,
      invoiceId,
      isEditMode ? fixedInvoiceSummaryData?.amount : 0,
      isEditMode ? fixedInvoiceSummaryData?.description : ''
    ),
    // TODO 29679 fix unknown
    resolver: yupResolver(
      CreateInvoiceFixedPriceFormValidation()
    ) as unknown as Resolver<InvoiceFixedPriceFormDto>
  });
  const dispatch = useThunkAppDispatch();
  useEffect(() => {
    if (selectedBillingPeriod === null || !isEditMode) return;
    dispatch(getFixedInvoiceDetails(selectedBillingPeriod?.billingPeriodId || 0))
      .then(unwrapResult)
      .catch(() => logError('Error fetching fixed invoice details'));
  }, []);

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors }
  } = methods;

  const onSubmit: SubmitHandler<InvoiceFixedPriceFormDto> = async (
    data: InvoiceFixedPriceFormDto
  ) => {
    try {
      if (isEditMode) {
        await dispatch(editFixedInvoice(data)).unwrap();
      } else {
        await dispatch(createFixedInvoice(data)).unwrap();
      }
      dispatch(getFixedInvoiceDetails(selectedBillingPeriod?.billingPeriodId || 0))
        .then(unwrapResult)
        .catch(() => logError('Error fetching fixed invoice details'));
      onClose(true);
    } catch (error) {
      logError('Error creating fixed invoice');
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} justifyContent="space-between" className="modal-container">
          {selectedBillingPeriod && (
            <Grid item xs={12} md={12}>
              <div className="billing-date-container">
                <p className="inter-caption_medium">Billing Period: </p>
                <p className="inter-caption_medium invoice-billing-date">{`${formatDate(
                  selectedBillingPeriod.billingPeriodStartDate
                )} - ${formatDate(selectedBillingPeriod.billingPeriodEndDate, '', true)}`}</p>
              </div>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <FormInputNumber
              label={InvoiceFixedPriceComponentNames.AmountLabel}
              name={InvoiceFixedPriceComponentNames.AmountName}
              required
              register={register}
              getValues={getValues}
              error={errors.amount?.message}
              minValue={0}
              className="input-form invoice-amount-input"
              isDecimal
              numberOfDigitsAfterDecimal={2}
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormTextField
              label={InvoiceFixedPriceComponentNames.DetailsLabel}
              name={InvoiceFixedPriceComponentNames.DetailsName}
              type={InputType.Text}
              required
              register={register}
              className="input-form"
              error={errors.description?.message}
            />
          </Grid>
          <CustomModalButtons
            submitButtonText={isEditMode ? ButtonsText.SaveChanges : ButtonsText.CreateInvoice}
            isCancelButtonVisible
            submitButtonClassName="add-button"
            onClick={() => onClose(false)}
            className="invoice-buttons-row"
          />
        </Grid>
      </StyledForm>
    </FormProvider>
  );
};
export default CreateEditInvoiceFixedPriceForm;
