import CalendarDictionariesDto from 'types/allocation/dto/calendarDto/CalendarDictionariesDto';

const getWeekId = (startDate: string, calendarDictionaries: CalendarDictionariesDto): number => {
  const formattedStartDate = new Date(startDate).toISOString();
  const indexOfTime = formattedStartDate.toString().indexOf('T');
  const dateWithoutTime = formattedStartDate.toString().substring(0, indexOfTime);
  let weekId = 0;

  const allCalendarsFlattened = Object.values(calendarDictionaries.calendar).flat();
  const allWeeksFlattened = allCalendarsFlattened.flatMap((calendar) => calendar.weeks);

  const foundWeek = allWeeksFlattened.find((week) => {
    const index = week.startDate.toString().indexOf('T');
    return week.startDate.toString().substring(0, index) === dateWithoutTime;
  });

  if (foundWeek) weekId = Number(foundWeek.id);
  return weekId;
};

export default getWeekId;
