export const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/g;
export const namePattern = /^(?! )[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ][a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]{2,1000}$/u;
export const positionPattern =
  /^(?! )[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ][a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ -]{1,999}$/u;
export const clientNamePattern = /^(?! )[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ 0-9-]{3,100}$/u;
export const projectNamePattern = /^([a-zA-Z0-9\s]{3,1000}|[\p{L}\s]{3,1000})$/u;
export const projectCodePattern = /^[a-zA-Z0-9]{3,5}$/u;
export const detailsPattern = /^[\p{L}\d\-![\]{}'"`~?@#$€*&%+/:;=^|—><()\\_.,\s]{0,1000}$/u;
export const reasonPattern = /^[\p{L}\d\-![\]{}'"`~?@#$€*&%+/:;=^|—><()\\_.,\s]{0,350}$/u;
export const notePattern = /^([a-zA-Z0-9{}'"`\-~?@#$€*&%+/:;=^\]|—>[!<()\\_.,\s]{0,350})$/u;
export const phonePattern = /^(?! )[0-9 -()]{3,20}[^ ]{2,}$/u;
export const emailSymbolLength = 320;
export const maxLengthForTextFields = 1000;
export const maxCapacity = 60;
export const minCapacity = 1;
export const minForecast = 0.5;
export const maxForecast = 60;
export const maxCustomBillingPeriodWeeks = 10;
export const minCustomBillingPeriodWeeks = 1;
export const minRate = 0;
export const maxRate = 350;
export const minAdditionalCost = 0.01;
export const minStartingSprintNumber = 1;
export const maxStartingSprintNumber = 9999;
export const maxAzdoAliasLengthToDisplay = 43;
export const sprintNotePattern = /^(?! )[\p{L}\d\-![\]{}'"`~?@#$€*&%+/:;=^|—><()\\_.,\s]{0,1000}$/u;
