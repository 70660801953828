import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import { RootState } from 'store';
import selectPositionPropertyById from 'store/dictionarySelectors/selectorPosition';
import selectSeniorityPropertyById from 'store/dictionarySelectors/selectorSeniority';
import selectPermissionsPropertyById from 'store/dictionarySelectors/selectorPermissions';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'store/redux-hooks/hooks';
import { memoize } from 'proxy-memoize';
import EmployeeFormComponentNames from 'types/employee/enums/EmployeeFormComponentNames';
import StyledDetailsContent from './StyledDetailsContetnt';

type EmployeesDetailsContentProps = {
  item: EmployeeDto;
};

const EmployeesDetailsContent = ({ item }: EmployeesDetailsContentProps) => {
  const employeeDetailsState = useSelector((state: RootState) => state.dictionaries);
  const employee = useAppSelector(
    memoize((state: RootState) => state.employees.data.find((e: EmployeeDto) => e.id === item.id))
  );

  const positionDisplayName =
    employee &&
    selectPositionPropertyById(
      employeeDetailsState,
      employee.positionId,
      DictionariesSelector.DisplayName
    );
  const seniorityDisplayName =
    employee &&
    selectSeniorityPropertyById(
      employeeDetailsState,
      employee.seniorityId,
      DictionariesSelector.DisplayName
    );
  const permissionsDisplayNames =
    employee &&
    selectPermissionsPropertyById(
      employeeDetailsState,
      employee.permissionIds,
      DictionariesSelector.DisplayName
    );

  return (
    <StyledDetailsContent>
      <Grid container spacing={2}>
        <Grid item xs={6} md={4}>
          <h3 className="inter-caption_medium">Position</h3>
          <p className="inter-p3"> {positionDisplayName}</p>
        </Grid>
        <Grid item xs={6} md={4}>
          <h3 className="inter-caption_medium">Seniority</h3>
          <p className="inter-p3">{seniorityDisplayName}</p>
        </Grid>
        <Grid item xs={6} md={4}>
          <h3 className="inter-caption_medium">Email</h3>
          <p className="inter-p3 email-text">{employee?.email}</p>
        </Grid>
        <Grid item xs={6} md={4}>
          <h3 className="inter-caption_medium">Weekly capacity (hours)</h3>
          <p className="inter-p3">{employee?.weeklyCapacity}</p>
        </Grid>
        <Grid item xs={6} md={4}>
          <h3 className="inter-caption_medium">Employee Status</h3>
          <div className="switch-row">
            <Switch
              checked={employee?.active}
              inputProps={{ 'aria-label': 'controlled' }}
              disabled
            />
            <p className="inter-p3">
              {employee?.active
                ? EmployeeFormComponentNames.ActivityLabel
                : EmployeeFormComponentNames.InactivityLabel}
            </p>
          </div>
        </Grid>
        <Grid item xs={6} md={4}>
          <h3 className="inter-caption_medium">System Access</h3>
          <div className="switch-row">
            <Switch
              checked={employee?.hasSystemAccess}
              inputProps={{ 'aria-label': 'controlled' }}
              disabled
            />
            <p className="inter-p3">
              {employee?.hasSystemAccess
                ? EmployeeFormComponentNames.AccessLabel
                : EmployeeFormComponentNames.NoAccessLabel}
            </p>
          </div>
        </Grid>
        {item?.permissionIds.length > 0 ? (
          <Grid item xs={6} md={4}>
            <h3 className="inter-caption_medium">Permissions</h3>
            <div className="permissions-row">
              {permissionsDisplayNames?.map((name) => (
                <p key={name} className="items-details-permissions inter-caption_medium">
                  {name}
                </p>
              ))}
            </div>
          </Grid>
        ) : null}
      </Grid>
    </StyledDetailsContent>
  );
};

export default EmployeesDetailsContent;
