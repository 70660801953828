import {
  dataToDisplayDto,
  subTotalsDiscountsDto,
  totalsDiscountsOnlyDto
} from 'types/PrpTable/dto/ProjectTableDto';
import isBorderVisible from 'helpers/isCellBorderVisible';
import calculateCellWidth from 'helpers/calculateTotalsCellWidth';
import isCellAloneInBillingPeriod from 'helpers/isCellAloneInBillingPerio';
import TotalsTableBorderType from 'types/PrpTable/enums/TotalsTableBorderType';
import { BpWeeksDto } from 'types/PrpTable/dto/ProjectTableParamsDto';
import ValuesCell from 'components/project/ProjectTable/ValuesCell/ValuesCell';
import {
  getCurrencyIfValueExistOrPlaceholder,
  getHourOrPlaceholder,
  getNegativeValueOrNull
} from 'helpers/formatCurrencyAndHours';
import getPrpTableCellClass from 'helpers/getPrpTableCellClass';

type SubTotalsDiscountsProps = {
  subTotalsDiscounts: dataToDisplayDto[];
  shouldRenderBorders: boolean;
  borderType: TotalsTableBorderType;
  billingPeriodWeeks: BpWeeksDto[];
  billingPeriodIndex: number;
  disableFinancialInfo: boolean;
};
const SubTotalsDiscounts = ({
  subTotalsDiscounts,
  shouldRenderBorders,
  borderType,
  billingPeriodWeeks,
  billingPeriodIndex,
  disableFinancialInfo
}: SubTotalsDiscountsProps) => {
  let summedNumberOfWeeks = billingPeriodWeeks[0]?.numberOfWeeks ?? 0;
  const renderDiscountValuesCells = (totals: totalsDiscountsOnlyDto) => (
    <>
      <ValuesCell
        duration={getHourOrPlaceholder(getNegativeValueOrNull(totals.preDiscountDuration))}
        cost={getCurrencyIfValueExistOrPlaceholder(getNegativeValueOrNull(totals.preDiscountCost))}
        className={getPrpTableCellClass({
          discountDuration: totals.preDiscountDuration
        })}
        disableFinancialInfo={disableFinancialInfo}
      />
      <ValuesCell
        duration={getHourOrPlaceholder(getNegativeValueOrNull(totals.standardDiscountDuration))}
        cost={getCurrencyIfValueExistOrPlaceholder(
          getNegativeValueOrNull(totals.standardDiscountCost)
        )}
        className={getPrpTableCellClass({
          discountDuration: totals.standardDiscountCost
        })}
        disableFinancialInfo={disableFinancialInfo}
      />
    </>
  );

  return subTotalsDiscounts.map((cell: subTotalsDiscountsDto, index: number) => {
    const { totals } = cell;

    const hasBorder = isBorderVisible(index, shouldRenderBorders, borderType, summedNumberOfWeeks);

    const cellWidth = calculateCellWidth({
      billingPeriodWeeks,
      borderType,
      cell
    });

    if (hasBorder) {
      billingPeriodIndex += 1;
      summedNumberOfWeeks += billingPeriodWeeks[billingPeriodIndex]?.numberOfWeeks ?? 0;
    }

    const isAloneInBillingPeriod = isCellAloneInBillingPeriod(
      billingPeriodWeeks,
      billingPeriodIndex
    );

    return (
      <div
        className={`table-content-type-details ${hasBorder ? 'totals-border' : ''}`}
        key={cell.id}
        style={{
          width: cellWidth,
          paddingLeft: isAloneInBillingPeriod ? '51px' : '0px'
        }}
      >
        {renderDiscountValuesCells(totals)}
      </div>
    );
  });
};

export default SubTotalsDiscounts;
