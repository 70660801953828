import apiRequest from 'api/apiRequest';
import { API_URL, AUTHORIZATION_HEADER } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AuthorizationDto } from 'types/authorization/AuthorizationsDto';

const authService: IApiService = {
  logout: async () => {
    try {
      const response = await apiRequest<void>({
        method: 'POST',
        endpoint: `${API_URL}/logout`,
        headers: {
          Authorization: AUTHORIZATION_HEADER
        }
      });
      return response;
    } catch (error) {
      logError('Error in logout');
      throw new Error('Error in logout');
    }
  },
  fetchUser: async () => {
    try {
      const response = await apiRequest<AuthorizationDto>({
        method: 'GET',
        endpoint: `${API_URL}/auth/user`,
        headers: {
          Authorization: AUTHORIZATION_HEADER
        }
      });
      return response;
    } catch (error) {
      logError('Error in getUser');
      throw new Error('Error in getUser');
    }
  }
};

interface IApiService {
  logout: () => Promise<void>;
  fetchUser: () => Promise<AuthorizationDto>;
}

export default authService;
