import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import AllocationsDictionariesDto from 'types/allocation/dto/AllocationsDictionariesDto';

const allocationsDictionariesApiService: IApiService = {
  fetchAllocationsDictionaries: async () => {
    try {
      const response = await apiRequest<AllocationsDictionariesDto>({
        method: 'GET',
        endpoint: `${API_URL}/dictionaries/allocations`
      });
      return response;
    } catch (error) {
      logError('Error in fetchAllocationsDictionaries');
      throw new Error('Error in fetchAllocationsDictionaries');
    }
  }
};

interface IApiService {
  fetchAllocationsDictionaries: () => Promise<AllocationsDictionariesDto>;
}

export default allocationsDictionariesApiService;
