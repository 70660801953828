import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { RootState } from 'store';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import FormAmountPicker from 'components/sharedComponents/FormComponents/FormAmountPicker/FormAmountPicker';
import formatDate from 'helpers/formatDate';
import ButtonsText from 'types/generalEnums/ButtonsText';
import PrpTableComponentNames from 'types/PrpTable/enums/projectTableComponentNames';
import EditBillingPeriodFormDto from 'types/PrpTable/dto/EditBillingPeriodFormDto';
import updateBillingPeriod from 'store/action/projectActions/updateBillingPeriod';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import { useEffect, useState } from 'react';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import getBillingPeriod from 'store/action/projectActions/getBillingPeriod';
import SpinnerButton from 'components/sharedComponents/Spinner/SpinnerButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import EditBillingPeriodDefaultValue from './EditBillingPeriodFormDefaultValue';
import EditBillingPeriodFormValidation from './EditBillingPeriodFormValidation';

type EditBillingPeriodFormProps = {
  startDate: string | null;
  onClose: () => void;
  editBillingPeriodId: number;
  refreshProjectData: () => void;
};

const EditBillingPeriodForm = ({
  startDate,
  onClose,
  editBillingPeriodId,
  refreshProjectData
}: EditBillingPeriodFormProps) => {
  const dispatch = useThunkAppDispatch();

  const methods = useForm<EditBillingPeriodFormDto>({
    defaultValues: EditBillingPeriodDefaultValue(editBillingPeriodId),
    // TODO 29679 fix unknown
    resolver: yupResolver(
      EditBillingPeriodFormValidation()
    ) as unknown as Resolver<EditBillingPeriodFormDto>
  });
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = methods;
  useEscapeKey(onClose);
  const [billingPeriodLength, setBillingPeriodLength] = useState(0);
  const [isBillingPeriodLengthLoading, setIsBillingPeriodLengthLoading] = useState(false);

  useEffect(() => {
    setIsBillingPeriodLengthLoading(true);
    setValue('id', editBillingPeriodId);
    dispatch(getBillingPeriod(editBillingPeriodId))
      .unwrap()
      .then((result) => {
        setBillingPeriodLength(result.billingPeriodLength);
        setIsBillingPeriodLengthLoading(false);
      })
      .catch(() => logError('Error fetching employees'));
  }, [editBillingPeriodId]);

  const onSubmit: SubmitHandler<EditBillingPeriodFormDto> = async (
    data: EditBillingPeriodFormDto
  ) => {
    if (data) {
      await dispatch(updateBillingPeriod(data)).unwrap();
      refreshProjectData();
      onClose();
    }
  };
  const loadingPublicHoliday = useAppSelector(
    (state: RootState) => state.allocationPublicHoliday.saveLoading
  );

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className="modal-container">
          <Grid item xs={12} md={12} className="edit-billing-row start-date-row">
            <p className="inter-p3"> Start date </p>
            <p className="inter-p3"> {formatDate(startDate)} </p>
          </Grid>
          <Grid item xs={12} md={12} className="edit-billing-row">
            <p className="inter-p3"> Amount of weeks </p>
            {isBillingPeriodLengthLoading ? (
              <SpinnerButton loading={isBillingPeriodLengthLoading} />
            ) : (
              <FormAmountPicker
                register={register}
                required
                value={billingPeriodLength}
                name={PrpTableComponentNames.EditBillingPeriodName}
                error={errors.amount?.message}
              />
            )}
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.Update}
          isSpinnerButtonAdded
          loading={loadingPublicHoliday}
          isCancelButtonVisible
          submitButtonClassName="add-button"
          onClick={onClose}
          spinnerClassName="spinner-allocation-public-holiday"
          className="add-buttons-row"
        />
      </StyledForm>
    </FormProvider>
  );
};

export default EditBillingPeriodForm;
