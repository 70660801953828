import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import * as yup from 'yup';
import { detailsPattern } from 'constants/validationConstants';
import invalidDateTest from 'helpers/invalidDateTest';

const TimeOffFormValidation = () => {
  return yup.object().shape({
    employeeId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(FormValidationMessage.EmploeeChoiceError),
    startDate: invalidDateTest(),
    endDate: invalidDateTest(),
    details: yup.string().matches(detailsPattern, FormValidationMessage.MaxLengthError),
    type: yup.string().required(FormValidationMessage.TypeChoiceError)
  });
};

export default TimeOffFormValidation;
