import { TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledList = styled.div({
  flex: '1 1 auto',
  display: 'flex',
  flexDirection: 'column',
  '& h3': {
    color: TEXT_COLOR
  },
  '.employees-list': {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    marginBottom: '4px'
  }
});

export default StyledList;
