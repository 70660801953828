import { FormControl, InputBase } from '@mui/material';
import StyledFormInput from 'components/sharedComponents/FormComponents/FormInput/StyledFormInput';
import { FieldValues, Path, UseFormRegister } from 'react-hook-form';

type FormInputProps<T extends FieldValues> = {
  label?: string;
  name: string;
  type: string;
  required: boolean;
  register: UseFormRegister<T>;
  className?: string;
  error?: string;
  disabled?: boolean;
  maxValue?: number;
  placeholder?: string;
  maxLength?: number;
};

const FormInput = <T extends FieldValues>({
  label,
  name,
  type,
  required,
  register,
  error,
  className,
  disabled,
  placeholder,
  maxValue,
  maxLength
}: FormInputProps<T>) => {
  return (
    <StyledFormInput>
      <FormControl>
        <p className="inter-caption_medium label">{label}</p>
        <InputBase
          id={name as Path<T>}
          {...register(name as Path<T>, { required })}
          type={type}
          className={className}
          disabled={disabled || false}
          inputProps={{ max: maxValue, maxLength }}
          placeholder={placeholder || ''}
        />
        {error && <p className="error inter-caption_medium">{error}</p>}
      </FormControl>
    </StyledFormInput>
  );
};

export default FormInput;
