enum PrpTableComponentNames {
  EditBillingPeriodName = 'amount',
  EditSprintLengthName = 'amount',
  DiscountName = 'duration',
  DiscountLabel = 'Discount Hours',
  DiscountReasonName = 'reason',
  DiscountReasonLabel = 'Reason',
  DiscountRepeatName = 'repeatForWeeksInBillingPeriod',
  DiscountRepeatLabel = 'Copy to every week in the current billing period',
  DiscountTypeName = 'discountType',
  SprintNoteName = 'note',
  SprintNoteLabel = 'Sprint updates'
}

export default PrpTableComponentNames;
