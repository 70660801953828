import { FieldValues } from 'react-hook-form';
import StyledFormModalWindow from 'components/sharedComponents/FormComponents/FormModalWindow/StyledFormModalWindow';

type FormModalWindowProps = {
  description?: string;
  btnText?: string;
  onSubmit: (field: FieldValues) => void;
  field: FieldValues;
  className?: string;
};

const FormModalWindow = ({
  description,
  btnText,
  onSubmit,
  field,
  className
}: FormModalWindowProps) => {
  return (
    <StyledFormModalWindow className={className}>
      <p className="inter-p3">{description}</p>
      <button type="button" className="modal-button inter-caption" onClick={() => onSubmit(field)}>
        {btnText}
      </button>
    </StyledFormModalWindow>
  );
};

export default FormModalWindow;
