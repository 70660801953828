import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { ProjectTableDto, ProjectTotalsDto } from 'types/PrpTable/dto/ProjectTableDto';

const projectTableApiService: IApiService = {
  fetchProjectTable: async (projectId: number, month: number, year: number) => {
    try {
      const response = await apiRequest<ProjectTableDto>({
        method: 'GET',
        endpoint: `${API_URL}/projects/${projectId}/prp?year=${year}&month=${month}`
      });
      return response;
    } catch (error) {
      logError('Error in fetchProjectTable');
      throw new Error('Error in fetchProjectTable');
    }
  },
  fetchProjectTotals: async (projectId: number, month: number, year: number) => {
    try {
      const response = await apiRequest<ProjectTotalsDto>({
        method: 'GET',
        endpoint: `${API_URL}/projects/${projectId}/prp/totals?year=${year}&month=${month}`
      });
      return response;
    } catch (error) {
      logError('Error in fetchProjectTotals');
      throw new Error('Error in fetchProjectTable');
    }
  }
};

interface IApiService {
  fetchProjectTable: (projectId: number, month: number, year: number) => Promise<ProjectTableDto>;
  fetchProjectTotals: (projectId: number, month: number, year: number) => Promise<ProjectTotalsDto>;
}

export default projectTableApiService;
