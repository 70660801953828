import { IS_PROD_ENV } from 'environment/environment';
import showToast from 'shared/errorHandling/ErrorToast/ErrorToast';
import { ErrorStatus, ErrorType } from 'types/ApiError/ErrorEnums';
import FmPrpApiError from 'types/ApiError/FmPrpApiError';
import GenericErrorMessage from 'types/generalEnums/GenericErrorMessage';

function capitalizeFirstLetter(message: string): string {
  return message.charAt(0).toUpperCase() + message.slice(1);
}
function formatErrorBody(errorBody: string, trimFirstWord: boolean): string {
  const errorBodyReplace = /[[{}|&;$%@"<>()+]/g;
  let result =
    `${errorBody
      .replaceAll(']', '')
      .replaceAll(',', ', ')
      .replace(errorBodyReplace, '')
      .replaceAll(':', ' ')}` ?? GenericErrorMessage.ContactAdministrator;

  if (trimFirstWord) {
    const spaceIndex = result.indexOf(' ');
    if (spaceIndex !== -1) {
      result = result.substring(spaceIndex + 1);
    }
  }

  return capitalizeFirstLetter(result);
}

export function notifyUser(
  errorTitle: string = GenericErrorMessage.SomethingWentWrong,
  errorBody: string = GenericErrorMessage.ContactAdministrator,
  trimFirstWord = true
): void {
  showToast(
    'error',
    errorTitle ?? GenericErrorMessage.SomethingWentWrong,
    formatErrorBody(errorBody, trimFirstWord)
  );
}
export function notifyUserWith(message: string) {
  notifyUser(
    GenericErrorMessage.SomethingWentWrong,
    message ?? GenericErrorMessage.ContactAdministrator
  );
}

export function logError(message: string): void {
  if (!IS_PROD_ENV) {
    /* eslint-disable */
    console.error(message);
  }
}

export class ApiError extends Error {
  header: string;
  type: ErrorType;
  status: ErrorStatus;
  errorsMap: Record<string, string>;

  constructor(apiError: FmPrpApiError) {
    super(apiError.message);
    Object.setPrototypeOf(this, ApiError.prototype);
    this.header = apiError.header;
    this.type = apiError.type;
    this.status = apiError.status;
    this.errorsMap = apiError.errorsMap;
  }
}
