import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledBookingEvent = styled.div({
  borderRadius: borderRadiusCommon,
  marginBottom: '10px',
  padding: '2px 2px 2px 2px',
  p: {
    margin: '0'
  },
  '.title': {
    color: TEXT_COLOR,
    marginBottom: '2px',
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    overflowWrap: 'break-word'
  }
});

export default StyledBookingEvent;
