enum InvoiceAction {
  GET_INVOICE = 'invoice/getInvoice',
  GET_PREINVOICE_SUMMARY = 'preinvoice/summary',
  GET_INVOICE_SUMMARY = 'invoice/summary',
  GET_FIXED_INVOICE_SUMMARY = 'fixedinvoice/summary',
  UPDATE_INVOICE_STATUS = 'invoice/updateInvoice',
  ADD_ADDITIONAL_COST = 'invoice/additionalCost',
  CREATE_FIXED_INVOICE = 'invoice/createFixedInvoice',
  DELETE_ADDITIONAL_COST = 'invoice/deleteAdditionalCost',
  DELETE_INVOICE = 'invoice/deleteInvoice',
  EDIT_FIXED_INVOICE = 'invoice/editFixedInvoice'
}

export default InvoiceAction;
