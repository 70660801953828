import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import FormTextField from 'components/sharedComponents/FormComponents/FormTextField/FormTextField';
import FormSelectDictionaries from 'components/sharedComponents/FormComponents/FormSelectDictionaries/FormSelectDictionaries';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { FormProvider, Resolver, useForm, SubmitHandler } from 'react-hook-form';
import ButtonsText from 'types/generalEnums/ButtonsText';
import InputType from 'types/generalEnums/InputType';
import ProjectFormComponentNames from 'types/project/enums/ProjectFormComponentNames';
import { InvoiceStatusFormDto } from 'types/project/dto/InvoiceStatusFormDto';
import { getInvoiceStatusName } from 'components/project/ProjectDetails/InvoiceDetails/InvoiceDetails';
import { unwrapResult } from '@reduxjs/toolkit';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { useThunkAppDispatch } from 'store/redux-hooks/hooks';
import { useEffect } from 'react';
import updateInvoiceStatus from 'store/action/invoiceAction/updateInvoiceStatus';
import getInvoiceDictionaries from 'store/action/invoiceAction/getInvoiceDictionaries';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import InvoiceStatusFormDefaultValue from './InvoiceStatusFormDefaultValue';
import InvoiceStatusFormValidation from './InvoiceStatusFormValidation';

type InvoiceStatusFormProps = {
  invoiceId: number | null;
  onClose: (isFormSubmitted: boolean) => void;
  statuses: string[];
  currentStatus: string;
};

const InvoiceStatusForm = ({
  onClose,
  statuses,
  invoiceId,
  currentStatus
}: InvoiceStatusFormProps) => {
  const filteredStatus = Number(
    statuses.indexOf(statuses.find((status) => status === currentStatus) || statuses[0])
  );
  const methods = useForm({
    defaultValues: InvoiceStatusFormDefaultValue(currentStatus, invoiceId),
    // TODO fix unknown 34916
    resolver: yupResolver(
      InvoiceStatusFormValidation()
    ) as unknown as Resolver<InvoiceStatusFormDto>
  });

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    formState: { errors }
  } = methods;
  useEscapeKey(() => onClose(false));
  useEffect(() => {
    if (invoiceId !== null) {
      setValue('invoiceId', invoiceId);
    }
  }, [invoiceId]);

  const invoiceStatusArray: { id: number; displayName: string; name: string }[] = statuses
    .map((key: string, index: number) => ({
      id: index,
      displayName: getInvoiceStatusName(key),
      name: key
    }))
    .filter((status) => status.displayName !== getInvoiceStatusName(currentStatus));

  useEffect(() => {
    const statusId =
      invoiceStatusArray.find(
        (status) => status.displayName === getInvoiceStatusName(currentStatus)
      )?.id || 0;
    setValue(ProjectFormComponentNames.InvoiceStatusName, statusId.toString());
  }, []);

  const dispatch = useThunkAppDispatch();
  const onSubmit: SubmitHandler<InvoiceStatusFormDto> = async (data: InvoiceStatusFormDto) => {
    const invoiceStatusIndex: number = Number(data?.invoiceStatus) || 0;
    const invoiceData = {
      ...data,
      invoiceStatus: data.invoiceStatus ? statuses[invoiceStatusIndex] : statuses[filteredStatus]
    };
    dispatch(updateInvoiceStatus(invoiceData))
      .then(unwrapResult)
      .then(() => {
        onClose(true);
        dispatch(getInvoiceDictionaries(invoiceId || 0))
          .then(unwrapResult)
          .catch(() => {
            logError('Error fetching invoice dictionaries');
          });
      })
      .catch(() => {
        logError('Error updating invoice status');
      });
  };
  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <FormSelectDictionaries
              title={ProjectFormComponentNames.InvoiceStatusName}
              label={ProjectFormComponentNames.InvoiceStatusNameLabel}
              control={control}
              options={invoiceStatusArray}
              className="invoice-status-select"
              error={errors.invoiceStatus?.message}
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormTextField
              label={ProjectFormComponentNames.InvoiceNoteNameLabel}
              name={ProjectFormComponentNames.InvoiceNoteName}
              type={InputType.Text}
              required
              register={register}
              className="invoice-status-note-input"
              error={errors.notes?.message}
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.UpdateStatus}
          isCancelButtonVisible
          submitButtonClassName="add-button"
          onClick={() => onClose(false)}
          className="add-buttons-row"
          isSubmitButtonDisabled={
            Number(watch(ProjectFormComponentNames.InvoiceStatusName)) === filteredStatus
          }
        />
      </StyledForm>
    </FormProvider>
  );
};

export default InvoiceStatusForm;
