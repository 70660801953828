import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import mapToClientNoteDto from 'store/mappers/clients/clientsNoteMapper';
import { ClientNoteDto } from 'types/clients/dto/ClientNoteDto';
import { ClientNoteFormDto } from 'types/clients/dto/ClientNoteFormDto';
import { ClientsDto } from 'types/clients/dto/ClientsDto';

const notesApiService: IApiService = {
  fetchClientsNotes: async (data: ClientsDto) => {
    try {
      const response = await apiRequest<ClientNoteDto[]>({
        method: 'GET',
        endpoint: `${API_URL}/clients/${data.id}/notes`
      });
      return response;
    } catch (error) {
      logError('Error in fetchClientsNotes');
      throw new Error('Error in fetchClientsNotes');
    }
  },
  addNote: async (data: ClientNoteFormDto) => {
    try {
      const addNotesDto = mapToClientNoteDto(data);
      const response = await apiRequest<ClientNoteDto>({
        method: 'POST',
        endpoint: `${API_URL}/clients/${addNotesDto.clientId}/notes`,
        requestBody: addNotesDto
      });
      return response;
    } catch (error) {
      logError('Error in addNote');
      throw new Error('Error in addNote');
    }
  }
};

interface IApiService {
  fetchClientsNotes: (data: ClientsDto) => Promise<ClientNoteDto[]>;
  addNote: (data: ClientNoteFormDto) => Promise<ClientNoteDto>;
}
export default notesApiService;
