import { LOW_EMPHASISE_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledDatePicker = styled.div({
  '.react-datepicker': {
    border: 'none',
    boxShadow: '2px 2px 15px rgba(64, 64, 85, 0.3)',
    position: 'relative'
  },
  '.react-datepicker__header': {
    backgroundColor: 'white',
    borderBottom: 'none'
  },
  '.react-datepicker__current-month': {
    marginBottom: '10px',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '400'
  },
  '.react-datepicker__month-container': {
    padding: '10px'
  },
  '.react-datepicker__navigation--next': {
    right: '10px'
  },
  '.react-datepicker__navigation': {
    top: '10px'
  },
  '.react-datepicker__navigation--previous': {
    left: '10px'
  },
  '.icon': {
    display: 'flex'
  },
  '.info-icon': {
    marginTop: '-20px'
  },
  '.button-with-icon': {
    padding: '0',
    backgroundColor: 'transparent',
    border: 'none',
    marginTop: '-5px'
  },
  '.label': {
    color: LOW_EMPHASISE_COLOR
  },
  '.date-error-container': {
    display: 'flex',
    flexDirection: 'column'
  }
});

export default StyledDatePicker;
