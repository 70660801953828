enum GenericErrorMessage {
  SomethingWentWrong = 'Something went wrong',
  ContactAdministrator = 'Please contact the administrator or check your internet connection',
  CompletedSuccessfully = 'Action completed successfully',
  FaildAuthorization = 'User authorization failed',
  FailedUpdatingBillingPeriodLength = 'Unfreeze the forecast to change the billing period',
  CannotUpdateBillingPeriodLength = 'Changing billing period length is disabled if the invoice is approved or issued',
  RegenerateForecastToEditForecast = 'Please regenerate forecast before editing the forecast',
  ForecastDoesNotExist = 'This forecast does not exist - please create a booking in the Allocation table'
}

export default GenericErrorMessage;
