import { useMemo, useState } from 'react';
import {
  addAdditionalCostModalHeight,
  deleteInvoiceModalHeight,
  invoiceStatusModalHeight,
  invoiceStatusModalWidth
} from 'components/sharedComponents/StyleConsts/styleConsts';
import ModalWindow from 'modals/ModalWindow';
import { formatToUsd, getCurrencyIfValueExistOrPlaceholder } from 'helpers/formatCurrencyAndHours';
import { addGrey, iconComment } from 'shared/PrpIcon/icons.constants';
import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import { InvoiceDictionariesDto, InvoiceStatus } from 'types/invoice/InvoiceDictionariesDto';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import { RootState } from 'store';
import PermissionsName from 'types/generalEnums/PermissionsName';
import { getDateOrPlaceholder } from 'helpers/dateUtils';
import { InvoiceDto } from 'types/project/dto/InvoiceDto';
import getFixedInvoiceDetails from 'store/action/projectActions/getFixedInvoiceSummary';
import { unwrapResult } from '@reduxjs/toolkit';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import AddEditAdditionalCostForm from 'components/project/ProjectDetails/InvoiceDetails/AdditionalCost/AddEditAdditionalCostForm';
import DeleteAdditionalCostForm from 'components/project/ProjectDetails/InvoiceDetails/AdditionalCost/DeleteAdditionalCostForm';
import InvoiceDetailsFormNames from 'types/project/enums/InvoiceDetailsFormNames';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import getInvoice from 'store/action/projectActions/getInvoice';
import { ProjectTableDto } from 'types/PrpTable/dto/ProjectTableDto';
import InvoiceStatusForm from './InvoiceStatusForm/InvoiceStatusForm';
import StyledInvoiceDetails from './StyledInvoiceDetails';

type FixedInvoiceDetailsProps = {
  onMainModalClose: () => void;
  invoiceState: InvoiceDto[];
  invoiceDictionariesState: InvoiceDictionariesDto;
  invoiceId: number | null;
  refetchPreInvoiceOrInvoiceDetails: (billingPeriodId?: number) => void;
  billingPeriodId: number;
  projectTableState: ProjectTableDto;
  isPreInvoice: boolean | null;
};

export const getInvoiceStatusName = (status: string | undefined): string => {
  switch (status) {
    case InvoiceStatus.APPROVED:
      return 'Approved';
    case InvoiceStatus.REJECTED:
      return 'Rejected';
    case InvoiceStatus.READY_FOR_APPROVAL:
      return 'Ready For Approval';
    case InvoiceStatus.ISSUED:
      return 'Issued';
    default:
      return 'New';
  }
};
export const getInvoiceStatusClassName = (status: string | undefined) => {
  switch (status) {
    case InvoiceStatus.APPROVED:
      return 'greenCircle';
    case InvoiceStatus.REJECTED:
      return 'redCircle';
    case InvoiceStatus.READY_FOR_APPROVAL:
      return 'orangeCircle';
    case InvoiceStatus.ISSUED:
      return 'blackCircle';
    default:
      return 'greyCircle';
  }
};

const FixedInvoiceDetails = ({
  invoiceDictionariesState,
  onMainModalClose,
  refetchPreInvoiceOrInvoiceDetails,
  invoiceId,
  invoiceState,
  billingPeriodId,
  projectTableState,
  isPreInvoice
}: FixedInvoiceDetailsProps) => {
  const dispatch = useThunkAppDispatch();
  const fixedInvoiceSummaryData = useAppSelector(
    (state: RootState) => state.fixedInvoiceSummary
  ).data;
  const [isEditInvoiceStatusModalVisible, setIsEditInvoiceStatusModalVisible] = useState(false);
  const [isTimeOffTooltipOpen, setIsTimeOffTooltipOpen] = useState(false);
  const user = useAppSelector((state: RootState) => state.auth.user);
  const currentStatus = fixedInvoiceSummaryData?.invoiceTotal.invoiceStatus || '';
  const [isAdditionalCostModalOpen, setIsAdditionalCostModalOpen] = useState(false);
  const [isDeleteAdditionalCostModalOpen, setIsDeleteAdditionalCostModalOpen] = useState(false);
  const [isEditModeVisible, setIsEditModeVisible] = useState(false);
  const billingPeriodStart = getDateOrPlaceholder(
    fixedInvoiceSummaryData?.invoiceTotal.startDate || ''
  );
  const billingPeriodEnd = getDateOrPlaceholder(
    fixedInvoiceSummaryData?.invoiceTotal.endDate || '',
    true
  );
  const invoiceTotal = getCurrencyIfValueExistOrPlaceholder(
    fixedInvoiceSummaryData?.invoiceTotal.invoiceCost || 0
  );
  const invoiceAmount = getCurrencyIfValueExistOrPlaceholder(fixedInvoiceSummaryData?.amount || 0);
  const onAddAdditionalCostFormClose = (isFormSubmitted: boolean) => {
    if (isFormSubmitted) {
      dispatch(getFixedInvoiceDetails(billingPeriodId))
        .then(unwrapResult)
        .catch(() => logError('Error fetching fixed invoice details'));
      dispatch(getInvoice(projectTableState))
        .then(unwrapResult)
        .catch(() => logError('Error fetching projects'));
    }
    setIsAdditionalCostModalOpen(false);
  };
  const onEditAdditionalCostButtonClick = () => {
    setIsEditModeVisible(true);
    setIsAdditionalCostModalOpen(true);
  };
  const onDeleteAdditionalCostFormClose = (isFormSubmitted: boolean) => {
    if (isFormSubmitted) {
      dispatch(getFixedInvoiceDetails(billingPeriodId))
        .then(unwrapResult)
        .catch(() => logError('Error fetching fixed invoice details'));
      dispatch(getInvoice(projectTableState))
        .then(unwrapResult)
        .catch(() => logError('Error fetching projects'));
    }
    setIsDeleteAdditionalCostModalOpen(false);
  };
  useEscapeKey(() => {
    if (isEditInvoiceStatusModalVisible) {
      setIsEditInvoiceStatusModalVisible(false);
    } else if (isDeleteAdditionalCostModalOpen) {
      setIsDeleteAdditionalCostModalOpen(false);
    } else if (isAdditionalCostModalOpen) {
      setIsAdditionalCostModalOpen(false);
    } else {
      onMainModalClose();
    }
  });
  const isAddAdditionalCostButtonVisible =
    fixedInvoiceSummaryData?.invoiceTotal.additionalCostAmount === null;
  const isAdditionalCostInfoVisible =
    fixedInvoiceSummaryData?.invoiceTotal.additionalCostAmount !== null &&
    fixedInvoiceSummaryData?.invoiceTotal.additionalCostAmount !== 0;
  const doesInvoiceNoteExist = useMemo(() => {
    return fixedInvoiceSummaryData?.invoiceTotal.notes;
  }, [invoiceState]);
  const noteText = useMemo(() => {
    return fixedInvoiceSummaryData?.invoiceTotal.notes || '';
  }, [invoiceState]);
  const description = useMemo(() => {
    return fixedInvoiceSummaryData?.description || '';
  }, [fixedInvoiceSummaryData]);
  const canUserEditInvoiceStatus =
    user?.permissions.some((permission) => permission.name === PermissionsName.ProjectManager) &&
    currentStatus !== InvoiceStatus.ISSUED;
  const additionalCostInfo = {
    additionalCostAmount: fixedInvoiceSummaryData?.invoiceTotal.additionalCostAmount || 0,
    additionalCostNotes: fixedInvoiceSummaryData?.invoiceTotal.additionalCostNotes || '',
    invoiceId: invoiceId || 0
  };
  const invoiceStatusElement = (
    <div className="invoice-status-wrapper">
      {doesInvoiceNoteExist && (
        <CustomTooltip
          onClose={() => setIsTimeOffTooltipOpen(false)}
          onOpen={() => setIsTimeOffTooltipOpen(true)}
          isTooltipOpen={isTimeOffTooltipOpen}
          title={<div className="note-text">{noteText}</div>}
          placement="bottom"
          icon={iconComment}
        />
      )}
      <div className={getInvoiceStatusClassName(currentStatus)} />
      {getInvoiceStatusName(currentStatus)}
    </div>
  );
  const onInvoiceStatusFormClose = (isFormSubmitted: boolean) => {
    if (isFormSubmitted) {
      refetchPreInvoiceOrInvoiceDetails(billingPeriodId);
      onMainModalClose();
    }
    setIsEditInvoiceStatusModalVisible(false);
  };
  return (
    <StyledInvoiceDetails>
      <div className="invoice-bp-time">
        Billing Period:
        <p className="bp-date">
          {billingPeriodStart} - {billingPeriodEnd}
        </p>
      </div>
      <div className="invoice-section-wrapper">
        <div className="section-left-column">
          <p className="section-title">Amount</p>
        </div>
        <div className="section-right-column">
          <div className="section-right-row-fixed-invoice">
            <div className="section-right-details">
              <p className="inter-caption_medium fixed-invoice-amount">{`${invoiceAmount}`}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="invoice-section-wrapper">
        <div className="section-left-column-fixed-invoice">
          <p className="section-title">Details</p>
          <p className="section-subtitle">{description}</p>
        </div>
      </div>
      {isAddAdditionalCostButtonVisible && (
        <div className="additional-cost-section-wrapper">
          <button
            className="adding-button"
            type="button"
            onClick={() => setIsAdditionalCostModalOpen(true)}
          >
            <PrpIcon src={addGrey} />
            <p>Add Additional Costs</p>
          </button>
        </div>
      )}
      {isAdditionalCostInfoVisible && (
        <div className="invoice-section-wrapper">
          <div className="section-left-column">
            <div>
              <p className="section-title">Additional costs</p>
              <p className="section-subtitle">
                {billingPeriodStart} - {billingPeriodEnd}
              </p>
            </div>
            <div className="additional-cost-details">
              <p className="section-title">Details</p>
              <p className="section-subtitle">
                {fixedInvoiceSummaryData?.invoiceTotal.additionalCostNotes}
              </p>
            </div>
            <div className="additional-cost-buttons">
              <button
                type="button"
                className="edit-button"
                onClick={onEditAdditionalCostButtonClick}
              >
                Edit
              </button>
              <button
                type="button"
                className="delete-button"
                onClick={() => setIsDeleteAdditionalCostModalOpen(true)}
              >
                Delete
              </button>
            </div>
          </div>
          <div className="section-right-column">
            <div className="section-right-row">
              <div className="section-right-details additional-cost">
                <div className="bold-text-duration">
                  <p className="inter-caption_medium text-right duration">
                    {formatToUsd(fixedInvoiceSummaryData.invoiceTotal?.additionalCostAmount ?? 0)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="invoice-section-wrapper invoice-summary-container">
        <div className="section-left-column">
          <p className="invoice-summary-title bold">
            {isPreInvoice
              ? InvoiceDetailsFormNames.FixedPricePreInvoiceDetailsTitle
              : InvoiceDetailsFormNames.FixedPriceInvoiceDetailsTotalTitle}
          </p>
          <p className="section-subtitle">
            {billingPeriodStart} - {billingPeriodEnd}
          </p>
        </div>
        <div className="section-right-column">
          <div className="section-right-row">
            {canUserEditInvoiceStatus ? (
              <button
                type="button"
                className="invoice-status-button"
                onClick={() => setIsEditInvoiceStatusModalVisible(true)}
              >
                {invoiceStatusElement}
              </button>
            ) : (
              invoiceStatusElement
            )}
            <div className="section-right-details">
              <p className="invoice-total-amount">{invoiceTotal}</p>
            </div>
          </div>
        </div>
      </div>
      {isEditInvoiceStatusModalVisible && (
        <ModalWindow
          onClose={() => setIsEditInvoiceStatusModalVisible(false)}
          headerTitle="Change Invoice Status"
          style={{
            width: invoiceStatusModalWidth,
            height: invoiceStatusModalHeight
          }}
        >
          <InvoiceStatusForm
            currentStatus={currentStatus || ''}
            statuses={invoiceDictionariesState.permittedStatuses}
            onClose={onInvoiceStatusFormClose}
            invoiceId={invoiceId}
          />
        </ModalWindow>
      )}
      {isAdditionalCostModalOpen && invoiceId && (
        <ModalWindow
          onClose={() => setIsAdditionalCostModalOpen(false)}
          headerTitle={isEditModeVisible ? 'Edit Additional Costs' : 'Add Additional Costs'}
          style={{
            width: invoiceStatusModalWidth,
            height: addAdditionalCostModalHeight
          }}
        >
          <AddEditAdditionalCostForm
            onClose={onAddAdditionalCostFormClose}
            editMode={isEditModeVisible}
            additionalCostInfo={additionalCostInfo}
          />
        </ModalWindow>
      )}
      {isDeleteAdditionalCostModalOpen && (
        <ModalWindow
          onClose={() => setIsDeleteAdditionalCostModalOpen(false)}
          headerTitle="Delete Additional Costs"
          style={{
            width: invoiceStatusModalWidth,
            height: deleteInvoiceModalHeight
          }}
        >
          <DeleteAdditionalCostForm
            onClose={onDeleteAdditionalCostFormClose}
            invoiceId={invoiceId}
          />
        </ModalWindow>
      )}
    </StyledInvoiceDetails>
  );
};
export default FixedInvoiceDetails;
