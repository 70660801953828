import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import * as yup from 'yup';
import { minAdditionalCost } from 'constants/validationConstants';

const AdditionalCostFormValidation = () => {
  return yup.object().shape({
    additionalCostAmount: yup
      .number()
      .required(FormValidationMessage.RequiredError)
      .min(minAdditionalCost, FormValidationMessage.AutoDiscountMinValueError),
    additionalCostNotes: yup.string().trim().required(FormValidationMessage.NoteError)
  });
};

export default AdditionalCostFormValidation;
