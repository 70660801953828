import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import { penGrey } from 'shared/PrpIcon/icons.constants';
import { useAppSelector } from 'store/redux-hooks/hooks';
import { RootState } from 'store';
import PageType from 'types/generalEnums/PageType';
import doesUserHavePermissions from 'helpers/permissionService';
import PermissionsName from 'types/generalEnums/PermissionsName';
import { memoize } from 'proxy-memoize';
import StyledDetailsHeader from './StyledDetailsHeader';

type DetailsHeaderProps = {
  item: EmployeeDto | ClientsDto | null;
  onChangeClickHandler: () => void;
  type: PageType.Employees | PageType.Clients;
  detailsManagementPermissions: PermissionsName[];
};

const DetailsHeader = ({
  item,
  onChangeClickHandler,
  type,
  detailsManagementPermissions
}: DetailsHeaderProps) => {
  const employee = useAppSelector(
    memoize((state: RootState) =>
      state.employees.data.find((e: EmployeeDto) => e.id === (item as EmployeeDto).id)
    )
  );
  const client = useAppSelector(
    memoize((state: RootState) =>
      state.clients.data.find((e: ClientsDto) => e.id === (item as ClientsDto).id)
    )
  );
  const user = useAppSelector((state: RootState) => state.auth.user);

  const canManageDetails = doesUserHavePermissions(user, detailsManagementPermissions);
  return (
    <StyledDetailsHeader>
      {type === PageType.Employees && (
        <h2 className="inter-caption_bold name-header">{employee?.name}</h2>
      )}
      {type === PageType.Clients && (
        <h2 className="inter-caption_bold name-header">{client?.name}</h2>
      )}
      {canManageDetails ? (
        <button className="button-group" onClick={onChangeClickHandler} type="button">
          <PrpIcon src={penGrey} width="20px" height="20px" />
        </button>
      ) : null}
    </StyledDetailsHeader>
  );
};

export default DetailsHeader;
