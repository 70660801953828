import { createAsyncThunk } from '@reduxjs/toolkit';
import allocationsBookingApiService from 'api/allocationBookingApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import { AllocationsAddBookingDto } from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingDto';
import AllocationsAction from 'types/allocation/enums/Allocations';

const getOneBooking = createAsyncThunk<
  AllocationsAddBookingDto,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(AllocationsAction.GET_BOOKING, async (id) => {
  const response = await allocationsBookingApiService.getBooking(id);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default getOneBooking;
