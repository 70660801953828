import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import addNotes from 'store/action/clientActions/addNotes';
import getNotes from 'store/action/clientActions/getNotes';
import { NotesState } from 'store/slicesState/notes';
import { ClientNoteDto } from 'types/clients/dto/ClientNoteDto';

const initialState: NotesState = {
  data: [],
  readLoading: false,
  saveLoading: false,
  error: null
};
export type DispatchError = {
  errorMessage: string;
};

const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    getNote: (state, action: PayloadAction<ClientNoteDto[]>) => {
      state.data = action.payload;
    },
    addNote: (state, action: PayloadAction<ClientNoteDto[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getNotes.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getNotes.fulfilled, (state, action) => {
      state.error = null;
      state.readLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getNotes.rejected, (state) => {
      state.error = 'getNotes error in slice';
      state.readLoading = false;
    });
    builder.addCase(addNotes.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(addNotes.fulfilled, (state, action) => {
      state.data.push(action.payload);
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(addNotes.rejected, (state) => {
      state.error = 'addNotes error in slice';
      state.saveLoading = false;
    });
  }
});

export const { getNote, addNote } = notesSlice.actions;
export default notesSlice.reducer;
