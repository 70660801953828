import { createAsyncThunk } from '@reduxjs/toolkit';
import projectApiService from 'api/projectApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import mapToProjectDto from 'store/mappers/project/projectMapper';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import { ProjectDto } from 'types/project/dto/ProjectDto';
import ProjectFormDto from 'types/project/dto/ProjectFormDto';
import ProjectAction from 'types/project/enums/Project';

const addProject = createAsyncThunk<
  ProjectDto,
  ProjectFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(ProjectAction.ADD_PROJECT, async (data: ProjectFormDto) => {
  const projectDto = mapToProjectDto(data);
  const response = await projectApiService.addProject(projectDto);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});
export default addProject;
