import styled from 'styled-components';
import { BACKGROUND_COLOR, BORDERS_COLOR, LOW_EMPHASISE_COLOR } from 'constants/colors';

const StyledSharedListItem = styled.div({
  backgroundColor: BACKGROUND_COLOR,
  padding: '16px 20px',
  width: '336px',
  height: 'auto',
  borderRight: 'solid 1px',
  borderColor: BORDERS_COLOR,
  overflow: 'hidden',
  '.search-item': {
    border: 'none',
    alignSelf: 'center'
  },
  '.list-item-scroll': {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 255px)'
  },
  '.list-item-scroll::-webkit-scrollbar': {
    width: '2px'
  },
  '.list-item-scroll::-webkit-scrollbar-thumb': {
    backgroundColor: LOW_EMPHASISE_COLOR
  }
});

export default StyledSharedListItem;
