import { MenuItem } from '@mui/material';
import {
  marginTopCommon,
  borderRadiusCommon
} from 'components/sharedComponents/StyleConsts/styleConsts';
import { BORDERS_COLOR, LOW_EMPHASISE_COLOR } from 'constants/colors';
import styled from 'styled-components';

export const StyledMenuItem = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '40px',
  height: '40px',
  cursor: 'pointer'
});

const StyledColorPicker = styled.div({
  '& .MuiPaper-root-MuiMenu-paper-MuiPopover-paper': {
    width: '220px'
  },
  '.rendered-color': {
    display: 'inline-block',
    height: '20px',
    width: '208px',
    borderRadius: '4px'
  },
  '.select-color': {
    width: '263px',
    marginTop: marginTopCommon,
    borderColor: BORDERS_COLOR,
    borderRadius: borderRadiusCommon,
    height: '43px'
  },
  '.label': {
    color: LOW_EMPHASISE_COLOR
  }
});

export default StyledColorPicker;
