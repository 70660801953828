import defaultFilteringValue from 'components/allocations/consts/defaultFilteringValue';
import AllocationsFilterDashboardDto from 'types/allocation/dto/AllocationsFilterDashboardDto';

const AllocationFilterDashboardDefaultValue: AllocationsFilterDashboardDto = {
  activeEmployeeNames: [],
  clientNames: [],
  projectNames: [],
  positions: [],
  filterNames: defaultFilteringValue.id,
  allEmployeeNames: []
};

export default AllocationFilterDashboardDefaultValue;
