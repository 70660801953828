import { createAsyncThunk } from '@reduxjs/toolkit';
import clientsApiService from 'api/clientsApiService';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/employee/employeeSlice';
import { ClientFormDto } from 'types/clients/dto/ClientFormDto';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import ClientsAction from 'types/clients/enums/Clients';

const updateClients = createAsyncThunk<
  ClientsDto,
  ClientFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(ClientsAction.UPDATE_CLIENT, async (updatedClient: ClientFormDto) => {
  const response = await clientsApiService.updateClients(updatedClient);
  return addKeyToReactItem(response);
});

export default updateClients;
