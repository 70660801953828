type PrpIconProps = {
  src: string;
  width?: string;
  height?: string;
  className?: string;
} & typeof defaultProps;

const defaultProps = {
  width: '20px',
  height: '20px',
  className: 'prp-icon'
};

const PrpIcon = ({ src, width, height, className }: PrpIconProps): JSX.Element => {
  return <img alt="icon" src={src} width={width} height={height} className={className} />;
};

PrpIcon.defaultProps = defaultProps;
export default PrpIcon;
