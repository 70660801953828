import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { PRIMARY_COLOR, SURFACE_COLOR, TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledClashForm = styled.div({
  padding: '0px 18px',
  '.add-button': {
    backgroundColor: PRIMARY_COLOR,
    padding: '14px 32px 14px 32px',
    color: SURFACE_COLOR,
    borderRadius: borderRadiusCommon,
    border: 'none',
    position: 'relative',
    cursor: 'pointer',
    p: {
      color: SURFACE_COLOR,
      margin: '0px'
    }
  },

  '.submit-buttons-row': {
    marginTop: '5px'
  },

  '.cancel-button': {
    backgroundColor: 'transparent',
    padding: '14px 32px 14px 32px',
    color: TEXT_COLOR,
    borderRadius: borderRadiusCommon,
    border: 'none',
    margin: '10px 0px 0px 15px',
    position: 'relative',
    cursor: 'pointer',
    p: {
      color: PRIMARY_COLOR,
      margin: '0px'
    }
  }
});

export default StyledClashForm;
