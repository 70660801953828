import { FieldValues, Path, UseFormRegister, useFormContext } from 'react-hook-form';
import { FormControl, InputBase, Grid } from '@mui/material';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import StyledFormTimePicker from 'components/sharedComponents/FormComponents/FormTimePicker/StyledFormTimePicker';
import { useEffect } from 'react';

type FormTimePickerProps<T extends FieldValues> = {
  dataToDisplay: FormTimePickerLabelsAndNames;
  required: boolean;
  register: UseFormRegister<T>;
  className?: string;
  minsMaxLimit?: number;
  minsMinLimit?: number;
  hoursMaxLimit?: number;
  hoursValue: number | null;
  minsValue: number | null;
  error?: string;
  caption?: string;
};

export type FormTimePickerLabelsAndNames = {
  hoursName: string;
  hoursLabel?: string;
  minsName: string;
  minsLabel?: string;
  hoursMaxLimit?: number;
  minsMaxLimit?: number;
  minsMinLimit?: number;
};

const FormTimePicker = <T extends FieldValues>({
  dataToDisplay,
  required,
  register,
  className,
  hoursValue,
  minsValue,
  error,
  caption
}: FormTimePickerProps<T>) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (hoursValue !== null) setValue(dataToDisplay.hoursName, hoursValue);
    if (minsValue !== null) setValue(dataToDisplay.minsName, minsValue);
  }, [hoursValue, minsValue]);

  const minsMaxLimit = dataToDisplay.minsMaxLimit ? dataToDisplay.minsMaxLimit : 59;
  const minsMinLimit = dataToDisplay.minsMinLimit ? dataToDisplay.minsMinLimit : 0;
  const hoursMaxLimit = dataToDisplay.hoursMaxLimit ? dataToDisplay.hoursMaxLimit : 40;

  const handleMinsInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const minsRegex = /^[0-9\b]+$/;
    const inputValue = e.target.value;
    const numericValue = Number(inputValue);
    if (inputValue === '' || minsRegex.test(inputValue)) {
      setValue(dataToDisplay.minsName, numericValue);
    }
    if (numericValue > minsMaxLimit) {
      setValue(dataToDisplay.minsName, minsMaxLimit);
    }
    if (numericValue === hoursMaxLimit) {
      setValue(dataToDisplay.minsName, 0);
    }
    if (inputValue && numericValue < minsMinLimit) {
      setValue(dataToDisplay.minsName, minsMinLimit);
    }
  };

  const handleHoursInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const hoursRegex = /^[0-9\b]+$/;
    const inputValue = e.target.value;
    const numericValue = Number(inputValue);
    if (inputValue === '' || hoursRegex.test(inputValue)) {
      setValue(dataToDisplay.hoursName, numericValue);
    }
    if (numericValue >= hoursMaxLimit) {
      setValue(dataToDisplay.hoursName, hoursMaxLimit);
      setValue(dataToDisplay.minsName, 0);
    }
    if (inputValue && numericValue === 0) {
      setValue(dataToDisplay.hoursName, numericValue);
      setValue(dataToDisplay.minsName, minsMinLimit);
    }
  };

  return (
    <StyledFormTimePicker>
      <div className="time-input-row">
        <FormControl>
          <Grid container columnSpacing={1} justifyContent="space-between">
            <Grid item xs={4} md={3}>
              <FormControl>
                <p className="inter-caption_medium label">{dataToDisplay.hoursLabel}</p>
                <InputBase
                  id={dataToDisplay.hoursName as Path<T>}
                  {...register(dataToDisplay.hoursName as Path<T>, { required })}
                  type="number"
                  className={className}
                  onChange={(e) => handleHoursInputChange(e)}
                  value={hoursValue}
                />
                {hoursValue === 0 && minsValue === 0 && (
                  <p className="error inter-caption_medium">{error}</p>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4} md={3}>
              <FormControl>
                <p className="inter-caption_medium label">{dataToDisplay.minsLabel}</p>
                <InputBase
                  id={dataToDisplay.minsName as Path<T>}
                  {...register(dataToDisplay.minsName as Path<T>, { required })}
                  type="number"
                  className={className}
                  value={minsValue}
                  onChange={(e) => handleMinsInputChange(e)}
                />
                {hoursValue === 0 && minsValue === 0 && (
                  <p className="error inter-caption_medium">{error}</p>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4} md={5}>
              <p className="inter-caption_medium per-week-caption">{caption}</p>
            </Grid>
          </Grid>
          {hoursValue === null && minsValue === null && error && (
            <p className="error inter-caption_medium">{error}</p>
          )}
        </FormControl>
      </div>
    </StyledFormTimePicker>
  );
};

export default FormTimePicker;
