import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import EmployeeCard from 'components/employee/EmployeeCard/EmployeeCard';
import { useAppSelector } from 'store/redux-hooks/hooks';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import selectPositionPropertyById from 'store/dictionarySelectors/selectorPosition';
import StyledList from './StyledList';

type EmployeeListProps = {
  filteredItems: EmployeeDto[] | null;
};

const EmployeesList = ({ filteredItems }: EmployeeListProps) => {
  const employeeListState = useSelector((state: RootState) => state.dictionaries);
  const selectedEmployee = useAppSelector((state: RootState) => state.employees.selectedEmployee);

  const groupedEmployees = filteredItems?.reduce((acc, employee) => {
    const departmentDisplayName = selectPositionPropertyById(
      employeeListState,
      employee.positionId,
      DictionariesSelector.DisplayName
    );
    if (!departmentDisplayName) {
      return acc;
    }
    if (!acc[departmentDisplayName]) {
      acc[departmentDisplayName] = [];
    }
    acc[departmentDisplayName].push(employee);
    acc[departmentDisplayName].sort((a, b) => a.name.localeCompare(b.name));
    return acc;
  }, {} as Record<string, EmployeeDto[]>);
  return (
    <StyledList>
      {Object.entries(groupedEmployees || {}).map(([departmentDisplayName, employees]) => (
        <div key={departmentDisplayName}>
          <h3 className="inter-caption_bold">
            {selectPositionPropertyById(
              employeeListState,
              employees[0].positionId,
              DictionariesSelector.DisplayName
            )}
          </h3>
          <div className="employees-list">
            {employees.map((employee) => {
              return (
                <EmployeeCard
                  key={employee?.key}
                  itemId={employee.id}
                  isSelected={employee === selectedEmployee}
                  item={employee}
                />
              );
            })}
          </div>
        </div>
      ))}
    </StyledList>
  );
};
export default EmployeesList;
