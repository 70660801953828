import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { PRIMARY_COLOR, SURFACE_COLOR, TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledAllocationFilterDashboard = styled.form({
  marginTop: '10px',
  '.allocation-category-selector': {
    width: '560px',
    borderRadius: borderRadiusCommon,
    height: '42px',
    marginBottom: '12px'
  },
  '& .MuiGrid-root>.MuiGrid-item': {
    paddingTop: '0'
  },
  '.button-row': {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '230px',
    justifyContent: 'space-between',
    position: 'absolute',
    bottom: '20px'
  },
  '.button': {
    backgroundColor: PRIMARY_COLOR,
    padding: '14px 32px 14px 32px',
    color: SURFACE_COLOR,
    borderRadius: borderRadiusCommon,
    border: 'none',
    marginTop: '15px',
    position: 'relative',
    cursor: 'pointer',
    p: {
      color: SURFACE_COLOR,
      margin: '0px'
    }
  },
  '.cancel-button': {
    backgroundColor: 'transparent',
    padding: '14px 32px 14px 32px',
    color: TEXT_COLOR,
    borderRadius: borderRadiusCommon,
    border: 'none',
    margin: '10px 0px 0px 15px',
    cursor: 'pointer',
    p: {
      color: TEXT_COLOR,
      margin: '0px'
    }
  }
});

export default StyledAllocationFilterDashboard;
