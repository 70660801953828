import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import mapToAllocationPublicHolidayDto from 'store/mappers/allocations/allocationPublicHolidayMapper';
import AllocationDeletePublicHolidayDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationDeletePublicHolidayDto';
import AllocationDeletePublicHolidayFormDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationDetelePublicHolidayFormDto';
import AllocationPublicHolidayDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayDto';
import AllocationPublicHolidayFormDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayFormDto';

const allocationsPublicHolidayApiService: IApiService = {
  getPublicHoliday: async (id: number) => {
    try {
      const response = await apiRequest<AllocationPublicHolidayDto>({
        method: 'GET',
        endpoint: `${API_URL}/allocations/holiday/${id}`
      });
      return response;
    } catch (error) {
      logError('Error in fetchPublicHoliday');
      throw new Error('Error in fetchPublicHoliday');
    }
  },
  updatePublicHoliday: async (data: AllocationPublicHolidayFormDto) => {
    const result = mapToAllocationPublicHolidayDto(data);
    try {
      const response = await apiRequest<AllocationPublicHolidayDto>({
        method: 'PUT',
        endpoint: `${API_URL}/allocations/holiday/${data.id}`,
        requestBody: result
      });
      return response;
    } catch (error) {
      logError('Error in updatePublicHoliday');
      throw new Error('Error in updatePublicHoliday');
    }
  },
  addPublicHoliday: async (data: AllocationPublicHolidayFormDto) => {
    const allocationHoliday = mapToAllocationPublicHolidayDto(data);
    try {
      const response = await apiRequest<AllocationPublicHolidayDto>({
        method: 'POST',
        endpoint: `${API_URL}/allocations/holiday`,
        requestBody: allocationHoliday
      });
      return response;
    } catch (error) {
      logError('Error in creating public holiday');
      throw new Error('Error in creating public holiday');
    }
  },
  deletePublicHoliday: async (data: AllocationDeletePublicHolidayFormDto) => {
    try {
      const response = await apiRequest<AllocationDeletePublicHolidayDto>({
        method: 'DELETE',
        endpoint: `${API_URL}/allocations/holiday/${data.holidayId}?deleteAllHolidaysThisDay=${data.deleteAllHolidaysThisDay}`
      });
      return response;
    } catch (error) {
      logError('Error in deleting public holiday');
      throw new Error('Error in deleting public holiday');
    }
  }
};

interface IApiService {
  getPublicHoliday: (id: number) => Promise<AllocationPublicHolidayDto>;
  updatePublicHoliday: (
    data: AllocationPublicHolidayFormDto
  ) => Promise<AllocationPublicHolidayDto>;
  addPublicHoliday: (data: AllocationPublicHolidayFormDto) => Promise<AllocationPublicHolidayDto>;
  deletePublicHoliday: (
    data: AllocationDeletePublicHolidayFormDto
  ) => Promise<AllocationDeletePublicHolidayDto>;
}

export default allocationsPublicHolidayApiService;
