enum ProjectFormComponentNames {
  ClientName = 'clientId',
  ClientNameLabel = 'Client Name',
  ProjectName = 'name',
  ProjectNameLabel = 'Project Name',
  ProjectCodeLabel = 'Project Code',
  ProjectCodeName = 'code',
  ColorName = 'colorHex',
  ColorLabel = 'Color',
  BillableName = 'billable',
  BillableLabel = 'Billable',
  BillingTypeName = 'billingType',
  BillingTypeLabel = 'Type Of Billing',
  BillingPeriodName = 'billingPeriodType',
  BillingPeriodLabel = 'Billing Period',
  ProjectManagerName = 'projectManagerId',
  ProjectManagerLabel = 'Project Manager',
  StatusName = 'status',
  StatusLabel = 'Project Status',
  CustomBillingPeriodName = 'customBillingPeriodWeeks',
  CustomBillingPeriodLabel = 'Weeks',
  StartDateLabel = 'Start Date',
  StartDateName = 'startDate',
  EndDateLabel = 'End Date',
  EndDateName = 'endDate',
  AzdoAliasName = 'azdoAlias',
  AzdoAliasLabel = 'AzDO alias',
  DeleteTimeOffTypeName = 'deleteFutureTimeOffs',
  EditBillingPeriodName = 'deleteBillingPeriod',
  ActualsLastModifiedName = 'actualsLastModified',
  ActualsLastModifiedLabel = 'Last modified',
  InvoiceStatusNameLabel = 'Status',
  InvoiceStatusName = 'invoiceStatus',
  InvoiceNoteNameLabel = 'Note',
  InvoiceNoteName = 'notes',
  AdditionalCostLabel = 'Additional Costs',
  AdditionalCostName = 'additionalCostAmount',
  AdditionalNoteLabel = 'Note',
  AdditionalNoteName = 'additionalCostNotes',
  StartingSprintNumberLabel = 'Starting sprint number',
  StartingSprintNumberName = 'startingSprintNumber'
}

export default ProjectFormComponentNames;
