import ProjectFormDto from 'types/project/dto/ProjectFormDto';
import { ProjectStatusNumber } from 'types/project/enums/ProjectStatusName';

const AddProjectFormDefaultValue: ProjectFormDto = {
  id: null,
  name: '',
  code: '',
  clientId: null,
  billable: true,
  billingType: null,
  billingPeriodType: 0,
  projectManagerId: null,
  colorHex: '',
  status: ProjectStatusNumber.Waiting,
  customBillingPeriodWeeks: null,
  startDate: '',
  endDate: '',
  clientColorHex: '',
  clientName: '',
  projectManagerName: '',
  azdoAlias: '',
  actualsLastModified: '',
  startingSprintNumber: 1
};

export default AddProjectFormDefaultValue;
