enum AllocationComponentsTitles {
  AllocationPublicHolidayPopUpText = 'Add Public Holiday',
  AllocationFilterDashboarTitle = 'Filter',
  AllocationPublicHolidayEditWindow = 'Edit Public Holiday',
  AllocationBookingEditWindow = 'Edit Booking',
  AllocationEditTimeOffWindow = 'Edit Time Off',
  AllocationDeletePublicHolidayWindow = 'Delete Public Holiday',
  AllocationDeleteBookingWindow = 'Delete Booking',
  AllocationDeleteTimeOffWindow = 'Delete Time Off'
}

export default AllocationComponentsTitles;
