import { useState } from 'react';
import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { holiday, holidayBlack } from 'shared/PrpIcon/icons.constants';

type VacationToolTipProps = {
  daysOff: number;
};
const VacationToolTip = ({ daysOff }: VacationToolTipProps) => {
  const [isTimeOffTooltipOpen, setIsTimeOffTooltipOpen] = useState(false);

  const handleReasonTooltipClose = () => {
    setIsTimeOffTooltipOpen(false);
  };

  const handleReasonTooltipOpen = () => {
    setIsTimeOffTooltipOpen(true);
  };

  return (
    <CustomTooltip
      onClose={handleReasonTooltipClose}
      onOpen={handleReasonTooltipOpen}
      isTooltipOpen={isTimeOffTooltipOpen}
      title={
        <div className="tooltip-title-container">
          <PrpIcon src={holiday} height="12px" width="12px" />
          Time-off: {daysOff} days
        </div>
      }
      placement="bottom"
      icon={holidayBlack}
    />
  );
};
export default VacationToolTip;
