import { createAsyncThunk } from '@reduxjs/toolkit';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import AllocationsAction from 'types/allocation/enums/Allocations';
import allocationsPublicHolidayApiService from 'api/allocationPublicHolidayApiService';
import AllocationPublicHolidayDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayDto';

const getOnePublicHoliday = createAsyncThunk<
  AllocationPublicHolidayDto,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(AllocationsAction.GET_PUBLIC_HOLIDAY, async (id) => {
  const response = await allocationsPublicHolidayApiService.getPublicHoliday(id);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default getOnePublicHoliday;
