import { LOW_EMPHASISE_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledFormSwitch = styled.div({
  '.form-switch-title': {
    marginBottom: '10px',
    color: LOW_EMPHASISE_COLOR
  },
  '.modal-title': {
    h3: {
      margin: 0
    }
  },
  '.MuiFormControlLabel-root': {
    width: '40px'
  }
});
export default StyledFormSwitch;
