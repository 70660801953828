import { ALERT_COLOR, BORDERS_COLOR, CAUTION_COLOR, SUCCESS_COLOR } from 'constants/colors';
import { ProjectStatusName } from 'types/project/enums/ProjectStatusName';
import ImportActualsStatusEnum from 'types/uploadActuals/uploadActualsEnum';

const getStatusColor = (status: string): string => {
  const statusColorMap = new Map<string, string>([
    [ProjectStatusName.Active, SUCCESS_COLOR],
    [ProjectStatusName.Closed, BORDERS_COLOR],
    [ProjectStatusName.Waiting, CAUTION_COLOR],
    [ImportActualsStatusEnum.COMPLETED, SUCCESS_COLOR],
    [ImportActualsStatusEnum.IN_PROGRESS, CAUTION_COLOR],
    [ImportActualsStatusEnum.FAILED, ALERT_COLOR],
    [ImportActualsStatusEnum.NOT_IMPORTED_YET, BORDERS_COLOR]
  ]);
  return statusColorMap.get(status) || BORDERS_COLOR;
};

export default getStatusColor;
