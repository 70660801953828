import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import AllocationPublicHolidayDefaultValue from 'components/allocations/AllocationPublicHoliday/AllocationPublicHolidayAddForm/AllocationPublicHolidayAddFormDefaultValue';
import AllocationPublicHolidayFormValidation from 'components/allocations/AllocationPublicHoliday/AllocationPublicHolidayAddForm/AllocationPublicHolidayFormValidation';
import { textFildInputPropsHeight } from 'components/allocations/consts/styleConst';
import FormDatePicker from 'components/sharedComponents/FormComponents/FormDatePicker/FormDatePicker';
import FormTextField from 'components/sharedComponents/FormComponents/FormTextField/FormTextField';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { useEffect } from 'react';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import addAllocationPublicHoliday from 'store/action/allocationsAction/addAllocationPublicHoliday';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import AllocationPublicHolidayFormDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationPublicHolidayFormDto';
import AllocationPublicHolidayFormNames from 'types/allocation/enums/AllocationPublicHolidayFormNames';
import ButtonsText from 'types/generalEnums/ButtonsText';
import InputType from 'types/generalEnums/InputType';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';

type AllocationPublicHolidayAddFormProps = {
  selectedDate: string | null;
  onClose: () => void;
};

const AllocationPublicHolidayAddForm = ({
  selectedDate,
  onClose
}: AllocationPublicHolidayAddFormProps) => {
  const methods = useForm<AllocationPublicHolidayFormDto>({
    defaultValues: AllocationPublicHolidayDefaultValue,
    // TODO fix unknown
    resolver: yupResolver(
      AllocationPublicHolidayFormValidation()
    ) as unknown as Resolver<AllocationPublicHolidayFormDto>
  });
  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors }
  } = methods;
  useEscapeKey(onClose);
  useEffect(() => {
    const date = new Date(selectedDate as string).toISOString();
    setValue(AllocationPublicHolidayFormNames.DataName, date ?? '');
    methods.trigger(AllocationPublicHolidayFormNames.DataName);
  }, [setValue, methods, selectedDate]);

  const dispatch = useThunkAppDispatch();

  const onSubmit: SubmitHandler<AllocationPublicHolidayFormDto> = async (
    data: AllocationPublicHolidayFormDto
  ) => {
    try {
      await dispatch(addAllocationPublicHoliday(data)).unwrap();
      onClose();
    } catch (error) {
      logError('Error adding project');
    }
  };

  const textFildInputProps = { style: { height: textFildInputPropsHeight } };
  const loadingPublicHoliday = useAppSelector(
    (state: RootState) => state.allocationPublicHoliday.saveLoading
  );

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)} className="modal-container">
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item xs={12} md={12} className="allocation-public-holiday-date">
            <FormDatePicker
              name={AllocationPublicHolidayFormNames.DataName}
              control={control}
              label={AllocationPublicHolidayFormNames.DateLabel}
              className="form-date allocation-public-holiday-datepicker"
              error={errors.date?.message}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormTextField
              label={AllocationPublicHolidayFormNames.DetailsLabel}
              name={AllocationPublicHolidayFormNames.DetailsName}
              type={InputType.Text}
              required={false}
              register={register}
              className="allocation-public-holidat-text-field"
              inputProps={textFildInputProps}
              error={errors.details?.message}
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.AddPublicHoliday}
          isSpinnerButtonAdded
          loading={loadingPublicHoliday}
          isCancelButtonVisible
          submitButtonClassName="add-button"
          onClick={onClose}
          spinnerClassName="spinner-allocation-form"
          className="add-buttons-holiday-row"
        />
      </StyledForm>
    </FormProvider>
  );
};

export default AllocationPublicHolidayAddForm;
