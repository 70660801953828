import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { ProjectDictionariesDto } from 'types/project/dto/ProjectDictionariesDto';

const projectDictionariesApiService: IApiService = {
  fetchProjectDictionaries: async () => {
    try {
      const response = await apiRequest<ProjectDictionariesDto>({
        method: 'GET',
        endpoint: `${API_URL}/dictionaries/projects`
      });
      return response;
    } catch (error) {
      logError('Error in fetchProjectDictionaries');
      throw new Error('Error in fetchProjectDictionaries');
    }
  }
};

interface IApiService {
  fetchProjectDictionaries: () => Promise<ProjectDictionariesDto>;
}

export default projectDictionariesApiService;
