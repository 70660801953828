import { createAsyncThunk } from '@reduxjs/toolkit';
import allocationsPublicHolidayApiService from 'api/allocationPublicHolidayApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import AllocationDeletePublicHolidayDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationDeletePublicHolidayDto';
import AllocationDeletePublicHolidayFormDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationDetelePublicHolidayFormDto';
import AllocationsAction from 'types/allocation/enums/Allocations';

const deletePublicHoliday = createAsyncThunk<
  AllocationDeletePublicHolidayDto,
  AllocationDeletePublicHolidayFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(AllocationsAction.DELETE_HOLIDAY, async (data: AllocationDeletePublicHolidayFormDto) => {
  const response = await allocationsPublicHolidayApiService.deletePublicHoliday(data);
  if (!response) notifyUser();
  return response;
});

export default deletePublicHoliday;
