import IntensityEnum from 'types/PrpTable/enums/intensityEnum';
import DiscountTypeEnum from 'types/PrpTable/enums/discountTypeEnum';

const defaultUpdateDiscountItem = {
  id: 0,
  date: '',
  name: '',
  position: '',
  sprintNo: 0,
  rate: 0,
  forecast: 0,
  actuals: 0,
  employeeId: 0,
  projectId: 0,
  weekId: 0,
  repeatForWeeksInBillingPeriod: false,
  discountDuration: 0,
  discountReason: '',
  discountCost: 0,
  discountId: 0,
  discountCreatedByEmployeeWithName: '',
  discountCreated: '',
  differenceIntensity: IntensityEnum.BELOW_5,
  discountType: DiscountTypeEnum.DEFAULT
};

export default defaultUpdateDiscountItem;
