import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import getCalendarsDictionaries from 'store/action/calendarAction/getCalendarsDictionaries';
import { CalendarState } from 'store/slicesState/calendar';
import CalendarDictionariesDto from 'types/allocation/dto/calendarDto/CalendarDictionariesDto';

const initialState: CalendarState = {
  data: {
    minYear: 0,
    maxYear: 0,
    minDate: '',
    maxDate: '',
    calendar: {}
  },
  loading: false,
  error: null
};
export type DispatchError = {
  errorMessage: string;
};

const calendarDictionariesSlice = createSlice({
  name: 'calendarDictionaries',
  initialState,
  reducers: {
    getCalendarDictionaries: (state, action: PayloadAction<CalendarDictionariesDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCalendarsDictionaries.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCalendarsDictionaries.fulfilled, (state, action) => {
      state.error = null;
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getCalendarsDictionaries.rejected, (state) => {
      state.error = 'getCalendarsDictionaries error in slice';
      state.loading = false;
    });
  }
});

export const { getCalendarDictionaries } = calendarDictionariesSlice.actions;
export default calendarDictionariesSlice.reducer;
