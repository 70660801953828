import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';

const freezeForecastApiService: IApiService = {
  freezeForecast: async (billingPeriodId: number) => {
    try {
      const response = await apiRequest<void>({
        method: 'POST',
        endpoint: `${API_URL}/billing-periods/${billingPeriodId}/freeze-forecast`
      });
      return response;
    } catch (error) {
      logError('Error in freezeForecast');
      throw new Error('Error in freezeForecast');
    }
  }
};

interface IApiService {
  freezeForecast: (billingPeriodId: number) => Promise<void>;
}

export default freezeForecastApiService;
