import { AddAdditionalCostFormDto } from 'types/project/dto/AddAdditionalCostFormDto';
import { AdditionalCostDto } from 'types/invoice/AdditionalCostDto';

const mapToAdditionalCostDto = (data: AddAdditionalCostFormDto): AdditionalCostDto => {
  return {
    additionalCostAmount: data.additionalCostAmount,
    additionalCostNotes: data.additionalCostNotes
  } as AdditionalCostDto;
};

export default mapToAdditionalCostDto;
