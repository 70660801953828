import { detailsPattern } from 'constants/validationConstants';
import invalidDateTest from 'helpers/invalidDateTest';
import FormValidationMessage from 'types/employee/enums/FormValidationMessage';
import * as yup from 'yup';

const AllocationBookingFormValidation = (isAutoDurationSelected: boolean) => {
  return yup.object().shape({
    projectId: yup.number().required(FormValidationMessage.ProjectChoiceError),
    employeeId: yup
      .number()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(FormValidationMessage.EmploeeChoiceError),
    startDate: invalidDateTest(),
    endDate: invalidDateTest(),
    hours: yup
      .number()
      .min(0)
      .transform((value) => (Number.isNaN(value) ? null : value))
      .required(FormValidationMessage.TimeError),
    details: yup.string().matches(detailsPattern, FormValidationMessage.MaxLengthError),
    autoDiscountDuration: isAutoDurationSelected
      ? yup
          .number()
          .min(0)
          .transform((value) => (Number.isNaN(value) ? null : value))
          .required(FormValidationMessage.DiscountError)
      : yup
          .number()
          .transform((value) => (Number.isNaN(value) ? null : value))
          .nullable()
  });
};

export default AllocationBookingFormValidation;
