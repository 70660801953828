export const getMinutes = (hours: number): number => {
  return hours * 60;
};

export const convertDecimalValueToHoursAndMinutes = (value: number) => {
  const hours = Math.floor(value);
  const minutes = Math.round((value - hours) * 60);
  return {
    hours,
    minutes
  };
};
