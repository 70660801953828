import ButtonsText from 'types/generalEnums/ButtonsText';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import deleteInvoice from 'store/action/invoiceAction/deleteInvoice';
import { useThunkAppDispatch } from 'store/redux-hooks/hooks';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { FormEvent } from 'react';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import InvoiceType from 'types/project/enums/InvoiceType';

type DeleteInvoiceFormProps = {
  onClose: (isSubmitted: boolean) => void;
  isPreInvoice: boolean | null;
  invoiceId: number;
  invoiceTitle: string;
  invoiceType: string;
};

const DeleteInvoiceForm = ({
  onClose,
  invoiceId,
  isPreInvoice,
  invoiceTitle,
  invoiceType
}: DeleteInvoiceFormProps) => {
  const dispatch = useThunkAppDispatch();

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await dispatch(deleteInvoice(invoiceId)).unwrap();
      onClose(true);
    } catch (error) {
      logError('Error deleting invoice');
    }
  };
  useEscapeKey(() => onClose(false));
  return (
    <StyledForm onSubmit={onSubmit}>
      <p className="inter-caption_medium delete-additional-cost-text">
        {`Are you sure you want to delete this ${invoiceTitle}? This action cannot be undone.`}
      </p>
      <div className="add-buttons-row">
        <button type="submit" className="add-button">
          <p className="inter-caption">
            {isPreInvoice && invoiceType !== InvoiceType.CORRECTION_INVOICE
              ? ButtonsText.DeletePreinvoice
              : ButtonsText.DeleteInvoice}
          </p>
        </button>
        <button type="button" onClick={() => onClose(false)} className="cancel-button">
          <p className="inter-caption">{ButtonsText.Cancel}</p>
        </button>
      </div>
    </StyledForm>
  );
};

export default DeleteInvoiceForm;
