import styled from 'styled-components';
import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { DARK_GREY, ON_PRIMARY_COLOR } from 'constants/colors';

const StyledSharedMultiSelect = styled.div({
  display: 'flex',
  maxWidth: '320px',
  '.multi-select': {
    width: '135px',
    height: '45px',
    margin: '5px 0 0 auto',
    borderRadius: borderRadiusCommon,
    backgroundColor: ON_PRIMARY_COLOR
  },
  '.project-details-label': {
    marginTop: '25px',
    marginLeft: '-6px'
  },
  '.MuiSelect-select': {
    em: { color: DARK_GREY }
  }
});

export default StyledSharedMultiSelect;
