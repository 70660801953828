import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import getInvoiceDetails from 'store/action/projectActions/getInvoiceSummary';
import { InvoiceSummary } from 'store/slicesState/invoiceSummary';
import { InvoiceSummaryDto } from 'types/project/dto/InvoiceSummaryDto';

const initialState: InvoiceSummary = {
  data: {
    actualsCost: 0,
    actualsDuration: 0,
    discountCost: 0,
    discountDuration: 0,
    billingPeriodStartDate: '',
    billingPeriodEndDate: '',
    invoiceTotal: {
      id: 0,
      startDate: '',
      endDate: '',
      invoiceStatus: '',
      invoiceCost: 0,
      notes: '',
      additionalCostNotes: '',
      additionalCostAmount: 0,
      preDiscountCost: 0,
      preDiscountDuration: 0
    }
  },
  readLoading: false,
  saveLoading: false,
  error: null
};

export type DispatchError = {
  errorMessage: string;
};

const invoiceSummarySlice = createSlice({
  name: 'invoiceSummary',
  initialState,
  reducers: {
    getInvoiceSummary: (state, action: PayloadAction<InvoiceSummaryDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoiceDetails.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getInvoiceDetails.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.readLoading = false;
    });
    builder.addCase(getInvoiceDetails.rejected, (state) => {
      state.error = 'getInvoiceSummary error in slice';
      state.readLoading = false;
    });
  }
});

export const { getInvoiceSummary } = invoiceSummarySlice.actions;
export default invoiceSummarySlice.reducer;
