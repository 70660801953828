import { createAsyncThunk } from '@reduxjs/toolkit';
import employeeApiService from 'api/employeeApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import getReactItemsWithKey from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/employee/employeeSlice';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import EmployeeAction from 'types/employee/enums/Employee';

const getEmployees = createAsyncThunk<
  EmployeeDto[],
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(EmployeeAction.FETCH_ALL, async () => {
  const response = await employeeApiService.fetchEmployees();
  if (!response) notifyUser();
  return getReactItemsWithKey(response);
});

export default getEmployees;
