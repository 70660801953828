enum ButtonsText {
  Add = 'Add',
  Cancel = 'Cancel',
  AddPublicHoliday = 'Add Public Holiday',
  Delete = 'Delete',
  Update = 'Update',
  AddTimeOff = 'Add Time Off',
  AddBooking = 'Add Booking',
  Apply = 'Apply',
  ImportActuals = 'Import Actuals',
  Confirm = 'Confirm',
  UpdateStatus = 'Update Status',
  AddAdditionalCosts = 'Add Additional Costs',
  SaveChanges = 'Save Changes',
  DeleteInvoice = 'Delete Invoice',
  DeletePreinvoice = 'Delete Preinvoice',
  GenerateInvoice = 'Generate Invoice',
  CreateInvoice = 'Create Invoice',
  NextStep = 'Next Step',
  CreateCorrectionInvoice = 'Create a correction invoice',
  UpdateRate = 'Update Rate',
  UpdateForecast = 'Update Forecast'
}

export default ButtonsText;
