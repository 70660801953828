import { useState } from 'react';
import { Controller, Control, FieldValues, FieldPath } from 'react-hook-form';
import StyledColorPicker, {
  StyledMenuItem
} from 'components/sharedComponents/ColorPicker/StyledColorPicker';
import { FormControl, Select, SelectChangeEvent } from '@mui/material';
import {
  menuColorPickerMargin,
  menuColorPickerPadding,
  menuColorPickerWidth
} from 'components/clients/StyleConsts/styleConsts';

type ColorPickerProps<T extends FieldValues, K extends FieldPath<T>> = {
  label: string;
  control: Control<T>;
  name: K;
  error?: string;
  colors: string[];
};

const ColorPicker = <T extends FieldValues, K extends FieldPath<T>>({
  label,
  control,
  name,
  error,
  colors
}: ColorPickerProps<T, K>) => {
  const [selectedColor, setSelectedColor] = useState<string | null>(null);
  return (
    <StyledColorPicker>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <FormControl>
              <p className="inter-caption_medium label">{label}</p>
              <Select
                value={field.value as string}
                className="select-color"
                onChange={(event: SelectChangeEvent) => {
                  field.onChange(event);
                  setSelectedColor(event.target.value);
                }}
                renderValue={() => (
                  <div
                    style={{
                      backgroundColor: field.value as string
                    }}
                    className="rendered-color"
                  />
                )}
                MenuProps={{
                  MenuListProps: {
                    style: {
                      display: 'flex',
                      flexWrap: 'wrap',
                      flexDirection: 'row',
                      width: menuColorPickerWidth,
                      listStyle: 'none',
                      justifyContent: 'center',
                      padding: menuColorPickerPadding,
                      margin: menuColorPickerMargin
                    }
                  }
                }}
              >
                {colors.map((color) => (
                  <StyledMenuItem
                    key={color}
                    value={color}
                    style={{
                      backgroundColor: color,
                      border: selectedColor === color ? '2px solid black' : ''
                    }}
                    className="menu-item"
                  />
                ))}
              </Select>
              {error && <p className="error inter-caption_medium">{error}</p>}
            </FormControl>
          );
        }}
      />
    </StyledColorPicker>
  );
};

export default ColorPicker;
