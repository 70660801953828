import { createAsyncThunk } from '@reduxjs/toolkit';
import calendarDictionariesApiService from 'api/calendarApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import CalendarDictionariesDto from 'types/allocation/dto/calendarDto/CalendarDictionariesDto';
import CalendarDictionariesAction from 'types/allocation/enums/CalendarDictionaries';

const getCalendarsDictionaries = createAsyncThunk<
  CalendarDictionariesDto,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(CalendarDictionariesAction.FETCH_CALENDAR_DICTIONARIES, async () => {
  const response = await calendarDictionariesApiService.fetchCalendarDictionaries();
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default getCalendarsDictionaries;
