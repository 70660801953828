import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import updateForecastApiService from 'api/updateForecastApiService';
import UpdateForecast from 'types/updateForecast/updateForecast';
import UpdateForecastDto from 'types/project/dto/UpdateForecastDto';

const updateForecast = createAsyncThunk<
  void,
  UpdateForecastDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(UpdateForecast.UPDATE_FORECAST, async (data) => {
  await updateForecastApiService.updateForecast(data);
});

export default updateForecast;
