import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { FixedInvoiceSummary } from 'store/slicesState/invoiceSummary';
import { InvoiceFixedPriceDto } from 'types/project/dto/FixedInvoiceDto';
import getFixedInvoiceDetails from 'store/action/projectActions/getFixedInvoiceSummary';
import { InvoiceStatus } from 'types/invoice/InvoiceDictionariesDto';

const initialState: FixedInvoiceSummary = {
  data: {
    invoiceTotal: {
      id: 0,
      startDate: '',
      endDate: '',
      invoiceStatus: InvoiceStatus.NEW,
      invoiceCost: 0,
      notes: '',
      additionalCostAmount: 0,
      additionalCostNotes: '',
      preDiscountCost: 0,
      preDiscountDuration: 0
    },
    description: '',
    amount: 0
  },
  readLoading: false,
  saveLoading: false,
  error: null
};

export type DispatchError = {
  errorMessage: string;
};

const fixedInvoiceSummarySlice = createSlice({
  name: 'fixedInvoiceSummary',
  initialState,
  reducers: {
    getFixedInvoiceSummary: (state, action: PayloadAction<InvoiceFixedPriceDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getFixedInvoiceDetails.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getFixedInvoiceDetails.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.readLoading = false;
    });
    builder.addCase(getFixedInvoiceDetails.rejected, (state) => {
      state.error = 'getFixedInvoiceSummary error in slice';
      state.readLoading = false;
    });
  }
});

export const { getFixedInvoiceSummary } = fixedInvoiceSummarySlice.actions;
export default fixedInvoiceSummarySlice.reducer;
