import { AllocationsDeleteBookingDto } from 'types/allocation/dto/allocationDeleteBookingDto/AllocationsDeleteBookingDto';

const AllocationBookingDeleteFormDefaultValue = (id: number): AllocationsDeleteBookingDto => {
  return {
    bookingId: id,
    deleteFutureBookings: false
  };
};

export default AllocationBookingDeleteFormDefaultValue;
