import { createAsyncThunk } from '@reduxjs/toolkit';
import projectTableApiService from 'api/projectTableApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import { ProjectTableParamsDto } from 'types/PrpTable/dto/ProjectTableParamsDto';
import { ProjectTotalsDto } from 'types/PrpTable/dto/ProjectTableDto';
import ProjectTableAction from 'types/project/enums/ProjectTableAction';

const getProjectTotals = createAsyncThunk<
  ProjectTotalsDto,
  ProjectTableParamsDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(ProjectTableAction.GET_PROJECT_TOTALS, async ({ projectId, month, year }) => {
  const response = await projectTableApiService.fetchProjectTotals(projectId, month, year);
  if (!response) notifyUser();
  return response;
});

export default getProjectTotals;
