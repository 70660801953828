import { FieldValues, Path, UseFormRegister, useFormContext } from 'react-hook-form';
import { FormControl, InputBase } from '@mui/material';
import { useEffect, useState } from 'react';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { plus, minus } from 'shared/PrpIcon/icons.constants';
import StyledFormAmountPicker from './StyledFormAmountPicker';

type FormAmountPickerProps<T extends FieldValues> = {
  name: string;
  label?: string;
  required: boolean;
  register: UseFormRegister<T>;
  className?: string;
  minLimit?: number;
  maxLimit?: number;
  value: number;
  error?: string;
};

const FormAmountPicker = <T extends FieldValues>({
  name,
  label,
  required,
  register,
  className,
  minLimit = 1,
  maxLimit = 12,
  value,
  error
}: FormAmountPickerProps<T>) => {
  const [amount, setAmount] = useState(value !== null ? value : 1);
  const { setValue } = useFormContext();
  setValue(name, amount);

  useEffect(() => {
    if (value !== null) setAmount(value);
  }, [value]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const hoursRegex = /^[0-9\b]+$/;
    if (e.target.value === '' || hoursRegex.test(e.target.value)) {
      setAmount(Number(e.target.value));
    }
    if (Number(e.target.value) >= maxLimit) {
      setAmount(maxLimit);
    }
    if (Number(e.target.value) <= minLimit) {
      setAmount(minLimit);
    }
  };
  const handleIncrementAmount = () => {
    if (amount < maxLimit) {
      setAmount(amount + 1);
    }
  };
  const handleDecrementAmount = () => {
    if (amount > minLimit) {
      setAmount(amount - 1);
    }
  };

  return (
    <StyledFormAmountPicker>
      <FormControl>
        <div className="picker">
          <button
            onClick={handleDecrementAmount}
            type="button"
            style={{ border: 'none', background: 'none', cursor: 'pointer' }}
          >
            <PrpIcon src={minus} />
          </button>
          <div className="vertical-line" />
          <FormControl>
            <p className="inter-caption_medium">{label}</p>
            <InputBase
              id={name as Path<T>}
              {...register(name as Path<T>, { required })}
              type="text"
              className={className}
              onChange={(e) => handleInputChange(e)}
              value={amount}
            />
          </FormControl>
          <div className="vertical-line" />
          <button
            onClick={handleIncrementAmount}
            type="button"
            style={{ border: 'none', background: 'none', cursor: 'pointer' }}
          >
            <PrpIcon src={plus} />
          </button>
        </div>
        {value === null && error && <p className="error inter-caption_medium">{error}</p>}
      </FormControl>
    </StyledFormAmountPicker>
  );
};

export default FormAmountPicker;
