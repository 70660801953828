import ALLOCATION_ITEM_DELIMITER from 'constants/symbolsConstants';
import { differenceInDays } from 'helpers/dateUtils';

export const extractAllocationEventTypeFromCssClass = (name: string) => {
  const typeNames = name.split(ALLOCATION_ITEM_DELIMITER);
  return typeNames[0];
};

export const extractDbIdFromCssClass = (name: string) => {
  const dbId = name.split(ALLOCATION_ITEM_DELIMITER);
  return parseInt(dbId[1], 10);
};

export const getAllocationMarginTop = (eventClassName: string, startDate: Date, endDate: Date) => {
  const oneDay = 1;
  const dateDifference = differenceInDays(startDate, endDate) - oneDay;
  if (eventClassName.includes('TimeOff')) {
    return dateDifference >= 2 ? '-3px' : '3px';
  }

  if (eventClassName.includes('Holiday')) {
    return '5px';
  }

  return '1px';
};

export const getAllocationMarginBottom = (eventClassName: string) => {
  if (eventClassName.includes('Holiday')) {
    return '2px';
  }

  return '10px';
};

export const getAllocationMaxHeight = (eventClassName: string) => {
  return eventClassName.includes('Booking') ? '50px' : '';
};
