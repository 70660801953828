import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { EmployeeDictionariesDto } from 'types/employee/dto/EmployeeDictionariesDto';

const dictionariesApiService: IApiService = {
  fetchDictionaries: async () => {
    try {
      const response = await apiRequest<EmployeeDictionariesDto>({
        method: 'GET',
        endpoint: `${API_URL}/dictionaries/employees`
      });
      return response;
    } catch (error) {
      logError('Error in fetchDictionaries');
      throw new Error('Error in fetchDictionaries');
    }
  }
};

interface IApiService {
  fetchDictionaries: () => Promise<EmployeeDictionariesDto>;
}

export default dictionariesApiService;
