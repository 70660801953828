import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import invoiceApiService from 'api/invoiceApiServices';
import InvoiceAction from 'types/project/enums/Invoice';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { InvoiceFixedPriceFormDto } from 'types/project/dto/InvoiceFixedPriceFormDto';
import { AddEditInvoiceFixedPriceDto } from 'types/project/dto/AddEditInvoiceFixedPriceDto';

const createFixedInvoice = createAsyncThunk<
  AddEditInvoiceFixedPriceDto,
  InvoiceFixedPriceFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(InvoiceAction.CREATE_FIXED_INVOICE, async (data: InvoiceFixedPriceFormDto) => {
  const response = await invoiceApiService.createFixedInvoice(data);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default createFixedInvoice;
