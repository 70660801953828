import { addFormInputBoderColor } from 'constants/styleConstants';
import {
  borderRadiusCommon,
  marginTopCommon
} from 'components/sharedComponents/StyleConsts/styleConsts';
import {
  ALERT_COLOR,
  PRIMARY_COLOR,
  SURFACE_COLOR,
  TEXT_COLOR,
  BACKGROUND_COLOR,
  LOW_EMPHASISE_COLOR,
  BORDERS_COLOR,
  DARK_GREY
} from 'constants/colors';
import styled from 'styled-components';
import { GREY_ONE_HUNDRED, GREY_THIRTY_FIVE, LIGHT_VIOLET } from 'constants/groupColors';

const StyledForm = styled.form({
  marginRight: '10px',
  p: {
    margin: 0
  },
  '.input-form': {
    width: '293px'
  },
  '.input-full-form': {
    width: '100%'
  },
  '.input-form-time': {
    width: '50px',
    textAlign: 'center'
  },
  '.input-form-allocation-details': {
    width: '567px',
    textarea: {
      padding: '9px'
    }
  },
  '.form-project-rate-details': {
    width: '605px',
    textarea: {
      padding: '9px'
    }
  },
  '.forecast-value': {
    width: '575px',
    marginBottom: '10px',
    textarea: {
      padding: '9px'
    }
  },
  '.input-capacity': {
    width: '293px'
  },
  '.MuiInputBase-input': {
    height: '28px',
    borderColor: addFormInputBoderColor
  },
  '.MuiFormControlLabel-label': {
    lineHeight: '18px',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Inter',
    color: TEXT_COLOR
  },
  '.MuiGrid-item': {
    paddingBottom: '10px'
  },
  '.error': {
    marginTop: '5px',
    color: ALERT_COLOR
  },
  '.form-select': {
    width: '263px',
    height: '42px'
  },
  '.add-button': {
    backgroundColor: PRIMARY_COLOR,
    padding: '14px 32px 14px 32px',
    color: SURFACE_COLOR,
    borderRadius: borderRadiusCommon,
    border: 'none',
    margin: '10px 0px 0px 15px',
    position: 'relative',
    cursor: 'pointer',
    p: {
      color: SURFACE_COLOR,
      margin: '0px'
    },
    ':disabled': {
      backgroundColor: 'grey',
      cursor: 'not-allowed'
    }
  },
  '.MuiGrid-container': {
    marginLeft: '0px',
    width: '100%'
  },
  '.form-permission-select': {
    width: '560px',
    borderRadius: borderRadiusCommon,
    marginTop: marginTopCommon
  },
  '.spinner-button-wrap': {
    position: 'absolute',
    zIndex: '100',
    backgroundColor: PRIMARY_COLOR,
    left: '20px',
    width: '50px'
  },
  '.input-form-color': {
    width: '268px',
    height: '43px',
    padding: '8px 8px 0px 8px',
    borderRadius: borderRadiusCommon,
    marginTop: marginTopCommon
  },
  '.input-client-name': {
    '& .MuiInputBase-input': {
      width: '560px'
    }
  },
  'input-project-name': {
    '& .MuiInputBase-input': {
      width: '290px'
    }
  },
  '.billiable-row': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    p: {
      alignSelf: 'center',
      textAlign: 'center',
      margin: '0'
    }
  },
  '.edit-billing-row': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: borderRadiusCommon,
    marginTop: '16px',
    paddingBottom: '16px',
    p: {
      alignSelf: 'center'
    }
  },
  '.update-discount-row': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
    borderRadius: borderRadiusCommon,
    p: {
      marginRight: '32px'
    },
    '.img': {
      alignSelf: 'center',
      p: {
        marginLeft: '44px'
      }
    }
  },
  '.update-discount-row-margin': {
    marginTop: marginTopCommon
  },
  '.start-date-row': {
    backgroundColor: BACKGROUND_COLOR,
    marginLeft: '12px',
    p: {
      paddingRight: '12px'
    }
  },
  '.update-discount-actuals-row': {
    backgroundColor: BACKGROUND_COLOR,
    marginLeft: '12px',
    textAlign: 'right',
    borderRadius: borderRadiusCommon,
    marginTop: '16px',
    marginBottom: '16px',
    '.top-row': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    }
  },

  '.actuals-values': {
    marginRight: '122px',
    p: {
      marginRight: '122px'
    }
  },
  '.hours': {
    paddingRight: '10px'
  },
  '.costs': {
    paddingRight: '10px'
  },

  '.end-date-row': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    p: {
      alignSelf: 'center',
      textAlign: 'left',
      margin: '0'
    }
  },
  '.project-form-select': {
    width: '550px',
    height: '42px',
    borderRadius: borderRadiusCommon,
    marginTop: marginTopCommon
  },
  '.allocation-form-select': {
    width: '570px',
    height: '42px',
    borderRadius: borderRadiusCommon,
    marginTop: marginTopCommon
  },
  '.project-radio-buttons': {
    flexDirection: 'row',
    marginTop: marginTopCommon
  },
  '.input-customer-period': {
    width: '215px'
  },
  '.project-form-status': {
    width: '260px'
  },
  '.form-date': {
    marginTop: marginTopCommon,
    borderRadius: borderRadiusCommon,
    width: '238px',
    padding: '12px',
    border: '1px solid darkgrey',
    fontSize: '14px'
  },
  '.actuals-form-date, .allocation-form-date': {
    marginTop: marginTopCommon,
    borderRadius: borderRadiusCommon,
    width: '90px',
    padding: '12px',
    border: '1px solid darkgrey',
    fontSize: '14px'
  },

  '.allocation-public-holidat-text-field': {
    width: '400px'
  },

  '.allocation-public-holiday-date': {
    marginTop: '10px',
    '& .allocation-public-holiday-datepicker': {
      width: '100px',
      marginRight: '10px'
    }
  },
  '.allocation-public-holiday-button-row': {
    marginTop: '80px'
  },
  '.spinner-allocation-form': {
    position: 'absolute',
    zIndex: '100',
    backgroundColor: PRIMARY_COLOR,
    left: '10px',
    width: '130px'
  },
  '.switch-row': {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '-12px',
    p: {
      alignSelf: 'center',
      marginBottom: '0',
      marginRight: '10px',
      marginLeft: '0px'
    },
    '.button-with-icon': {
      marginLeft: '0',
      marginRight: 'auto'
    }
  },
  '.time-input-row, .discount-time-input-row': {
    display: 'flex',
    flexDirection: 'row'
  },
  '.discount-time-input-row': {
    '#minutes': {
      marginLeft: '2px'
    },
    '.per-week-caption': {
      width: '160px'
    }
  },
  '.discount-input-form': {
    width: '110px'
  },
  '.auto-discount-container': {
    paddingLeft: '16px'
  },
  '.per-week-caption': {
    alignSelf: 'center',
    marginBottom: '0px',
    marginTop: '40px',
    marginLeft: '16px'
  },
  '.costs-caption': {
    alignSelf: 'center',
    marginBottom: '0px',
    marginTop: '40px'
  },
  '.total-time-caption': {
    marginTop: '0'
  },
  '.calendar-element': {
    position: 'absolute',
    right: '6%',
    top: '41%',
    zIndex: 999,
    flexDirection: 'column',
    boxShadow: '2px 2px 15px rgba(64, 64, 85, 0.3)',
    borderRadius: '8px',
    overflow: 'hidden'
  },
  '.calendarWrap': {
    display: 'block'
  },
  '.allocation-form-mainspinner': {
    div: {
      width: '30px',
      height: '30px',
      borderWidth: '6px'
    }
  },
  '.modal-container': {
    position: 'relative'
  },
  '.add-buttons-row, .add-buttons-holiday-row, .button-row': {
    position: 'absolute',
    bottom: '20px'
  },
  '.invoice-buttons-row': {
    marginTop: '15px'
  },
  '.submit-buttons-row': {
    marginTop: '5px'
  },
  '.add-buttons-holiday-row': {
    marginTop: '3%'
  },
  '.total': {
    justifyContent: 'center',
    display: 'flex',
    marginTop: '40px'
  },
  '.cancel-button': {
    backgroundColor: 'transparent',
    padding: '14px 32px 14px 32px',
    color: TEXT_COLOR,
    borderRadius: borderRadiusCommon,
    border: 'none',
    margin: '10px 0px 0px 15px',
    position: 'relative',
    cursor: 'pointer',
    p: {
      color: TEXT_COLOR,
      margin: '0px'
    }
  },
  '.cancel-upload-button': {
    margin: '10px 0px 0px 5px',
    padding: '14px 22px 14px 18px'
  },
  '.delete-descriotion': {
    color: TEXT_COLOR
  },
  '.delete-radio-button-first': {
    marginBottom: '5px'
  },
  '.delete-radio-button': {
    backgroundColor: LIGHT_VIOLET,
    borderRadius: borderRadiusCommon,
    padding: '3px',
    width: '100%'
  },

  '.edit-rate-date': {
    '& .edit-rate-datepicker': {
      width: '100px',
      marginRight: '10px'
    }
  },
  '.rate': {
    marginLeft: 'auto',
    marginRight: '10px',
    textAlign: 'right'
  },
  '.edit-project-range-buttons': {
    position: 'absolute',
    bottom: '20px'
  },
  '.header-with-icon': {
    display: 'flex'
  },
  '.button-with-icon': {
    backgroundColor: 'transparent',
    border: 'none',
    marginRight: '0',
    marginLeft: 'auto',
    cursor: 'pointer'
  },
  '.rate-history': {
    width: '100%',
    marginLeft: '15px',
    marginTop: '20px',
    paddingBottom: '10px',
    borderBottom: '1px solid',
    borderColor: GREY_ONE_HUNDRED
  },
  '.card-rate-history': {
    background: GREY_THIRTY_FIVE,
    marginTop: '20px',
    marginLeft: '0',
    width: 'auto',
    cursor: 'auto'
  },
  '.forecast': {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  '.forecast-container': {
    width: '100%'
  },
  '.booking-confirmed-label': {
    color: TEXT_COLOR
  },
  '.import-dates': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '75%',
    '.actuals-form-date': {
      width: '125px'
    }
  },
  '.booking-dates': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  '.upload-actuals-modal-subtitle': {
    margin: '-2px 0 20px -2px',
    fontSize: '15px'
  },
  '.error-message': {
    fontSize: '12px',
    color: ALERT_COLOR,
    marginTop: '10px'
  },
  '.billing-period-container': {
    display: 'flex',
    flexDirection: 'row',
    marginTop: '10px'
  },
  '.billing-period-title': {
    fontSize: '14px',
    color: LOW_EMPHASISE_COLOR
  },
  '.billing-period-text': {
    fontSize: '14px',
    color: TEXT_COLOR,
    marginLeft: '5px'
  },
  '.container-with-frame': {
    border: '2px solid',
    borderColor: BORDERS_COLOR,
    borderRadius: '10px',
    width: '100%',
    minHeight: '55px',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 0',
    marginTop: '-10px',
    '.text-container': {
      display: 'flex',
      flexDirection: 'column'
    },
    '.cost': {
      width: 'auto',
      marginLeft: 'auto',
      marginRight: '12px'
    }
  },
  '.container-with-background': {
    backgroundColor: BACKGROUND_COLOR,
    borderRadius: '10px',
    width: '100%',
    minHeight: '55px',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 0',
    marginTop: '-10px',
    '.text-container': {
      display: 'flex',
      flexDirection: 'column'
    }
  },
  '.preinvoice-forecast-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 'auto 12px'
  },
  '.forecast-title, .forecast-text': {
    fontWeight: '600',
    fontSize: '12px',
    color: TEXT_COLOR
  },
  '.forecast-text': {
    fontWeight: 'bold'
  },
  '.forecast-date, .forecast-cost, .forecast-grey': {
    fontSize: '12px',
    color: LOW_EMPHASISE_COLOR
  },
  '.right-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 'auto 12px',
    float: 'right',
    maxWidth: '195px',
    marginLeft: '64%',
    marginTop: '10px'
  },
  '.discount-select, .pre-discount-select': {
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: DARK_GREY,
    borderRadius: '12px',
    color: TEXT_COLOR,
    padding: '8px 10px',
    textTransform: 'none',
    fontSize: '12px'
  },
  '.pre-discount-select': {
    marginLeft: '10px'
  },
  '.button-selected': {
    border: '2px solid'
  },
  '.discount-label': {
    color: LOW_EMPHASISE_COLOR,
    marginBottom: '8px'
  },
  '.discount-title-container': {
    display: 'flex',
    maxWidth: '116px',
    '.button-with-icon': {
      marginTop: '-4px'
    }
  },
  '.discount-type-icon': {
    marginTop: '-2px',
    backgroundColor: BORDERS_COLOR,
    fontSize: '12px',
    padding: '4px 6px',
    borderRadius: '5px'
  },
  '.delete-additional-cost-text': {
    marginLeft: '16px',
    width: '95%'
  },
  '.discount-error': {
    fontSize: '12px',
    color: ALERT_COLOR,
    width: '300px'
  },
  '.radio-button-container': {
    backgroundColor: BACKGROUND_COLOR,
    borderRadius: '8px',
    marginBottom: '10px',
    padding: '10px 20px',
    marginLeft: '15px',
    width: '405px',
    '.MuiFormControlLabel-label': {
      fontWeight: 'bold'
    }
  },
  '.radio-button-description': {
    color: LOW_EMPHASISE_COLOR,
    margin: '-8px 0 8px 30px'
  },
  '.billing-date-container': {
    display: 'flex'
  },
  '.invoice-billing-date': {
    fontWeight: 'bolder',
    marginLeft: '5px'
  },
  '.repeat-label': {
    label: {
      width: '350px !important'
    }
  }
});

export default StyledForm;
