import { createAsyncThunk } from '@reduxjs/toolkit';
import allocationsTimeOffApiService from 'api/allocationTimeOffApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import { AllocationTimeOffDto } from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffDto';
import AllocationsAction from 'types/allocation/enums/Allocations';

const getOneTimeOff = createAsyncThunk<
  AllocationTimeOffDto,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(AllocationsAction.GET_TIME_OFF, async (id) => {
  const response = await allocationsTimeOffApiService.getTimeOff(id);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default getOneTimeOff;
