import { AllocationsAddBookingDto } from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingDto';
import AllocationsAddBookingFormDto from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingFormDto';
import { getTargetDayOfWeek } from 'helpers/dateUtils';
import WeekDayNumber from 'helpers/weekDayEnum';

const AllocationEditBookingFormDefaultValue = (
  item: AllocationsAddBookingDto,
  startDate: string | null,
  endDate: string | null
): AllocationsAddBookingFormDto => {
  const defaultStartDate = getTargetDayOfWeek(startDate || '', WeekDayNumber.MONDAY);
  const defaultEndDate = getTargetDayOfWeek(endDate || '', WeekDayNumber.FRIDAY);
  return {
    id: item.id,
    hours: item.hours ?? null,
    projectId: item.projectId ?? null,
    employeeId: item.employeeId ?? null,
    startDate: defaultStartDate || '',
    endDate: defaultEndDate || '',
    minutes: item.minutes ?? null,
    details: item.details ?? '',
    confirmed: item.confirmed ?? true,
    weekId: item.weekId ?? null,
    isAutoDiscountApplied: item.isAutoDiscountApplied ?? false,
    autoDiscountDuration: item.autoDiscountDuration ?? null
  };
};

export default AllocationEditBookingFormDefaultValue;
