import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store';
import getProjectDictionaries from 'store/action/projectActions/getProjectDictionaries';
import { ProjectDictionariesState } from 'store/slicesState/projectDictionaries';
import { ProjectDictionariesDto } from 'types/project/dto/ProjectDictionariesDto';

const initialState: ProjectDictionariesState = {
  data: {
    clientNames: [],
    projectManagers: [],
    billingTypes: [],
    billingPeriodTypes: [],
    statuses: [],
    clientColors: []
  },
  loading: false,
  error: null
};
export type DispatchError = {
  errorMessage: string;
};

const projectDictionariesSlice = createSlice({
  name: 'projectDictionaries',
  initialState,
  reducers: {
    getProjectDictionary: (state, action: PayloadAction<ProjectDictionariesDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectDictionaries.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getProjectDictionaries.fulfilled, (state, action) => {
      state.error = null;
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getProjectDictionaries.rejected, (state) => {
      state.error = 'getProjectDictionaries error in slice';
      state.loading = false;
    });
  }
});

export const { getProjectDictionary } = projectDictionariesSlice.actions;
export const SELECT_PROJECT_DICTIONARIES = (root: RootState) => root.projectDictionaries;
export default projectDictionariesSlice.reducer;
