import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import getProjectTable from 'store/action/projectActions/getProjectTable';
import { ProjectTableState } from 'store/slicesState/projectTableState';
import { ProjectTableDto } from 'types/PrpTable/dto/ProjectTableDto';

const initialState: ProjectTableState = {
  data: {
    projectId: 0,
    year: 0,
    month: 0,
    employeesInfo: [],
    billingPeriods: [],
    sprints: []
  },
  loading: false,
  error: null
};
export type DispatchError = {
  errorMessage: string;
};

const projectTableSlice = createSlice({
  name: 'projectTable',
  initialState,
  reducers: {
    getProjectsTable: (state, action: PayloadAction<ProjectTableDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectTable.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getProjectTable.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getProjectTable.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const { getProjectsTable } = projectTableSlice.actions;
export default projectTableSlice.reducer;
