enum AllocationDeleteFormNames {
  DeletePublicHolidayTitle = 'Do you want to delete the Public Holiday?',
  DeletePublicHolidayName = 'deleteAllHolidaysThisDay',
  DeletePublicHolidayFirstLabel = 'Delete Public Holiday for this user',
  DeletePublicHolidaySecondLabel = 'Delete Public Holiday for all users',
  DeleteBookingTitle = 'Do you want to delete the Booking?',
  DeleteBookingName = 'deleteFutureBookings',
  DeleteBookingFirstLabel = 'Delete current booking',
  DeleteBookingSecondLabel = 'Delete all future bookings',
  DeleteTimeOffFirstLabel = 'Delete current Time Off',
  DeleteTimeOffSecondLabel = 'Delete all future Time Offs',
  DeleteTimeOffTitle = 'Do you want to delete the Time Off?'
}

export default AllocationDeleteFormNames;
