import { createAsyncThunk } from '@reduxjs/toolkit';
import notesApiService from 'api/notesApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import mapToClientNoteDto from 'store/mappers/clients/clientsNoteMapper';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import { ClientNoteDto } from 'types/clients/dto/ClientNoteDto';
import { ClientNoteFormDto } from 'types/clients/dto/ClientNoteFormDto';
import NotesAction from 'types/clients/enums/Notes';

const addNotes = createAsyncThunk<
  ClientNoteDto,
  ClientNoteFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(NotesAction.ADD_NOTE, async (data: ClientNoteFormDto) => {
  const noteDto = mapToClientNoteDto(data);
  const response = await notesApiService.addNote(noteDto);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default addNotes;
