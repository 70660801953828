import { Autocomplete, Chip, TextField, styled } from '@mui/material';
import { PRIMARY_COLOR } from 'constants/colors';
import { RootState } from 'store';
import { useAppSelector } from 'store/redux-hooks/hooks';

type ProjectListMultiplyButtonProps = {
  selectedStatus: string[];
  handleStatusChange: (e: unknown, value: string[]) => void;
};

const CustomTextField = styled(TextField)({
  'input::placeholder': {
    color: PRIMARY_COLOR,
    fontSize: '12px',
    opacity: '1'
  }
});

const ProjectListMultiplyButton = ({
  selectedStatus,
  handleStatusChange
}: ProjectListMultiplyButtonProps) => {
  const projectDictinaries = useAppSelector((state: RootState) => state.projectDictionaries.data);
  return (
    <Autocomplete
      multiple
      value={selectedStatus}
      onChange={handleStatusChange}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          placeholder="Project status"
          variant="standard"
          className="custom-textfield"
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => <Chip label={option} {...getTagProps({ index })} />)
      }
      options={projectDictinaries.statuses.map(
        (option: { displayName: string }) => option.displayName
      )}
      getOptionLabel={(option) => option}
    />
  );
};

export default ProjectListMultiplyButton;
