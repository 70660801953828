import { createAsyncThunk } from '@reduxjs/toolkit';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import InvoiceAction from 'types/project/enums/Invoice';
import { AddAdditionalCostFormDto } from 'types/project/dto/AddAdditionalCostFormDto';
import { AdditionalCostDto } from 'types/invoice/AdditionalCostDto';
import additionalCostsApiService from 'api/additionalCostsApiService';

const editAdditionalCost = createAsyncThunk<
  AdditionalCostDto,
  AddAdditionalCostFormDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(InvoiceAction.ADD_ADDITIONAL_COST, async (data: AddAdditionalCostFormDto) => {
  const response = await additionalCostsApiService.editAdditionalCost(data);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default editAdditionalCost;
