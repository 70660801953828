import {
  subTotalsDevelopersDto,
  totalsDevelopersOnlyDto
} from 'types/PrpTable/dto/ProjectTableDto';
import isBorderVisible from 'helpers/isCellBorderVisible';
import calculateCellWidth from 'helpers/calculateTotalsCellWidth';
import isCellAloneInBillingPeriod from 'helpers/isCellAloneInBillingPerio';
import TotalsTableBorderType from 'types/PrpTable/enums/TotalsTableBorderType';
import { BpWeeksDto } from 'types/PrpTable/dto/ProjectTableParamsDto';
import ValuesCell from 'components/project/ProjectTable/ValuesCell/ValuesCell';
import {
  getCurrencyIfValueExistOrPlaceholder,
  getHourOrPlaceholder
} from 'helpers/formatCurrencyAndHours';

type SubTotalsDevelopersProps = {
  subTotalsDevelopers: subTotalsDevelopersDto[];
  shouldRenderBorders: boolean;
  borderType: TotalsTableBorderType;
  billingPeriodWeeks: BpWeeksDto[];
  billingPeriodIndex: number;
  isActualsButtonClicked: boolean;
  disableFinancialInfo: boolean;
};
const SubTotalsDevelopers = ({
  subTotalsDevelopers,
  shouldRenderBorders,
  borderType,
  billingPeriodWeeks,
  billingPeriodIndex,
  isActualsButtonClicked,
  disableFinancialInfo
}: SubTotalsDevelopersProps) => {
  let summedNumberOfWeeks = billingPeriodWeeks[0]?.numberOfWeeks ?? 0;
  const renderDeveloperValuesCells = (totals: totalsDevelopersOnlyDto) => (
    <>
      <ValuesCell
        duration={getHourOrPlaceholder(totals.forecastDuration)}
        cost={getCurrencyIfValueExistOrPlaceholder(totals.forecastCost)}
        disableFinancialInfo={disableFinancialInfo}
      />
      {isActualsButtonClicked && (
        <ValuesCell
          duration={getHourOrPlaceholder(totals.actualDuration)}
          cost={getCurrencyIfValueExistOrPlaceholder(totals.actualCost)}
          disableFinancialInfo={disableFinancialInfo}
        />
      )}
    </>
  );

  return subTotalsDevelopers.map((cell: subTotalsDevelopersDto, index: number) => {
    const { totals } = cell;

    const hasBorder = isBorderVisible(index, shouldRenderBorders, borderType, summedNumberOfWeeks);

    const cellWidth = calculateCellWidth({
      billingPeriodWeeks,
      borderType,
      cell
    });

    if (hasBorder) {
      billingPeriodIndex += 1;
      summedNumberOfWeeks += billingPeriodWeeks[billingPeriodIndex]?.numberOfWeeks ?? 0;
    }
    const isAloneInBillingPeriod = isCellAloneInBillingPeriod(
      billingPeriodWeeks,
      billingPeriodIndex
    );

    return (
      <div
        className={`table-content-type-details ${hasBorder ? 'totals-border' : ''}`}
        key={cell.id}
        style={{
          width: cellWidth,
          paddingLeft: isAloneInBillingPeriod ? '51px' : '0px'
        }}
      >
        {renderDeveloperValuesCells(totals)}
      </div>
    );
  });
};

export default SubTotalsDevelopers;
