import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import createDiscount from 'store/action/projectActions/addDiscount';
import updateDiscount from 'store/action/projectActions/updateDiscount';
import { DiscountState } from 'store/slicesState/discount';
import UpdateDiscountDto from 'types/project/dto/UpdateDiscountDto';
import DiscountTypeEnum from 'types/PrpTable/enums/discountTypeEnum';

const initialState: DiscountState = {
  data: {
    id: 0,
    weekId: 0,
    employeeId: 0,
    projectId: 0,
    duration: 0,
    reason: '',
    repeatForWeeksInBillingPeriod: false,
    discountType: DiscountTypeEnum.DEFAULT
  },
  loading: false,
  error: null
};

export type DispatchError = {
  errorMessage: string;
};

const allocationBookingSlice = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    addDiscounts: (state, action: PayloadAction<UpdateDiscountDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createDiscount.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createDiscount.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(createDiscount.rejected, (state) => {
      state.error = 'createDiscount error in slice';
      state.loading = false;
    });
    builder.addCase(updateDiscount.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateDiscount.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(updateDiscount.rejected, (state) => {
      state.error = 'updateDiscount error in slice';
      state.loading = false;
    });
  }
});

export const { addDiscounts } = allocationBookingSlice.actions;
export default allocationBookingSlice.reducer;
