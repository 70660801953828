import { FormControl, MenuItem, Select } from '@mui/material';
import StyledFormSelectController from 'components/sharedComponents/FormComponents/FormSelectDictionaries/StyledFormSelectController';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import ProjectFormComponentNames from 'types/project/enums/ProjectFormComponentNames';

type FormSelectDictionariesProps<T extends FieldValues> = {
  control: Control<T>;
  options: { id: number; displayName: string; name: string }[];
  title: Path<T>;
  label: string;
  error?: string;
  className?: string;
  disabled?: boolean;
  valueType?: 'number' | 'string';
};

const FormSelectDictionaries = <T extends object>({
  title,
  control,
  options,
  label,
  error,
  className,
  disabled = false,
  valueType
}: FormSelectDictionariesProps<T>) => {
  return (
    <StyledFormSelectController>
      <Controller
        name={title}
        control={control}
        render={({ field }) => {
          const value = valueType === 'string' ? (field.value as string) : (field.value as number);
          const isValidValue =
            valueType === 'string'
              ? options.some((item) => item.name === value)
              : options.some((item) => item.id === value);

          return (
            <FormControl>
              <p className="inter-caption_medium label">{label}</p>
              <Select
                {...field}
                value={isValidValue ? value : ''}
                className={className}
                disabled={disabled}
              >
                {title === ProjectFormComponentNames.ProjectManagerName ? (
                  <MenuItem key={null} value="">
                    None selected
                  </MenuItem>
                ) : null}
                {options.map((item) => (
                  <MenuItem key={item.id} value={valueType === 'string' ? item.name : item.id}>
                    {item.displayName}
                  </MenuItem>
                ))}
              </Select>
              {error && <p className="error inter-caption_medium">{error}</p>}
            </FormControl>
          );
        }}
      />
    </StyledFormSelectController>
  );
};

export default FormSelectDictionaries;
