enum ProjectModalWindowTitle {
  ProjectAddFormTitle = 'Add Project',
  ProjectChangeFormTitme = 'Edit Project',
  EditBillingPeriodFormTitle = 'Edit Billing Period',
  EditSprintLengthFormTitle = 'Edit sprint',
  UpdateDiscountFormTitle = 'Update Discount',
  UploadActualsFormTitle = 'Import AzDO actuals'
}

export default ProjectModalWindowTitle;
