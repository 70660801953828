const formatDateToCustomString = (date: Date, useMaxTimeValues?: boolean): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, useMaxTimeValues ? '23' : '0');
  const minutes = String(date.getMinutes()).padStart(2, useMaxTimeValues ? '59' : '0');
  const seconds = String(date.getSeconds()).padStart(2, useMaxTimeValues ? '59' : '0');
  const milliseconds = String(date.getMilliseconds()).padStart(3, useMaxTimeValues ? '999' : '0');
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
};

export default formatDateToCustomString;
