import {
  formChangeHeaderFontSize,
  formChangeHeaderFontWeight
} from 'components/sharedComponents/StyleConsts/styleConsts';
import { SURFACE_COLOR, TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledFormChangeHeader = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginBottom: '24px',
  h2: {
    color: TEXT_COLOR
  },
  button: {
    border: 'none',
    background: 'none',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    cursor: 'pointer',
    img: {
      alignSelf: 'center',
      marginRight: '5px'
    },
    p: {
      alignSelf: 'center',
      color: TEXT_COLOR
    }
  },
  '.btn-group': {
    width: '132px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  '.save-button': {
    position: 'relative'
  },
  '.spinner-button-row': {
    position: 'absolute',
    zIndex: 100,
    backgroundColor: SURFACE_COLOR,
    width: '60px',
    alignSelf: 'center'
  },
  '.input-form-header': {
    fontWeight: formChangeHeaderFontWeight,
    color: TEXT_COLOR,
    fontSize: formChangeHeaderFontSize
  }
});

export default StyledFormChangeHeader;
