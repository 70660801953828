import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { Grid } from '@mui/material';
import FormAutocomplete from 'components/sharedComponents/FormComponents/FormAutocomplete/FormAutocomplete';
import AllocationsFormComponentNames from 'types/allocation/dto/enums/AllocationsFormComponentNames';
import InputType from 'types/generalEnums/InputType';
import formatDate from 'helpers/formatDate';
import { differenceInBusinessDays, makeDecemberLastMonthInMaxYear } from 'helpers/dateUtils';
import FormTextField from 'components/sharedComponents/FormComponents/FormTextField/FormTextField';
import SpinnerButton from 'components/sharedComponents/Spinner/SpinnerButton';
import { FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import TimeOffAddFormValidation from 'components/allocations/AllocationTimeOff/AllocationTimeOffAddForm/AllocationTimeOffAddFormValidation';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import { useEffect } from 'react';
import formatDateToCustomString from 'helpers/formatDateToCustomString';
import getAllocationsDictionaries from 'store/action/allocationsAction/getAllocationsDictionaries';
import { unwrapResult } from '@reduxjs/toolkit';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import ButtonsText from 'types/generalEnums/ButtonsText';
import updateTimeOff from 'store/action/allocationsAction/updateTimeOff';
import AllocationTimeOffAddFormDefaultValue from 'components/allocations/AllocationTimeOff/AllocationTimeOffAddForm/AllocationTimeOffAddFormDefaultValue';
import AllocationTimeOffEditFormDefaultValue from 'components/allocations/AllocationTimeOff/AllocationTimeOffEditForm/AllocationTimeOffEditDefaultValue';
import MAX_TIME_STRING from 'constants/maxTimeString';
import AllocationTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffFormDto';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import FormDatePicker from 'components/sharedComponents/FormComponents/FormDatePicker/FormDatePicker';

type AllocationTimeOffEditFormProps = {
  onClose: () => void;
  showDeleteModal: boolean;
};

const AllocationTimeOffEditForm = ({
  onClose,
  showDeleteModal
}: AllocationTimeOffEditFormProps) => {
  const timeOffState = useAppSelector((state: RootState) => state.allocationTimeOff.data);
  const loadingTimeOff = useAppSelector((state: RootState) => state.allocationTimeOff.readLoading);
  const calendarState = useAppSelector((state: RootState) => state.calendarDictionaries.data);
  const minYear = calendarState?.minDate ? new Date(calendarState.minDate) : new Date();
  const maxYear = calendarState?.maxDate
    ? makeDecemberLastMonthInMaxYear(new Date(calendarState.maxDate))
    : new Date();

  const methods = useForm<AllocationTimeOffFormDto>({
    defaultValues: timeOffState
      ? AllocationTimeOffEditFormDefaultValue(timeOffState)
      : AllocationTimeOffAddFormDefaultValue,
    // TODO fix unknown
    resolver: yupResolver(
      TimeOffAddFormValidation()
    ) as unknown as Resolver<AllocationTimeOffFormDto>
  });

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors }
  } = methods;
  const dispatch = useThunkAppDispatch();
  useEffect(() => {
    dispatch(getAllocationsDictionaries())
      .then(unwrapResult)
      .catch(() => logError('Error fetching allocationDictionaries'));
  }, []);

  useEffect(() => {
    if (timeOffState) {
      methods.reset(timeOffState);
    }
  }, [timeOffState, methods]);
  useEscapeKey(() => {
    if (!showDeleteModal) {
      onClose();
    }
  });
  const allocationsDictionaries = useAppSelector(
    (state: RootState) => state.allocationsDictionaries.data
  );
  const startDateWatch = watch(AllocationsFormComponentNames.StartDate);
  const endDateWatch = watch(AllocationsFormComponentNames.EndDate);
  const loadingPublicHoliday = useAppSelector(
    (state: RootState) => state.allocationPublicHoliday.saveLoading
  );
  const onSubmit: SubmitHandler<AllocationTimeOffFormDto> = async (
    data: AllocationTimeOffFormDto
  ) => {
    try {
      data.startDate = formatDateToCustomString(new Date(data.startDate));
      data.endDate = formatDateToCustomString(
        new Date(`${formatDate(data.endDate, '', true)} ${MAX_TIME_STRING}`),
        true
      );
      await dispatch(updateTimeOff(data));
      onClose();
    } catch (error) {
      logError('Error updating time-off');
    }
  };

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        {loadingTimeOff ? (
          <SpinnerButton loading={loadingTimeOff} className="allocation-form-mainspinner" />
        ) : (
          <Grid container spacing={2} columns={20}>
            <Grid item xs={20} md={20}>
              <FormAutocomplete
                title={AllocationsFormComponentNames.PeopleRecourses}
                label={AllocationsFormComponentNames.PeopleRecoursesLabel}
                control={control}
                options={allocationsDictionaries.bookableEmployeeNames || []}
                className="allocation-form-select"
                error={errors.employeeId?.message}
                disabled
              />
            </Grid>
            <Grid item xs={10} md={9}>
              <div className="booking-dates">
                <FormDatePicker
                  name={AllocationsFormComponentNames.StartDate}
                  control={control}
                  label={AllocationsFormComponentNames.FromDateLabel}
                  className="allocation-form-date"
                  error={errors.startDate?.message}
                  minDate={minYear}
                  maxDate={maxYear}
                />
                <FormDatePicker
                  name={AllocationsFormComponentNames.EndDate}
                  control={control}
                  label={AllocationsFormComponentNames.ToDateLabel}
                  className="allocation-form-date"
                  error={errors.endDate?.message}
                  minDate={minYear}
                  maxDate={maxYear}
                />
              </div>
            </Grid>
            <Grid item xs={16} md={10}>
              <p className="inter-caption-grey total-time-caption total">{`Total: ${differenceInBusinessDays(
                new Date(startDateWatch),
                new Date(endDateWatch)
              )}d (${
                differenceInBusinessDays(new Date(startDateWatch), new Date(endDateWatch)) * 8
              }h)`}</p>
            </Grid>
            <Grid item xs={20} md={20}>
              <FormAutocomplete
                title={AllocationsFormComponentNames.Type}
                label={AllocationsFormComponentNames.TypeLabel}
                control={control}
                options={allocationsDictionaries.timeOffTypes || []}
                className="allocation-form-select"
                error={errors.type?.message}
                shouldValueBeAString
              />
            </Grid>
            <Grid item xs={16} md={20}>
              <FormTextField
                label={AllocationsFormComponentNames.TimeOffDetailsLabel}
                name={AllocationsFormComponentNames.TimeOffDetails}
                type={InputType.Text}
                register={register}
                required={false}
                className="input-form-allocation-details"
                error={errors.details?.message}
              />
            </Grid>
          </Grid>
        )}
        <div className="add-buttons-row">
          <button type="submit" className="add-button" onSubmit={handleSubmit(onSubmit)}>
            <div className="spinner-button-wrap">
              <SpinnerButton loading={loadingPublicHoliday} />
            </div>
            <p className="inter-caption"> {ButtonsText.Update} </p>
          </button>
          <button type="button" onClick={onClose} className="cancel-button">
            <p className="inter-caption"> {ButtonsText.Cancel} </p>
          </button>
        </div>
      </StyledForm>
    </FormProvider>
  );
};

export default AllocationTimeOffEditForm;
