import { Grid, Switch } from '@mui/material';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  getCurrentMonth,
  getCurrentYear,
  getFirstDayOfMonth,
  getLastDayOfMonth,
  getLastImportDateOrPlaceholder
} from 'helpers/dateUtils';
import ProjectChangeForm from 'components/project/ProjectChangeForm/ProjectChangeForm';
import StyledProjectDetails from 'components/project/ProjectDetails/StyledProjectDetails';
import MainSpinner from 'components/sharedComponents/Spinner/MainSpinner';
import { topPanelModalWindowWidth } from 'components/sharedComponents/StyleConsts/styleConsts';
import getCalendarsDictionaries from 'store/action/calendarAction/getCalendarsDictionaries';
import TopPanel from 'components/sharedComponents/TopPanel/TopPanel';
import formatDate from 'helpers/formatDate';
import getStatusColor from 'helpers/getStatusColor';
import doesUserHavePermissions from 'helpers/permissionService';
import { ModalContext, ModalContextType } from 'modals/ModalProvider';
import ModalWindow from 'modals/ModalWindow';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import RoutingPath from 'routes/routing';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { clockGrey, penGrey } from 'shared/PrpIcon/icons.constants';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import getProjectDetails from 'store/action/projectActions/getOneProject';
import getProjectDictionaries from 'store/action/projectActions/getProjectDictionaries';
import selectBillingPeriodTypesPropertyById from 'store/dictionarySelectors/selectorBillingPeriodTypes';
import selectBillingTypesPropertyById from 'store/dictionarySelectors/selectorBillingTypes';
import selectClientColorsById from 'store/dictionarySelectors/selectorClientColors';
import selectClientsPropertyById from 'store/dictionarySelectors/selectorClients';
import selectprojectManagersPropertyById from 'store/dictionarySelectors/selectorProjectManagers';
import selectStatusPropertyById from 'store/dictionarySelectors/selectorStatus';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import PageType from 'types/generalEnums/PageType';
import PermissionsName from 'types/generalEnums/PermissionsName';
import ProjectModalWindowTitle from 'types/project/enums/ProjectModalWindowTitle';
import ChangeMonthSwitch from 'components/project/ProjectTable/ChangeMonthSwitch/ChangeMonthSwitch';
import allocationFormatDate from 'pages/AllocationsPage/helpers/allocationFormatDate';
import ProjectTable from 'components/project/ProjectTable/ProjectTable';
import AllocationFilterDashboardNames from 'types/allocation/enums/AllocationFilterDashboardNames';
import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import { maxAzdoAliasLengthToDisplay } from 'constants/validationConstants';

const ProjectDetails = () => {
  const { projectId } = useParams();
  const selectedProject = useAppSelector((state: RootState) => state.project.selectedProject);
  const dispatch = useThunkAppDispatch();
  const [month, setMonth] = useState(getCurrentMonth());
  const [year, setYear] = useState(getCurrentYear());

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showModal } = useContext(ModalContext) as ModalContextType;
  const billableChecked = selectedProject?.billable || false;
  const user = useAppSelector((state: RootState) => state.auth.user);
  const loadingProject = useAppSelector((state: RootState) => state.project.readLoading);
  const navigate = useNavigate();
  const [isAzdoAliasTooltipOpen, setIsAzdoAliasTooltipOpen] = useState(false);
  const handleChangeFormButton = () => {
    setIsModalOpen(true);
  };
  useEffect(() => {
    showModal(isModalOpen);
  }, [isModalOpen, showModal]);

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (projectId) {
      const parsedProjectId = parseInt(projectId, 10);
      dispatch(getProjectDictionaries())
        .then(unwrapResult)
        .then(() => {
          dispatch(getProjectDetails(parsedProjectId))
            .then(unwrapResult)
            .catch(() => logError('Error fetching projects'));
        })
        .catch(() => {
          logError('Error fetching project dictionaries');
        });
    }
  }, [projectId, dispatch]);

  useEffect(() => {
    dispatch(getCalendarsDictionaries())
      .then(unwrapResult)
      .catch(() => logError('Error fetching calendar dictionaries'));
  }, []);

  const calendarState = useAppSelector((state: RootState) => state.calendarDictionaries.data);
  const minYear = calendarState?.minDate ? new Date(calendarState.minDate) : new Date();
  const maxYear = calendarState?.maxDate ? new Date(calendarState.maxDate) : new Date();
  const projectStartDate = selectedProject?.startDate
    ? new Date(selectedProject?.startDate)
    : new Date();

  const projectState = useAppSelector((state: RootState) => state.projectDictionaries);
  const getProjectManagerDisplayName =
    selectedProject &&
    selectprojectManagersPropertyById(
      projectState,
      selectedProject.projectManagerId,
      DictionariesSelector.DisplayName
    );
  const getClientDisplayName =
    selectedProject &&
    selectClientsPropertyById(
      projectState,
      selectedProject.clientId,
      DictionariesSelector.DisplayName
    );

  const getBillingPeriodTypeDisplayName = () => {
    if (!selectedProject || selectedProject.billingPeriodType == null || !selectedProject.billable)
      return '-';
    return selectBillingPeriodTypesPropertyById(
      projectState,
      selectedProject.billingPeriodType,
      DictionariesSelector.DisplayName
    );
  };
  const getBillingTypeDisplayName =
    selectedProject && selectedProject.billable
      ? selectedProject.billingType !== null &&
        selectBillingTypesPropertyById(
          projectState,
          selectedProject.billingType,
          DictionariesSelector.DisplayName
        )
      : 'Non-billable';
  const getClientColorDisplayName = (id: number | undefined): string => {
    const displayName =
      id !== undefined
        ? selectClientColorsById(projectState, id, DictionariesSelector.DisplayName)
        : null;

    return displayName ?? '';
  };
  const getStatusDisplayName = (id: number | undefined): string => {
    const displayName =
      id !== undefined
        ? selectStatusPropertyById(projectState, id, DictionariesSelector.DisplayName)
        : null;

    return displayName ?? '';
  };
  const projectManagementPermissions = [
    PermissionsName.ProjectManager,
    PermissionsName.ManageProjects
  ];
  const canManageDetails = doesUserHavePermissions(user, projectManagementPermissions);
  const goToAllocationPage = () => {
    const projectName = selectedProject?.name;
    const filterName = AllocationFilterDashboardNames.ProjectLabel;
    if (!selectedProject) {
      return;
    }
    const allocationPageParams = {
      filterType: filterName,
      value: projectName,
      startDate: allocationFormatDate(getFirstDayOfMonth(), true),
      endDate: allocationFormatDate(getLastDayOfMonth(), false)
    };
    const searchParams = new URLSearchParams();
    Object.entries(allocationPageParams).forEach(([key, value]) => {
      if (value !== undefined) {
        searchParams.set(key, value.toString());
      }
    });
    const url = `${RoutingPath.AllocationPage}?${searchParams.toString()}`;
    navigate(url);
  };
  const isProjectClosed = getStatusDisplayName(selectedProject?.status) === 'Closed';

  const azdoAliasLength = selectedProject?.azdoAlias?.length || 0;
  const shouldDisplayTooltip = azdoAliasLength > maxAzdoAliasLengthToDisplay;

  return (
    <StyledProjectDetails>
      <TopPanel
        type={PageType.ProjectDetails}
        className="top-panel"
        showAddButtonPermissions={[]}
      />
      <div className="main-content">
        <MainSpinner loading={loadingProject} />
        <div className="sticky-padding-wrapper">
          <div className="content-header-border">
            <div className="content-header">
              <div className="content-header-text">
                <div style={{ backgroundColor: selectedProject?.colorHex }} className="color" />
                <h3 className="inter-h2">{selectedProject?.name}</h3>
              </div>
              <div className="content-header-buttons">
                <button type="button" className="allocation-button" onClick={goToAllocationPage}>
                  <PrpIcon src={clockGrey} />
                  <p className="inter-p3"> Open In Allocation </p>
                </button>
                {canManageDetails ? (
                  <button type="button" onClick={handleChangeFormButton}>
                    <PrpIcon src={penGrey} />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          {isModalOpen && (
            <ModalWindow
              onClose={closeModal}
              headerTitle={ProjectModalWindowTitle.ProjectChangeFormTitme}
              style={{ width: topPanelModalWindowWidth }}
            >
              <ProjectChangeForm onClose={closeModal} item={selectedProject} />
            </ModalWindow>
          )}
          <div className="content-description">
            <Grid container spacing={2}>
              <Grid item xs={4} md={3}>
                <h3 className="inter-caption_medium">Client Name</h3>
                <div className="project-row">
                  <div
                    style={{
                      backgroundColor: getClientColorDisplayName(selectedProject?.clientId)
                    }}
                    className="color"
                  />
                  <p className="inter-p3">{getClientDisplayName || ''}</p>
                </div>
              </Grid>
              <Grid item xs={4} md={3}>
                <h3 className="inter-caption_medium">Project Manager</h3>
                <p className="inter-p3">{getProjectManagerDisplayName || '-'}</p>
              </Grid>
              <Grid item xs={4} md={3}>
                <div className="start-end-date">
                  <div>
                    <h3 className="inter-caption_medium">Start Date</h3>
                    <p>{selectedProject !== null ? formatDate(selectedProject.startDate) : '-'}</p>
                  </div>
                  {isProjectClosed ? (
                    <div className="end-date">
                      <h3 className="inter-caption_medium">End Date</h3>
                      <p>{selectedProject !== null ? formatDate(selectedProject.endDate) : '-'}</p>
                    </div>
                  ) : null}
                </div>
              </Grid>
              <Grid item xs={4} md={3}>
                <h3 className="inter-caption_medium">Type Of Billing</h3>
                <p className="inter-p3">{getBillingTypeDisplayName || '-'}</p>
              </Grid>
              <Grid item xs={4} md={3} className="azdo-alias">
                <h3 className="inter-caption_medium">AZDO alias</h3>
                <CustomTooltip
                  onClose={() => setIsAzdoAliasTooltipOpen(false)}
                  onOpen={() => setIsAzdoAliasTooltipOpen(shouldDisplayTooltip)}
                  isTooltipOpen={isAzdoAliasTooltipOpen || false}
                  label={selectedProject?.azdoAlias || '-'}
                  title={selectedProject?.azdoAlias || '-'}
                  className="azdo-tooltip"
                  placement="bottom"
                />
              </Grid>
              <Grid item xs={4} md={3}>
                <h3 className="inter-caption_medium">Project Code</h3>
                <p className="inter-p3">{selectedProject?.code}</p>
              </Grid>
              <Grid item xs={4} md={3}>
                <h3 className="inter-caption_medium">Project Status</h3>
                <div className="project-row">
                  <div
                    style={{
                      backgroundColor: getStatusColor(getStatusDisplayName(selectedProject?.status))
                    }}
                    className="status-icon"
                  />
                  <p className="inter-p3">{getStatusDisplayName(selectedProject?.status)}</p>
                </div>
              </Grid>
              <Grid item xs={4} md={3}>
                <h3 className="inter-caption_medium swith-label">Project Type</h3>
                <div className="switch-row">
                  <Switch
                    checked={billableChecked}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled
                  />
                  <p className="inter-p3">{billableChecked ? 'Billable' : 'Unbillable'}</p>
                </div>
              </Grid>
              <Grid item xs={4} md={3}>
                <h3 className="inter-caption_medium">Billing Period</h3>
                <p className="inter-p3">{getBillingPeriodTypeDisplayName()}</p>
              </Grid>
              <Grid item xs={4} md={3}>
                <h3 className="inter-caption_medium">Weeks</h3>
                <p className="inter-p3">{selectedProject?.customBillingPeriodWeeks || '-'}</p>
              </Grid>
              <Grid item xs={4} md={3}>
                <h3 className="inter-caption_medium">Actuals last updated</h3>
                <p className="inter-p3">
                  {getLastImportDateOrPlaceholder(selectedProject?.actualsLastModified)}
                </p>
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="stick-utils-button-wrapper">
          <div className="month-switch">
            <ChangeMonthSwitch
              currentMonth={getCurrentMonth()}
              currentYear={getCurrentYear()}
              month={month}
              year={year}
              minYear={minYear.getFullYear()}
              maxYear={maxYear.getFullYear()}
              setMonth={setMonth}
              setYear={setYear}
              projectStartDate={projectStartDate}
            />
          </div>
        </div>
        {projectId ? (
          <ProjectTable projectId={parseInt(projectId, 10)} month={month} year={year} />
        ) : null}
      </div>
    </StyledProjectDetails>
  );
};

export default ProjectDetails;
