import { unwrapResult } from '@reduxjs/toolkit';
import ProjectList from 'components/project/ProjectList/ProjectList';
import MainSpinner from 'components/sharedComponents/Spinner/MainSpinner';
import TopPanel from 'components/sharedComponents/TopPanel/TopPanel';
import { useEffect, useState } from 'react';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import getAllProjects from 'store/action/projectActions/getAllProjects';
import getProjectDictionaries from 'store/action/projectActions/getProjectDictionaries';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import PageType from 'types/generalEnums/PageType';
import PermissionsName from 'types/generalEnums/PermissionsName';

const ProjectsPage = () => {
  const projectManagementPermissions = [
    PermissionsName.ProjectManager,
    PermissionsName.ManageProjects
  ];

  const projectState = useAppSelector((state: RootState) => state.project.data);
  const projectDictionariesState = useAppSelector((state: RootState) => state.projectDictionaries);
  const loadingProject = useAppSelector((state: RootState) => state.project.readLoading);
  const loadingProjectDictionaries = useAppSelector(
    (state: RootState) => state.projectDictionaries.loading
  );
  const [itemsCount, setItemsCount] = useState(projectState.length || 0);

  const dispatch = useThunkAppDispatch();
  useEffect(() => {
    dispatch(getProjectDictionaries())
      .then(() => {
        dispatch(getAllProjects())
          .then(unwrapResult)
          .catch(() => logError('Error fetching projects'));
      })
      .catch(() => {
        logError('Error fetching project dictionaries');
      });
  }, []);

  return (
    <div className="main-section">
      <TopPanel
        title="Projects"
        itemsCount={itemsCount}
        addButtonTitle="Add project"
        type={PageType.Project}
        showAddButtonPermissions={projectManagementPermissions}
      />
      <div className="content-section">
        <MainSpinner
          loading={loadingProject || loadingProjectDictionaries}
          className="project-section-spinner"
        />
        <ProjectList
          setItemsCount={setItemsCount}
          items={projectState}
          projectDictionariesState={projectDictionariesState}
        />
      </div>
    </div>
  );
};

export default ProjectsPage;
