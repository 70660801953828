import { Control, FieldValues, Path, useController } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import StyledDatePicker from 'components/sharedComponents/FormComponents/FormDatePicker/StyledDatePicker';
import { makeDecemberLastMonthInMaxYear } from 'helpers/dateUtils';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import getCalendarsDictionaries from 'store/action/calendarAction/getCalendarsDictionaries';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import formatDate from 'helpers/formatDate';
import formDatePickerDateFormat from 'constants/dateFormats';
import formatDateToCustomString from 'helpers/formatDateToCustomString';
import { infoGrey } from 'shared/PrpIcon/icons.constants';
import CustomTooltip from 'components/sharedComponents/Tooltip/CustomTooltip';
import TootlipName from 'components/sharedComponents/Tooltip/TooltipNames';
import OnlyDateFilter from 'types/generalEnums/DatePickerFilterType';

type FormDatePickerProps<TFieldValues extends FieldValues> = {
  name: Path<TFieldValues>;
  control: Control<TFieldValues>;
  className?: string;
  label: string;
  disabled?: boolean;
  error?: string;
  infoIcon?: boolean;
  filter?: OnlyDateFilter;
  minDate?: Date;
  maxDate?: Date;
  isEndDatePicker?: boolean;
};

const FormDatePicker = <TFieldValues extends FieldValues>({
  name,
  control,
  className,
  label,
  disabled: disable,
  error,
  infoIcon,
  filter,
  minDate,
  maxDate,
  isEndDatePicker
}: FormDatePickerProps<TFieldValues>) => {
  const {
    field: { value, onChange }
  } = useController({ name, control });
  const dispatch = useThunkAppDispatch();

  useEffect(() => {
    dispatch(getCalendarsDictionaries())
      .then(unwrapResult)
      .catch(() => logError('Error fetching calendar dictionaries'));
  }, []);

  const calendarState = useAppSelector((state: RootState) => state.calendarDictionaries.data);
  const minYear = minDate || calendarState?.minDate ? new Date(calendarState.minDate) : new Date();
  const maxYear =
    maxDate || calendarState?.maxDate
      ? makeDecemberLastMonthInMaxYear(new Date(calendarState.maxDate))
      : new Date();

  const [openTooltip, setOpenTooltip] = React.useState(false);

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };
  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };
  const isWeekends = (date: Date) => {
    const dayOfWeek = date.getDay();
    return !(dayOfWeek === 0 || dayOfWeek === 6);
  };
  const isMonday = (date: Date): boolean => date.getDay() === 1;
  const isFriday = (date: Date): boolean => date.getDay() === 5;
  const isSunday = (date: Date): boolean => date.getDay() === 0;
  const isAllowedDate = (date: Date): boolean => {
    const disabledDate = new Date('December 29, 2025 00:00:00');
    return date.getTime() !== disabledDate.getTime();
  };
  const filterDates = (date: Date): boolean => {
    switch (filter) {
      case OnlyDateFilter.Mondays:
        return isMonday(date) && isAllowedDate(date);
      case OnlyDateFilter.Fridays:
        return isFriday(date);
      case OnlyDateFilter.Sundays:
        return isSunday(date);
      default:
        return isWeekends(date);
    }
  };
  return (
    <StyledDatePicker>
      {infoIcon ? (
        <div className="icon">
          <p className="inter-caption_medium">{label}</p>
          <CustomTooltip
            icon={infoGrey}
            onClose={handleCloseTooltip}
            onOpen={handleOpenTooltip}
            isTooltipOpen={openTooltip}
            title={TootlipName.EditRateTitle}
            placement="bottom"
          />
        </div>
      ) : (
        <p className="inter-caption_medium label">{label}</p>
      )}
      <div className="date-error-container">
        <DatePicker
          selected={value ? new Date(value) : null}
          onChange={(date) => {
            if (date) {
              onChange(formatDateToCustomString(date));
            }
          }}
          dateFormat={formDatePickerDateFormat}
          value={value ? formatDate(value, '', !!isEndDatePicker) : ''}
          className={className}
          disabled={disable}
          maxDate={maxYear}
          minDate={minYear}
          filterDate={(date: Date) => filterDates(date)}
          calendarStartDay={1}
        />
        {error && <span className="error-message error inter-caption_medium">{error}</span>}
      </div>
    </StyledDatePicker>
  );
};

export default FormDatePicker;
