import { createAsyncThunk } from '@reduxjs/toolkit';
import projectApiService from 'api/projectApiService';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import { ProjectDto } from 'types/project/dto/ProjectDto';
import ProjectAction from 'types/project/enums/Project';

const getProjectDetails = createAsyncThunk<
  ProjectDto,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(ProjectAction.GET_PROJECT, async (projectId: number) => {
  const response = await projectApiService.fetchOneProject(projectId);
  return addKeyToReactItem(response);
});

export default getProjectDetails;
