import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import AllocationDto from 'types/allocation/dto/AllocationDto';

const allocationsApiService: IApiService = {
  fetchAllocations: async (
    startDate: string,
    endDate: string,
    currentFilter: number,
    ids?: number[]
  ) => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.append('startDate', startDate.toString());
      queryParams.append('endDate', endDate.toString());
      queryParams.append('filter', currentFilter.toString());

      if (ids && ids.length > 0) {
        const idsQueryParam = ids.join(',');
        queryParams.append('ids', idsQueryParam);
      }

      const response = await apiRequest<AllocationDto>({
        method: 'GET',
        endpoint: `${API_URL}/allocations?${queryParams}`
      });
      return response;
    } catch (error) {
      logError('Error in fetchAllocations');
      throw new Error('Error in fetchAllocations');
    }
  }
};

interface IApiService {
  fetchAllocations: (
    startDate: string,
    endDate: string,
    currentFilter: number,
    ids?: number[]
  ) => Promise<AllocationDto>;
}

export default allocationsApiService;
