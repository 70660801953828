import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { ON_PRIMARY_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledAllocationTimeOff = styled.div({
  borderRadius: borderRadiusCommon,
  padding: '0 8px 0 8px',
  p: {
    margin: '0'
  },

  '.holiday-title': {
    color: ON_PRIMARY_COLOR
  },
  '.holiday-row': {
    display: 'flex',
    flexDirection: 'column'
  },
  '.holiday-row-item': {
    display: 'flex',
    flexDirection: 'column'
  },
  '.holiday-row-wrapper': {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '1px',
    marginTop: '1px',
    '.holiday-img': {
      marginRight: '5px',
      alignSelf: 'center'
    }
  },
  '.holiday-row-item-small': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'center',
    padding: '4px 0 4px 0',
    '.holiday-img': {
      marginBottom: '5px',
      alignSelf: 'center'
    },
    '.holiday-title': {
      alignSelf: 'center'
    }
  }
});

export default StyledAllocationTimeOff;
