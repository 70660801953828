import convertTitles from 'helpers/convertTitles';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { holiday, publicHoliday } from 'shared/PrpIcon/icons.constants';
import StyledPublicHoliday from 'components/allocations/AllocationScheduleEvents/StyledPublicHoliday';
import StyledBookingEvent from 'components/allocations/AllocationScheduleEvents/StyledBookingEvent';
import StyledAllocationTimeOff from 'components/allocations/AllocationScheduleEvents/StyledAllocationTimeOff';
import maxAllowedDateDifference from 'constants/maxAllowedDateDifference';
import { countDaysInMonth, differenceInDays } from 'helpers/dateUtils';
import { LIGHT_GREY } from 'constants/colors';
import {
  getAllocationMarginBottom,
  getAllocationMarginTop,
  getAllocationMaxHeight
} from 'pages/AllocationsPage/helpers/allocationCssClassFunctions';

// We are using any because the library return any
// eslint-disable-next-line
const AllocationScheduleEvent = ({ original, currentMonth }: any) => {
  const event = original;

  const eventStyle = {
    borderColor: event.color,
    borderWidth: '2px',
    border: 'solid',
    marginBottom: getAllocationMarginBottom(event.cssClass),
    maxHeight: getAllocationMaxHeight(event.cssClass),
    marginTop: getAllocationMarginTop(event.cssClass || '', event.start, event.end),
    backgroundColor:
      event.extendedProps?.confirmed === true ||
      event.extendedProps?.type ||
      (event.cssClass && event.cssClass?.includes('Holiday'))
        ? event.color
        : LIGHT_GREY
  };
  const startDate = event.start.toLocaleDateString('en-US', { day: '2-digit' });
  const endDate = event.end.toLocaleDateString('en-US', { day: '2-digit' });
  const startDateMonth = event.start.toLocaleDateString('en-US', { month: 'short' });
  const endDateMonth = event.end.toLocaleDateString('en-US', { month: 'short' });
  const year = event.start.toLocaleDateString('en-US', { year: 'numeric' });
  const showShortTitle = countDaysInMonth(event.start, event.end, currentMonth) === 1;
  const showMediumTitle = countDaysInMonth(event.start, event.end, currentMonth) === 2;
  const getEventHoursAndMinutes = (withDiscount: boolean): string => {
    const h = event.extendedProps.hours;
    const min = event.extendedProps.minutes;
    const discountSuffix = withDiscount ? ' %' : '';

    let titleSuffix = '';
    if (showShortTitle) {
      titleSuffix = min !== 0 ? 'm/w' : '/w';
    } else if (showMediumTitle) {
      titleSuffix = min !== 0 ? 'm/week' : '/week';
    } else {
      titleSuffix = min !== 0 ? 'min/week' : '/week';
    }
    return min !== 0
      ? `${h}h ${min}${titleSuffix}${discountSuffix}`
      : `${h}h${titleSuffix}${discountSuffix}`;
  };

  const oneDay = 1;
  const dateDifference = differenceInDays(event.start, event.end) - oneDay;

  const getTimeOffDate = (): string => {
    if (dateDifference <= maxAllowedDateDifference) {
      return `${startDate}-${endDate}`;
    }
    if (showShortTitle) {
      return `${event.start.toLocaleDateString('en-US', {
        month: '2-digit'
      })}.${startDate} ${event.end.toLocaleDateString('en-US', {
        month: '2-digit'
      })}.${endDate}`;
    }
    if (startDateMonth !== endDateMonth) {
      return `${startDateMonth} ${startDate}-${endDateMonth} ${endDate}, ${year}`;
    }
    return `${startDateMonth} ${startDate}-${endDate}, ${year}`;
  };

  if (!event.extendedProps) {
    return (
      <StyledPublicHoliday style={eventStyle}>
        <div className="public-holiday-row">
          <PrpIcon src={publicHoliday} width="12px" height="12px" className="public-holiday-img" />
          <p className="holiday-title inter-caption_medium">
            {endDate && endDate !== startDate ? `${startDate}-${endDate}` : `${startDate}`}
          </p>
        </div>
      </StyledPublicHoliday>
    );
  }
  if (event.extendedProps && event.extendedProps.minutes) {
    return (
      <StyledBookingEvent style={eventStyle}>
        {endDate && endDate === startDate ? (
          <div>
            {event.extendedProps.isAutoDiscountApplied ? (
              <p className="title title-short inter-caption_m_bold">
                {event.extendedProps.hours} %
              </p>
            ) : (
              <p className="title title-short inter-caption_m_bold">{event.extendedProps.hours}</p>
            )}
            <p className="title title-short inter-caption_medium">{event.title}</p>
          </div>
        ) : (
          <div>
            <p className="title inter-caption_m_bold">
              {getEventHoursAndMinutes(event.extendedProps.isAutoDiscountApplied)}
            </p>
            <p className="title inter-caption_medium">
              {showShortTitle || showMediumTitle
                ? `${event.extendedProps.projectCode}`
                : `[${event.extendedProps.projectCode}] ${event.title}`}
            </p>
          </div>
        )}
      </StyledBookingEvent>
    );
  }
  if (event.extendedProps && event.extendedProps.hours && !event.extendedProps.minutes) {
    return (
      <StyledBookingEvent style={eventStyle}>
        <p className="title inter-caption_m_bold">
          {getEventHoursAndMinutes(event.extendedProps.isAutoDiscountApplied)}
        </p>
        <p className="title inter-caption_medium">
          {showShortTitle || showMediumTitle
            ? `${event.extendedProps.projectCode}`
            : `[${event.extendedProps.projectCode}] ${event.title}`}
        </p>
      </StyledBookingEvent>
    );
  }
  if (event.extendedProps && event.extendedProps.type) {
    return (
      <StyledAllocationTimeOff style={eventStyle}>
        <div className="holiday-row">
          {endDate !== startDate ? (
            <div className="holiday-row-item">
              <div className="holiday-row-wrapper">
                <PrpIcon src={holiday} width="12px" height="12px" className="holiday-img" />
                {dateDifference <= maxAllowedDateDifference ? null : (
                  <p className="holiday-title inter-caption_medium">
                    {showShortTitle || showMediumTitle ? '' : convertTitles(event.title)}
                  </p>
                )}
              </div>
              <p className="holiday-title inter-caption_medium">{getTimeOffDate()}</p>
            </div>
          ) : (
            <div className="holiday-row-item-small">
              <PrpIcon src={holiday} width="12px" height="12px" className="holiday-img" />
              <p className="holiday-title inter-caption_medium">{startDate}</p>
            </div>
          )}
        </div>
      </StyledAllocationTimeOff>
    );
  }
};

export default AllocationScheduleEvent;
