import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import UnfreezeForecast from 'types/unfreezeForecast/UnfreezeForecast';
import unfreezeForecastApiService from 'api/unfreezeForecastApiService';

const unfreezeForecast = createAsyncThunk<
  void,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(UnfreezeForecast.UNFREEZE_FORECAST, async (data: number) => {
  await unfreezeForecastApiService.unfreezeForecast(data);
});
export default unfreezeForecast;
