import { unwrapResult } from '@reduxjs/toolkit';
import EmptyView from 'components/sharedComponents/EmptyView/EmptyView';
import SharedDetailsComponent from 'components/sharedComponents/SharedDetailsComponent/SharedDetailsComponent';
import SharedListItem from 'components/sharedComponents/SharedListItem/SharedListItem';
import TopPanel from 'components/sharedComponents/TopPanel/TopPanel';
import { useEffect, useState } from 'react';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { selectClients } from 'shared/PrpIcon/icons.constants';
import getClients from 'store/action/clientActions/getClients';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import PageType from 'types/generalEnums/PageType';
import { RootState } from 'store';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { useNavigate, useParams } from 'react-router-dom';
import RoutingPath from 'routes/routing';
import { selectClient } from 'store/slices/clients/clientsSlice';
import cleanSelectedClients from 'store/action/clientActions/cleanSelectedClients';
import {
  pageDetailsMinHeightOverride,
  pageDetailsScrollableMaxHeight
} from 'components/sharedComponents/StyleConsts/styleConsts';
import PermissionsName from 'types/generalEnums/PermissionsName';
import getProjectDictionaries from 'store/action/projectActions/getProjectDictionaries';

const ClientsPage = () => {
  const dispatch = useThunkAppDispatch();
  const selectedClient = useAppSelector((state: RootState) => state.clients.selectedClient);
  const clients = useAppSelector((state: RootState) => state.clients.data);
  const param = useParams<{ clientId: string }>();
  const [itemsCount, setItemsCount] = useState(clients.length || 0);
  const navigation = useNavigate();

  useEffect(() => {
    dispatch(getClients())
      .then(unwrapResult)
      .catch(() => {
        logError('Error fetching clients');
      });
  }, []);

  useEffect(() => {
    dispatch(getProjectDictionaries())
      .then(unwrapResult)
      .catch(() => {
        logError('Error fetching projectDictionaries');
      });
  }, []);

  const navigateOnResorceNotFound = () => {
    if (param.clientId && clients.length > 0) {
      const clientId = parseInt(param.clientId, 10);
      const doesEmployeeExist = !!clients?.find((e) => e.id === clientId);
      if (doesEmployeeExist) {
        dispatch(selectClient(clientId));
      } else {
        navigation(RoutingPath.NotFoundPage);
      }
    } else {
      dispatch(cleanSelectedClients());
    }
  };

  useEffect(() => {
    navigateOnResorceNotFound();
  }, [param.clientId, clients]);

  useEffect(() => {
    return () => {
      dispatch(cleanSelectedClients());
    };
  }, []);

  const clientManagementPermissions = [PermissionsName.ManageClients];

  return (
    <div className="main-section">
      <TopPanel
        title="Clients"
        itemsCount={itemsCount}
        addButtonTitle="Add client"
        type={PageType.Clients}
        showAddButtonPermissions={clientManagementPermissions}
      />
      <div className="content-section">
        <SharedListItem setItemsCount={setItemsCount} items={clients} type={PageType.Clients} />
        <div className="main-section-details">
          <SharedDetailsComponent
            item={selectedClient}
            style={{
              minHeight: pageDetailsMinHeightOverride,
              maxHeight: pageDetailsScrollableMaxHeight,
              overflowX: 'hidden',
              overflowY: 'auto'
            }}
            type={PageType.Clients}
            detailsManagementPermissions={clientManagementPermissions}
          />
        </div>
        {!selectedClient ? (
          <EmptyView
            title="Select a Client"
            description="Please, select a Client on the left to see the Client’s details here. "
          >
            <PrpIcon src={selectClients} width="197px" height="155px" />
          </EmptyView>
        ) : null}
      </div>
    </div>
  );
};

export default ClientsPage;
