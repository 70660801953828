import { Autocomplete, FormControl, TextField } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { DictionaryItemDto } from 'types/employee/enums/DictionaryItemDto';
import StyledFormAutocomplete from './StyledFormAutocomplete';

type FormAutocompleteProps<T extends FieldValues> = {
  control: Control<T>;
  options: DictionaryItemDto[];
  title: Path<T>;
  label: string;
  error?: string;
  className?: string;
  shouldValueBeAString?: boolean;
  disabled?: boolean;
};

const FormAutocomplete = <T extends object>({
  title,
  control,
  options,
  label,
  error,
  className,
  shouldValueBeAString,
  disabled = false
}: FormAutocompleteProps<T>) => {
  const getUpdatedValue = (newValue: DictionaryItemDto | null) => {
    if (newValue) {
      return !shouldValueBeAString ? newValue.id : newValue.name;
    }
    return null;
  };

  return (
    <StyledFormAutocomplete>
      <Controller
        name={title}
        control={control}
        render={({ field }) => {
          const value = !shouldValueBeAString ? (field.value as number) : (field.value as string);
          const isValidValue = !shouldValueBeAString
            ? options.some((item) => item.id === value)
            : options.some((item) => item.name === value);
          const optionsForSort = [...options];
          const optionsAlphabetical = optionsForSort.sort((a, b) =>
            a.displayName.localeCompare(b.displayName)
          );
          const chosenValue = !shouldValueBeAString
            ? options.find((item) => item.id === value)
            : options.find((item) => item.name === value);
          return (
            <FormControl>
              <p className="inter-caption_medium label">{label}</p>
              <Autocomplete
                {...field}
                id={title}
                disabled={disabled}
                options={optionsAlphabetical}
                value={isValidValue ? chosenValue : null}
                className={className}
                getOptionLabel={(option) => option.displayName}
                renderInput={(params) => <TextField {...params} />}
                renderOption={(props, option) => <li {...props}>{option.displayName}</li>}
                onChange={(_, newValue: DictionaryItemDto | null) => {
                  field.onChange(getUpdatedValue(newValue));
                }}
              />
              {error && <p className="error inter-caption_medium">{error}</p>}
            </FormControl>
          );
        }}
      />
    </StyledFormAutocomplete>
  );
};

export default FormAutocomplete;
