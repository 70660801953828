import styled from 'styled-components';
import { Tooltip } from '@mui/material';
import { TEXT_COLOR, SURFACE_COLOR } from 'constants/colors';
import { borderRadiusCommon, marginTopCommon } from '../StyleConsts/styleConsts';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ reasonTooltip }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: reasonTooltip ? SURFACE_COLOR : TEXT_COLOR,
    color: reasonTooltip ? TEXT_COLOR : SURFACE_COLOR,
    display: 'inline-flex',
    padding: '16px',
    borderRadius: borderRadiusCommon,
    div: {
      maxWidth: '300px'
    }
  },
  p: {
    margin: reasonTooltip ? '0' : '5px 0 5px 0'
  },
  div: {
    '.initials': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'start',
      marginBottom: marginTopCommon
    }
  },
  '.tooltip-title-container': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
    height: '18px'
  }
}));

export default StyledTooltip;
