import AllocationTimeOffFormDto from 'types/allocation/dto/allocationTimeOffDto/AllocationTimeOffFormDto';

const AllocationTimeOffAddFormDefaultValue: AllocationTimeOffFormDto = {
  employeeId: null,
  startDate: '',
  endDate: '',
  details: '',
  type: '',
  id: null
};

export default AllocationTimeOffAddFormDefaultValue;
