import { createAsyncThunk } from '@reduxjs/toolkit';
import authService from 'api/authApiService';
import { AppDispatch, RootState } from 'store';
import { DispatchError, logOutUser } from 'store/slices/authSlice';
import AuthorizationAction from 'types/authorization/enum/Authorization';

const logOut = createAsyncThunk<
  void,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(AuthorizationAction.LOG_OUT, async (_, thunkApi) => {
  const response = await authService.logout();
  thunkApi.dispatch(logOutUser());
  return response;
});
export default logOut;
