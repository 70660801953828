import { ClientNoteFormDto } from 'types/clients/dto/ClientNoteFormDto';
import { ClientsDto } from 'types/clients/dto/ClientsDto';

const ClientNotesDefaultValues = (client: ClientsDto): ClientNoteFormDto => {
  return {
    content: '',
    clientId: client.id,
    authorId: null,
    authorName: '',
    timestamp: '',
    id: null
  };
};

export default ClientNotesDefaultValues;
