import AllocationFilterDashboardNames from 'types/allocation/enums/AllocationFilterDashboardNames';

const getSearchParams = (allocationPageParams: {
  endDate: string;
  filterType: AllocationFilterDashboardNames;
  value: string;
  startDate: string;
}) => {
  const searchParams = new URLSearchParams();
  Object.entries(allocationPageParams).forEach(([key, value]) => {
    if (value !== undefined) {
      searchParams.set(key, value.toString());
    }
  });
  return searchParams;
};

export default getSearchParams;
