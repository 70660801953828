import { FormControlLabel, Switch } from '@mui/material';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import StyledFormSwitch from 'components/sharedComponents/FormComponents/FormSwitch/StyledFormSwitch';
import { useState } from 'react';
import ModalWindow from 'modals/ModalWindow';
import FormModalWindow from 'components/sharedComponents/FormComponents/FormModalWindow/FormModalWindow';
import {
  formModalWindowPadding,
  formSwitchModalTop,
  formModalWindowWidth
} from 'constants/styleConstants';
import FormModalConfig from 'types/generalEnums/FormModalConfigType';

type FormSwitchProps<T extends FieldValues> = {
  label?: string;
  labelOn?: string;
  labelOff?: string;
  name: Path<T>;
  title?: string;
  modalConfig?: FormModalConfig;
  disabled?: boolean;
  control: Control<T>;
  initialValue?: boolean;
};

const FormSwitch = <T extends object>({
  label,
  labelOn,
  labelOff,
  name,
  title,
  modalConfig,
  disabled = false,
  control,
  initialValue
}: FormSwitchProps<T>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [switchLabel, setSwitchLabel] = useState(initialValue ? labelOn : labelOff);

  const onCloseModal = () => setIsModalOpen(false);

  const onSubmitModal = (field: FieldValues) => {
    field.onChange(false);
    setIsModalOpen(false);
    if (labelOff) setSwitchLabel(labelOff);
  };
  const onSwitchChangeLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (labelOn && labelOff && event.target.checked) {
      setSwitchLabel(labelOn);
    }
    if (labelOn && labelOff && !event.target.checked) {
      setSwitchLabel(labelOff);
    }
  };

  const shouldOpenModalOnToggle = modalConfig != null;
  return (
    <StyledFormSwitch>
      <p className="inter-caption_medium form-switch-title">{title}</p>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div>
            {shouldOpenModalOnToggle ? (
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      id={name}
                      checked={field.value || false}
                      onChange={(event) => {
                        if (event.target.checked) {
                          field.onChange(true);
                          if (labelOn) setSwitchLabel(labelOn);
                        } else {
                          setIsModalOpen(true);
                        }
                      }}
                    />
                  }
                  disabled={disabled}
                  label={label || switchLabel}
                />
                {isModalOpen === true ? (
                  <ModalWindow
                    headerTitle={modalConfig?.headerTitle}
                    onClose={onCloseModal}
                    className="modal-title"
                    style={{
                      width: formModalWindowWidth,
                      top: formSwitchModalTop,
                      padding: formModalWindowPadding
                    }}
                  >
                    <FormModalWindow
                      onSubmit={onSubmitModal}
                      field={field}
                      btnText={modalConfig?.btnText}
                      description={modalConfig?.description}
                    />
                  </ModalWindow>
                ) : null}
              </div>
            ) : (
              <FormControlLabel
                control={
                  <Switch
                    id={name}
                    checked={field.value || false}
                    onChange={(event) => {
                      field.onChange(event.target.checked);
                      onSwitchChangeLabel(event);
                    }}
                  />
                }
                label={label || switchLabel}
                disabled={disabled}
              />
            )}
          </div>
        )}
      />
    </StyledFormSwitch>
  );
};

export default FormSwitch;
