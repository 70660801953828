import { Action, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import allocationBookingSlice from 'store/slices/allocations/allocationBookingSlice';
import allocationPublicHolidaySlice from 'store/slices/allocations/allocationPublicHolidaySlice';
import allocationsDictionariesSlice from 'store/slices/allocations/allocationsDictionariesSlice';
import allocationsSlice from 'store/slices/allocations/allocationsSlice';
import pageDateSlice from 'store/slices/allocations/pageDateSlice';
import authSlice from 'store/slices/authSlice';
import calendarDictionariesSlice from 'store/slices/calendar/calendarDictionariesSlice';
import clientsSlice from 'store/slices/clients/clientsSlice';
import notesSlice from 'store/slices/clients/notesSlice';
import dictionariesSlice from 'store/slices/employee/dictionariesSlice/dictionariesSlice';
import projectDictionariesSlice from 'store/slices/project/projectDictionariesSlice/projectDictionariesSlice';
import projectSlice from 'store/slices/project/projectSlice';
import projectTableSlice from 'store/slices/project/projectTableSlice/projectTableSlice';
import allocationTimeOffSlice from 'store/slices/allocations/allocationTimeOffSlice';
import employeeRateSlice from 'store/slices/project/employeeRate/employeeRateSlice';
import invoiceSlice from 'store/slices/project/invoiceSlice/invoiceSlice';
import preinvoiceSummarySlice from 'store/slices/project/preinvoiceSlice/preinvoiceSlice';
import invoiceDictionariesSlice from 'store/slices/invoice/invoiceDictionariesSlice';
import fixedInvoiceSummarySlice from 'store/slices/project/invoiceSummarySlice/fixedInvoiceSummarySlice';
import actualsSlice from 'store/slices/project/actualsSlice/actualsSlice';
import discountsSlice from './slices/project/discountsSlice/discountsSlice';
import projectTotalsSlice from './slices/project/projectTotalsSlice/projectTotalsSlice';
import employeesSlice from './slices/employee/employeeSlice';
import invoiceSummarySlice from './slices/project/invoiceSummarySlice/invoiceSummarySlice';

const middlewares = [thunk];

export const store = configureStore({
  reducer: {
    auth: authSlice,
    employees: employeesSlice,
    dictionaries: dictionariesSlice,
    discount: discountsSlice,
    clients: clientsSlice,
    notes: notesSlice,
    project: projectSlice,
    projectDictionaries: projectDictionariesSlice,
    allocations: allocationsSlice,
    allocationsDictionaries: allocationsDictionariesSlice,
    calendarDictionaries: calendarDictionariesSlice,
    allocationPublicHoliday: allocationPublicHolidaySlice,
    allocationBooking: allocationBookingSlice,
    allocationTimeOff: allocationTimeOffSlice,
    pageDateSlica: pageDateSlice,
    employeeRatesSlice: employeeRateSlice,
    projectTable: projectTableSlice,
    projectTotals: projectTotalsSlice,
    invoice: invoiceSlice,
    preinvoice: preinvoiceSummarySlice,
    invoiceDictionaries: invoiceDictionariesSlice,
    invoiceSummary: invoiceSummarySlice,
    fixedInvoiceSummary: fixedInvoiceSummarySlice,
    actuals: actualsSlice
  },
  middleware: middlewares
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, Action>;
