import { API_URL } from 'environment/environment';
import { ApiError, logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { mapToEmployeeDto } from 'store/mappers/employee/employeeMapper';
import { EmployeeDto } from 'types/employee/dto/EmployeeDto';
import { EmployeeFormDto } from 'types/employee/dto/EmployeeFormDto';
import apiRequest from './apiRequest';

const employeeApiService: IApiService = {
  fetchEmployees: async () => {
    try {
      const response = await apiRequest<EmployeeDto[]>({
        method: 'GET',
        endpoint: `${API_URL}/employees`
      });
      return response;
    } catch (error) {
      logError('Error in fetchEmployees');
      throw new Error('Error in fetchEmployees');
    }
  },
  updateEmployee: async (data: EmployeeFormDto) => {
    try {
      const employeeDto = mapToEmployeeDto(data);
      const response = await apiRequest<EmployeeDto>({
        method: 'PUT',
        endpoint: `${API_URL}/employees/${data.id}`,
        requestBody: employeeDto
      });
      return response;
    } catch (error) {
      if (error instanceof ApiError) throw new ApiError(error);

      logError('Error in updateEmployee');
      throw new Error('Error in updateEmployee');
    }
  },
  addEmployee: async (data: EmployeeFormDto) => {
    try {
      const addEmployeeDto = mapToEmployeeDto(data);
      const response = await apiRequest<EmployeeDto>({
        method: 'POST',
        endpoint: `${API_URL}/employees`,
        requestBody: addEmployeeDto
      });
      return response;
    } catch (error) {
      logError('Error in addEmployee');
      throw new Error('Error in addEmployee');
    }
  }
};

interface IApiService {
  fetchEmployees: () => Promise<EmployeeDto[]>;
  updateEmployee: (data: EmployeeFormDto) => Promise<EmployeeDto>;
  addEmployee: (data: EmployeeFormDto) => Promise<EmployeeDto>;
}

export default employeeApiService;
