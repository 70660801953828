import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import mapToSprintDto from 'store/mappers/project/sprintLengthMapper';
import EditSprintLengthFormDto from 'types/PrpTable/dto/EditSprintLengthFormDto';
import EditSprintLengthDto from 'types/PrpTable/dto/EditSprintLengthDto';
import apiRequest from './apiRequest';

const sprintApiService: IApiService = {
  updateSprintLength: async (data: EditSprintLengthFormDto) => {
    const updateSprintDto = mapToSprintDto(data);
    try {
      const response = await apiRequest<EditSprintLengthDto>({
        method: 'PUT',
        endpoint: `${API_URL}/sprints/${data.sprintId}`,
        requestBody: updateSprintDto
      });
      return response;
    } catch (error) {
      logError('Error in updateSprint');
      throw new Error('Error in updateSprint');
    }
  },
  getSprintLength: async (sprintId: number) => {
    try {
      return await apiRequest<EditSprintLengthDto>({
        method: 'GET',
        endpoint: `${API_URL}/sprints/${sprintId}`
      });
    } catch (error) {
      logError('Error in getSprint');
      throw new Error('Error in getSprint');
    }
  }
};
interface IApiService {
  updateSprintLength: (data: EditSprintLengthFormDto) => Promise<EditSprintLengthDto>;
  getSprintLength: (sprintId: number) => Promise<EditSprintLengthDto>;
}
export default sprintApiService;
