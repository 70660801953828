const convertTitles = (eventTitle: string) => {
  const words = eventTitle.split('_').map((word) => {
    const capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    if (capitalizedWord === 'Maternity') {
      return `${capitalizedWord} /`;
    }
    return capitalizedWord.replace('_', ' ');
  });

  return words.join(' ');
};

export default convertTitles;
