import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { PRIMARY_COLOR, SURFACE_COLOR, TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledLoginPage = styled.div({
  h1: {
    textAlign: 'center',
    color: TEXT_COLOR
  },
  p: {
    margin: '0',
    textAlign: 'center',
    color: TEXT_COLOR
  },
  '.login-content-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '90%'
  },
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
  '.login-button': {
    width: '185px',
    borderRadius: borderRadiusCommon,
    backgroundColor: PRIMARY_COLOR,
    border: 'none',
    padding: '16px 14px 16px 14px',
    alignSelf: 'center',
    marginTop: '32px',
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'row',
    p: {
      margin: '0px',
      color: SURFACE_COLOR,
      marginLeft: '7px',
      textAlign: 'center'
    }
  },
  '.login-image': {
    alignSelf: 'center'
  },
  '.prpLogo': {
    marginTop: '20px',
    marginLeft: '20px'
  }
});

export default StyledLoginPage;
