import EditSprintLengthFormDto from 'types/PrpTable/dto/EditSprintLengthFormDto';
import EditSprintLengthDto from 'types/PrpTable/dto/EditSprintLengthDto';

const mapToSprintDto = (data: EditSprintLengthFormDto): EditSprintLengthDto => {
  return {
    sprintLength: data.amount ?? null,
    note: data.note ?? null
  } as EditSprintLengthDto;
};
export default mapToSprintDto;
