import { createAsyncThunk } from '@reduxjs/toolkit';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import { PreInvoiceSummaryDto } from 'types/project/dto/PreinvoiceSummaryDto';
import InvoiceAction from 'types/project/enums/Invoice';
import preinvoiceSummaryApiService from 'api/preinvoiceSummaryApiService';

const getPreinvoiceDetails = createAsyncThunk<
  PreInvoiceSummaryDto,
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(InvoiceAction.GET_PREINVOICE_SUMMARY, async (billingPeriodId) => {
  const response = await preinvoiceSummaryApiService.getPreinvoiceSummary(billingPeriodId);
  if (!response) notifyUser();
  return addKeyToReactItem(response);
});

export default getPreinvoiceDetails;
