export const borderRadiusCommon = '8px';
export const marginTopCommon = '12px';
export const leftBarBigLinkWidth = '183px';
export const leftBarSmallWidth = '22px';
export const leftMenuBigWidth = '255px';
export const leftMenuSmallWidth = '95px';
export const topPanelModalWindowWidth = '600px';
export const topPanelModalWindowHeight = '420px';
export const formChangeHeaderFontWeight = '700';
export const formChangeHeaderFontSize = '14px';
export const notFoundPageImgWidth = '138px';
export const notFoundPageImgHeight = '118px';
export const pageDetailsMinHeightOverride = '260px';
export const pageDetailsScrollableMaxHeight = 'calc(100vh - 255px)';
export const allocationModalWindowWidth = '600px';
export const allocationModalWindowHeight = '350px';
export const allocationPublicHolidayModalWidth = '450px';
export const allocationModalWindowWithTabsWidth = '650px';
export const allocationDeleteModalWidth = '350px';
export const allocationDeleteModalTop = '20px';
export const allocationDeleteTimeOffModalWidth = '350px';
export const updateForecastModalWindowWidth = '600px';
export const updateForecastModalWindowHeight = '340px';
export const editRateModalWindowWidth = '630px';
export const editRateModalWindowHeight = '550px';
export const publicHolidayModalWindowHeight = '380px';
export const uploadActualsModalWidth = '460px';
export const uploadActualsModalHeight = '250px';
export const updateSprintModalHeight = '430px';
export const uploadActualsModalFullHeight = '440px';
export const projectTitleHeight = 80;
export const stickyUtilsContainerHeight = 87;
export const bookingClashModalWidth = '460px';
export const bookingClashModalHeight = '170px';
export const invoiceModalWidth = '653px';
export const invoiceModalHeight = '556px';
export const invoiceStatusModalWidth = '429px';
export const invoiceStatusModalHeight = '320px';
export const deleteInvoiceModalHeight = '200px';
export const preinvoiceDetailsModalWindowWidth = '600px';
export const preinvoiceDetailsModalWindowHeight = '600px';
export const preinvoiceDetailsModalWindowMinHeight = '300px';
export const addAdditionalCostModalHeight = '330px';
export const deleteBookingModalHeight = '250px';
export const employeeConfirmationModalHeight = '150px';
export const selectInvoiceTypeModalMaxHeight = '320px';
export const selectInvoiceTypeModalMinHeight = '200px';
