import { Grid } from '@mui/material';
import AddProjectFormDefaultValue from 'components/project/AddProjectForm/AddProjectFormDefaultValue';
import FormRadioButtons from 'components/project/ProjectFormComponents/FormRadioButtons';
import FormSelectDictionaries from 'components/sharedComponents/FormComponents/FormSelectDictionaries/FormSelectDictionaries';
import FormInput from 'components/sharedComponents/FormComponents/FormInput/FormInput';
import FormInputNumber from 'components/sharedComponents/FormComponents/FormInput/FormInputNumber';
import {
  maxCustomBillingPeriodWeeks,
  maxStartingSprintNumber,
  minCustomBillingPeriodWeeks,
  minStartingSprintNumber
} from 'constants/validationConstants';
import FormSwitch from 'components/sharedComponents/FormComponents/FormSwitch/FormSwitch';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import getShadeColors from 'helpers/getShadeColors';
import { Control, FormProvider, Resolver, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import selectClientColorsById from 'store/dictionarySelectors/selectorClientColors';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import InputType from 'types/generalEnums/InputType';
import ProjectFormDto from 'types/project/dto/ProjectFormDto';
import ProjectFormComponentNames from 'types/project/enums/ProjectFormComponentNames';
import ProjectFormValidation from 'components/project/AddProjectForm/ProjectFormValidation';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import DependentColorPicker from 'components/sharedComponents/ColorPicker/DependentColorPicker';
import ButtonsText from 'types/generalEnums/ButtonsText';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import FormDatePicker from 'components/sharedComponents/FormComponents/FormDatePicker/FormDatePicker';
import OnlyDateFilter from 'types/generalEnums/DatePickerFilterType';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { setProject } from 'store/slices/project/projectSlice';
import { ProjectDto } from 'types/project/dto/ProjectDto';
import addProject from 'store/action/projectActions/addProject';
import getAllProjects from 'store/action/projectActions/getAllProjects';
import BillingType from 'types/project/enums/BillingType';
import ProjectFormFieldMaxLengths from '../../../types/project/enums/ProjectFormFieldMaxLengths';

type AddProjectFormProps = {
  onClose: () => void;
};
const AddProjectForm = ({ onClose }: AddProjectFormProps) => {
  const [isCustomBillingPeriodEnabled, setCustomBillingPeriodEnabled] = useState(false);
  const methods = useForm({
    defaultValues: AddProjectFormDefaultValue,
    // TODO fix unknown
    resolver: yupResolver(
      ProjectFormValidation(
        minCustomBillingPeriodWeeks,
        maxCustomBillingPeriodWeeks,
        isCustomBillingPeriodEnabled
      )
    ) as unknown as Resolver<ProjectFormDto>
  });

  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    getValues,
    clearErrors,
    formState: { errors }
  } = methods;

  const projectDictionaries = useAppSelector((state: RootState) => state.projectDictionaries.data);
  const loadingProject = useAppSelector((state: RootState) => state.project.saveLoading);
  const calendarState = useAppSelector((state: RootState) => state.calendarDictionaries.data);
  const calendarMinDate = calendarState?.minDate ? new Date(calendarState.minDate) : new Date();
  const dispatch = useThunkAppDispatch();
  const projects = useSelector((state: RootState) => state.project.data);
  useEscapeKey(onClose);
  const selectedClientId = watch(ProjectFormComponentNames.ClientName) ?? 0;
  const billableName = watch(ProjectFormComponentNames.BillableName);
  const colorName = watch(ProjectFormComponentNames.ColorName);
  const customBillingPeriodWeeks = watch(ProjectFormComponentNames.CustomBillingPeriodName);
  const selectedClientColorDisplayName = useSelector((state: RootState) =>
    selectClientColorsById(
      state.projectDictionaries,
      selectedClientId,
      DictionariesSelector.DisplayName
    )
  );

  const shadesArray = selectedClientColorDisplayName
    ? [1, 2, 3, 4].map((i) => getShadeColors(selectedClientColorDisplayName, i))
    : [];

  const onSubmit: SubmitHandler<ProjectFormDto> = async (data: ProjectFormDto) => {
    try {
      const newProject: ProjectDto = await dispatch(addProject(data)).unwrap();
      dispatch(setProject([...projects, newProject]));
      dispatch(getAllProjects())
        .then(onClose)
        .catch(() => logError('Error fetching projects'));
    } catch (error) {
      logError('Error adding project');
    }
  };

  useEffect(() => {
    if (!billableName) {
      setValue(
        ProjectFormComponentNames.BillingTypeName as keyof ProjectFormDto,
        BillingType.NON_BILLABLE
      );
    } else {
      setValue(ProjectFormComponentNames.BillingTypeName as keyof ProjectFormDto, null);
    }
  }, [billableName, setValue]);

  useEffect(() => {
    setValue(ProjectFormComponentNames.ColorName as keyof ProjectFormDto, '');
  }, [selectedClientId, setValue]);

  useEffect(() => {
    clearErrors(ProjectFormComponentNames.ColorName);
  }, [colorName]);

  useEffect(() => {
    clearErrors(ProjectFormComponentNames.CustomBillingPeriodName);
  }, [customBillingPeriodWeeks]);

  useEffect(() => {
    setValue(ProjectFormComponentNames.CustomBillingPeriodName, null);
  }, []);

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className="modal-container">
          <Grid item xs={12} md={12}>
            <FormSelectDictionaries
              title={ProjectFormComponentNames.ClientName}
              label={ProjectFormComponentNames.ClientNameLabel}
              control={control}
              options={projectDictionaries.clientNames || []}
              className="project-form-select"
              error={errors.clientId?.message}
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormInput
              label={ProjectFormComponentNames.ProjectNameLabel}
              name={ProjectFormComponentNames.ProjectName}
              maxLength={ProjectFormFieldMaxLengths.ProjectName}
              type={InputType.Text}
              required
              register={register}
              className="input-project-name input-form"
              error={errors.name?.message}
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormInput
              label={ProjectFormComponentNames.AzdoAliasLabel}
              name={ProjectFormComponentNames.AzdoAliasName}
              maxLength={ProjectFormFieldMaxLengths.AzdoAlias}
              type={InputType.Text}
              required
              register={register}
              className="input-project-name input-form"
              error={errors.azdoAlias?.message}
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <FormInput
              label={ProjectFormComponentNames.ProjectCodeLabel}
              name={ProjectFormComponentNames.ProjectCodeName}
              maxLength={ProjectFormFieldMaxLengths.ProjectCode}
              type={InputType.Text}
              required
              register={register}
              className="input-project-name input-form"
              error={errors.code?.message}
            />
          </Grid>
          <Grid item xs={10} md={6}>
            <DependentColorPicker<ProjectFormDto>
              label={ProjectFormComponentNames.ColorLabel}
              name={ProjectFormComponentNames.ColorName}
              control={control as Control<ProjectFormDto>}
              error={errors.colorHex?.message}
              colors={shadesArray}
              dependentComponentName={ProjectFormComponentNames.ClientName}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <FormDatePicker
              name={ProjectFormComponentNames.StartDateName}
              control={control}
              label={ProjectFormComponentNames.StartDateLabel}
              className="form-date"
              filter={OnlyDateFilter.Mondays}
              error={errors.startDate?.message}
              minDate={calendarMinDate}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <FormInputNumber
              name={ProjectFormComponentNames.StartingSprintNumberName}
              label={ProjectFormComponentNames.StartingSprintNumberLabel}
              className="input-project-name input-form"
              error={errors.startingSprintNumber?.message}
              required
              getValues={getValues}
              register={register}
              maxValue={maxStartingSprintNumber}
              minValue={minStartingSprintNumber}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormSelectDictionaries
              title={ProjectFormComponentNames.ProjectManagerName}
              label={ProjectFormComponentNames.ProjectManagerLabel}
              control={control}
              options={projectDictionaries.projectManagers || []}
              className="project-form-select"
              error={errors.projectManagerId?.message}
            />
          </Grid>
          <Grid item xs={11} md={11} className="billiable-row">
            <p className="inter-caption_bold ">Billing information</p>
            <FormSwitch
              label={ProjectFormComponentNames.BillableLabel}
              name={ProjectFormComponentNames.BillableName}
              control={control}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormSelectDictionaries
              title={ProjectFormComponentNames.BillingTypeName}
              label={ProjectFormComponentNames.BillingTypeLabel}
              control={control}
              options={projectDictionaries.billingTypes || []}
              className="project-form-select"
              disabled={!billableName}
              valueType="string"
              error={errors.billingType?.message}
            />
          </Grid>
          <Grid item xs={7} md={7}>
            <FormRadioButtons
              name={ProjectFormComponentNames.BillingPeriodName}
              label={ProjectFormComponentNames.BillingPeriodLabel}
              control={control}
              dictionaries={projectDictionaries}
              className="project-radio-buttons"
              disabled={!billableName}
              setDependentTextInput={setCustomBillingPeriodEnabled}
              radioButtonIdForDependentInput={2}
            />
          </Grid>
          <Grid item xs={5} md={5}>
            <FormInputNumber
              label={ProjectFormComponentNames.CustomBillingPeriodLabel}
              name={ProjectFormComponentNames.CustomBillingPeriodName}
              required
              numberOfDigitsAfterDecimal={0}
              register={register}
              getValues={getValues}
              minValue={minCustomBillingPeriodWeeks}
              className="input-customer-period"
              error={errors.customBillingPeriodWeeks?.message}
              disabled={!isCustomBillingPeriodEnabled}
            />
          </Grid>
        </Grid>
        <CustomModalButtons
          submitButtonText={ButtonsText.Add}
          isSpinnerButtonAdded
          loading={loadingProject}
          isCancelButtonVisible
          submitButtonClassName="add-button button-text"
          onClick={onClose}
        />
      </StyledForm>
    </FormProvider>
  );
};

export default AddProjectForm;
