import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import mapToProjectDto from 'store/mappers/project/projectMapper';
import { ProjectDto } from 'types/project/dto/ProjectDto';
import ProjectFormDto from 'types/project/dto/ProjectFormDto';

const projectApiService: IApiService = {
  fetchProjects: async () => {
    try {
      const response = await apiRequest<ProjectDto[]>({
        method: 'GET',
        endpoint: `${API_URL}/projects`
      });
      return response;
    } catch (error) {
      logError('Error in fetchProjects');
      throw new Error('Error in fetchProjects');
    }
  },
  addProject: async (data: ProjectFormDto) => {
    try {
      const addProjectDto = mapToProjectDto(data);
      const response = await apiRequest<ProjectDto>({
        method: 'POST',
        endpoint: `${API_URL}/projects`,
        requestBody: addProjectDto
      });
      return response;
    } catch (error) {
      logError('Error in addCProject');
      throw new Error('Error in addProject');
    }
  },
  fetchOneProject: async (projectId: number) => {
    try {
      const response = await apiRequest<ProjectDto>({
        method: 'GET',
        endpoint: `${API_URL}/projects/${projectId}`
      });
      return response;
    } catch (error) {
      logError('Error in fetchOneProject');
      throw new Error('Error in fetchOneProject');
    }
  },
  updateProjectDetails: async (data: ProjectFormDto) => {
    try {
      const projectDeatailsDto = mapToProjectDto(data);
      const response = await apiRequest<ProjectDto>({
        method: 'PUT',
        endpoint: `${API_URL}/projects/${data.id}`,
        requestBody: projectDeatailsDto
      });
      return response;
    } catch (error) {
      logError('Error in updateProjectDetails');
      throw new Error('Error in updateProjectDetails');
    }
  }
};

interface IApiService {
  fetchProjects: () => Promise<ProjectDto[]>;
  addProject: (data: ProjectFormDto) => Promise<ProjectDto>;
  updateProjectDetails: (data: ProjectFormDto) => Promise<ProjectDto>;
  fetchOneProject: (projectId: number) => Promise<ProjectDto>;
}

export default projectApiService;
