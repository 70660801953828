import { dataToDisplayDto, subTotalsDevelopersDto } from 'types/PrpTable/dto/ProjectTableDto';
import { BpWeeksDto } from 'types/PrpTable/dto/ProjectTableParamsDto';
import TotalsTableBorderType from 'types/PrpTable/enums/TotalsTableBorderType';
import SubTotalType from 'types/PrpTable/enums/subTotalTypeEnum';
import SubTotalsDiscounts from 'components/project/ProjectTable/TotalsTable/SubTotals/SubTotalsDiscounts';
import SubTotalsDevelopers from 'components/project/ProjectTable/TotalsTable/SubTotals/SubTotalsDevelopers';

type SubTotalsProps = {
  subTotalsDevelopers: subTotalsDevelopersDto[];
  subTotalsDiscounts: dataToDisplayDto[];
  filterTableRows: () => { name: string; id: number }[];
  shouldRenderBorders: boolean;
  isActualsButtonClicked: boolean;
  borderType: TotalsTableBorderType;
  title?: string;
  billingPeriodWeeks: BpWeeksDto[];
  subTotalType?: SubTotalType;
  disableFinancialInfo: boolean;
};

const SubTotals = ({
  subTotalsDevelopers,
  subTotalsDiscounts,
  filterTableRows,
  shouldRenderBorders,
  borderType,
  isActualsButtonClicked,
  title = 'Developers Billing Period Totals',
  billingPeriodWeeks,
  subTotalType = SubTotalType.DEVELOPERS,
  disableFinancialInfo
}: SubTotalsProps) => {
  const billingPeriodIndex = 0;

  return (
    <div className="main-totals-wrapper subtotals-wrapper">
      <div className="spacer-left" />
      <div className="table-right-content-with-background subtotals-padding">
        <div className="table-content-container">
          <div className="table-content-name">
            <p className="inter-caption_bold totals-title">{title ?? ''}</p>
          </div>
        </div>
        <div
          className={`table-content-types ${
            disableFinancialInfo && 'totals-without-financial-info'
          }`}
        >
          {filterTableRows()
            .splice(0, 2)
            .map((item: { name: string; id: number }) => (
              <p className="inter-caption_medium" key={item.id}>
                {item.name}
              </p>
            ))}
        </div>
      </div>
      <div className="table-content-actuals">
        <div className="sprint-wrapper-weeks-total">
          <div className="sprint-row-actuals subtotals-cell">
            {subTotalType === SubTotalType.DEVELOPERS ? (
              <SubTotalsDevelopers
                subTotalsDevelopers={subTotalsDevelopers}
                shouldRenderBorders={shouldRenderBorders}
                borderType={borderType}
                billingPeriodWeeks={billingPeriodWeeks}
                billingPeriodIndex={billingPeriodIndex}
                isActualsButtonClicked={isActualsButtonClicked}
                disableFinancialInfo={disableFinancialInfo}
              />
            ) : (
              <SubTotalsDiscounts
                subTotalsDiscounts={subTotalsDiscounts}
                shouldRenderBorders={shouldRenderBorders}
                billingPeriodWeeks={billingPeriodWeeks}
                borderType={borderType}
                billingPeriodIndex={billingPeriodIndex}
                disableFinancialInfo={disableFinancialInfo}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubTotals;
