import AllocationsAddBookingFormDto from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingFormDto';

const AllocationBookingAddFormDefaultValue: AllocationsAddBookingFormDto = {
  id: null,
  hours: 0,
  projectId: null,
  employeeId: null,
  startDate: '',
  endDate: '',
  minutes: 0,
  details: '',
  confirmed: true,
  weekId: null,
  isAutoDiscountApplied: false,
  autoDiscountDuration: 0
};

export default AllocationBookingAddFormDefaultValue;
