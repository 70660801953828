import { MbscCalendarEvent, formatDate } from '@mobiscroll/react';
import { TEXT_COLOR } from 'constants/colors';
import ALLOCATION_ITEM_DELIMITER from 'constants/symbolsConstants';
import AllocationsBookingDto from 'types/allocation/dto/AllocationsBookingDto';
import AllocationsHolidaysDto from 'types/allocation/dto/AllocationsHolidaysDto';
import AllocationsTypeDto from 'types/allocation/dto/AllocationsTypeDto';
import EmployeeAllocationsDto from 'types/allocation/dto/EmployeeAllocationsDto';
import AllocationsTimeOffDto from 'types/allocation/dto/allocationTimeOffDto/AllocationsTimeOffDto';
import AllocationEventsTypes from 'types/allocation/enums/AllocationEventsTypes';
import formatToEndAllocationDate from 'helpers/formatToEndAllocationDate';

const allocationDataToEventsTransformer = (employeeAllocations: EmployeeAllocationsDto[]) => {
  const events: MbscCalendarEvent[] = [];
  const processAllocations = (allocations: AllocationsTypeDto, employeeId: number) => {
    if (Array.isArray(allocations)) {
      allocations.forEach((allocation) => {
        processAllocations(allocation, employeeId);
      });
    } else {
      const { bookings, timeOffs, holidays } = allocations;

      if (bookings) {
        const getTootlipText = (booking: AllocationsBookingDto) => {
          if (booking.projectName && !!booking.autoDiscountDuration) {
            const autoDiscountDurationHours = Math.floor(booking.autoDiscountDuration / 60);
            const autoDiscountDurationMinutes = Math.round(booking.autoDiscountDuration % 60);
            return `${booking.projectName}, ${formatDate(
              'MM.DD.YYYY',
              new Date(booking.startDate)
            )} - ${formatDate(
              'MM.DD.YYYY',
              formatToEndAllocationDate(booking.endDate)
            )}, Auto-Discounted: ${autoDiscountDurationHours}h ${autoDiscountDurationMinutes}m`;
          }
          if (booking.projectName && booking.autoDiscountDuration === null) {
            return `${booking.projectName}, ${formatDate(
              'MM.DD.YYYY',
              new Date(booking.startDate)
            )} - ${formatDate('MM.DD.YYYY', formatToEndAllocationDate(booking.endDate))}`;
          }
          return `${formatDate('MM.DD.YYYY', new Date(booking.startDate))} - ${formatDate(
            'MM.DD.YYYY',
            formatToEndAllocationDate(booking.endDate)
          )}`;
        };
        const bookingEvent: MbscCalendarEvent[] = bookings.map(
          (booking: AllocationsBookingDto) => ({
            id: booking.id,
            title: booking.projectName,
            color: booking.projectColorHex,
            start: new Date(booking.startDate),
            end: formatToEndAllocationDate(booking.endDate),
            resource: employeeId,
            text: booking.details,
            cssClass: `${AllocationEventsTypes.Booking}${ALLOCATION_ITEM_DELIMITER}${booking.dbid}`,
            extendedProps: {
              hours: booking.hours,
              minutes: booking.minutes,
              projectId: booking.projectId,
              confirmed: booking.confirmed,
              isAutoDiscountApplied: !!booking.autoDiscountDuration,
              projectCode: booking.projectCode
            },
            tooltip: getTootlipText(booking)
          })
        );

        events.push(...bookingEvent);
      }

      if (timeOffs) {
        const timeOffEvents: MbscCalendarEvent[] = timeOffs.map(
          (timeOff: AllocationsTimeOffDto) => ({
            id: timeOff.id,
            start: new Date(timeOff.startDate),
            end: formatToEndAllocationDate(timeOff.endDate),
            title: timeOff.type,
            resource: employeeId,
            text: timeOff.details,
            color: TEXT_COLOR,
            cssClass: `${AllocationEventsTypes.TimeOff}${ALLOCATION_ITEM_DELIMITER}${timeOff.dbid}`,
            extendedProps: {
              type: timeOff.type
            },
            tooltip: timeOff.type
              ? `${timeOff.type}, ${formatDate(
                  'MM.DD.YYYY',
                  new Date(timeOff.startDate)
                )} - ${formatDate('MM.DD.YYYY', formatToEndAllocationDate(timeOff.endDate))}`
              : `${formatDate('MM.DD.YYYY', new Date(timeOff.startDate))} - ${formatDate(
                  'MM.DD.YYYY',
                  formatToEndAllocationDate(timeOff.endDate)
                )}`
          })
        );

        events.push(...timeOffEvents);
      }

      if (holidays) {
        const holidayEvents: MbscCalendarEvent[] = holidays.map(
          (holiday: AllocationsHolidaysDto) => ({
            id: holiday.id,
            start: new Date(holiday.date),
            end: new Date(holiday.date),
            resource: employeeId,
            text: holiday.details,
            color: TEXT_COLOR,
            cssClass: `${AllocationEventsTypes.Holiday}${ALLOCATION_ITEM_DELIMITER}${holiday.dbid}`,
            tooltip: holiday.details
              ? `${holiday.details}, ${formatDate('MM.DD.YYYY', new Date(holiday.date))}`
              : `${formatDate('MM.DD.YYYY', new Date(holiday.date))} `
          })
        );

        events.push(...holidayEvents);
      }
    }
  };

  employeeAllocations.forEach(({ weekAllocations, employeeId }) => {
    weekAllocations.forEach(({ allocations }) => {
      if (allocations) {
        processAllocations(allocations as AllocationsTypeDto, employeeId);
      }
    });
  });

  return events;
};

export default allocationDataToEventsTransformer;
