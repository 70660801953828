import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import EmployeeRatesDto from 'types/project/dto/EmployeeRatesDto';
import EmployeeRate from 'types/project/enums/EmployeeRate';
import employeeRateApiService from 'api/employeeRateApiService';
import EmployeeRateParamsDto from 'types/project/dto/EmployeeRateParamsDto';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';

const getEmployeeRates = createAsyncThunk<
  EmployeeRatesDto[],
  EmployeeRateParamsDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(EmployeeRate.GET_EMPLOYEE_RATES, async (data) => {
  const response = await employeeRateApiService.getEmployeeRates(data.projectId, data.employeeId);
  if (!response) notifyUser();
  return response;
});

export default getEmployeeRates;
