import { ProjectDictionariesState } from 'store/slicesState/projectDictionaries';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';

const selectStatusPropertyById = (
  state: ProjectDictionariesState,
  id: number,
  property: DictionariesSelector.Name | DictionariesSelector.DisplayName
): string | null => {
  const statusDictionary = state.data?.statuses;
  if (!statusDictionary) {
    return null;
  }
  const status = statusDictionary.find((cl) => cl.id === id);
  return status?.[property] ?? null;
};

export default selectStatusPropertyById;
