import { createAsyncThunk } from '@reduxjs/toolkit';
import projectTableApiService from 'api/projectTableApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import { DispatchError } from 'store/slices/authSlice';
import { ProjectTableDto } from 'types/PrpTable/dto/ProjectTableDto';
import { ProjectTableParamsDto } from 'types/PrpTable/dto/ProjectTableParamsDto';
import ProjectTableAction from 'types/project/enums/ProjectTableAction';

const getProjectTable = createAsyncThunk<
  ProjectTableDto,
  ProjectTableParamsDto,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(ProjectTableAction.GET_PROJECT_TABLE, async ({ projectId, month, year }) => {
  const response = await projectTableApiService.fetchProjectTable(projectId, month, year);
  if (!response) notifyUser();
  return response;
});

export default getProjectTable;
