import { Grid } from '@mui/material';
import { RootState } from 'store';
import { useAppSelector } from 'store/redux-hooks/hooks';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import displayEndDate from 'components/clients/helpers/displayEndDate';
import Initials from 'components/sharedComponents/Initials/Initials';
import formatDate from 'helpers/formatDate';
import selectStatusPropertyById from 'store/dictionarySelectors/selectorStatus';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import getShadeColors from 'helpers/getShadeColors';
import { memoize } from 'proxy-memoize';

type ClientDetailsContentProps = {
  item: ClientsDto;
};

const ClientDetailsProject = ({ item }: ClientDetailsContentProps) => {
  const client = useAppSelector(
    memoize((state: RootState) => state.clients.data.find((e: ClientsDto) => e.id === item.id))
  );

  const projectDictionariesState = useAppSelector((state: RootState) => state.projectDictionaries);

  const getStatusDisplayName = (projectId: number): string => {
    const displayName = selectStatusPropertyById(
      projectDictionariesState,
      projectId,
      DictionariesSelector.DisplayName
    );
    return displayName ?? '';
  };

  return (
    <div>
      <h3 className="inter-caption_medium">Associated Projects</h3>
      <div className="clients-projects-row">
        {item?.projects.map((project, index) => {
          const color = getShadeColors(client?.colorHex ?? '', index);
          return (
            <Grid container spacing={2} className="clients-projects-wrapper" key={project.id}>
              <Grid item xs={3} md={3} className="client-code-row">
                <div
                  style={{
                    backgroundColor: color
                  }}
                  className="client-color"
                />

                <p className="inter-caption_medium">{`${project?.name} (${project?.code})`}</p>
              </Grid>
              <Grid item xs={4} md={1.5}>
                <p className="inter-caption_medium">{formatDate(project?.startDate)}</p>
              </Grid>
              <Grid item xs={4} md={1.5}>
                <p className="inter-caption_medium">
                  {displayEndDate(formatDate(project?.endDate, '', true))}
                </p>
              </Grid>
              <Grid item xs={4} md={1.5}>
                <p className="inter-caption_medium"> {getStatusDisplayName(project?.status)} </p>
              </Grid>
              <Grid item xs={6} md={4} className="clients-name-row">
                {project.projectManagerName ? (
                  <>
                    <Initials name={project.projectManagerName} />
                    <p className="inter-caption_medium"> {project.projectManagerName} </p>
                  </>
                ) : (
                  <p className="inter-caption_medium"> — </p>
                )}
              </Grid>
            </Grid>
          );
        })}
      </div>
    </div>
  );
};

export default ClientDetailsProject;
