import Initials from 'components/sharedComponents/Initials/Initials';
import formatPositionName from 'helpers/formatPositionName';
import { EmployeeInfoDto } from 'types/PrpTable/dto/ProjectTableDto';

type EmployeeNameAndInitialsProps = {
  employee: EmployeeInfoDto;
};

const EmployeeNameAndInitials = ({ employee }: EmployeeNameAndInitialsProps) => {
  return (
    <div className="table-content-name">
      <Initials name={employee.employeeName ?? ''} className="employee-img" />
      <div className="employee-name-row">
        <p className="inter-p3 employee-name">{employee.employeeName}</p>
        <p className="inter-p3 employee-position">
          {`${formatPositionName(employee.employeePositionName)}, ${formatPositionName(
            employee.employeeSeniorityName
          )}`}
        </p>
      </div>
    </div>
  );
};

export default EmployeeNameAndInitials;
