import { createAsyncThunk } from '@reduxjs/toolkit';
import projectApiService from 'api/projectApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import getReactItemsWithKey from 'store/mappers/sharedMapper';
import { DispatchError } from 'store/slices/authSlice';
import { ProjectDto } from 'types/project/dto/ProjectDto';
import ProjectAction from 'types/project/enums/Project';

const getAllProjects = createAsyncThunk<
  ProjectDto[],
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(ProjectAction.FETCH_ALL, async () => {
  const response = await projectApiService.fetchProjects();
  if (!response) notifyUser();
  return getReactItemsWithKey(response);
});

export default getAllProjects;
