import { DictionariesState } from 'store/slicesState/dictionaries';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';

const selectPermissionsPropertyById = (
  state: DictionariesState,
  permissions: number[],
  property: DictionariesSelector.Name | DictionariesSelector.DisplayName
): string[] => {
  const permissionDictionary = state.data?.permissions;
  if (!permissionDictionary) {
    return [];
  }
  const permissionNames: string[] = [];
  permissions.forEach((id) => {
    const permission = permissionDictionary.find((per) => per.id === id);
    if (permission) {
      permissionNames.push(permission[property]);
    }
  });
  return permissionNames;
};
export default selectPermissionsPropertyById;
