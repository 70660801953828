import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { PRIMARY_COLOR, SURFACE_COLOR, TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledProjectList = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  height: 'auto',
  padding: '24px',
  '.button-row': {
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '850px',
    margin: '0 0 15px 24px'
  },
  '.button': {
    width: '108px',
    padding: '10px',
    border: 'none',
    borderRadius: borderRadiusCommon,
    backgroundColor: SURFACE_COLOR,
    boxShadow: '2px 2px 15px rgba(64, 64, 85, 0.05)',
    marginRight: '16px',
    cursor: 'pointer',
    p: {
      margin: 0,
      alignSelf: 'center'
    }
  },
  '.button.active': {
    border: '1px solid',
    borderColor: PRIMARY_COLOR
  },

  '& .custom-textfield .MuiInputBase-root::before': {
    borderBottom: 'none !important'
  },
  '& .custom-textfield .MuiInputBase-root::after': {
    borderBottom: 'none !important'
  },
  '& .custom-textfield .MuiInputBase-root': {
    padding: '6px'
  },
  '.MuiAutocomplete-root .MuiAutocomplete-inputRoot ': {
    maxWidth: '600px',
    width: 'fit-content'
  },
  '& .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
    minWidth: '90px',
    textAlign: 'center'
  },
  '& .MuiTextField-root': {
    backgroundColor: SURFACE_COLOR,
    borderRadius: borderRadiusCommon
  },

  '& .MuiTableContainer-root': {
    padding: '24px',
    width: 'auto',
    '& table': {
      maxWidth: '1102px',
      borderRadius: '10px 0 0 10px',
      borderCollapse: 'separate',
      borderSpacing: '0 4px'
    },
    '& thead > tr > th': {
      border: 'none',
      padding: '16px 16px 4px 16px',
      p: {
        margin: 0,
        color: TEXT_COLOR
      }
    },
    '& tbody > tr': {
      boxShadow: 'none',
      backgroundColor: SURFACE_COLOR,
      '& .MuiTableCell-root': {
        border: 'none',
        cursor: 'pointer'
      },
      p: {
        color: PRIMARY_COLOR
      }
    },
    '.name-row': {
      display: 'flex',
      flexDirection: 'row',
      '.icon': {
        alignSelf: 'center'
      }
    },
    '.color': {
      width: '6px',
      height: '32px',
      borderRadius: borderRadiusCommon,
      alignSelf: 'center',
      marginRight: '12px'
    },
    '.status-icon': {
      width: '12px',
      height: '12px',
      borderRadius: '100%',
      alignSelf: 'center',
      marginRight: '12px'
    }
  },
  '.scroll-container': {
    overflowY: 'auto',
    height: 'calc(100vh - 255px)',
    overflowX: 'hidden'
  },
  '.column-button': {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    fontWeight: 'normal'
  },
  '.selected': {
    p: { fontWeight: 'bold' }
  }
});

export default StyledProjectList;
