import { API_OAUTH2_LOGIN_URL, AUTHORIZATION_ERROR_QUERY_PARAM } from 'environment/environment';
import StyledLoginPage from 'pages/LoginPage/StyledLogin';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import logo from 'shared/PrpIcon/svg/logo-black.svg';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import GenericErrorMessage from 'types/generalEnums/GenericErrorMessage';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { google, logIn } from 'shared/PrpIcon/icons.constants';
import RoutingPath from 'routes/routing';

const LoginPage = () => {
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname === RoutingPath.LogInPage &&
      location.search.includes(AUTHORIZATION_ERROR_QUERY_PARAM)
    ) {
      notifyUser(GenericErrorMessage.FaildAuthorization);
    }
  }, [location]);

  const loginTestHandler = () => {
    window.location.href = API_OAUTH2_LOGIN_URL;
  };

  return (
    <StyledLoginPage>
      <div>
        <PrpIcon src={logo} className="prpLogo" height="24px" width="102px" />
      </div>
      <div className="login-content-wrapper">
        <PrpIcon src={logIn} height="80px" width="50px" className="login-image" />
        <h1 className="inter-h2">PRP Tool Log in</h1>
        <p className="inter-p3">Use your google account to access the system.</p>
        <button type="button" onClick={loginTestHandler} className="login-button">
          <PrpIcon src={google} />
          <p className="inter-caption">Log in with Google</p>
        </button>
      </div>
    </StyledLoginPage>
  );
};

export default LoginPage;
