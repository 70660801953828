import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import addClient from 'store/action/clientActions/addClient';
import getClients from 'store/action/clientActions/getClients';
import updateClients from 'store/action/clientActions/updateClients';
import cleanSelectedEmployees from 'store/action/employeeActions/cleanSelectedEmployee';
import { ClientsState } from 'store/slicesState/clients';
import { ClientsDto } from 'types/clients/dto/ClientsDto';

const initialState: ClientsState = {
  data: [],
  readLoading: false,
  saveLoading: false,
  error: null,
  selectedClient: null
};
export type DispatchError = {
  errorMessage: string;
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    getClient: (state, action: PayloadAction<ClientsDto[]>) => {
      state.data = action.payload;
    },
    selectClient: (state, action: PayloadAction<number | null>) => {
      state.selectedClient = state.data.find((client) => client.id === action.payload) || null;
    },
    setClient: (state, action: PayloadAction<ClientsDto[]>) => {
      state.data = action.payload;
    },
    cleanSelectedClient: (state) => {
      state.selectedClient = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getClients.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getClients.fulfilled, (state, action) => {
      state.error = null;
      state.readLoading = false;
      state.data = action.payload;
    });
    builder.addCase(getClients.rejected, (state) => {
      state.error = 'getClients error in slice';
      state.readLoading = false;
    });
    builder.addCase(cleanSelectedEmployees, (state) => {
      state.selectedClient = null;
    });
    builder.addCase(updateClients.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(updateClients.fulfilled, (state, action) => {
      const updatedClient = action.payload;
      const index = state.data.findIndex((client) => client.id === updatedClient.id);
      if (index !== -1) {
        state.data[index] = updatedClient;
      }
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(updateClients.rejected, (state) => {
      state.error = 'updateClients error in slice';
      state.saveLoading = false;
    });
    builder.addCase(addClient.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(addClient.fulfilled, (state, action) => {
      state.data.push(action.payload);
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(addClient.rejected, (state) => {
      state.error = 'addClient error in slice';
      state.saveLoading = false;
    });
  }
});

export const { getClient, selectClient, cleanSelectedClient, setClient } = clientsSlice.actions;
export default clientsSlice.reducer;
