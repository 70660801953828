import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InvoiceState } from 'store/slicesState/invoice';
import { InvoiceDto } from 'types/project/dto/InvoiceDto';
import getInvoice from 'store/action/projectActions/getInvoice';

const initialState: InvoiceState = {
  data: [],
  readLoading: false,
  saveLoading: false,
  error: null
};

export type DispatchError = {
  errorMessage: string;
};

const invoiceSlice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    getInvoices: (state, action: PayloadAction<InvoiceDto[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoice.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getInvoice.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.readLoading = false;
    });
    builder.addCase(getInvoice.rejected, (state) => {
      state.readLoading = false;
    });
  }
});

export const { getInvoices } = invoiceSlice.actions;
export default invoiceSlice.reducer;
