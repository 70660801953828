import { ClientFormDto } from 'types/clients/dto/ClientFormDto';

const ClientAddFormDefaultValue: ClientFormDto = {
  id: null,
  name: '',
  contactName: '',
  contactPosition: '',
  contactPhone: '',
  contactEmail: '',
  colorHex: '',
  projects: []
};

export default ClientAddFormDefaultValue;
