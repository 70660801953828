import ButtonsText from 'types/generalEnums/ButtonsText';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { useThunkAppDispatch } from 'store/redux-hooks/hooks';
import deleteAdditionalCost from 'store/action/invoiceAction/deleteAdditionalCost';
import { FormEvent } from 'react';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import useEscapeKey from 'store/customHooks/useEscapeKey';

type DeleteAdditionalCostFormProps = {
  onClose: (isFormSubmitted: boolean) => void;
  invoiceId: number | null;
};

const DeleteAdditionalCostForm = ({ onClose, invoiceId }: DeleteAdditionalCostFormProps) => {
  const dispatch = useThunkAppDispatch();
  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await dispatch(deleteAdditionalCost(invoiceId || 0)).then(() => onClose(true));
    } catch (error) {
      logError('Error deleting additional cost');
    }
  };
  useEscapeKey(() => onClose(false));
  return (
    <StyledForm onSubmit={onSubmit}>
      <p className="inter-caption_medium delete-additional-cost-text modal-container">
        Are you sure you want to delete additional costs? This action cannot be undone.
      </p>
      <CustomModalButtons
        submitButtonText={ButtonsText.Delete}
        isCancelButtonVisible
        submitButtonClassName="add-button"
        onClick={() => onClose(false)}
        className="add-buttons-row"
      />
    </StyledForm>
  );
};

export default DeleteAdditionalCostForm;
