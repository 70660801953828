import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import getAllProjects from 'store/action/projectActions/getAllProjects';
import getProjectDetails from 'store/action/projectActions/getOneProject';
import updateProjectDetails from 'store/action/projectActions/updateProjectDetails';
import { ProjectState } from 'store/slicesState/project';
import { ProjectDto } from 'types/project/dto/ProjectDto';

const initialState: ProjectState = {
  data: [],
  readLoading: false,
  saveLoading: false,
  error: null,
  selectedProject: null
};
export type DispatchError = {
  errorMessage: string;
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    getProjects: (state, action: PayloadAction<ProjectDto[]>) => {
      state.data = action.payload;
    },
    selectProject: (state, action: PayloadAction<number | null>) => {
      state.selectedProject = state.data.find((project) => project.id === action.payload) || null;
    },
    setProject: (state, action: PayloadAction<ProjectDto[]>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAllProjects.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getAllProjects.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.readLoading = false;
    });
    builder.addCase(getAllProjects.rejected, (state) => {
      state.readLoading = false;
    });
    builder.addCase(updateProjectDetails.pending, (state) => {
      state.saveLoading = true;
      state.error = null;
    });
    builder.addCase(updateProjectDetails.fulfilled, (state, action) => {
      const updatedProject = action.payload;
      const index = state.data.findIndex((project) => project.id === updatedProject.id);
      if (index !== -1) {
        state.data[index] = updatedProject;
      }
      state.error = null;
      state.saveLoading = false;
    });
    builder.addCase(updateProjectDetails.rejected, (state) => {
      state.error = 'updateClients error in slice';
      state.saveLoading = false;
    });
    builder.addCase(getProjectDetails.pending, (state) => {
      state.readLoading = true;
      state.error = null;
    });
    builder.addCase(getProjectDetails.fulfilled, (state, action) => {
      state.selectedProject = action.payload;
      state.error = null;
      state.readLoading = false;
    });
  }
});

export const { getProjects, selectProject, setProject } = projectSlice.actions;
export default projectSlice.reducer;
