import {
  borderRadiusCommon,
  marginTopCommon
} from 'components/sharedComponents/StyleConsts/styleConsts';
import { BORDERS_COLOR, LOW_EMPHASISE_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledFormSelectController = styled.div({
  '.form-select': {
    height: '40px',
    width: '265px',
    marginTop: marginTopCommon,
    borderColor: BORDERS_COLOR,
    borderRadius: borderRadiusCommon
  },
  '& .MuiSelect-select': {
    display: 'flex',
    flexDirection: 'row'
  },
  '.modal-window-title': {
    h3: {
      margin: '0'
    }
  },
  '.label': {
    color: LOW_EMPHASISE_COLOR
  }
});

export default StyledFormSelectController;
