import {
  borderRadiusCommon,
  marginTopCommon
} from 'components/sharedComponents/StyleConsts/styleConsts';
import { DARK_GREY, LOW_EMPHASISE_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledFormInput = styled.div({
  input: {
    border: '1px solid',
    borderColor: DARK_GREY,
    borderRadius: borderRadiusCommon,
    padding: '6px',
    marginTop: marginTopCommon
  },
  '.label': { color: LOW_EMPHASISE_COLOR },
  '.input-form': {
    maxWidth: '263px'
  },
  '.input-full-form': {
    width: '100%'
  },
  '.title-with-icon': {
    display: 'flex',
    '.label': {
      marginRight: '8px'
    }
  },
  '.invoice-amount-input': {
    maxWidth: '580px'
  },
  '.invoice-additional-cost-input': {
    height: '50px',
    width: '381px'
  }
});

export default StyledFormInput;
