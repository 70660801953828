import { API_URL } from 'environment/environment';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import EmployeeRatesDto from 'types/project/dto/EmployeeRatesDto';
import AddEmployeeRateDto from 'types/project/dto/AddEmployeeRateDto';
import EditEmployeeRateFormDto from 'types/project/dto/EditEmployeeRateFormDto';
import mapToAddEmployeeRateDto from 'store/mappers/project/employeeRateMapper';
import apiRequest from './apiRequest';

const employeeRateApiService: IApiService = {
  getEmployeeRates: async (projectId: number, employeeId: number) => {
    try {
      const response = await apiRequest<EmployeeRatesDto[]>({
        method: 'GET',
        endpoint: `${API_URL}/projects/${projectId}/rates/${employeeId}`
      });
      return response;
    } catch (error) {
      logError('Error in fetchEmployeeRates');
      throw new Error('Error in fetchEmployeeRates');
    }
  },
  addEmployeeRate: async (data: EditEmployeeRateFormDto) => {
    try {
      const addEmployeeRateDto = mapToAddEmployeeRateDto(data);
      const response = await apiRequest<AddEmployeeRateDto>({
        method: 'POST',
        endpoint: `${API_URL}/projects/${addEmployeeRateDto.projectId}/rates/${addEmployeeRateDto.employeeId}`,
        requestBody: addEmployeeRateDto
      });
      return response;
    } catch (error) {
      logError('Error in addEmployeeRate');
      throw new Error('Error in addEmployeeRate');
    }
  }
};

interface IApiService {
  getEmployeeRates: (projectId: number, employeeId: number) => Promise<EmployeeRatesDto[]>;
  addEmployeeRate: (data: EditEmployeeRateFormDto) => Promise<AddEmployeeRateDto>;
}

export default employeeRateApiService;
