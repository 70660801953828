import { FormControlLabel, Checkbox } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { checkBoxEmpty, checkBox } from 'shared/PrpIcon/icons.constants';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import StyledCheckbox from './StyledCheckbox';

type FormCheckboxProps = {
  label: string;
  name: string;
  title?: string;
  disabled?: boolean;
};

const FormCheckbox = ({ label, name, title, disabled = false }: FormCheckboxProps) => {
  const { control } = useFormContext();

  return (
    <StyledCheckbox>
      <p className="inter-p3">{title}</p>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.checked);
                  }}
                  icon={<PrpIcon src={checkBoxEmpty} />}
                  checkedIcon={<PrpIcon src={checkBox} />}
                />
              }
              label={label}
              disabled={disabled}
            />
          </div>
        )}
      />
    </StyledCheckbox>
  );
};

export default FormCheckbox;
