enum AllocationFilterDashboardNames {
  ClientNames = 'clientNames',
  Clientlabel = 'Clients',
  ActiveEmployeeNames = 'activeEmployeeNames',
  EmployeeLabel = 'Employee',
  Positions = 'positions',
  PositionsLabel = 'Positions',
  ProjectNames = 'projectNames',
  ProjectLabel = 'Projects',
  CategoryLabel = 'Category',
  FilterNames = 'filterNames',
  SearchLabel = 'Search',
  AllEmployeeNames = 'allEmployeeNames'
}

export default AllocationFilterDashboardNames;
