import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import logoSmall from 'shared/PrpIcon/svg/logo-sm.svg';
import logo from 'shared/PrpIcon/svg/logo-black.svg';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import {
  clockGrey,
  clockTransperent,
  leftArrowGrey,
  lineFolderGrey,
  lineFolderTransperent,
  rightArrowGrey,
  starGrey,
  starTransperent,
  userGrey,
  userTransperent
} from 'shared/PrpIcon/icons.constants';
import { Link, useLocation } from 'react-router-dom';
import RoutingPath from 'routes/routing';
import {
  leftBarBigLinkWidth,
  leftBarSmallWidth,
  leftMenuBigWidth,
  leftMenuSmallWidth
} from 'components/sharedComponents/StyleConsts/styleConsts';
import {
  pathClientPattern,
  pathEmployeePattern,
  pathProjectPattern
} from 'constants/routingPatterns';
import PageType from 'types/generalEnums/PageType';
import { Drawer } from '@mui/material';
import StyledLeftSideNavBar from './StyledLeftSideNavBar';

type LeftSideNavBarProps = {
  setMenuLeftMargin: Dispatch<SetStateAction<string>>;
};

const LeftSideNavBar = ({ setMenuLeftMargin }: LeftSideNavBarProps): JSX.Element => {
  const [hidden, setHidden] = useState<boolean>(false);
  const [active, setActive] = useState<string>('');
  const [clientsIcon, setClientsIcon] = useState<string>(starGrey);
  const [employeesIcon, setEmployeesIcon] = useState<string>(userGrey);
  const [allocationIcon, setAllocationIcon] = useState<string>(clockGrey);
  const [projectsIcon, setProjectsIcon] = useState<string>(lineFolderGrey);
  const [navListWidth, setNavListWidth] = useState<string>(leftBarBigLinkWidth);
  const location = useLocation();

  const hideButtonClickHandler = () => {
    setHidden((prevState) => !prevState);
    setNavListWidth(hidden ? leftBarBigLinkWidth : leftBarSmallWidth);
    setMenuLeftMargin(hidden ? leftMenuBigWidth : leftMenuSmallWidth);
  };

  const handleLinkClick = (activeLink: string) => {
    setActive(activeLink);
    setClientsIcon(activeLink === PageType.Clients ? starTransperent : starGrey);
    setEmployeesIcon(activeLink === PageType.Employees ? userTransperent : userGrey);
    setAllocationIcon(activeLink === PageType.Allocation ? clockTransperent : clockGrey);
    setProjectsIcon(activeLink === PageType.Project ? lineFolderTransperent : lineFolderGrey);
  };

  useEffect(() => {
    const path = location.pathname.replace(/\/$/, '');
    // If you want to have selected page when the page has id you should remember about path pattern /client/src/constants/routingPatterns.ts
    switch (path) {
      case RoutingPath.AllocationPage:
        handleLinkClick(PageType.Allocation);
        break;
      case RoutingPath.ProjectsPage:
        handleLinkClick(PageType.Project);
        break;
      case RoutingPath.ClientsPage: // hightlight ClientNav when we on Page with ClientId
      case path.match(pathClientPattern)?.find(Boolean):
        handleLinkClick(PageType.Clients);
        break;
      case RoutingPath.EmployeesPage:
      case path.match(pathEmployeePattern)?.find(Boolean): // hightlight EnployeeNav when we on Page with EmployeeId
        handleLinkClick(PageType.Employees);
        break;
      case RoutingPath.NotFoundPage:
        handleLinkClick('');
        break;
      case RoutingPath.ProjectDetailsPage:
      case path.match(pathProjectPattern)?.find(Boolean): // hightlight ProjectNav when we on Page with ProjectId
        handleLinkClick(PageType.Project);
        break;
      default:
        break;
    }
  }, [location]);

  return (
    <Drawer variant="permanent" open={!hidden}>
      <StyledLeftSideNavBar>
        {hidden ? (
          <PrpIcon src={logoSmall} height="24px" width="32px" />
        ) : (
          <PrpIcon src={logo} height="24px" width="102px" />
        )}
        <ul className="nav-list">
          <li className={active === PageType.Allocation ? 'active-link' : ''}>
            <Link
              to={RoutingPath.AllocationPage}
              onClick={() => handleLinkClick(PageType.Allocation)}
              className="nav-link"
              style={{ width: navListWidth }}
            >
              <PrpIcon src={allocationIcon} className="bar-icon" />
              {hidden ? null : <p className="bar-link-title">Allocation</p>}
            </Link>
          </li>
          <li className={active === PageType.Project ? 'active-link' : ''}>
            <Link
              to={RoutingPath.ProjectsPage}
              onClick={() => handleLinkClick(PageType.Project)}
              className="nav-link"
              style={{ width: navListWidth }}
            >
              <PrpIcon src={projectsIcon} className="bar-icon" />
              {hidden ? null : <p className="bar-link-title">Projects</p>}
            </Link>
          </li>
          <li className={active === PageType.Clients ? 'active-link' : ''}>
            <Link
              to={RoutingPath.ClientsPage}
              className="nav-link"
              onClick={() => handleLinkClick(PageType.Clients)}
              style={{ width: navListWidth }}
            >
              <PrpIcon src={clientsIcon} className="bar-icon" />
              {hidden ? null : <p className="bar-link-title">Clients</p>}
            </Link>
          </li>
          <li className={active === PageType.Employees ? 'active-link' : ''}>
            <Link
              to={RoutingPath.EmployeesPage}
              className="nav-link"
              onClick={() => handleLinkClick(PageType.Employees)}
              style={{ width: navListWidth }}
            >
              <PrpIcon src={employeesIcon} className="bar-icon" />
              {hidden ? null : <p className="bar-link-title">Employees</p>}
            </Link>
          </li>
        </ul>
        <div className="hide-button-wrapper">
          <button className="hide-button" type="button" onClick={hideButtonClickHandler}>
            {hidden ? (
              <PrpIcon src={leftArrowGrey} />
            ) : (
              <PrpIcon src={rightArrowGrey} className="hide-button-icon" />
            )}
            {hidden ? null : 'Hide'}
          </button>
        </div>
      </StyledLeftSideNavBar>
    </Drawer>
  );
};

export default LeftSideNavBar;
