import apiRequest from 'api/apiRequest';
import { API_URL } from 'environment/environment';
import objectToQueryString from 'helpers/objectToQueryString';
import { ApiError, logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import mapToBookingDto from 'store/mappers/allocations/allocationsBookingMapper';
import { AllocationsAddBookingDto } from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingDto';
import AllocationsAddBookingFormDto from 'types/allocation/dto/allocationAddBookingDto/AllocationsAddBookingFormDto';
import { AllocationsDeleteBookingDto } from 'types/allocation/dto/allocationDeleteBookingDto/AllocationsDeleteBookingDto';
import AllocationsDeleteBookingFormDto from 'types/allocation/dto/allocationDeleteBookingDto/AllocationsDeleteBookingFormDto';

const allocationsBookingApiService: IApiService = {
  getBooking: async (id: number) => {
    try {
      const response = await apiRequest<AllocationsAddBookingDto>({
        method: 'GET',
        endpoint: `${API_URL}/allocations/booking/${id}`
      });
      return response;
    } catch (error) {
      logError('Error in fetchBooking');
      throw new Error('Error in fetchBooking');
    }
  },
  addBooking: async (data: AllocationsAddBookingFormDto, force?: boolean) => {
    const addBookingDto = mapToBookingDto(data);
    try {
      const response = await apiRequest<AllocationsAddBookingDto>({
        method: 'POST',
        endpoint: `${API_URL}/allocations/booking?${objectToQueryString({ force })}`,
        requestBody: addBookingDto
      });
      return response;
    } catch (error) {
      if (error instanceof ApiError) throw new ApiError(error);

      logError('Error in creating booking');
      throw new Error('Error in addBooking');
    }
  },
  updateBooking: async (data: AllocationsAddBookingFormDto, force?: boolean) => {
    const result = mapToBookingDto(data);
    try {
      const response = await apiRequest<AllocationsAddBookingDto>({
        method: 'PUT',
        endpoint: `${API_URL}/allocations/booking/${data.id}?${objectToQueryString({ force })}`,
        requestBody: result
      });
      return response;
    } catch (error) {
      if (error instanceof ApiError) throw new ApiError(error);

      logError('Error in updateBooking');
      throw new Error('Error in updateBooking');
    }
  },
  deleteBooking: async (data: AllocationsDeleteBookingFormDto) => {
    try {
      const response = await apiRequest<AllocationsDeleteBookingDto>({
        method: 'DELETE',
        endpoint: `${API_URL}/allocations/booking/${data.bookingId}?deleteFutureBookings=${data.deleteFutureBookings}`
      });
      return response;
    } catch (error) {
      logError('Error in deleteBooking');
      throw new Error('Error in deleteBooking');
    }
  }
};

interface IApiService {
  getBooking: (id: number) => Promise<AllocationsAddBookingDto>;
  addBooking: (
    data: AllocationsAddBookingFormDto,
    force?: boolean
  ) => Promise<AllocationsAddBookingDto>;
  updateBooking: (
    data: AllocationsAddBookingFormDto,
    force?: boolean
  ) => Promise<AllocationsAddBookingDto>;
  deleteBooking: (data: AllocationsDeleteBookingFormDto) => Promise<AllocationsDeleteBookingDto>;
}

export default allocationsBookingApiService;
