enum ClientFormComponentNames {
  ContactName = 'contactName',
  ContactNameLabel = 'Name',
  ContactPosition = 'contactPosition',
  ContactPositionLabel = 'Position',
  ContactPhone = 'contactPhone',
  ContactPhoneLabel = 'Phone',
  ContactEmail = 'contactEmail',
  ContactEmailLabel = 'Email',
  Content = 'content',
  ContentLabel = 'Add notes',
  ColorHexLabel = 'Color',
  ColorHexName = 'colorHex',
  Name = 'name',
  NameLabel = 'Name'
}

export default ClientFormComponentNames;
