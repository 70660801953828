import StyledDownTimeErrorPage from 'pages/DownTimeErrorPage/StyledDownTimeErrorPage';
import PrpIcon from 'shared/PrpIcon/PrpIcon';

import logo from 'shared/PrpIcon/svg/logo-black.svg';

const DownTimeErrorPage = () => {
  return (
    <StyledDownTimeErrorPage>
      <div className="content-wrapper">
        <PrpIcon src={logo} height="80px" width="220px" className="login-image" />
        <p className="inter-p3">
          The application is down outside of work hours. Please contact the administrator if you
          need to access it or try to access it during the work hours.
        </p>
      </div>
    </StyledDownTimeErrorPage>
  );
};

export default DownTimeErrorPage;
