import TotalsTableBorderType from 'types/PrpTable/enums/TotalsTableBorderType';

const isBorderVisible = (
  cellIndex: number,
  shouldRenderBorders: boolean,
  borderType: TotalsTableBorderType,
  summedNumberOfWeeks: number
) => {
  const doesUserWantToSeeBorders = shouldRenderBorders && cellIndex !== 0;
  if (borderType === TotalsTableBorderType.BillingPeriod) {
    return doesUserWantToSeeBorders;
  }
  return cellIndex === summedNumberOfWeeks;
};

export default isBorderVisible;
