import { ProjectDictionariesState } from 'store/slicesState/projectDictionaries';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';

const selectBillingPeriodTypesPropertyById = (
  state: ProjectDictionariesState,
  id: number,
  property: DictionariesSelector.Name | DictionariesSelector.DisplayName
): string | null => {
  const billingPeriodTypesDictionary = state.data?.billingPeriodTypes;
  if (!billingPeriodTypesDictionary) {
    return null;
  }
  const billingPeriodType = billingPeriodTypesDictionary.find((b) => b.id === Number(id));
  return billingPeriodType?.[property] ?? null;
};

export default selectBillingPeriodTypesPropertyById;
