import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import getUser from 'store/action/authorizationAction/getUsers';
import logOut from 'store/action/authorizationAction/logOut';
import { AuthState } from 'store/slicesState/auth';
import { AuthorizationDto } from 'types/authorization/AuthorizationsDto';

const initialState: AuthState = {
  isAuthenticated: false,
  isFetching: false,
  user: null,
  error: null
};

export type DispatchError = {
  errorMessage: string;
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    fetchOneUser: (state, action: PayloadAction<AuthorizationDto>) => {
      state.user = action.payload;
    },
    logOutUser: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(logOut.pending, (state) => {
      state.isFetching = true;
      state.error = null;
    });
    builder.addCase(logOut.fulfilled, (state) => {
      state.isFetching = false;
      state.isAuthenticated = false;
      state.user = null;
    });
    builder.addCase(logOut.rejected, (state) => {
      state.isFetching = false;
      state.error = 'logOut error in slice';
    });
    builder.addCase(getUser.pending, (state) => {
      state.isFetching = true;
      state.error = null;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.isFetching = false;
      state.user = action.payload;
    });
    builder.addCase(getUser.rejected, (state) => {
      state.isFetching = false;
      state.error = 'getUser error in slice';
    });
  }
});

export default authSlice.reducer;

export const { fetchOneUser, logOutUser } = authSlice.actions;
