import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import RoutingPath from 'routes/routing';
import { selectClient } from 'store/slices/clients/clientsSlice';
import { ClientsDto } from 'types/clients/dto/ClientsDto';
import { useAppDispatch } from 'store/redux-hooks/hooks';
import StyledItemCard from 'components/sharedComponents/SharedItemCardStyle/StyledItemCard';

type ClientsCardProps = {
  color?: string;
  isSelected?: boolean;
  item: ClientsDto;
  itemId: number;
};

const ClientsCard = ({ color, isSelected, itemId, item }: ClientsCardProps): JSX.Element => {
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const cardClickHandler = useCallback(() => {
    navigation(`${RoutingPath.ClientsPage}/${itemId}`);
    dispatch(selectClient(itemId));
  }, [navigation]);

  return (
    <StyledItemCard onClick={cardClickHandler} className={`card ${isSelected ? 'selected' : ''}`}>
      <div className="color-block" style={{ backgroundColor: `${color}` }} />
      <div className="info-box client-info-box">
        <div style={{ backgroundColor: item.colorHex }} className="client-color" />
        <h3 className="inter-p3 info-box_text client-name">{item?.name}</h3>
      </div>
    </StyledItemCard>
  );
};

export default ClientsCard;
