import { createAsyncThunk } from '@reduxjs/toolkit';
import dictionariesApiService from 'api/dictionaryApiService';
import { notifyUser } from 'shared/errorHandling/ErrorToast/errorHandling';
import { AppDispatch, RootState } from 'store';
import getDictionariesWithKeys from 'store/mappers/dictionaries/dictionariesMapper';
import { DispatchError } from 'store/slices/employee/employeeSlice';
import { EmployeeDictionariesDto } from 'types/employee/dto/EmployeeDictionariesDto';
import DictionariesAction from 'types/employee/enums/Dictionaries';

const getDictionaries = createAsyncThunk<
  EmployeeDictionariesDto,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: DispatchError;
  }
>(DictionariesAction.FETCH_ALL, async () => {
  const response = await dictionariesApiService.fetchDictionaries();
  if (!response) notifyUser();
  return getDictionariesWithKeys(response);
});

export default getDictionaries;
