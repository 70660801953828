import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import getProjectTotals from 'store/action/projectActions/getProjectTotals';
import { ProjectTotalsState } from 'store/slicesState/projectTotalsState';
import { ProjectTotalsDto } from 'types/PrpTable/dto/ProjectTableDto';

const initialState: ProjectTotalsState = {
  data: {
    weekTotals: [],
    weekTotalsDevelopersOnly: [],
    billingPeriodTotals: [],
    billingPeriodTotalsDevelopersOnly: []
  },
  loading: false,
  error: null
};
export type DispatchError = {
  errorMessage: string;
};

const projectTotalsSlice = createSlice({
  name: 'projectTotals',
  initialState,
  reducers: {
    getProjectsTotals: (state, action: PayloadAction<ProjectTotalsDto>) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProjectTotals.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getProjectTotals.fulfilled, (state, action) => {
      state.data = action.payload;
      state.error = null;
      state.loading = false;
    });
    builder.addCase(getProjectTotals.rejected, (state) => {
      state.loading = false;
    });
  }
});

export const { getProjectsTotals } = projectTotalsSlice.actions;
export default projectTotalsSlice.reducer;
