import { addKeyToReactItem } from 'store/mappers/sharedMapper';
import AllocationsDictionariesDto from 'types/allocation/dto/AllocationsDictionariesDto';

const getAllocationsDictionariesWithKeys = (
  allocationsDictionaries: AllocationsDictionariesDto
): AllocationsDictionariesDto => {
  return {
    ...allocationsDictionaries,
    key: crypto.randomUUID(),
    activeEmployeeNames: allocationsDictionaries.activeEmployeeNames?.map((activeEmployeeName) =>
      addKeyToReactItem(activeEmployeeName)
    ),
    bookableEmployeeNames: allocationsDictionaries.bookableEmployeeNames?.map(
      (bookableEmployeeName) => addKeyToReactItem(bookableEmployeeName)
    ),
    timeOffTypes: allocationsDictionaries.timeOffTypes?.map((timeOffType) =>
      addKeyToReactItem(timeOffType)
    ),
    employeeWeeklyCapacities: allocationsDictionaries.employeeWeeklyCapacities?.map((capacities) =>
      addKeyToReactItem(capacities)
    ),
    clientNames: allocationsDictionaries.clientNames?.map((clientName) =>
      addKeyToReactItem(clientName)
    ),
    projectNames: allocationsDictionaries.projectNames?.map((projectName) =>
      addKeyToReactItem(projectName)
    ),
    positions: allocationsDictionaries.positions?.map((position) => addKeyToReactItem(position)),
    filterNames: allocationsDictionaries.filterNames?.map((filter) => addKeyToReactItem(filter)),
    allEmployeeNames: allocationsDictionaries.allEmployeeNames?.map((employeeName) =>
      addKeyToReactItem(employeeName)
    )
  };
};
export default getAllocationsDictionariesWithKeys;
