import { unwrapResult } from '@reduxjs/toolkit';
import TopPanel from 'components/sharedComponents/TopPanel/TopPanel';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { RootState } from 'store';
import getAllAllocations from 'store/action/allocationsAction/getAllAllocations';
import { useAppSelector, useThunkAppDispatch } from 'store/redux-hooks/hooks';
import PageType from 'types/generalEnums/PageType';
import PermissionsName from 'types/generalEnums/PermissionsName';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import {
  CalendarNav,
  CalendarNext,
  CalendarPrev,
  CalendarToday,
  Eventcalendar,
  MbscCalendarEvent,
  MbscCalendarEventData
} from '@mobiscroll/react';
import AllocationEmployeesDetails from 'components/allocations/AllocationEmployeesDetails/AllocationeEmployeesDetails';
import { ModalContext, ModalContextType } from 'modals/ModalProvider';
import {
  allocationDeleteModalTop,
  allocationDeleteModalWidth,
  allocationDeleteTimeOffModalWidth,
  allocationModalWindowHeight,
  allocationModalWindowWidth,
  allocationModalWindowWithTabsWidth,
  allocationPublicHolidayModalWidth,
  deleteBookingModalHeight,
  publicHolidayModalWindowHeight
} from 'components/sharedComponents/StyleConsts/styleConsts';
import ModalWindow from 'modals/ModalWindow';
import AllocationFilterDashboard from 'components/allocations/AllocationFilterDashboard/AllocationFilterDashboard';
import getCalendarsDictionaries from 'store/action/calendarAction/getCalendarsDictionaries';
import AllocationScheduleEvent from 'components/allocations/AllocationScheduleEvents/AllocationScheduleEvent';
import defaultFilteringValue from 'components/allocations/consts/defaultFilteringValue';
import allocationDataToResourcesTransformer, {
  ResourcesData
} from 'pages/AllocationsPage/helpers/allocationDataToResourcesTransformer';
import allocationFormatDate from 'pages/AllocationsPage/helpers/allocationFormatDate';
import {
  getFirstDayOfMonth,
  getLastDayOfMonth,
  makeDecemberLastMonthInMaxYear
} from 'helpers/dateUtils';
import allocationDataToEventsTransformer from 'pages/AllocationsPage/helpers/allocationDataToEventsTransformer';
import AllocationEventcalendarHeader from 'components/allocations/AllocationEventcalendarHeader/AllocationEventcalendarHeader';
import AllocationComponentsTitles from 'types/allocation/enums/AllocationComponentsTitles';
import AllocationEventsTypes from 'types/allocation/enums/AllocationEventsTypes';
import AllocationPublicHolidayEditForm from 'components/allocations/AllocationPublicHoliday/AllocationPublicHolidayEditForm/AllocationPublicHolidayEditForm';
import {
  extractAllocationEventTypeFromCssClass,
  extractDbIdFromCssClass
} from 'pages/AllocationsPage/helpers/allocationCssClassFunctions';
import AllocationEditBookingForm from 'components/allocations/AllocationBooking/AllocationEditBookingForm/AllocationEditBookingForm';
import getOneBooking from 'store/action/allocationsAction/getBooking';
import AllocationTimeOffEditForm from 'components/allocations/AllocationTimeOff/AllocationTimeOffEditForm/AllocationTimeOffEditForm';
import AllocationPublicHolidayAddForm from 'components/allocations/AllocationPublicHoliday/AllocationPublicHolidayAddForm/AllocationPublicHolidayAddForm';
import AllocationTimeOffDeleteForm from 'components/allocations/AllocationTimeOff/AllocationTimeOffDeleteForm/AllocationTimeOffDeleteForm';
import { getEndDate, getStartDate } from 'store/slices/allocations/pageDateSlice';
import AllocationModalWindowWithTabs from 'components/allocations/AllocationModalWindowWithTabs/AllocationModalWindowWithTabs';
import getOneTimeOff from 'store/action/allocationsAction/getTimeOff';
import getOnePublicHoliday from 'store/action/allocationsAction/getPublicHoliday';
import AllocationBookingDeleteForm from 'components/allocations/AllocationBooking/AllocationBookingDeleteForm/AllocationBookingDeleteForm';
import AllocationDeletePublicHolidayForm from 'components/allocations/AllocationPublicHoliday/AllocationDeletePublicHoliday/AllocationDeletePublicHolidayForm';
import getAllocationsDictionaries from 'store/action/allocationsAction/getAllocationsDictionaries';
import PrpIcon from 'shared/PrpIcon/PrpIcon';
import { iconCloseX } from 'shared/PrpIcon/icons.constants';
import { useSearchParams } from 'react-router-dom';
import AllocationParamsDto from 'types/allocation/dto/AllocationParamsDto';
import AllocationFilterSelectedName from 'types/allocation/enums/AllocationFilterSelectedName';
import AllocationFilterDashboardNames from 'types/allocation/enums/AllocationFilterDashboardNames';
import { DictionaryItemDto } from 'types/employee/enums/DictionaryItemDto';
import getEmployees from 'store/action/employeeActions/getEmployee';
import getClients from 'store/action/clientActions/getClients';
import getAllProjects from 'store/action/projectActions/getAllProjects';
import MainSpinner from 'components/sharedComponents/Spinner/MainSpinner';
import getDictionaries from 'store/action/employeeActions/getDictionaries';

const AllocationsPage = () => {
  const allocationsManagementPermissions = [
    PermissionsName.ManagerAllocations,
    PermissionsName.AssignedToProjects
  ];
  const [allocationPageParams, setAllocationPageParams] = useState<AllocationParamsDto | null>(
    null
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const filterName: string = searchParams.get('filterType') || '';
  const selectedStartDate = searchParams.get('startDate');
  const selectedEndDate = searchParams.get('endDate');
  const values: string[] = searchParams?.get('value')?.split(',') ?? [];
  const [events, setEvents] = useState<MbscCalendarEvent[]>([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [isAddHolidayModalOpen, setIsAddHolidayModalOpen] = useState(false);
  const [isHolidayModalOpen, setIsHolidayModalOpen] = useState(false);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [isTimeOffModalOpen, setIsTimeOffModalOpen] = useState(false);
  const [isBookingOrTimeOffAddedByButton, setIsBookingOrTimeOffAddedByButton] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [onCellClickWindowOpen, setOnCellClickWindowOpen] = useState(false);
  const { showModal } = useContext(ModalContext) as ModalContextType;
  const dispatch = useThunkAppDispatch();
  const pageStartDateRef = useRef<string>(allocationFormatDate(getFirstDayOfMonth(), true));
  const pageEndDateRef = useRef<string>(allocationFormatDate(getLastDayOfMonth(), false));
  const allocationsState = useAppSelector((state: RootState) => state.allocations.data);
  const allocationsStateLoading = useAppSelector(
    (state: RootState) => state.allocations.readLoading
  );
  const allocationDictionariesState = useAppSelector(
    (state: RootState) => state.allocationsDictionaries.data
  );
  const allocationDictionariesStateLoading = useAppSelector(
    (state: RootState) => state.allocationsDictionaries.loading
  );
  const stateEmployee = useAppSelector((state: RootState) => state.dictionaries);
  const employeeState = useAppSelector((state: RootState) => state.employees);
  const stateEmployeeLoading = useAppSelector((state: RootState) => state.dictionaries.loading);
  const loadingEmployee = useAppSelector((state: RootState) => state.employees.saveLoading);
  const loadingClients = useAppSelector((state: RootState) => state.clients.saveLoading);
  const calendarState = useAppSelector((state: RootState) => state.calendarDictionaries.data);
  const calendarStateLoading = useAppSelector(
    (state: RootState) => state.calendarDictionaries.loading
  );
  const [selectedFilterCategory, setSelectedFilterCategory] = useState('');
  const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showFiltersSpinner, setShowFiltersSpinner] = useState(true);
  const showMainAllocationSpinner = useMemo(
    () =>
      allocationDictionariesStateLoading ||
      allocationsStateLoading ||
      stateEmployeeLoading ||
      loadingEmployee ||
      loadingClients ||
      calendarStateLoading ||
      showFiltersSpinner,
    [
      allocationDictionariesStateLoading,
      allocationsStateLoading,
      stateEmployeeLoading,
      loadingEmployee,
      loadingClients,
      calendarStateLoading,
      showFiltersSpinner
    ]
  );
  useEffect(() => {
    dispatch(getEmployees())
      .then(unwrapResult)
      .catch(() => logError('Error fetching employees'));
    dispatch(getClients())
      .then(unwrapResult)
      .catch(() => {
        logError('Error fetching clients');
      });
    dispatch(getAllProjects())
      .then(unwrapResult)
      .catch(() => logError('Error fetching projects'));
    dispatch(getCalendarsDictionaries())
      .then(unwrapResult)
      .catch(() => logError('Error fetching calendar dictionaries'));
    dispatch(getAllocationsDictionaries())
      .then(unwrapResult)
      .catch(() => logError('Error fetching allocationDictionaries'));
    dispatch(getDictionaries())
      .then(unwrapResult)
      .catch(() => logError('Error fetching dictionaries'));
  }, []);

  const getFilterNumber = (selected: string): number => {
    const labels = {
      [AllocationFilterDashboardNames.EmployeeLabel]: AllocationFilterSelectedName.Employees,
      [AllocationFilterDashboardNames.Clientlabel]: AllocationFilterSelectedName.Clients,
      [AllocationFilterDashboardNames.ProjectLabel]: AllocationFilterSelectedName.Projects,
      [AllocationFilterDashboardNames.PositionsLabel]: AllocationFilterSelectedName.Positions
    };
    return Number((labels as Record<string, AllocationFilterSelectedName>)[selected]);
  };

  const validateFiltersInUrl = (filterType: string, selectedValues: string[]): boolean => {
    const allowedTypes = ['Employee', 'Positions', 'Projects', 'Clients'];
    const allowedValues = [
      ...allocationDictionariesState[AllocationFilterDashboardNames.AllEmployeeNames].map(
        (allocation) => allocation.name
      ),
      ...allocationDictionariesState[AllocationFilterDashboardNames.ClientNames].map(
        (allocation) => allocation.name
      ),
      ...allocationDictionariesState[AllocationFilterDashboardNames.ProjectNames].map(
        (allocation) => allocation.name
      ),
      ...allocationDictionariesState[AllocationFilterDashboardNames.Positions].map(
        (allocation) => allocation.name
      )
    ];
    const isTypeCorrect = allowedTypes.includes(filterType);
    const anyValueNotInArray =
      !selectedValues || selectedValues.some((value) => !allowedValues.includes(value));

    return !anyValueNotInArray && isTypeCorrect;
  };
  const setFilterCategory = (filterCategory: string) => {
    setSelectedFilterCategory(filterCategory);
  };
  const setFilterCount = (filterCount: number) => {
    setSelectedFiltersCount(filterCount);
  };

  const onClearFiltersClick = () => {
    setSearchParams({});
    setFilterCategory('');
    setFilterCount(0);
  };

  const onFinishSubmittingFiltersForm = (
    filterType: string,
    selectedValues: DictionaryItemDto[],
    startDate: string,
    endDate: string
  ) => {
    if (
      validateFiltersInUrl(
        filterType,
        selectedValues.map((v) => v.name)
      )
    ) {
      setSearchParams({
        filterType,
        value: selectedValues.map((value) => value.name).join(','),
        startDate,
        endDate
      });
      setAllocationPageParams({
        startDate,
        endDate,
        filter: getFilterNumber(filterType),
        ids: selectedValues.map((value) => value.id)
      });
    } else {
      onClearFiltersClick();
    }
  };

  const [currentMonth, setCurrentMonth] = useState<number | null>(null);

  useEffect(() => {
    const event = allocationDataToEventsTransformer(allocationsState.employeeAllocations);
    setEvents(event);
  }, [allocationsState]);

  const handelFilteModalOpen = () => {
    setIsFilterModalOpen(true);
  };

  useEffect(() => {
    showModal(isFilterModalOpen);
  }, [isFilterModalOpen, showModal]);

  const handelFilterModalClose = () => {
    setIsFilterModalOpen(false);
  };
  const minYear = calendarState?.minDate ? new Date(calendarState.minDate) : new Date();
  const maxYear = calendarState?.maxDate ? new Date(calendarState.maxDate) : new Date();

  const handelCalendarDateClick = (selectDate: string) => {
    setIsAddHolidayModalOpen(true);
    setSelectedDate(selectDate);
  };

  useEffect(() => {
    showModal(
      isAddHolidayModalOpen || isBookingModalOpen || isTimeOffModalOpen || onCellClickWindowOpen
    );
  }, [
    isAddHolidayModalOpen,
    isBookingModalOpen,
    isTimeOffModalOpen,
    onCellClickWindowOpen,
    showModal
  ]);

  const handleEventCalendarModalsClose = () => {
    setIsBookingModalOpen(false);
    setIsHolidayModalOpen(false);
    setIsTimeOffModalOpen(false);
    setIsAddHolidayModalOpen(false);
    setOnCellClickWindowOpen(false);
  };

  const handleDeleteButtonClick = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteModalClose = (isSubmitted: boolean) => {
    setShowDeleteModal(false);
    if (isSubmitted) {
      handleEventCalendarModalsClose();
    }
  };

  const [clickCellName, setclickCellName] = useState<number>();
  const [clickCellDate, setClickCellDate] = useState<Date>();

  const selectedIds = useMemo((): number[] => {
    if (getFilterNumber(filterName) === AllocationFilterSelectedName.Employees) {
      const employeeIds = allocationDictionariesState[
        AllocationFilterDashboardNames.AllEmployeeNames
      ]
        .filter((employee) => values.includes(employee.name))
        .map((employee) => employee.id);
      return employeeIds ?? [];
    }
    if (getFilterNumber(filterName) === AllocationFilterSelectedName.Clients) {
      const clientIds = allocationDictionariesState[AllocationFilterDashboardNames.ClientNames]
        .filter((client) => values.includes(client.name))
        .map((client) => client.id);
      return clientIds ?? [];
    }
    if (getFilterNumber(filterName) === AllocationFilterSelectedName.Projects) {
      const projectIds = allocationDictionariesState[AllocationFilterDashboardNames.ProjectNames]
        .filter((project) => values.includes(project.name))
        .map((project) => project.id);
      return projectIds ?? [];
    }
    if (getFilterNumber(filterName) === AllocationFilterSelectedName.Positions) {
      const positionIds = allocationDictionariesState[AllocationFilterDashboardNames.Positions]
        .filter((position) => values.includes(position.name))
        .map((position) => position.id);
      return positionIds ?? [];
    }
    return [];
  }, [allocationDictionariesState, values, filterName]);

  useEffect(() => {
    if (searchParams.size !== 0 && validateFiltersInUrl(filterName, values)) {
      setAllocationPageParams({
        startDate: selectedStartDate || '',
        endDate: selectedEndDate || '',
        filter: getFilterNumber(filterName),
        ids: selectedIds
      });
      dispatch(
        getAllAllocations({
          startDate: selectedStartDate || '',
          endDate: selectedEndDate || '',
          filter: getFilterNumber(filterName),
          ids: selectedIds
        })
      ).then(() => setShowFiltersSpinner(false));
      setSelectedFilterCategory(filterName);
      setSelectedFiltersCount(selectedIds.length);
    } else {
      const currentMonthParams = {
        startDate: allocationFormatDate(getFirstDayOfMonth(), true),
        endDate: allocationFormatDate(getLastDayOfMonth(), false),
        filter: defaultFilteringValue.id
      };
      dispatch(getAllAllocations(currentMonthParams)).then(() => {
        if (filterName === '' || !validateFiltersInUrl(filterName, values)) {
          setShowFiltersSpinner(false);
        }
      });
      setAllocationPageParams(currentMonthParams);
    }
  }, [
    searchParams,
    allocationDictionariesState,
    isBookingModalOpen,
    isHolidayModalOpen,
    isTimeOffModalOpen,
    isAddHolidayModalOpen
  ]);

  useEffect(() => {
    setCurrentMonth(new Date(pageStartDateRef.current).getMonth() + 1);
  }, [pageStartDateRef.current]);

  return (
    <div className="main-section allocation-mainscreen">
      <TopPanel
        title="Allocations"
        addButtonTitle="Add allocation"
        type={PageType.Allocation}
        showAddButtonPermissions={allocationsManagementPermissions}
        isBookingModalOpen={isBookingModalOpen}
        isTimeOffModalOpen={isTimeOffModalOpen}
        setIsBookingModalOpen={setIsBookingModalOpen}
        setIsTimeOffModalOpen={setIsTimeOffModalOpen}
        setIsBookingOrTimeOffAddedByButton={setIsBookingOrTimeOffAddedByButton}
        onCellClickWindowOpen={onCellClickWindowOpen}
      />
      <MainSpinner loading={showMainAllocationSpinner} className="allocation-section-spinner" />
      <div className="content-section">
        <div className="allocation-section">
          <div className="filter-buttons">
            <button type="button" className="allocations-button" onClick={handelFilteModalOpen}>
              {selectedFiltersCount === 0 ? (
                <p className="inter-p3"> Filter</p>
              ) : (
                <p className="inter-p3">{`${selectedFilterCategory} (${selectedFiltersCount})`}</p>
              )}
            </button>
            {selectedFiltersCount !== 0 && (
              <button type="button" className="clear-filters-button" onClick={onClearFiltersClick}>
                <PrpIcon src={iconCloseX} height="30" width="30" />
                <p className="inter-caption_medium">Clear the filter</p>
              </button>
            )}
          </div>
          {isFilterModalOpen ? (
            <ModalWindow
              onClose={handelFilterModalClose}
              headerTitle={AllocationComponentsTitles.AllocationFilterDashboarTitle}
              style={{ width: allocationModalWindowWidth, height: allocationModalWindowHeight }}
              className="allocation-filtering-popup"
            >
              <AllocationFilterDashboard
                onClose={handelFilterModalClose}
                firstDaySelectedMonth={pageStartDateRef}
                lastDaySelectedMonth={pageEndDateRef}
                filterParams={allocationPageParams}
                setFilterCategory={setFilterCategory}
                setFilterCount={setFilterCount}
                onFinishSubmittingFiltersForm={onFinishSubmittingFiltersForm}
              />
            </ModalWindow>
          ) : null}
          <Eventcalendar
            theme="windows"
            themeVariant="light"
            view={{
              timeline: {
                type: 'month',
                size: 1,
                startDay: 1,
                endDay: 5,
                resolutionHorizontal: 'day',
                eventList: true,
                weekNumbers: true
              }
            }}
            height="fit-content"
            min={minYear}
            max={makeDecemberLastMonthInMaxYear(maxYear)}
            className="mbsc-eventcalendar"
            resources={allocationDataToResourcesTransformer(
              allocationsState,
              allocationDictionariesState,
              employeeState.data,
              stateEmployee
            )}
            // List of Employees
            renderResource={(resource: ResourcesData) => (
              <AllocationEmployeesDetails resource={resource} />
            )}
            data={events}
            // List of Events
            renderScheduleEvent={(data: MbscCalendarEventData) => (
              <AllocationScheduleEvent original={data.original} currentMonth={currentMonth} />
            )}
            // Calendar view and click to create public holiday
            renderDay={(calendarDate) => (
              <AllocationEventcalendarHeader
                calendarDate={calendarDate}
                handleDateClick={handelCalendarDateClick}
              />
            )}
            renderHeader={() => {
              return (
                <>
                  <div className="calendar-header">
                    <CalendarPrev className="md-header-filter-prev arrow-calendar-button" />
                    <CalendarNav className="md-header-filter-nav" />
                    <CalendarNext className="md-header-filter-next arrow-calendar-button" />
                  </div>
                  <CalendarToday className="md-header-filter-today" />
                </>
              );
            }}
            // Click on arrow in the calendar
            onPageChange={(pageChange) => {
              const pageStartDate = allocationFormatDate(pageChange.firstDay, true);
              const pageEndDate = allocationFormatDate(pageChange.lastDay, false);
              pageStartDateRef.current = pageStartDate;
              pageEndDateRef.current = pageEndDate;
              dispatch(getStartDate(pageStartDate));
              dispatch(getEndDate(pageEndDate));

              if (!allocationPageParams) {
                const allocationsParams = {
                  startDate: pageStartDate,
                  endDate: pageEndDate,
                  filter: defaultFilteringValue.id
                };
                dispatch(getAllAllocations(allocationsParams));
              } else {
                const updatedAllocationParams = {
                  ...allocationPageParams,
                  startDate: pageStartDate,
                  endDate: pageEndDate
                };
                dispatch(getAllAllocations(updatedAllocationParams));
              }
            }}
            // Click on event for editing
            onEventClick={(event) => {
              const type = extractAllocationEventTypeFromCssClass(event.event.cssClass as string);
              if (type === AllocationEventsTypes.Holiday) {
                dispatch(
                  getOnePublicHoliday(
                    extractDbIdFromCssClass(event.event.cssClass ? event.event.cssClass : '')
                  )
                );
                setIsHolidayModalOpen(true);
              }
              if (type === AllocationEventsTypes.Booking) {
                dispatch(
                  getOneBooking(
                    extractDbIdFromCssClass(event.event.cssClass ? event.event.cssClass : '')
                  )
                );
                setIsBookingModalOpen(true);
              }
              if (type === AllocationEventsTypes.TimeOff) {
                dispatch(
                  getOneTimeOff(
                    extractDbIdFromCssClass(event.event.cssClass ? event.event.cssClass : '')
                  )
                );
                setIsTimeOffModalOpen(true);
              }
            }}
            // Click on cell to Add Booking/Time Off
            onCellClick={(event) => {
              setOnCellClickWindowOpen(true);
              setclickCellName(event.resource as number);
              setClickCellDate(event.date);
            }}
          />
          {isAddHolidayModalOpen ? (
            <ModalWindow
              onClose={handleEventCalendarModalsClose}
              headerTitle={AllocationComponentsTitles.AllocationPublicHolidayPopUpText}
              style={{
                width: allocationPublicHolidayModalWidth,
                minHeight: publicHolidayModalWindowHeight
              }}
            >
              <AllocationPublicHolidayAddForm
                selectedDate={selectedDate}
                onClose={handleEventCalendarModalsClose}
              />
            </ModalWindow>
          ) : null}

          <div>
            {isHolidayModalOpen ? (
              <div>
                <ModalWindow
                  headerTitle={AllocationComponentsTitles.AllocationPublicHolidayEditWindow}
                  style={{
                    width: allocationPublicHolidayModalWidth,
                    height: publicHolidayModalWindowHeight
                  }}
                  onMore={handleDeleteButtonClick}
                  onClose={handleEventCalendarModalsClose}
                >
                  <AllocationPublicHolidayEditForm
                    onClose={handleEventCalendarModalsClose}
                    showDeleteModal={showDeleteModal}
                  />
                </ModalWindow>
                {showDeleteModal ? (
                  <div className="delete-modal">
                    <ModalWindow
                      headerTitle={AllocationComponentsTitles.AllocationDeletePublicHolidayWindow}
                      style={{
                        width: allocationDeleteModalWidth,
                        height: deleteBookingModalHeight
                      }}
                      onClose={() => handleDeleteModalClose(false)}
                    >
                      <AllocationDeletePublicHolidayForm
                        onCloseDeleteWindow={handleDeleteModalClose}
                      />
                    </ModalWindow>
                  </div>
                ) : null}
              </div>
            ) : null}

            {isBookingModalOpen && !onCellClickWindowOpen && !isBookingOrTimeOffAddedByButton ? (
              <div>
                <ModalWindow
                  headerTitle={AllocationComponentsTitles.AllocationBookingEditWindow}
                  style={{ width: allocationModalWindowWidth }}
                  onMore={handleDeleteButtonClick}
                  onClose={handleEventCalendarModalsClose}
                >
                  <AllocationEditBookingForm
                    onClose={handleEventCalendarModalsClose}
                    showDeleteModal={showDeleteModal}
                  />
                </ModalWindow>
                {showDeleteModal ? (
                  <div className="delete-modal">
                    <ModalWindow
                      headerTitle={AllocationComponentsTitles.AllocationDeleteBookingWindow}
                      style={{
                        width: allocationDeleteModalWidth,
                        top: allocationDeleteModalTop,
                        height: deleteBookingModalHeight
                      }}
                      onClose={() => handleDeleteModalClose(false)}
                    >
                      <AllocationBookingDeleteForm onCloseDeleteWindow={handleDeleteModalClose} />
                    </ModalWindow>
                  </div>
                ) : null}
              </div>
            ) : null}
            {isTimeOffModalOpen && !onCellClickWindowOpen && !isBookingOrTimeOffAddedByButton ? (
              <div>
                <ModalWindow
                  headerTitle={AllocationComponentsTitles.AllocationEditTimeOffWindow}
                  style={{ width: allocationModalWindowWidth }}
                  onMore={handleDeleteButtonClick}
                  onClose={handleEventCalendarModalsClose}
                >
                  <AllocationTimeOffEditForm
                    onClose={handleEventCalendarModalsClose}
                    showDeleteModal={showDeleteModal}
                  />
                </ModalWindow>
                {showDeleteModal ? (
                  <div className="delete-modal">
                    <ModalWindow
                      headerTitle={AllocationComponentsTitles.AllocationDeleteTimeOffWindow}
                      style={{
                        width: allocationDeleteTimeOffModalWidth,
                        height: deleteBookingModalHeight
                      }}
                      onClose={() => handleDeleteModalClose(false)}
                    >
                      <AllocationTimeOffDeleteForm onClose={handleDeleteModalClose} />
                    </ModalWindow>
                  </div>
                ) : null}
              </div>
            ) : null}
            {onCellClickWindowOpen && !isBookingOrTimeOffAddedByButton ? (
              <ModalWindow
                style={{ width: allocationModalWindowWithTabsWidth }}
                onClose={handleEventCalendarModalsClose}
                className="window-with-tabs"
              >
                <AllocationModalWindowWithTabs
                  onClose={handleEventCalendarModalsClose}
                  clickCellDate={clickCellDate as Date}
                  clickCellName={clickCellName as number}
                  setIsTimeOffModalOpen={setIsTimeOffModalOpen}
                  setIsBookingModalOpen={setIsBookingModalOpen}
                />
              </ModalWindow>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllocationsPage;
