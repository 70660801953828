import {
  borderRadiusCommon,
  projectTitleHeight,
  stickyUtilsContainerHeight
} from 'components/sharedComponents/StyleConsts/styleConsts';
import { BACKGROUND_COLOR, BORDERS_COLOR, LOW_EMPHASISE_COLOR, TEXT_COLOR } from 'constants/colors';
import styled from 'styled-components';

const StyledProjectDetails = styled.div({
  p: {
    margin: '0',
    color: TEXT_COLOR
  },
  h3: {
    color: LOW_EMPHASISE_COLOR
  },
  width: 'auto',
  backgroundColor: BACKGROUND_COLOR,
  '.top-panel': {
    height: '64px',
    borderBottom: 'none'
  },
  '.main-content': {
    backgroundColor: BACKGROUND_COLOR,
    position: 'relative',
    paddingBottom: '20px'
  },
  '.sticky-padding-wrapper': {
    position: 'sticky',
    top: 0,
    paddingInline: '20px',
    backgroundColor: BACKGROUND_COLOR,
    zIndex: 10
  },
  '.content-header-border': {
    border: '1px solid',
    height: projectTitleHeight,
    zIndex: 4,
    backgroundColor: BACKGROUND_COLOR,
    borderColor: BORDERS_COLOR,
    h3: {
      margin: '0',
      color: TEXT_COLOR
    }
  },
  '.content-wrapper': {
    border: 'solid 1px',
    borderTop: 'none',
    margin: '0 20px 0 20px',
    borderColor: BORDERS_COLOR
  },
  '.content-header': {
    padding: '24px ',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '.content-header-text': {
      display: 'flex',
      flexDirection: 'row'
    }
  },
  '.content-description': {
    padding: '8px 24px 24px 24px'
  },
  '.project-row': {
    display: 'flex',
    flexDirection: 'row',
    p: {
      textAlign: 'center',
      alignSelf: 'center'
    }
  },
  '.status-icon': {
    width: '12px',
    height: '12px',
    borderRadius: '100%',
    alignSelf: 'center',
    marginRight: '12px'
  },
  '.color': {
    width: '6px',
    height: '32px',
    borderRadius: borderRadiusCommon,
    alignSelf: 'center',
    marginRight: '12px'
  },
  '.content-header-buttons': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '250px',
    button: {
      background: 'none',
      border: 'none',
      cursor: 'pointer'
    },
    '.allocation-button': {
      display: 'flex',
      flexDirection: 'row',
      p: {
        marginLeft: '5px',
        alignSelf: 'center'
      },
      img: {
        alignSelf: 'center'
      }
    }
  },
  '.switch-row': {
    display: 'flex',
    flexDirection: 'row',
    p: {
      margin: '0',
      alignSelf: 'center'
    }
  },
  '.swith-label': {
    marginBottom: '2px'
  },
  '.start-end-date': {
    display: 'flex'
  },
  '.end-date': {
    marginLeft: '10%'
  },
  '.billing-period-button': {
    padding: '0',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    p: {
      color: LOW_EMPHASISE_COLOR,
      textAlign: 'start',
      margin: 0
    }
  },
  '.azdo-alias': {
    '.button-with-icon': {
      backgroundColor: 'transparent',
      border: 'none',
      marginLeft: '-6px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      textAlign: 'left',
      maxWidth: '100%'
    }
  },
  '.discount-button': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    paddingInline: '0px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    ':hover': {
      backgroundColor: BORDERS_COLOR,
      borderRadius: '5px'
    }
  },
  '.tooltip-cell-container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    paddingInline: '0px',
    lineHeight: '14px'
  },
  '.tooltip-container': {
    marginLeft: '4px'
  },
  '.cell-button': {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    width: '120px',
    textAlign: 'end',
    marginRight: '12px',
    paddingRight: '8px',
    marginTop: '11px',
    paddingTop: '5px',
    paddingBottom: '5px',
    border: 'none',
    ':hover': {
      backgroundColor: BORDERS_COLOR,
      borderRadius: '5px'
    }
  },
  '.cell-button-cost': {
    color: LOW_EMPHASISE_COLOR
  },
  '.sprint-length-button': {
    padding: '0',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    width: '100%',
    textAlign: 'start',
    display: 'flex',
    height: '20px',
    '.button-with-icon': {
      marginLeft: '5px',
      marginTop: '-1px'
    }
  },
  '.stick-utils-button-wrapper': {
    position: 'sticky',
    height: stickyUtilsContainerHeight,
    backgroundColor: BACKGROUND_COLOR,
    top: projectTitleHeight,
    zIndex: 3,
    paddingInline: '20px'
  },
  '.month-switch': {
    height: 65
  }
});

export default StyledProjectDetails;
