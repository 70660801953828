import styled from 'styled-components';
import { LOW_EMPHASISE_COLOR, PRIMARY_COLOR, SURFACE_COLOR } from 'constants/colors';
import { borderRadiusCommon } from 'components/sharedComponents/StyleConsts/styleConsts';
import { GREY_THIRTY_FIVE } from 'constants/groupColors';

export const editRateCardStyle = {
  background: GREY_THIRTY_FIVE,
  marginTop: '20px',
  marginLeft: '15px',
  width: '92%',
  cursor: 'auto'
};

export const updateForecastCardStyle = {
  background: GREY_THIRTY_FIVE,
  marginTop: '20px',
  marginLeft: '15px',
  width: '260px',
  cursor: 'auto'
};

const StyledItemCard = styled.div({
  backgroundColor: SURFACE_COLOR,
  padding: '12px',
  width: '294px',
  display: 'flex',
  alignItems: 'center',
  borderRadius: borderRadiusCommon,
  boxShadow: '2px 2px 15px 0px #4040550d',
  position: 'relative',
  '&.selected': {
    border: '1px solid',
    borderColor: PRIMARY_COLOR
  },
  ':hover': {
    cursor: 'pointer'
  },
  gap: '8px',
  '.info-box': {
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
    '.info-box_text': {
      padding: 0,
      margin: 0,
      color: PRIMARY_COLOR
    },
    '.position': {
      color: LOW_EMPHASISE_COLOR
    }
  },
  '.color-block': {
    width: '6px',
    height: '32px',
    borderRadius: '4px'
  },
  '.inactive': {
    color: LOW_EMPHASISE_COLOR,
    margin: '0',
    position: 'absolute',
    top: '15px',
    left: '250px'
  },
  '.client-color': {
    width: '6px',
    height: '32px',
    borderRadius: borderRadiusCommon,
    marginRight: '12px'
  },
  '.client-info-box': {
    display: 'flex',
    flexDirection: 'row',
    '.info-box_text': {
      alignSelf: 'center'
    },
    width: '100%'
  },
  '.history-rates-cards': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '98%'
  },
  '.client-name': {
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    maxWidth: '85%'
  },
  '.employee-name': {
    overflow: 'hidden',
    display: 'block',
    textOverflow: 'ellipsis',
    maxWidth: '85%'
  },
  '.employee-card': {
    width: '100%'
  },
  '.forecast-hours-sum': {
    marginLeft: 'auto',
    marginRight: '0',
    textAlign: 'right'
  },
  '.history_rate_date': {
    width: '30%'
  },
  '.history_rate_text': {
    width: '70%',
    wordWrap: 'break-word',
    display: 'inline-block',
    marginRight: '5px'
  }
});

export default StyledItemCard;
