import PrpIcon from 'shared/PrpIcon/PrpIcon';
import StyledTooltip from 'components/sharedComponents/Tooltip/StyledTooltip';
import { MouseEventHandler } from 'react';

type customTooltipProps = {
  icon?: string;
  onClose: () => void;
  onOpen: () => void;
  isTooltipOpen: boolean;
  title: string | JSX.Element;
  className?: string;
  buttonClassName?: string;
  label?: string | JSX.Element;
  placement: string;
  reasonTooltip?: boolean;
  onClick?: () => void;
  isEmployeeTooltip?: boolean;
};
const CustomTooltip = ({
  icon,
  isTooltipOpen,
  onOpen,
  onClose,
  title,
  className,
  buttonClassName,
  label,
  placement,
  onClick,
  reasonTooltip = false,
  isEmployeeTooltip = false
}: customTooltipProps) => {
  const handleButtonClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick();
    }
  };
  return (
    <StyledTooltip
      open={isTooltipOpen}
      onClose={onClose}
      onOpen={onOpen}
      title={title}
      className={className}
      placement={placement}
      reasonTooltip={reasonTooltip}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: isEmployeeTooltip && [0, -14]
              }
            }
          ]
        }
      }}
    >
      <button
        type="button"
        className={buttonClassName || 'button-with-icon'}
        onClick={handleButtonClick}
      >
        {icon ? <PrpIcon src={icon} height="22px" width="22px" /> : label}
      </button>
    </StyledTooltip>
  );
};
export default CustomTooltip;
