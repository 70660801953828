import AllocationDeletePublicHolidayDto from 'types/allocation/dto/allocationPublicHolidayDto/AllocationDeletePublicHolidayDto';

const AllocationDeletePublicHolidayFormDefaultValue = (
  id: number
): AllocationDeletePublicHolidayDto => {
  return {
    holidayId: id,
    deleteAllHolidaysThisDay: false
  };
};

export default AllocationDeletePublicHolidayFormDefaultValue;
