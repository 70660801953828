import ButtonsText from 'types/generalEnums/ButtonsText';
import StyledForm from 'components/sharedComponents/StyledForm/StyledForm';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ChangeEvent, FormEvent, useState } from 'react';
import Grid from '@mui/material/Grid';
import InvoiceType from 'types/project/enums/InvoiceType';
import generateInvoice from 'store/action/projectActions/generateInvoice';
import { logError } from 'shared/errorHandling/ErrorToast/errorHandling';
import { useThunkAppDispatch } from 'store/redux-hooks/hooks';
import getInvoiceDictionaries from 'store/action/invoiceAction/getInvoiceDictionaries';
import { unwrapResult } from '@reduxjs/toolkit';
import CustomModalButtons from 'components/sharedComponents/CustomModalButton/CustomModalButton';
import { InvoicePermissionsDto } from 'types/project/dto/InvoicePermissionsDto';
import useEscapeKey from 'store/customHooks/useEscapeKey';
import BillingType from 'types/project/enums/BillingType';

type SelectInvoiceTypeFormProps = {
  onClose: () => void;
  refreshProjectData: () => Promise<void>;
  billingPeriodId: number;
  billingType: BillingType | null;
  invoicePermissions: InvoicePermissionsDto;
};
const SelectInvoiceTypeForm = ({
  onClose,
  refreshProjectData,
  billingPeriodId,
  billingType,
  invoicePermissions
}: SelectInvoiceTypeFormProps) => {
  const [invoiceType, setInvoiceType] = useState<string>(InvoiceType.INVOICE);
  const dispatch = useThunkAppDispatch();
  const isProjectBilledOnForecast = billingType === BillingType.FORECAST;
  const isRegularInvoiceVisible = invoicePermissions.canGenerateInvoice;
  const isPreInvoiceVisible = invoicePermissions.canGeneratePreInvoice;
  const isCorrectionInvoiceVisible = invoicePermissions.canGenerateCorrectionInvoice;
  useEscapeKey(onClose);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInvoiceType((event.target as HTMLInputElement).value);
  };

  const onGenerateRegularInvoiceButtonClick = async () => {
    const projectRelatedInvoiceType = isProjectBilledOnForecast
      ? InvoiceType.PRE_INVOICE
      : InvoiceType.INVOICE;

    try {
      const generateInvoiceResult = await dispatch(
        generateInvoice({
          billingPeriodId,
          invoiceType: projectRelatedInvoiceType
        })
      )
        .unwrap()
        .then();

      if (generateInvoiceResult.invoiceId) {
        dispatch(getInvoiceDictionaries(generateInvoiceResult.invoiceId ?? 0))
          .then(unwrapResult)
          .catch(() => {
            logError('Error fetching invoice dictionaries');
          });
      }
      await refreshProjectData();
      onClose();
    } catch (error) {
      logError('Error generating invoice');
    }
  };

  const onGenerateCorrectionInvoiceButtonClick = async () => {
    try {
      const correctionInvoiceResult = await dispatch(
        generateInvoice({
          billingPeriodId,
          invoiceType: InvoiceType.CORRECTION_INVOICE
        })
      ).unwrap();
      if (correctionInvoiceResult.invoiceId) {
        dispatch(getInvoiceDictionaries(correctionInvoiceResult.invoiceId ?? 0))
          .then(unwrapResult)
          .catch(() => {
            logError('Error fetching invoice dictionaries');
          });
      }
      await refreshProjectData();
      onClose();
    } catch (error) {
      logError('Error generating correction invoice');
    }
  };

  const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    switch (invoiceType) {
      case InvoiceType.INVOICE:
        await onGenerateRegularInvoiceButtonClick();
        break;
      case InvoiceType.CORRECTION_INVOICE:
        await onGenerateCorrectionInvoiceButtonClick();
        break;
      default:
        break;
    }
  };

  const getSubmitButtonName = () => {
    if (invoiceType === InvoiceType.INVOICE) {
      return ButtonsText.GenerateInvoice;
    }
    return ButtonsText.CreateCorrectionInvoice;
  };

  return (
    <StyledForm onSubmit={onSubmit}>
      <FormControl>
        <RadioGroup
          aria-labelledby="select-invoice-type"
          defaultValue={InvoiceType.INVOICE}
          name="radio-buttons-group"
          onChange={handleChange}
          value={invoiceType}
          className="radio-buttons-invoice modal-container"
        >
          {(isRegularInvoiceVisible || isPreInvoiceVisible) && (
            <Grid item xs={12} md={12} className="radio-button-container">
              <FormControlLabel
                value={InvoiceType.INVOICE}
                control={<Radio />}
                label={isRegularInvoiceVisible ? 'Regular Invoice' : 'Regular PreInvoice'}
              />
              <p className="inter-caption_medium radio-button-description">
                {isRegularInvoiceVisible
                  ? 'Generate an invoice based on hours'
                  : 'Generate a preinvoice based on hours'}
              </p>
            </Grid>
          )}
          {isCorrectionInvoiceVisible && (
            <Grid item xs={12} md={12} className="radio-button-container">
              <FormControlLabel
                value={InvoiceType.CORRECTION_INVOICE}
                control={<Radio />}
                label="Correction Invoice"
              />
              <p className="inter-caption_medium radio-button-description">
                Generate an invoice with correction
              </p>
            </Grid>
          )}
        </RadioGroup>
      </FormControl>
      <CustomModalButtons
        submitButtonText={getSubmitButtonName()}
        isCancelButtonVisible
        submitButtonClassName="add-button"
        onClick={onClose}
        className="add-buttons"
      />
    </StyledForm>
  );
};

export default SelectInvoiceTypeForm;
