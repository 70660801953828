import { ProjectDictionariesState } from 'store/slicesState/projectDictionaries';
import DictionariesSelector from 'types/employee/enums/DictionariesSelector';
import BillingType from 'types/project/enums/BillingType';

const selectBillingTypesPropertyById = (
  state: ProjectDictionariesState,
  billingTypeName: BillingType,
  property: DictionariesSelector.Name | DictionariesSelector.DisplayName
): string | null => {
  const billingTypesDictionary = state.data?.billingTypes;
  if (!billingTypesDictionary) {
    return null;
  }
  const billingType = billingTypesDictionary.find((b) => b.name === billingTypeName);
  return billingType?.[property] ?? null;
};

export default selectBillingTypesPropertyById;
